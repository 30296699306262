import React from 'react'
import { Col } from 'reactstrap'

const Colxx = (props: any) => {
  return (
    <Col {...props} widths={['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']} />
  )
}

export default Colxx
