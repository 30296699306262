import React from 'react'
import { Row, Col, Card, CardBody, Container } from 'reactstrap'
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import queryString from 'querystring'
import Spinner from '../components/Spinner'
import I18nMessages from '../components/I18nMessages'
import { InjectedBenServiceProps, withBenService, ActionCode } from '../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import { useMountedState } from '../lib'

import appLogo from '../assets/media/logo.svg'
import getMessage from '../language/getMessage'

type Props = InjectedBenNotificationProps
  & InjectedBenServiceProps
  & InjectedIntlProps
  & RouteComponentProps

enum ConfirmationStatus {
  ok,
  badCode,
  internalError
}

function getCodeFromQueryParams (search: string): string | undefined {
  const params = queryString.parse(search.replace('?', ''))

  if (params['code']) {
    return params['code'] as string
  }

  return
}

function mapActionResponseCode (code: ActionCode | undefined): ConfirmationStatus | undefined {
  switch (code) {
    case ActionCode.ok: return ConfirmationStatus.ok
    case ActionCode.notFound: return ConfirmationStatus.badCode

    default:
      return ConfirmationStatus.internalError
  }
}

const EmailConfirmPage: React.FC<Props> = ({
  benNotification,
  benService,
  intl,
  location
}) => {

  const isMounted = useMountedState()
  const [isLoading, setLoading] = React.useState(true)
  const [status, setStatus] = React.useState<ConfirmationStatus | undefined>()

  const benServiceRef = React.useRef(benService)

  React.useEffect(() => {
    const code = getCodeFromQueryParams(location.search)

    if (code) {
      benServiceRef.current.emailConfirm(code)
        .then(result => isMounted() && setStatus(mapActionResponseCode(result.code)))
        .then(() => benServiceRef.current.getUser(intl.locale))
        .catch(error => isMounted() && setStatus(mapActionResponseCode(error.code)))
        .finally(() => isMounted() && setLoading(false))
    } else if (isMounted()) {
      setLoading(false)
      setStatus(ConfirmationStatus.badCode)
    }
  }, [isMounted, location.search, benServiceRef, intl])

  React.useEffect(() => {
    if (status === ConfirmationStatus.internalError) {
      benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
      })
    }
  }, [status, benNotification, intl])

  React.useEffect(() => {
    document.body.classList.add('background')

    return () => {
      document.body.classList.remove('background')
    }
  }, [])

  return (
    <div className="fixed-background">
      <main>
        <Container>
          <Row className="h-100">
            <Col md="6" className="mx-auto my-auto">
              <Card>
                <CardBody>
                  <div className="benjamin-logo-container mb-4">
                    <img src={appLogo} className="login-logo" alt="logo" />
                  </div>

                  {isLoading && (
                    <div><Spinner /></div>
                  )}

                  {(status === ConfirmationStatus.ok) && (
                    <p className="text-success text-center">
                      <I18nMessages id="email-confirmation-page.successful-confirm" />
                    </p>
                  )}

                  {(status === ConfirmationStatus.badCode) && (
                    <p className="text-danger text-center">
                      <I18nMessages id="email-confirmation-page.bad-code-label" />
                    </p>
                  )}

                  {(status === ConfirmationStatus.internalError) && (
                    <p className="text-center">
                      <I18nMessages id="side-effect.internal-error-message" />
                    </p>
                  )}

                  {!isLoading && (
                    <div className="text-center mt-4">
                      <NavLink className="btn btn-primary btn-lg" to="/">
                        <I18nMessages id="email-confirmation-page.button-home-page-label" />
                      </NavLink>
                    </div>
                  )}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  )
}

export default withBenNotification(withBenService(withRouter(injectIntl(EmailConfirmPage))))
