import React from 'react'

type Props = {
  label?: string
}

const spinner: React.FC<Props> = ({
  label
}) => {

  return (
    <div className="p-4">
      <div className="loading" style={{ position: 'relative' }}>
        {label && <span className="sr-only">{label}</span>}
      </div>
    </div>
  )
}

export default spinner
