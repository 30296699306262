import React from 'react'
import { ChartData } from 'chart.js'
import Chart from 'react-chartjs-2'
import I18nMessages from '../components/I18nMessages'
import { maxStringLength, getColorForChartBackground, PieChartDefaults } from '../lib'
import { TopBundle } from '../providers/benServiceProvider'
import Spinner from '../components/Spinner'

type Props = {
  topBundle: TopBundle | null | undefined
}

const TopDomainsChart: React.FC<Props> = ({
  topBundle
}) => {

  function getChartData(bundle: TopBundle): ChartData 
  {
    const data: ChartData = {
      labels: bundle.domains.data.map(topDomain => maxStringLength(topDomain.domain, 18)),
      datasets: [{
        borderWidth: 1,
        label: 'Percent',
        backgroundColor: bundle.domains.data.map((_, index) => getColorForChartBackground(index)),
        data: bundle.domains.data.map(topDomain => topDomain.percent)
      }]
    }

    return data
  }
 
  return (

    <React.Fragment>

      { topBundle === undefined && (
        <div className="chart-container d-flex align-items-center justify-content-center text-center">
          <Spinner/>
        </div>
      )}

      {  (topBundle && topBundle.domains.data.length === 0) && (
          <div className="chart-container d-flex align-items-center justify-content-center text-center">
            <I18nMessages id="no-data.label-mid" />
          </div>
      )}

      { ( topBundle && topBundle.domains.data.length !== 0 ) && (

        <div className="chart-container">
          <Chart
            type="pie"
            legend={PieChartDefaults.legend}
            options={PieChartDefaults.options}
            data={getChartData(topBundle)}
          />
        </div>
      )}

    </React.Fragment>
  )
}

export default TopDomainsChart
