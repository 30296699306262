import React from 'react'

interface NotificationProps {
  type?: 'info' | 'success' | 'warning' | 'error' | 'primary' | 'secondary'
  title?: React.ReactNode
  message: React.ReactNode,
  timeOut?: number
  onClick?: () => void
  onRequestHide?: () => void
  className?: string
}

class Notification extends React.Component<NotificationProps> {

  static defaultProps: NotificationProps = {
    type: 'info',
    title: null,
    message: null,
    timeOut: 5000,
    onClick: () => undefined,
    onRequestHide: () => undefined,
    className: ''
  }

  private timer: number | undefined

  componentDidMount () {
    if (this.props.timeOut !== 0) {
      this.timer = setTimeout(this.handleRequestHide, this.props.timeOut)
    }
  }

  componentWillUnmount () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  private handleClick = () => {
    this.props.onClick && this.props.onClick()
  }

  private handleRequestHide = () => {
    this.props.onRequestHide && this.props.onRequestHide()
  }

  render () {
    const className = `notification filled notification-${this.props.type} ${this.props.className}`
    return (
      <div className={className} onClick={this.handleClick}>
        <div className="notification-message" role="alert">
          {this.props.title && <span>{this.props.title}</span>}
          <div className="message">{this.props.message}</div>
        </div>
      </div>
    )
  }
}

export default Notification
