import React from 'react'
import { IntlProvider } from 'react-intl'
import i18n, { Locales, LanguageMessagesKeys } from '../language'
import { getDefaultLang, setDefaultLang } from '../lib/utils'

export type Locale = keyof Locales

interface I18nProviderState {
  locale: Locale
}

export interface I18nContextValue {
  locale: Locale
  locales: Locales
  changeLocale: (locale: Locale) => void
}

export interface InjectedI18nProviderProps {
  i18nProvider: I18nContextValue
}

export const I18nContext = React.createContext<I18nContextValue>({
  changeLocale: () => undefined,
  locales: i18n,
  locale: getDefaultLang() as Locale
})



export class I18nProvider extends React.Component<{}, I18nProviderState> {

  
  state = { locale: getDefaultLang() as Locale }

  get localeCode (): string {
    return i18n[this.state.locale].locale
  }

  get localeMessages (): LanguageMessagesKeys {
    return i18n[this.state.locale].messages
  }

  get locales (): Locales {
    return i18n
  }

  changeLocale = (locale: Locale) => {
    setDefaultLang(locale)
    this.setState({ locale })
  }

  render () {
    const providerValue: I18nContextValue = {
      changeLocale: this.changeLocale,
      locale: this.state.locale,
      locales: i18n
    }

    return (
      <I18nContext.Provider value={providerValue}>
        <IntlProvider locale={this.localeCode} messages={this.localeMessages}>
          {this.props.children}
        </IntlProvider>
      </I18nContext.Provider>
    )
  }
}

export function withI18nProvider<P> (Component: React.ComponentType<P>) {
  return (props: Omit<P, keyof InjectedI18nProviderProps>) => (
    <I18nContext.Consumer>
      {state => <Component {...props as P} i18nProvider={state} />}
    </I18nContext.Consumer>
  )
}