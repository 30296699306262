import React, {useCallback, MouseEvent } from 'react'
import { RouteComponentProps, withRouter, NavLink, Redirect } from 'react-router-dom'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Row, Col, Card, CardBody, Label, Alert, FormGroup } from 'reactstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCaptcha from 'react-google-recaptcha'
import { Formik, Form, Field } from 'formik'
import AsyncButton from '../components/AsyncButton'
import I18nMessages from '../components/I18nMessages'
import Separator from '../components/Separator'
import CheckboxField from '../containers/CheckboxField'
import {
  InjectedBenServiceProps,
  withBenService,
  ActionCode,
  Agreements,
  AgreementsData
} from '../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import getMessage from '../language/getMessage'
import * as _ from 'underscore'

// Assets
import appLogo from '../assets/media/logo.svg'
import { validateEmail, validateNotEmpty, reCaptchaDefaults } from '../lib'
import { isWebView, callWebViewClose, callWebViewSuccess, getBuildConfigStr, translateByConfigStr } from '../lib/utils'
import ConfirmationModal from '../containers/ConfirmationModal'

import eye from '../assets/media/eye.svg'
import eyeslash from '../assets/media/eye-off.svg'
import MiniRodo from '../components/MiniRodo'
import LanguageDropdown from '../containers/LanguageDropdown'
import { InjectedI18nProviderProps, withI18nProvider } from '../providers/i18nProvider'


type Props = InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps
  & RouteComponentProps
  & InjectedI18nProviderProps

type ErrorAlert = {
  visible: boolean
  reason: string
}

type FormValues = {
  email: string,
  password: string,
  phoneNumber: string,
  reCaptcha: string
}

const initialValue: FormValues = {
  email: '',
  password: '',
  phoneNumber: '',
  reCaptcha: ''
}

const RegisterPage: React.FC<Props> = ({
  benService,
  i18nProvider,
  benNotification,
  history,
  intl
}) => {

  const [confirmationStrongPassModal, setConfirmationStrongPassModal] = React.useState(false)

  const formikRef = React.useRef<Formik<FormValues>>(null)
  const reCaptchaRef = React.useRef<ReCaptcha>(null)
  const [errorAlert, setErrorAlert] = React.useState<ErrorAlert>({ visible: false, reason: '' })
  const [isLoading, setIsLoading] = React.useState(false)
  const [isMounted, setMounted] = React.useState(false)
  const [allAgreements, setAllAgreements] = React.useState<Agreements>({ agreement: [] })
  const [isSendButtonClicked, setSendButtonClicked] = React.useState(false)
  const [passVisible, setPassVisible] = React.useState(false)
  const [inputPhoneNumber, setInputPhoneNumber] = React.useState<string>('')
  const [countryCode, setCountryCode] = React.useState<string|undefined>(undefined)

  //const [tmpGeoStr, setTmpGeoStr] = React.useState<string>('wait...')

  const webView = isWebView() as boolean

  function handleLoginClicked( e : MouseEvent ) : boolean
  {
    if( callWebViewClose() )
    {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation();

      return false
    }

    return true
  }

  function handleOnFormikSubmit (values: any) {
    setIsLoading(true)
    let userAgreements: any = []

    // eslint-disable-next-line
    allAgreements.agreement.map(agreement => {
      if (values['agreement' + agreement.id]) {
        userAgreements.push({
          id: agreement.id,
          v: 1
        })
      } else {
        userAgreements.push({
          id: agreement.id,
          v: 2
        })
      }
    })


    const email = String(values.email)
    const phoneNumber = inputPhoneNumber

    const timeZoneOffset = new Date().getTimezoneOffset()
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

    benService.register(email, values.password, phoneNumber, intl.locale, timeZoneOffset, timeZoneName, values.reCaptcha, userAgreements, !webView)
      .then(() => {
        benNotification.notify({
          type: 'success',
          title: getMessage('register-page.register-success-title', intl),
          message: getMessage('register-page.register-success-message', intl)
        })

        if( webView )
        {
          setTimeout( () => { callWebViewSuccess() }, 3000 );
        }
        else
        {
          benService.getUser(intl.locale).then(() => history.push('/'))
        }
      })

      .catch(result => {
        switch (result.code) {

          case ActionCode.captchaError:
            isMounted && setErrorAlert({
              visible: true,
              reason: getMessage('register-page.register-error-captcha', intl)
            })
            break
          case ActionCode.alreadyRegistered:
            isMounted && setErrorAlert({
              visible: true,
              reason: getMessage('register-page.register-error-already-registered-label', intl)
            })
            break
          case ActionCode.passwordToShort:
            isMounted && setErrorAlert({
              visible: true,
              reason: getMessage('register-page.register-error-password-to-short-label', intl)
            })
            break

          default: return benNotification.notify({
            type: 'error',
            title: getMessage('side-effect.internal-error-title', intl),
            message: getMessage('side-effect.internal-error-message', intl)
          })
        }
      })
      .finally(() => isMounted && setIsLoading(false))
  }

  function isPasswordStrong(values: FormValues) : boolean
  {
    let cntLower = 0
    let cntUpper = 0
    let cntDigit = 0
    //let cntOther = 0
    let set = new Set();

    if( values.password.length < 8 )
      return false

    for( let i = 0; i<values.password.length; i++)
    {
      let c = values.password.charAt(i)

      set.add(c)

      if( c >= '0' && c <= '9')
        cntDigit++
      else if( c >= 'a' && c <= 'z')
        cntLower++
      else if( c >= 'A' && c <= 'Z')
        cntUpper++
      //else
      //  cntOther++
    }

    return cntDigit > 0 && cntLower > 0 && cntUpper > 0 && set.size > 4
  }

  function handleOnSubmitClick (values: FormValues, errors: any) {

    setSendButtonClicked(true)
    setErrorAlert({ visible: false, reason: '' })

    if (Object.keys(errors).length !== 0)
      return

    if( !isPasswordStrong( values ) ) {
      setConfirmationStrongPassModal(true)
      return
    }

    processSubmit();
  }

  function onStrongPassModalOK()
  {
    setConfirmationStrongPassModal(false)
    processSubmit()
  }

  function onStrongPassModalCancel()
  {
    setConfirmationStrongPassModal(false)
  }

  function processSubmit() {

    if (!reCaptchaRef.current) {
      return
    }

    if (reCaptchaRef.current.getValue()) {
      formikRef.current && formikRef.current.submitForm()
    } else {
      reCaptchaRef.current.execute()
    }

  }

  function handleOnReCaptchaChange (value: string | null) {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reCaptcha', value || '')
      value !== null && formikRef.current.submitForm()
    }
  }

  function handleOnReCaptchaExpired () {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reCaptcha', '')
    }
  }

  function isSubmitBtnDisabled (values: FormValues): boolean {

    return false

    // if (values.email.length || values.password.length || values.phoneNumber.length) {
    //   return false
    // }

    // return true
  }

  function handleOnToggleAlert () {
    setErrorAlert({ ...errorAlert, visible: !errorAlert.visible })
  }

  function agreementComponent (props: any): JSX.Element {
    return <CheckboxField
      {...props}
      label={props.label}
    />
  }

  function toggleVisibility(e: React.MouseEvent)
  {
    e.preventDefault()
    e.stopPropagation()
    setPassVisible(!passVisible)
  }

  React.useEffect(() => {
    document.body.classList.add('background')

    return () => {
      document.body.classList.remove('background')
    }
  }, [])

  React.useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
    }
  }, [])

  const getAllAgreements = useCallback(() => {
    setIsLoading(true)

    benService.getAllAgreements(intl.locale)
      .then(result => setAllAgreements(result.data))
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line

    // w przypadku bledu, nieznanego IP, lub innego nieszczescia, funkcja zwraca we wszystkich polach '-'
    benService.geo()
      .then(result => {
        if (result?.data?.countryCode !== '-' ) {
          setCountryCode(result.data.countryCode)
        }
        else {
          setCountryCode(i18nProvider.locale)
        }

        // setTmpGeoStr(result.data.countryCode + ', ' +
        //              result.data.countryName + ', ' +
        //              result.data.area + ', ' +
        //              result.data.city )
      })

  }, [benService, intl.locale, i18nProvider.locale])

  function getAgreementDescription( agreement : AgreementsData ) : string
  {
    if( agreement.short_name === undefined || agreement.short_name === 'null' || agreement.short_name === null )
    {
      return agreement.descr
    }

    //return intl.formatMessage( {id:agreement.short_name} );
    return translateByConfigStr( agreement.short_name, intl )
  }


  React.useEffect(() => {
    getAllAgreements()
  }, [getAllAgreements])

  if (benService.isAuthorized) {
    return <Redirect to="/" />
  }

  return (
    <div className="fixed-background auto-scroll">

      <div className="fixed-top m-2"  >
          <LanguageDropdown/>
      </div>

      <ReCaptcha
        ref={reCaptchaRef}
        sitekey={reCaptchaDefaults.sitekey}
        size="invisible"
        onChange={handleOnReCaptchaChange}
        onExpired={handleOnReCaptchaExpired}
        hl={intl.locale}
      />


      <ConfirmationModal
        isOpen={confirmationStrongPassModal}
        onCancel={onStrongPassModalCancel}
        onConfirm={onStrongPassModalOK}
      ><I18nMessages id="register-page.strong-pass"/></ConfirmationModal>

      <main>
        <div className="container">
          <Row className="h-100">
            <Col md="8" lg="6" className="mx-auto my-auto">
              <Card>
                <CardBody>

                  { !webView && (
                  <div className="benjamin-logo-container">
                    <img src={appLogo} className="login-logo" alt="logo" />
                  </div>
                  )}

                  <div>
                  <p className="text-center mb-4 h5"><I18nMessages id="register-page.description"/></p>

                    <Alert color="danger" isOpen={errorAlert.visible} toggle={handleOnToggleAlert}>
                      <span>{errorAlert.reason}</span>
                    </Alert>
                  </div>

                  <Formik <any> ref={formikRef} initialValues={initialValue} onSubmit={handleOnFormikSubmit}>
                    {({ errors, touched, values, setFieldValue }) => (
                      <Form className="av-tooltip tooltip-label-bottom">
                        <FormGroup >
                          <Label className="form-group has-float-label auth-card">
                            <I18nMessages id="register-page.email-label"/>
                            <Field
                              name="email"
                              type="email"
                              className="form-control"
                              validate={(value: string) => validateEmail(value, intl)}
                            />
                            {(errors.email && touched.email) && (
                              <div className="invalid-feedback d-block">{errors.email}</div>
                            )}
                          </Label>
                        </FormGroup>

                        <FormGroup >
                          <Label className="form-group has-float-label auth-card">
                            <span className="show-pass-ico"> <img src={ passVisible ? eye : eyeslash} onClick={ (e)=>toggleVisibility(e) } alt="eye" /> </span>

                            <span>
                              { intl.formatMessage({ id: 'register-page.password-label' }, { app:getBuildConfigStr('NAME') } ) }
                            </span>

                            <Field
                              name="password"
                              type={passVisible ? "input" : "password" }
                              className="form-control"
                              validate={(value: boolean) => validateNotEmpty(value, intl)}
                            />
                            {(errors.password && touched.password) && (
                              <div className="invalid-feedback d-block">
                                {errors.password}
                              </div>
                            )}
                          </Label>
                        </FormGroup>

                        <FormGroup >

                          {/* Geo: {tmpGeoStr} */}

                          <Label className="form-group has-float-label auth-card">
                            <I18nMessages id="register-page.phoneNumber-label" />

                            <PhoneInput
                              inputClass="form-control"
                              country={countryCode?.toLowerCase()}
                              value={inputPhoneNumber}
                              copyNumbersOnly={false}
                              onChange={(value, country, e, formattedValue) => {
                                setInputPhoneNumber(`+${value}`)
                              }}
                            />
                          </Label>
                        </FormGroup>

                        {allAgreements.agreement.map(agreement =>
                          <FormGroup className="custom-form-check" key={`${agreement.id}`}>
                              <Field
                                  id={'agreement' + agreement.id}
                                  name={'agreement' + agreement.id}
                                  component={agreementComponent}
                                  validate={(value: boolean) => agreement && agreement.required !== '0' ? validateNotEmpty(value, intl) : null}
                              />
                              <Label for={'agreement' + agreement.id} dangerouslySetInnerHTML={{ __html: _.unescape( getAgreementDescription(agreement) ) }}/>
                            {(errors['agreement' + agreement.id] && (touched['agreement' + agreement.id] || isSendButtonClicked)) && (
                              <div style={{ marginTop:30 }} className="invalid-feedback d-block">
                                {errors['agreement' + agreement.id]}
                              </div>
                            )}
                          </FormGroup>
                        )}

                        <Separator className="my-4" />

                        <div className="d-flex justify-content-between align-items-center ">
                          <NavLink to="/login" onClick={ (e) => handleLoginClicked(e)} >
                            <I18nMessages id="register-page.login-link-label" />
                          </NavLink>
                          <AsyncButton
                            disabled={isSubmitBtnDisabled(values)}
                            color="primary"
                            showSpinner={isLoading}
                            onClick={() => handleOnSubmitClick(values, errors)}
                          >
                            <I18nMessages id="register-page.register-button" />
                          </AsyncButton>
                      </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>

              <MiniRodo inline={true} />

              <div style={{ height:100 }}></div>

              { webView  && (
                <div style={{ height:150 }}></div>
              ) }

            </Col>
          </Row>

          </div>



      </main>

    </div>
  )
}

export default withRouter(injectIntl(withBenNotification(withBenService(withI18nProvider(RegisterPage)))))
