import React from 'react';
import { InjectedIntl, injectIntl } from 'react-intl';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

import SearchIcoBing from '../../../../assets/media/SearchIcoBing.png';
import SearchIcoDuckDuckGo from '../../../../assets/media/SearchIcoDuckDuckGo.png';
import SearchIcoGoogle from '../../../../assets/media/SearchIcoGoogle.png';
import SearchIcoLycos from '../../../../assets/media/SearchIcoLycos.png';
import SearchIcoOther from '../../../../assets/media/SearchIcoOther.png';
import SearchIcoYahoo from '../../../../assets/media/SearchIcoYahoo.png';
import SearchIcoYouTube from '../../../../assets/media/SearchIcoYouTube.png';
import { toLocaleDateTime } from '../../../../lib/utils';
interface KeywordItemProps  {
  index: number
  date: Date
  engine: string
  keyword: string
  url: string
  intl: InjectedIntl
}

function getImg(engineName: string )
{
  switch(engineName)
  {
    case 'Google':      return SearchIcoGoogle;
    case 'Bing!':       return SearchIcoBing;
    case 'YouTube':     return SearchIcoYouTube;
    case 'DuckDuckGo':  return SearchIcoDuckDuckGo;
    case 'Yahoo!':      return SearchIcoYahoo;
    case 'Lycos':       return SearchIcoLycos;
    default:            return SearchIcoOther;
  }
}

const KeywordItem = ({
  index,
  date,
  engine,
  keyword,
  url,
  intl
}: KeywordItemProps) => (

  <div className={`pb-1 border-bottom`}>
    <Row>
      <Col className="col-1" style={{margin: "auto"}}>
        <div >
          <img id={'img'+String(index)} className="search-ico-container" src={getImg(engine)} alt={engine} />
        </div>
        <UncontrolledTooltip placement="right" target={'img'+String(index)} >
           { engine }
        </UncontrolledTooltip>
      </Col>
      <Col className="d-flex">
        <div>
        <p className="font-weight-medium mb-1">{keyword}</p>
        <p className="text-muted mb-1 text-small">{ toLocaleDateTime(date,intl) }</p>
        </div>
      </Col>
    </Row>
  </div>
)

export default injectIntl(KeywordItem)
