import React from 'react'
import I18nMessages from '../components/I18nMessages'
import { ContactData } from '../providers/benServiceProvider'
import { HtmlEntities } from '../lib/HtmlEntities'
import { toLocaleDateTime } from '../lib/utils'
import { InjectedIntlProps, injectIntl } from 'react-intl'

type SMSType = 'incoming' | 'outgoing'

export type SMS = {
  date: Date
  id: string
  message: string
  phoneNo: string
  type: SMSType
}

type Props = InjectedIntlProps & {
  data: SMS[]
  contacts: ContactData[]
}

const SMSLog: React.FC<Props> = ({
  data,
  contacts,
  intl
}) => {

  const entities = new HtmlEntities()

  function mapTypeToIconClassName (type: SMSType): string {
    return type === 'incoming' ? 'iconsminds-mail-inbox' : 'iconsminds-mail-outbox'
  }

  function mapTypeToTextColorClassName (type: SMSType): string {
    return type === 'incoming' ? 'direction-in' : 'direction-out'
  }

  // function sortByDate (a: SMS, b: SMS) {
  //   return b.date.getTime() - a.date.getTime()
  // }


  function buildMap(contacts: ContactData[])
  {
    let m = new Map<string, string>()

    contacts.forEach(item => {

      if( item.text.length > 0 && item.name.length > 0 )
        m.set( item.text, item.name );

      if( item.text2.length > 0 && item.name.length > 0)
        m.set( item.text2, item.name );
      
    });

    return m
  }

  function getName(map : Map<string, string>,  phone: string)
  {
    if( phone.length < 1 )
      return phone

    if( map.has(phone) )
      return entities.decode( map.get(phone) )

    return ""
  }
  

  const namesMap = buildMap(contacts)
    

  if (data.length === 0) {
    return (
      <div className="d-flex p-4 align-items-center justify-content-center text-center">
        <I18nMessages id="empty-list-label" />
      </div>
    )
  }

  return (
    <React.Fragment>
      {data.map(item => (
        <div key={item.id} className="d-flex mb-4 pb-4 border-bottom">
          <div className="px-2 align-self center">
            <i
              className={`${mapTypeToIconClassName(item.type)} ${mapTypeToTextColorClassName(item.type)}`}
              style={{ fontSize: '24px' }}
            />
          </div>
          <div className={`ml-3 ${mapTypeToTextColorClassName(item.type)}`}>
            <p className="mb-0 list-item-heading">{ getName(namesMap, item.phoneNo) }</p>
            <p className="mb-0 list-item-heading">{item.phoneNo}</p>            
            <p className="mb-2 text-muted">{ toLocaleDateTime(item.date, intl) }</p>
            <p className="text-body">{ entities.decode(item.message) }</p>
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

export default injectIntl(SMSLog)
