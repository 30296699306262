import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import I18nMessage from '../components/I18nMessages'
import HamburgerButton from '../components/HamburgerMenu'
import { InjectedBenServiceProps, withBenService } from '../providers/benServiceProvider'
import { InjectedI18nProviderProps, withI18nProvider } from '../providers/i18nProvider'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import getMessages from '../language/getMessage'
import PlansInfoPage from '../routes/account/PlansInfoPage'
import UpgradePage from '../routes/account/UpgradePage'
import { withBenHelper, InjectedBenHelperProps } from '../providers/benHelperProvider'
import AccountSettingsPage from '../routes/account/AccountSettingsPage'
import LanguageDropdown from './LanguageDropdown'
import AddDevicePage from '../routes/account/AddDevicePage'


type InjectedProps =  InjectedBenHelperProps &
                      InjectedBenServiceProps  & 
                      InjectedI18nProviderProps  & 
                      InjectedBenAccountProps & 
                      InjectedIntlProps & 
                      RouteComponentProps

type TopNavigationProps = InjectedProps & {
  onHamburgerMenuClick: () => void
}

type TopNavigationState = {
  isPlanInfoVisible: boolean
  isAccountSettingsVisible: boolean
  isUpgradeVisible: boolean
  isAddDeviceVisible: boolean
}

// TODO: Move TopNavigation to React.FC component
class TopNavigation extends React.Component<TopNavigationProps, TopNavigationState> {

  constructor (props: TopNavigationProps) {
    super(props)
    this.state = { isPlanInfoVisible: false, isAccountSettingsVisible:false, isUpgradeVisible:false, isAddDeviceVisible:false }
  }


  // get languageName (): string {
  //   return this.props.i18nProvider.locale.toUpperCase()
  // }

  private handleShowPlansInfo = () => {
    this.setState({ ...this.state, isPlanInfoVisible: true })
  }

  private handleShowPlansInfoToggle = () => {
    let s = this.state.isPlanInfoVisible
    this.setState({ ...this.state, isPlanInfoVisible: !s })
  }

  //

  private handleShowAccountSettings  = () => {
    this.setState({ ...this.state, isAccountSettingsVisible: true })
  }

  private handleAccountSettingsToggle = () => {
    let s = this.state.isAccountSettingsVisible
    this.setState({ ...this.state, isAccountSettingsVisible: !s })
  }

  //

  private handleShowPlansCallUpgrade = () => {
    this.setState({ ...this.state, isPlanInfoVisible: false, isAccountSettingsVisible:false, isUpgradeVisible: true, isAddDeviceVisible: false })
  }

  private handleUpgradeInfoToggle = () => {
    let s = this.state.isUpgradeVisible
    this.setState({ ...this.state, isUpgradeVisible: !s })
  }


  private handleShowAddDevice = () => {
    this.setState({ ...this.state, isPlanInfoVisible: false, isAccountSettingsVisible:false, isUpgradeVisible: false, isAddDeviceVisible: true })
  }

  private handleShowAddDeviceToggle = () => {
    const v = this.state.isAddDeviceVisible
    this.setState({ ...this.state, isAddDeviceVisible: !v })
  }

  
  //

  private handleLogout = async () => {
    await this.props.benService.logout()
    this.props.benHelper.setRewardId('')
    this.props.benHelper.setRewardResult(0)
    this.props.benHelper.setPromoCode('')
    this.props.benHelper.setRodoClicked(false)
  }

  private handleChangePassword = () => {
    this.props.history.push('/account/change-password')
  }

  private handleAddDevice = () => {
    // this.props.history.push('/account/add-device')
    this.handleShowAddDevice()
  }

  // private renderLanguageDropdownMenuItems () {
  //   return Object.keys(this.props.i18nProvider.locales).map((code) => {
  //     const locale = code as Locale
  //     const name = this.props.i18nProvider.locales[locale].name
  //     const onClickHandler = () => this.props.i18nProvider.changeLocale(locale)

  //     return (
  //       <DropdownItem key={code} onClick={onClickHandler}>
  //         {name}
  //       </DropdownItem>
  //     )
  //   })
  // }

  render () {
    const profileName = this.props.benAccount.email ? this.props.benAccount.email : ''

    return (
      <nav className="navbar fixed-top">

        { this.state.isPlanInfoVisible && (
          <PlansInfoPage
            isOpen={ true }
            onToggle={ this.handleShowPlansInfoToggle }
            onCallUpgradePlan={this.handleShowPlansCallUpgrade }
          />
        )}

        { this.state.isAccountSettingsVisible && (
          <AccountSettingsPage
            isOpen={ true }
            onToggle={ this.handleAccountSettingsToggle }
          />
        )}

        { this.state.isUpgradeVisible && (
          <UpgradePage
            isOpen={ true }
            onToggle={ this.handleUpgradeInfoToggle }
          />
        )}

        { this.state.isAddDeviceVisible && (
          <AddDevicePage
            isOpen={ true }
            onToggle={ this.handleShowAddDeviceToggle }
          />
        )}

        <HamburgerButton
          label={getMessages('top-navigation.hamburger-menu', this.props.intl)}
          onMenuClick={this.props.onHamburgerMenuClick}
        />

        <LanguageDropdown/>

        {/* Beniamin logotype */}
        <a href="/" className="navbar-logo">
          <span className="logo"></span>
        </a>

        {/* Account data */}
        <div className="ml-auto">
          <div className="header-icons d-inline-block align-middle"></div>
          <div className="d-inline-block">
            <UncontrolledDropdown className="account-menu dropdown-menu-right">
              <DropdownToggle caret className="p-0" color="empty">
                <span className="mr-1 account-menu-label">
                   <I18nMessage id="top-navigation.user-menu.account-label" />              
                </span>
              </DropdownToggle>
              <DropdownMenu className="mt-3">
                <div className="account-email">{profileName}</div>

                <DropdownItem onClick={this.handleAddDevice}>
                  <I18nMessage id="top-navigation.user-menu.add-device-label" />
                </DropdownItem>

                <DropdownItem onClick={this.handleShowPlansInfo}>
                  <I18nMessage id="top-navigation.user-menu.subscription-label" />
                </DropdownItem>

                <DropdownItem onClick={this.handleShowAccountSettings}>
                  <I18nMessage id="account-settings-page.title" />
                </DropdownItem>                

                <DropdownItem onClick={this.handleChangePassword}>
                  <I18nMessage id="top-navigation.user-menu.change-password-label" />
                </DropdownItem>
                <DropdownItem onClick={this.handleLogout}>
                  <I18nMessage id="top-navigation.user-menu.sign-out" />
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </nav>
    )
  }
}

export default withBenHelper( withRouter(injectIntl(withBenService(withI18nProvider(withBenAccount(TopNavigation))))) )
