import React, { KeyboardEvent } from 'react'
import { NavLink, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { Row, Card, Form, Label, Alert } from 'reactstrap'
import ReCaptcha from 'react-google-recaptcha'
import Colxx from '../components/Colxx'
import I18nMessage from '../components/I18nMessages'
import { InjectedBenServiceProps, withBenService, ActionCode } from '../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import getMessage from '../language/getMessage'
import { reCaptchaDefaults } from '../lib'
import { Formik, Field } from 'formik'
//import ReactGA  from 'react-ga'
import appLogo from '../assets/media/logo.svg'
import AsyncButton from '../components/AsyncButton'

import eye from '../assets/media/eye.svg'
import eyeslash from '../assets/media/eye-off.svg'
import MiniRodo from '../components/MiniRodo'
import { withBenHelper, InjectedBenHelperProps } from '../providers/benHelperProvider'
import LanguageDropdown from '../containers/LanguageDropdown'
import { InjectedI18nProviderProps, withI18nProvider, Locale } from '../providers/i18nProvider'
import { getAllowedLanguages } from '../lib/utils'


type LoginPageProps = InjectedBenHelperProps &  RouteComponentProps & InjectedBenServiceProps & InjectedBenNotificationProps & InjectedIntlProps & InjectedI18nProviderProps

type ErrorAlert = {
  visible: boolean
  reason: string
}

type FormValues = {
  email: string,
  password: string,
  reCaptcha: string
}

const initialValue: FormValues = {
  email: '',
  password: '',
  reCaptcha: ''
}

const LoginPage: React.FC<LoginPageProps> = ({
    benHelper,
    benService,
    benNotification,
    history,
    intl,
    i18nProvider
  }) => {

  const formikRef = React.useRef<Formik<FormValues>>(null)
  const reCaptchaRef = React.useRef<ReCaptcha>(null)
  const [errorAlert, setErrorAlert] = React.useState<ErrorAlert>({ visible: false, reason: '' })
  const [isLoading, setIsLoading] = React.useState(false)
  const [isMounted, setMounted] = React.useState(false)
  const [invalidCredentialErrorVisible, setInvalidCredentialErrorVisible] = React.useState(false)
  const [passVisible, setPassVisible] = React.useState(false)


  function getValidLocale(lang:string) : Locale
  {
      const allLangs = getAllowedLanguages()
      let locale = allLangs[0] as Locale

      if( lang !== undefined && lang !== '' )
      {
        for( const code of allLangs )
        {
          if( lang.startsWith(code) )
          {
            locale = code as Locale
            break
          }
        }
      }

      return locale
  }

  function handleOnFormikSubmit (values: any)
  {
    setIsLoading(true)

    const email = String(values.email)
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

    benService.login2(email.trim(), values.password, values.reCaptcha, intl.locale, timeZoneName)
    .then( response => {

      // ReactGA.event({
      //   category: 'Login',
      //   action: 'LoginOK',
      // })

      let locale = getValidLocale(response.data.lang)

      i18nProvider.changeLocale(locale)

      if(benHelper.rewardId.length > 0 ) {
        history.push('/reward')
      }
      else {
        history.push('/')
      }
    })

    .catch(result => {

      // ReactGA.event({
      //   category: 'Login',
      //   action: 'LoginFailed',
      // })

      if( reCaptchaRef.current )
        reCaptchaRef.current.reset()

      switch (result.code) {

        case ActionCode.captchaError:
          isMounted && setErrorAlert({
            visible: true,
            reason: getMessage('register-page.register-error-captcha', intl)
          })
          break

        case ActionCode.badCredentials:
          setInvalidCredentialErrorVisible(true)
          break

        default: return benNotification.notify({
          type: 'error',
          title: getMessage('side-effect.internal-error-title', intl),
          message: getMessage('side-effect.internal-error-message', intl)
        })
      }
    })
    .finally(() => isMounted && setIsLoading(false))
}



  function isLoginButtonDisabled (values: FormValues): boolean {
    return values.email.length < 1 || values.password.length < 1
  }

  React.useEffect(() => {
    document.body.classList.add('background')

    setMounted(true)

    return () => {
      document.body.classList.remove('background')

      setMounted(false)
    }
  }, [])


  function handleOnKeyDown (event : KeyboardEvent, errors: any)
  {
      if( event.key === 'Enter') {
          event.preventDefault()
          event.stopPropagation()
          handleOnSubmitClick(errors)
      }
  }

  function handleOnSubmitClick (errors: any) {

    setErrorAlert({ visible: false, reason: '' })

    if (Object.keys(errors).length === 0) {
      if (!reCaptchaRef.current) {
        return
      }

      let local = window.location.hostname === 'localhost' || window.location.hostname.endsWith('.local')
      // let local = false

      if (local || reCaptchaRef.current.getValue()) {
        formikRef.current && formikRef.current.submitForm()
      } else {
        reCaptchaRef.current.execute()
      }
    }
  }

  function handleOnReCaptchaChange (value: string | null) {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reCaptcha', value || '')
      value !== null && formikRef.current.submitForm()
    }
  }

  function handleOnReCaptchaExpired () {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reCaptcha', '')
    }
  }

  function handleOnToggleAlert () {
    setErrorAlert({ ...errorAlert, visible: !errorAlert.visible })
  }

  function handleDismissInvalidCredentialErrorVisible() {
    setInvalidCredentialErrorVisible(false);
  }

  function toggleVisibility(e: React.MouseEvent)
  {
    e.preventDefault()
    e.stopPropagation()
    setPassVisible(!passVisible)
  }

  if (benService.isAuthorized) {

    if( benHelper.rewardId.length > 0 ) {
      return <Redirect to="/reward" />
    }
    else {
      return <Redirect to="/" />
    }
  }

  return (

      <React.Fragment>

        <div className="fixed-top m-2"  >
          <LanguageDropdown/>
        </div>

        <div className="fixed-background auto-scroll">

          <ReCaptcha
            ref={reCaptchaRef}
            sitekey={reCaptchaDefaults.sitekey}
            size="invisible"
            onChange={handleOnReCaptchaChange}
            onExpired={handleOnReCaptchaExpired}
            badge="bottomright"
            hl={intl.locale}
          />

          <main>
            <div className="container">


              <Alert color="danger" isOpen={errorAlert.visible} toggle={handleOnToggleAlert}>
                      <span>{errorAlert.reason}</span>
              </Alert>

              <Row className="h-100">
                <Colxx xxs="12" md="10" className="mx-auto my-auto">

                  <Card className="auth-card">

                    <div className="position-relative image-side d-none d-lg-block">
                      <p className="text-white h3"><I18nMessage id="login-page.brandline" /></p>
                    </div>

                    <div className="form-side">

                      <div className="benjamin-logo-container">
                        <img src={appLogo} className="login-logo" alt="logo" />
                      </div>

                      <p className="text-center mb-4 h5">
                        <I18nMessage id="login-page.brandline" />
                      </p>

                      <Alert
                        color="danger"
                        isOpen={invalidCredentialErrorVisible}
                        toggle={handleDismissInvalidCredentialErrorVisible}
                      >
                        <I18nMessage id="login-page.invalid-login-error"/>
                      </Alert>

                      <Formik <any> ref={formikRef} initialValues={initialValue} onSubmit={handleOnFormikSubmit}>
                      {({ errors, touched, values, setFieldValue }) => (
                        <Form onKeyDown={ (e) => handleOnKeyDown(e, errors) }>

                          <Label className="form-group has-float-label mb-4">
                              <I18nMessage id="login-page.email-label" />
                              <Field
                                name="email"
                                type="email"
                                className="form-control"
                            />
                            </Label>

                            <Label className="form-group has-float-label mb-4">
                              <span className="show-pass-ico"> <img src={ passVisible ? eye : eyeslash} onClick={ (e)=>toggleVisibility(e) } alt="eye" /> </span>

                              <I18nMessage id="login-page.password-label" />
                              <Field
                                name="password"
                                type={passVisible ? "input" : "password" }
                                className="form-control"
                              />

                            </Label>

                            <div className="d-flex justify-content-between align-items-center">

                              <div></div>
                              <AsyncButton
                                  disabled={isLoginButtonDisabled(values)}
                                  color="primary"
                                  showSpinner={isLoading}
                                  size="lg"
                                  onClick={() => handleOnSubmitClick(errors)}>
                                  <I18nMessage id="login-page.login-button" />
                              </AsyncButton>
                            </div>


                            <div className="d-flex justify-content-between align-items-center mt-4">
                              <NavLink to={`/forgot-password`}>
                                <I18nMessage id="login-page.recovery-link" />
                              </NavLink>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-4">
                              <div className="mb-4">
                                <I18nMessage id="register-page.create-account-label">
                                  { message => <NavLink to={`/register`}>{message}</NavLink>}
                                </I18nMessage>
                              </div>
                            </div>

                          </Form>
                      )}

                      </Formik>

                    </div>

                  </Card>

                  <MiniRodo inline={true}/>

                </Colxx>

              </Row>

            </div>
          </main>

        </div>

      </React.Fragment>
    )
  }


export default withBenHelper( withBenNotification(withI18nProvider(injectIntl(withRouter(withBenService(LoginPage))))) )
