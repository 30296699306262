import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import Spinner from '../../../components/Spinner'
import Schedule, { DaysOfWeek } from '../../../components/Schedule'
import I18nMessages from '../../../components/I18nMessages'
import TimeLimitPicker from '../../../containers/TimeLimitPicker'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService } from '../../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../../providers/notificationProvider'
import getMessage from '../../../language/getMessage'
import TimeLimitPicker2 from '../../../containers/TimeLimitPicker2'
import { array2string, hasDeviceFeature, string2array } from '../../../lib/utils'


type AccessTimePageProps = InjectedBenNotificationProps
  & InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedIntlProps

interface AccessTimePageState {
  timeLimitNet: number
  timeLimitNetWeek: number[]
  isLoading: boolean
  isSubmitting: boolean
  schedule: DaysOfWeek
}

class InternetAccessTimePage extends React.Component<AccessTimePageProps, AccessTimePageState> {

  state: AccessTimePageState = {
    timeLimitNet: 0,
    timeLimitNetWeek: [0,0,0,0,0,0,0],
    isLoading: false,
    isSubmitting: false,
    schedule: {
      mon: [],
      tue: [],
      wed: [],
      thu: [],
      fri: [],
      sat: [],
      sun: []
    }
  }

  private timerId: NodeJS.Timeout | null = null
  private saveSettingsDelay = 1500

  componentDidMount () {
    this.refresh()
  }

  componentWillUnmount () {
    if (this.timerId) clearTimeout(this.timerId)
  }

  componentDidUpdate (prevProps: AccessTimePageProps) {
    if (this.props.benAccount.currentProfile.deviceId !== prevProps.benAccount.currentProfile.deviceId) {
      this.refresh()
    }
  }

  private get daysOfWeekLabels () {
    return {
      mon: getMessage('monday-short-label', this.props.intl),
      tue: getMessage('tuesday-short-label', this.props.intl),
      wed: getMessage('wednesday-short-label', this.props.intl),
      thu: getMessage('thursday-short-label', this.props.intl),
      fri: getMessage('friday-short-label', this.props.intl),
      sat: getMessage('saturday-short-label', this.props.intl),
      sun: getMessage('sunday-short-label', this.props.intl)
    }
  }

  private refresh = () => {

    if (!this.props.benAccount.currentProfile.deviceId || !this.props.benAccount.currentProfile.profileId) {
      return
    }

    const deviceId = this.props.benAccount.currentProfile.deviceId
    const profileId = this.props.benAccount.currentProfile.profileId

    this.props.benService.loadSettings(profileId, deviceId)

      .then(result => this.setState({
        timeLimitNet: result.data.timeLimitNet,
        timeLimitNetWeek: string2array(result.data.timeLimitNetWeek, 7),
        schedule: {
          mon: Array.from(result.data.skd1, v => v === '1'),
          tue: Array.from(result.data.skd2, v => v === '1'),
          wed: Array.from(result.data.skd3, v => v === '1'),
          thu: Array.from(result.data.skd4, v => v === '1'),
          fri: Array.from(result.data.skd5, v => v === '1'),
          sat: Array.from(result.data.skd6, v => v === '1'),
          sun: Array.from(result.data.skd7, v => v === '1'),
        }
      }))

      .finally(() => this.setState({ isLoading: false }))
  }

  private saveSettings = () => {

    if (!this.props.benAccount.currentProfile.deviceId || !this.props.benAccount.currentProfile.profileId) {
      return
    }

    const deviceId = this.props.benAccount.currentProfile.deviceId
    const profileId = this.props.benAccount.currentProfile.profileId

    this.setState({ isSubmitting: true })

    this.props.benService.saveSettings(profileId, deviceId, {
      skd1: this.mapDayOfWeekToSKD(this.state.schedule.mon),
      skd2: this.mapDayOfWeekToSKD(this.state.schedule.tue),
      skd3: this.mapDayOfWeekToSKD(this.state.schedule.wed),
      skd4: this.mapDayOfWeekToSKD(this.state.schedule.thu),
      skd5: this.mapDayOfWeekToSKD(this.state.schedule.fri),
      skd6: this.mapDayOfWeekToSKD(this.state.schedule.sat),
      skd7: this.mapDayOfWeekToSKD(this.state.schedule.sun)
    })

    .then(() => this.props.benNotification.notify({
      type: 'success',
      title: getMessage('side-effect.successful-title', this.props.intl),
      message: getMessage('side-effect.successful-message', this.props.intl)
    }))

    .catch(() => this.props.benNotification.notify({
      type: 'error',
      title: getMessage('side-effect.internal-error-title', this.props.intl),
      message: getMessage('side-effect.internal-error-message', this.props.intl)
    }))

    .finally(() => this.setState({ isSubmitting: false }, () => this.refresh()))
  }

  private handleOnScheduleUpdate = (dayOfWeek: keyof DaysOfWeek, hour: number, value: boolean) => {

    const updateSchedule = this.state.schedule[dayOfWeek]

    updateSchedule[hour] = value

    this.setState(state => ({
      isSubmitting: true,
      schedule: {
        ...state.schedule,
        ...updateSchedule
      }
    }), () => {

      if (this.timerId) {
        clearTimeout(this.timerId)
      }

      this.timerId = setTimeout(() => this.saveSettings(), this.saveSettingsDelay)
    })
  }

  private mapDayOfWeekToSKD (data: boolean[]): string {
    return data.map(value => value ? '1' : '0').join('')
  }

  private handleOnInputChange = (value: number) => {
    if (!isNaN(value) && value <= 24*60) {
      setTimeout(() => this.updateSettings(value, null), 500)
    }
  }

  private handleOnInputChange2 = (values: number[] ) => {
    setTimeout(() => this.updateSettings(null, values), 500)
  }

  private updateSettings (value: number|null, valuesWeek: number[]|null) 
  {
    const { deviceId, profileId } = this.props.benAccount.currentProfile

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) 
    {
      let val = {}

      if( value != null )
      {
        const arrNum = Array(7).fill(value)
        const arr = array2string(arrNum,7)

        val = { timeLimitNet: value, timeLimitNetWeek: arr }
      }
      else if( valuesWeek != null )
      {
        const arr = array2string(valuesWeek,7)
        // obejscie dla niezaktualizowanych aplikacji
        val = { timeLimitNet: valuesWeek[0], timeLimitNetWeek: arr }
      }
      else
      {
        return
      }

      this.props.benService.saveSettings( profileId, deviceId, val )

        .then(() => this.props.benNotification.notify({
          type: 'success',
          title: getMessage('side-effect.successful-title', this.props.intl),
          message: getMessage('side-effect.successful-message', this.props.intl)
        }))

        .catch(() => this.props.benNotification.notify({
          type: 'error',
          title: getMessage('side-effect.internal-error-title', this.props.intl),
          message: getMessage('side-effect.internal-error-message', this.props.intl)
        }))
    }
  }

  private supportsWeeklyTimeLimit()
  {
    return hasDeviceFeature(this.props.benAccount, '_TLW_')    
  }

  render () {

    if (this.state.isLoading) {
      return <Spinner />
    }

    return (
      <React.Fragment>
        <Row className="mb-4">
          <Col sm="12">
            <Card>
              <CardBody>

                { !this.supportsWeeklyTimeLimit() && (
                  <Row>
                  <Col sm="6">
                    <CardTitle tag="h5">
                      <I18nMessages id="internet-page.access-time-limit-set-label" />
                    </CardTitle>
                    <I18nMessages id="internet-page.access-time-limit-description" />
                  </Col>
                  <Col sm="6">
                  <TimeLimitPicker value={this.state.timeLimitNet} onChange={this.handleOnInputChange} />
                  </Col>
                </Row>
                )}
                
                { this.supportsWeeklyTimeLimit() && (
                  <Row >
                    <Col sm="4">
                      <CardTitle tag="h5">
                        <I18nMessages id="internet-page.access-time-limit-set-label" />
                      </CardTitle>
                      <I18nMessages id="internet-page.access-time-limit-description" />
                    </Col>
                    <Col sm="8">
                    <TimeLimitPicker2 supportsBonus={false} values={this.state.timeLimitNetWeek} onChange2={this.handleOnInputChange2} timeBonusIn={-1} />
                    </Col>
                  </Row>
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <CardTitle tag="h5">
                  <I18nMessages id="internet-page.access-time.schedule-card-title" />
                </CardTitle>
                <Schedule
                  daysOfWeek={this.state.schedule}
                  daysOfWeekLabels={this.daysOfWeekLabels}
                  onUpdate={this.handleOnScheduleUpdate}
                />
                <div>
                  <h6 className="mt-4">
                    <I18nMessages id="internet-page.access-time.schedule-legend-label" />
                  </h6>
                  <div className="mb-1">
                    <span className="schedule-legend-item align-middle" />
                    <span className="align-middle ml-1">
                      <I18nMessages id="internet-page.access-time.schedule-internet-blocked-label" />
                    </span>
                  </div>
                  <div>
                    <span className="schedule-legend-item selected align-middle" />
                    <span className="align-middle ml-1">
                      <I18nMessages id="internet-page.access-time.schedule-internet-available-label" />
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default withBenNotification(withBenAccount(withBenService(injectIntl(InternetAccessTimePage))))
