import React from 'react'
import classnames from 'classnames'

interface AsyncLinkProps  {
  showSpinner?: boolean
  className?:string
  onClick: (e: React.MouseEvent) => void
}

const AsyncLink:  React.FC<AsyncLinkProps> = ({
  children,
  showSpinner = false,
  className,
  onClick,
  
}) => {

  const classes = classnames('btn-multiple-state link-multiple-state', className, {
    'show-spinner': showSpinner
  })

  return (
    <span onClick={onClick} className={classes}>
      { showSpinner && (
        <span className="spinner d-inline-block">
            <span className="bounce1 dark-spinner"></span>
            <span className="bounce2 dark-spinner"></span>
            <span className="bounce3 dark-spinner"></span>
        </span>
      )}

      <span className="label">{children}</span>
    </span>
  )
}

export default AsyncLink
