import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import FilterWithDateRange from '../../../containers/FilterWithDateRange'
import I18nMessages from '../../../components/I18nMessages'
import Pagination from '../../../components/Pagination'
import Spinner from '../../../components/Spinner'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, KeywordData } from '../../../providers/benServiceProvider'
import { subtractDateByDays } from '../../../lib'
import KeywordItem from './components/KeywordItem'

type SearchesPageProps = InjectedBenServiceProps & InjectedBenAccountProps

interface SearchesPageState {
  currentPage: number
  dateFrom: Date
  dateTo: Date
  filter: string
  isLoading: boolean
  items: KeywordData[]
  pageCount: number
}

class SearchesPage extends React.Component<SearchesPageProps, SearchesPageState> {

  private itemsPerPage = 10
  private filterDelay = 500
  private timer: NodeJS.Timer | undefined

  constructor (props: Readonly<SearchesPageProps>) {
    super(props)

    const currentDate = new Date()

    this.state = {
      currentPage: 1,
      dateFrom: subtractDateByDays(currentDate, 7),
      dateTo: currentDate,
      filter: '',
      isLoading: false,
      items: [],
      pageCount: 0,
    }
  }

  componentDidMount () {
    this.refresh()
  }

  componentDidUpdate (prevProps: SearchesPageProps) {
    if (this.props.benAccount.currentProfile.deviceId !== prevProps.benAccount.currentProfile.deviceId) {
      this.refresh()
    }
  }

  private refresh = (): void => {

    if (!this.props.benAccount.currentProfile.deviceId || !this.props.benAccount.currentProfile.profileId) {
      return
    }

    const profile = this.props.benAccount.currentProfile.profileId
    const device = this.props.benAccount.currentProfile.deviceId
    const dateFrom = this.state.dateFrom
    const dateTo = this.state.dateTo
    const filter = this.state.filter
    //const offset = ((this.state.currentPage - 1) * this.itemsPerPage)

    this.setState({ isLoading: true })

    //this.props.benService.getUrls(profile, device, dateFrom, dateTo, filter, offset, this.itemsPerPage)
    this.props.benService.getKeywords(profile, device, dateFrom, dateTo, filter, 0, 500)

      .then(result => this.setState({
        items: result.data,
        pageCount: Math.ceil(result.total / this.itemsPerPage),
      }))

      .finally(() => this.setState({ isLoading: false }))
  }

  private handleOnDateFromChanged = (dateFrom: Date | null) => {
    if (!dateFrom) return
    this.setState({ dateFrom, currentPage: 1 }, () => this.refresh())
  }

  private handleOnDateToChanged = (dateTo: Date | null) => {
    if (!dateTo) return
    this.setState({ dateTo, currentPage: 1 }, () => this.refresh())
  }

  private handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filter = event.target.value
    this.setState({ filter, currentPage: 1 }, () => {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => this.refresh(), this.filterDelay)
    })
  }

  private handleOnPaginationPageChange = (currentPage: number) => {
    //this.setState({ currentPage }, () => this.refresh())
    this.setState( { currentPage } )
  }

  private mapItemToElement = (item: KeywordData, index: number): JSX.Element => {

    const date = new Date(item.ts * 1000)
    return <KeywordItem key={String(index)} index={index} date={date} keyword={item.keyword} url={item.url} engine={item.engine} />
  }

  private getItems() : KeywordData[]
  {
    let idx = (this.state.currentPage-1)*this.itemsPerPage;
    return this.state.items.slice( idx, idx+this.itemsPerPage )
  }

  render () {
    return (
      <React.Fragment>
        <FilterWithDateRange
          filter={this.state.filter}
          dateFrom={this.state.dateFrom}
          dateTo={this.state.dateTo}
          onDateFromChanged={this.handleOnDateFromChanged}
          onDateToChanged={this.handleOnDateToChanged}
          onSearchChanged={this.handleOnFilterChange}
        />

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>

                <CardTitle tag="h3">
                  <I18nMessages id="internet-page.searches.searches-pages-card-title" />
                </CardTitle>

                {this.state.isLoading && (
                  <Spinner />
                )}

                {(!this.state.isLoading && this.state.items.length === 0) && (
                  <div className="text-center p-4">
                    <I18nMessages id="empty-list-label" />
                  </div>
                )}

                {(!this.state.isLoading && this.state.items.length > 0) && this.getItems().map(this.mapItemToElement)}

                <Pagination
                  currentPage={this.state.currentPage}
                  pageCount={this.state.pageCount}
                  onChangePage={this.handleOnPaginationPageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </React.Fragment>
    )
  }
}

export default withBenService(withBenAccount(SearchesPage))
