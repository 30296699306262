import React from 'react'

interface ThumbnailLettersProps {
  className?: string
  color?: string
  rounded?: boolean
  small?: boolean
  text: string
}

class ThumbnailLetters extends React.Component<ThumbnailLettersProps> {

  get letters (): string {
    const { text } = this.props
    let letters = ''

    if (text.indexOf(' ') > -1) {
      text.split(' ').forEach(word => {
        if (word.length > 1) {
          letters += word.slice(0, 1)
          return
        }
      })
    } else {
      letters += text.slice(0, 2)
    }

    if (letters.length > 2) {
      letters = letters.slice(0, 2)
    }

    return letters.toUpperCase()
  }

  get classNames (): string[] {
    const classNames: string[] = ['align-self-center', 'list-thumbnail-letters']

    if (this.props.className) classNames.push(this.props.className)
    if (this.props.color) classNames.push(`btn-${this.props.color}`)
    if (this.props.rounded) classNames.push('rounded-circle')
    if (this.props.small) classNames.push('small')

    return classNames
  }

  render () {
    return (
      <div className={this.classNames.join(' ')} title={this.props.text}>
        {this.letters}
      </div>
    )
  }
}

export default ThumbnailLetters
