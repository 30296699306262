import React from 'react'
import { Device } from './benServiceProvider'

type CurrentProfileDataType = {
  profileId: string | null
  deviceId: string | null
  device: Device | null
}

interface BenHelperState {
  rewardId: string
  rewardResult: number
  rodoClicked: boolean
}

export interface BenHelperContextValue {
  rewardId: string
  rewardResult: number

  promoCode: string

  rodoClicked: boolean

  currentProfileData: CurrentProfileDataType

  setRewardId: (v: string) => void
  setRewardResult: (v: number) => void

  setPromoCode: (v: string) => void

  setRodoClicked: (v: boolean) => void

  setCurrentProfileData: (v: CurrentProfileDataType) => void
}

export interface InjectedBenHelperProps {
  benHelper: BenHelperContextValue
}

export const BenHelperContext = React.createContext<BenHelperContextValue>({
  rewardId: '',
  rewardResult: 0,
  promoCode: '',
  rodoClicked: false,
  currentProfileData: {
    profileId: null,
    deviceId: null,
    device: null
  },
  setRewardId: (v) => undefined,
  setRewardResult: (v) => undefined,
  setPromoCode: (v) => undefined,
  setRodoClicked: (v) => undefined,
  setCurrentProfileData: () => null
})

export class BenHelperProvider extends React.Component<{}, BenHelperState> {

  state = {
    rewardId: '',
    rewardResult: 0,
    promoCode: '',
    rodoClicked: false,
    currentProfileData: {
      profileId: null,
      deviceId: null,
      device: null
    }
  }

  reset()
  {
    const state = {
      rewardId: '',
      rewardResult: 0,
      promoCode: '',
      rodoClicked: false,
      currentProfileData: null
    }

    this.setState(state)
  }

  componentDidMount()
  {
    // console.log('componentDidMount')
    this.loadStateToLocalStorage()
  }

  componentDidUpdate(prevProps:any, prevState:any)
  {
    // console.log('componentDidUpdate')
    this.saveStateToLocalStorage(this.state)
  }

  saveStateToLocalStorage = (s:any) => {

    const val = JSON.stringify(s)

    localStorage.setItem('benHelper', val )

    // console.log('saveStateToLocalStorage ' + val )
  }

  loadStateToLocalStorage = () => {

    const val = localStorage.getItem('benHelper')

    if( val ) {
      const obj = JSON.parse( val )
      this.setState(obj)
    }

    // console.log('loadStateToLocalStorage  ' + val )
  }

  get rewardId () : string {
    return this.state.rewardId
  }

  get rewardResult () : number {
    return this.state.rewardResult
  }

  get promoCode() : string {
    return this.state.promoCode
  }

  get rodoClicked () : boolean {
    return this.state.rodoClicked
  }

  get currentProfileData (): CurrentProfileDataType {
    return this.state.currentProfileData
  }

  setRewardId = (v:string) => {

    const s = {
      ...this.state,
      rewardId: v
    }

    this.setState(s)
    //this.saveStateToLocalStorage(s)
  }

  setRewardResult = (v:number) => {

    const s = {
      ...this.state,
      rewardResult: v
    }

    this.setState(s)
    //this.saveStateToLocalStorage(s)
  }

  setPromoCode = (v:string) => {

    const s = {
      ...this.state,
      promoCode: v
    }

    this.setState(s)
    //this.saveStateToLocalStorage(s)
  }

  setRodoClicked = (v:boolean) => {

    const s = {
      ...this.state,
      rodoClicked: v
    }
    this.setState(s)
    //this.saveStateToLocalStorage(s)
  }

  setCurrentProfileData = (v: CurrentProfileDataType) => {

    // console.log("setCurrentProfileData", v.profileId, v.deviceId, v.device )

    const s = {
      ...this.state,
      currentProfileData: v
    }
    this.setState(s)
  }

  render () {
    //this.loadStateToLocalStorage()

    const providerValue: BenHelperContextValue = {
      rewardId: this.rewardId,
      rewardResult: this.rewardResult,
      promoCode: this.promoCode,
      rodoClicked: this.rodoClicked,
      currentProfileData: this.currentProfileData,
      setRewardId: this.setRewardId,
      setRewardResult: this.setRewardResult,
      setPromoCode: this.setPromoCode,
      setRodoClicked: this.setRodoClicked,
      setCurrentProfileData: this.setCurrentProfileData
    }

    return (
        <BenHelperContext.Provider value={providerValue}>
          {this.props.children}
        </BenHelperContext.Provider>
    )
  }
}

export function withBenHelper <P extends InjectedBenHelperProps> (Component: React.ComponentType<P>) {
  return (props: Omit<P, keyof InjectedBenHelperProps>) => (
      <BenHelperContext.Consumer>
        {state => <Component {...props as P} benHelper={state} />}
      </BenHelperContext.Consumer>
  )
}


export default BenHelperProvider
