import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { InjectedBenServiceProps, withBenService } from '../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'

import '../../assets/css/sass/edu.scss'
import imgchart1 from '../../assets/media/edu/chart1.jpg'
import imgchart2 from '../../assets/media/edu/chart2.jpg'
import appLogo from '../../assets/media/logo.svg'
import { getBuildConfigStr, getCurrentYear } from '../../lib/utils'

type Props = InjectedBenNotificationProps
  & InjectedBenServiceProps
  & InjectedIntlProps
  & RouteComponentProps


const PageEdu: React.FC<Props> = ({
    benNotification,
    benService,
    intl,
    location
}) => {

function shouldShow() : boolean
{
    return  window.location.hostname.toLowerCase().indexOf('cert') >= 0 ||
            window.location.hostname.toLowerCase().indexOf('localhost') >= 0 
}
    
return (

    <React.Fragment>

    { shouldShow() && (
    

    <div className="container">

        <div className="header">
            <div className="logo">
                <img src={appLogo} alt="" className="logo"/>
            </div>
            <div className="description">
                Rozwiążemy zadania, sprawdziany. Nauczymy!
            </div>
        </div>
        <div className="separator"></div>


        <div className="card congratulations">
            <div className="congratulations-content">
                <h2>Gratulacje, Jan! 🎉</h2>
                <p>Twoje wyniki są o 68% lepsze niż wczoraj. Sprawdź swoją nową odznakę w profilu.</p>
            </div>
            <div className="avatar"></div>
        </div>
        
        <div className="subjects-container">
            <div className="tabs-container">
                <div className="tab active">
                    <svg className="tab-icon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#34D399" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.745 3A23.933 23.933 0 003 12c0 3.183.62 6.22 1.745 9M19.5 3c.967 2.78 1.5 5.817 1.5 9s-.533 6.22-1.5 9M8.25 8.885l1.444-.89a.75.75 0 011.105.402l2.402 7.206a.75.75 0 001.104.401l1.445-.889m-8.25.75l.213.09a1.687 1.687 0 002.062-.617l4.45-6.676a1.688 1.688 0 012.062-.618l.213.09" />
                    </svg>
                    <div className="tab-title">Matematyka</div>
                    <div className="tab-indicator"></div>
                </div>
                <div className="tab">
                    <svg className="tab-icon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#EC4899" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    </svg>
                    <div className="tab-title">Fizyka</div>
                    <div className="tab-indicator"></div>
                </div>
                <div className="tab">
                    <svg className="tab-icon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3B82F6" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
                    </svg>
                    <div className="tab-title">Chemia</div>
                    <div className="tab-indicator"></div>
                </div>
                <div className="tab">
                    <svg className="tab-icon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#F59E0B" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                    </svg>
                    <div className="tab-title">Angielski</div>
                    <div className="tab-indicator"></div>
                </div>
                <div className="tab">
                    <svg className="tab-icon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#8B5CF6" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" />
                    </svg>
                    <div className="tab-title">Programowanie</div>
                    <div className="tab-indicator"></div>
                </div>
            </div>
            <div className="actions-box">
                <div className="actions-buttons">
                    <div className="dropdown">
                        <button className="dropdown-toggle">Szkoła</button>
                        <div className="dropdown-menu">
                            <div className="dropdown-item">Szkoła Podstawowa</div>
                            <div className="dropdown-item">Liceum</div>
                            <div className="dropdown-item">Technikum</div>
                        </div>
                    </div>
                    <div className="dropdown">
                        <button className="dropdown-toggle">Klasa</button>
                        <div className="dropdown-menu">
                            <div className="dropdown-item">Klasa 1</div>
                            <div className="dropdown-item">Klasa 2</div>
                            <div className="dropdown-item">Klasa 3</div>
                            <div className="dropdown-item">Klasa 4</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="task-box mobile-only">
            <button className="task-button">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" style={{width: 'var(--icon-width, 30px)' }} >
                    <rect x="4" y="10" width="32" height="24" rx="2" ry="2" fill="#ffffff"/>
                    <circle cx="20" cy="22" r="8" fill="#666"/>
                    <circle cx="20" cy="22" r="6" fill="#999"/>
                    <rect x="12" y="8" width="16" height="4" rx="2" ry="2" fill="#ffffff"/>
                  </svg>
                <span>Zrób zdjęcie zadania</span>
            </button>
        </div>
        
        <div className="form-list-section">
            <div className="card form-container">
                <h3>Zadaj pytanie</h3>
                <form>
                    <div className="form-group">

                        <textarea id="question" rows={4} placeholder="Wpisz swoje pytanie tutaj lub wklej screen"></textarea>
                    </div>
                    <button type="submit" className="button">Wyślij pytanie</button>
                </form>
            </div>
            <div className="card list-container">
                <h3>Ostatnie pytania</h3>
                <div className="list-item">
                    <div className="list-item-color" style={{ backgroundColor: '#4F46E5' }}></div>
                    <span>Jak rozwiązać równanie kwadratowe?</span>
                </div>
                <div className="list-item">
                    <div className="list-item-color" style={{ backgroundColor: "#22C55E;" }}></div>
                    <span>Co to jest fotosynteza?</span>
                </div>
                <div className="list-item">
                    <div className="list-item-color" style={{backgroundColor: "#EAB308;"}}></div>
                    <span>Kiedy wybuchła II wojna światowa?</span>
                </div>
                <div className="list-item">
                    <div className="list-item-color" style={{backgroundColor: "#08ea66;" }}></div>
                    <span>Kiedy wybuchła III wojna światowa?</span>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="congratulations-content">
                <h2>Odpowiedź</h2>
                <p>Wynik zadania</p>
                <hr style={{border: "1px dashed #cccccc; margin-bottom:20px; width: 100%;" }} />
                <h2>Wyjaśnienie</h2>
                <p>Wyjaśnienie zadania</p>
                <hr style={{border: "1px dashed #cccccc; margin-bottom:20px; width: 100%;" }} />
                <h2>Rozwiązanie krok po kroku</h2>
                <p>Wyjaśnienie zadania</p>
            </div>
            <hr style={{border: "1px dashed #cccccc; margin-bottom:20px; width: 100%;" }} />
            <div className="buttonmore">Chcę poćwiczyć</div>
            <div className="buttondesc">Wyjaśnij mi ten temat ogólnie</div>
        </div>

        <div className="stats">
            <div className="card stat-card">
                <h3> +22% <span style={{ color: "#22c55e; font-size: 16px;"}}>↑</span> 155 ukończonych zadań</h3>
                <small style={{color: "#6b7280;" }} >Aktualny miesiąc</small>
                <img src={imgchart1} alt='' style={{ maxWidth: '500px' }} />
            </div>
            <div className="card stat-card">
                <h3>38.5h <span style={{ color: "#22c55e; font-size: 16px;" }} >↑</span></h3>
                <p>Czas nauki w tym miesiącu</p>
                <div style={{ textAlign: "center" }} >
                <img src={imgchart2} alt='' style={{ maxWidth: '300px' }} />
                </div>
            </div>
        </div>

        <footer>
            <div className="separator"></div>
            <div>
                <p>&copy; { getCurrentYear() } { getBuildConfigStr("NAME") }. Wszelkie prawa zastrzeżone.</p>
            </div>
        </footer>

    </div>

    ) }

    </React.Fragment>

    )
}

export default withBenNotification(withBenService(withRouter(injectIntl(PageEdu))))
