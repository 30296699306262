import React from 'react'
import Overlay from '../components/Overlay'
import { Button } from 'reactstrap'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { ActionCode, ActionStatus, InjectedBenServiceProps, withBenService } from '../providers/benServiceProvider'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import getMessage from '../language/getMessage'
import I18nMessages from '../components/I18nMessages'
import CodeVerificationInput from './CodeVerificationInput'
import Spinner from '../components/Spinner'


type Props = InjectedBenAccountProps
  & RouteComponentProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps

const EmailConfirmationOverlay: React.FC<Props> = ({
  benAccount,
  benNotification,
  benService,
  children,
  intl,
}) => {

  const [isEmailAlertVisible, setEmailAlertVisibility] = React.useState(false)

  const [isEmailResent, setEmailResent] = React.useState(false)
  const [isCheckingCode, setCheckingCode] = React.useState(false)
  const [isCodeVerified, setCodeVerified] = React.useState(false)
  const [lastCode, setLastCode] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [code, setCode] = React.useState<string[]>(['', '', '', '', '', ''])

  function handleOnEmailAlertToggle() {
    setEmailAlertVisibility(!isEmailAlertVisible)

    if( isCodeVerified )
      benAccount.changeEmailConfirmed(true)
  }

  async function handleResendEmail() {

    setEmailResent(true)

    try {
      await benService.resendEmailConfirm(intl.locale)

      benNotification.notify({
        type: 'success',
        title: getMessage('panel-page.email-alert-resend-title', intl),
        message: getMessage('panel-page.email-alert-resend-msg', intl),
      })

    } catch {
      benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
      })

    }
  }

  function handleCodeVerificationInputValueChange(index: number) {
    // Validate code
    if (index === 5) {
      const code = Array.from(document.querySelectorAll('.verification-code__inputs input')).map((el: any) => el.value)
      validateCode(code.join(''))
    }

    // Got to next or first if focused on last one
    const el = (document.querySelectorAll('.verification-code__inputs input')[index + 1]) as HTMLElement;
    if (el) {
      el.focus();
    }
    else {
      ((document.querySelectorAll('.verification-code__inputs input')[0]) as HTMLElement).focus();
    }
  }

  function handlePaste(event: any) {
    event.clipboardData.items[0].getAsString((text: string) => {
      const code = text
      .replace(/\s/g, '')
      .split('')
      .filter((char) => {
        if (Number.isInteger(Number(char))) {
          return char;
        }
      });
      setCode(code);
    })
  }

  function validateCode(code: string) {
    var codeStr = ''

    for (let c of code) {
      if (c >= '0' && c <= '9')
        codeStr += c
    }

    if (codeStr.length !== 6 || lastCode === codeStr)
      return


    setErrorMessage('')
    setLastCode(codeStr)
    setCheckingCode(true)

    benService.verifyConfirmCode(codeStr)
      .then(result => {
        if (result.status === ActionStatus.ok && result.code === ActionCode.ok) {
          setCodeVerified(true)
        }
      })
      .catch(result => {

        if (result && result.code) {
          if (result.code === ActionCode.CODE_WRONG_CONFIRM_VAL) {
            setErrorMessage(getMessage('panel-page.email-confirmation-code-error-invalid-code', intl))
          }
          else {
            setErrorMessage(getMessage('panel-page.email-confirmation-code-error', intl))
          }
        }
      })
      .finally(() => {
        setCheckingCode(false)
      })
  }


  React.useEffect(() => {
    setEmailAlertVisibility(!benAccount.emailConfirmed)
  }, [benAccount.emailConfirmed])

  const EmailI18n = () => {

    return (
      <div className="text-center">
        { (isCodeVerified) && (
          <div>
            <I18nMessages tagName="h2" id="panel-page.email-confirmation-code-success-header" />

            <div className="font-weight-bold h4 py-4">
              <I18nMessages id="panel-page.email-confirmation-code-success-desc" />
            </div>

            <div className="py-3">
              <Button color="primary" onClick={handleOnEmailAlertToggle}>
                <I18nMessages id="panel-page.email-confirmation-overlay-close" />
              </Button>
            </div>
          </div>
        )}

        { (!isCodeVerified) && (
          <div className="verification-code">
            <I18nMessages tagName="h2" id="panel-page.email-confirmation-overlay-header" />

            <div className="font-weight-bold h4 py-4">
              {benAccount.email}
            </div>

            <h2 className="pb-2" dangerouslySetInnerHTML={{ __html: intl.formatHTMLMessage({ id: 'panel-page.email-confirmation-code-header' }) }}></h2>

            <form className="verification-code__inputs py-2" onPaste={handlePaste}>
              <CodeVerificationInput value={code[0]} index={0} onValueChange={handleCodeVerificationInputValueChange} />
              <CodeVerificationInput value={code[1]} index={1} onValueChange={handleCodeVerificationInputValueChange} />
              <CodeVerificationInput value={code[2]} index={2} onValueChange={handleCodeVerificationInputValueChange} />
              <CodeVerificationInput value={code[3]} index={3} onValueChange={handleCodeVerificationInputValueChange} />
              <CodeVerificationInput value={code[4]} index={4} onValueChange={handleCodeVerificationInputValueChange} />
              <CodeVerificationInput value={code[5]} index={5} onValueChange={handleCodeVerificationInputValueChange} />
            </form>
            <div className="py-2 verification-code__error-msg">
              {isCheckingCode && <Spinner />}
              {errorMessage && (
                <div className="verification-code__text py-2">
                  {errorMessage}
                </div>
              )}
            </div>

            <hr/>

            <div className="py-3">
              <Button color="primary" className="mr-4 mr-md-5" onClick={handleResendEmail} disabled={isEmailResent} >
                <I18nMessages id="panel-page.email-confirmation-overlay-resend" />
              </Button>

              <Button color="primary" className="ml-md-5" onClick={handleOnEmailAlertToggle}>
                <I18nMessages id="panel-page.email-confirmation-overlay-postpone" />
              </Button>
            </div>

            <div className="pt-4" dangerouslySetInnerHTML={{ __html: intl.formatHTMLMessage({ id: 'panel-page.email-confirmation-overlay-desc' }) }}></div>
          </div>
        )}
      </div>
    )
  }

  if (isEmailAlertVisible) {
    return (
      <Overlay blurred contentComponent={EmailI18n()} borderStyle="2px solid #FF6905" disablePositionRelative={true}>
        {children}
      </Overlay>
    )
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default withRouter(injectIntl(withBenNotification(withBenService(withBenAccount(EmailConfirmationOverlay)))))
