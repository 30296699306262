import React from 'react'
import { Modal, ModalHeader, ModalBody, Card, CardBody, Button } from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import AsyncButton from '../../components/AsyncButton'
import { InjectedI18nProviderProps, withI18nProvider } from '../../providers/i18nProvider'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, UpgradesInfo, LicUpgrade } from '../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'
import getMessage from '../../language/getMessage'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { fd, formatAmount, formatInterval, getPlanName, isAvosmart } from '../../lib/utils'
import I18nMessages from '../../components/I18nMessages'
import { InjectedBenHelperProps, withBenHelper } from '../../providers/benHelperProvider'
import Spinner from '../../components/Spinner'
import ConfirmationModal from '../../containers/ConfirmationModal'

type Props = InjectedBenHelperProps &
            InjectedI18nProviderProps & 
            InjectedBenAccountProps & 
            InjectedBenServiceProps & 
            RouteComponentProps &
            InjectedIntlProps &
            InjectedBenNotificationProps &
{
    isOpen: boolean
    onToggle: () => void
}

const UpgradePage: React.FC<Props> = ({
    isOpen,
    onToggle,
    benHelper,
    benAccount,
    benService,
    benNotification,
    i18nProvider,
    history,
    intl
}) => {

    const [isLoading, setLoading] = React.useState(true)
    const [isProcessing, setProcessing] = React.useState(false)
    const [upgrades, setUpgrades] = React.useState<UpgradesInfo|null>(null)

    const [selectedPlan, setSelectedPlan] = React.useState<LicUpgrade|null>(null)

    function upgradeLicense (licType: number) 
    {
        if( isProcessing )
            return

        setProcessing(true)
    
        benService.upgradeLicense(licType, benHelper.promoCode, intl.locale)
            .then(result => {
                if( result.data.redirectUri )
                    window.location.href = result.data.redirectUri
                else
                    window.location.href = document.location.origin + '/account/purchase-status'
            })
            .catch(() => {
                benNotification.notify({
                type: 'error',
                title: getMessage('side-effect.internal-error-title', intl),
                message: getMessage('side-effect.internal-error-message', intl) })
            })
            .finally( () => {
                setProcessing(false)
            })
    }    


    function handleOnUpgradeClick(e: React.MouseEvent, item: LicUpgrade) 
    {
        if( isAvosmart() )
        {
            setSelectedPlan(item)
        }
        else
        {
            upgradeLicense(item.type)
        }

        e.preventDefault()
        e.stopPropagation()
    }

    function handleOnConfirmUpgrade()
    {
        const sp = selectedPlan

        setSelectedPlan(null)

        if( sp )
            upgradeLicense(sp.type)
    }
    
    function handleOnCancelUpgrade()
    {
        setSelectedPlan(null)
    }
    
    function formatPayNow(item: LicUpgrade | null) : string
    {
        if( item ) {
            return intl.formatMessage( {id:"upgrade-page.pay-now"}, {amount:formatAmount( item.price, item.currency ) })
        }

        return ''
    }

    function formatPayNowConfirm(item: LicUpgrade | null) : string
    {
        if( item ) {
            const price = formatAmount( item.price, item.currency ) + '  (' + item.currency.toUpperCase() + ')'
            return intl.formatMessage( {id:"upgrade-page.confirm-upgrade"}, {amount:price })
        }

        return ''
    }

    function formatPayThen( item: LicUpgrade ) : string
    {
        if( item.subscriptionPrice && item.intervalCnt && item.intervalName)
        {
            return intl.formatMessage({ id:'upgrade-page.pay-then' }, 
            { amount:formatAmount( item.subscriptionPrice, item.currency ), 
              period:formatInterval(item.intervalName, item.intervalCnt, intl) })
        }
        else
        {
            return ''
        }
    }

    function formatCurrency(item: LicUpgrade) : string
    {
        return item.currency.toUpperCase()
    }

    function getPlanOrSubscriptionName( item: LicUpgrade ) : string
    {
        if( !item.description )
            return getPlanName(item.type, intl)

        // nie wiem ktore wybrac...
        return getPlanName(item.type, intl)
        //return item.description
    }


    React.useEffect(() => {
        
        benService.listUpgrades(benHelper.promoCode, intl.locale)
            .then(result => { 
                setUpgrades(result.data) 
                setLoading(false)
            })

    }, [benService, intl, benHelper.promoCode] )
    

    return (

        <React.Fragment>

        <Modal isOpen={isOpen} toggle={onToggle} size="lg">
            <ModalHeader>
                <Button color="light" size="xs" onClick={onToggle} className="button-x" >
                    X
                </Button>
                
                <div className="modal-large-title">
                    <I18nMessages id="upgrade-page.title" />
                </div>                
            </ModalHeader>

            <ModalBody className="upgrade-plan-body">

                <Card body className="shadow upgrade-plan-card" >
                    
                    <CardBody className="upgrade-plan-card-body">

                        { (isLoading || isProcessing) && (
                            <Spinner/>
                        )}

                        <div className="upgrade-plan-select">

                            {upgrades && upgrades.upgrades.map((item, i) => (

                                <a key={i} href="/" onClick={(e)=>handleOnUpgradeClick(e, item)} >

                                    <div style={{margin:10}} >
                                        <p style={{ fontSize:'1.5rem' }}>
                                        { getPlanOrSubscriptionName(item) }
                                        </p>
                                    </div>
                                    <div style={{ fontSize:'1rem' }}>
                                        <I18nMessages id="upgrade-page.devices" />{item.devices}
                                    </div>
                                    <div style={{ fontSize:'1rem' }}>
                                        <I18nMessages id="upgrade-page.valid-till" />{ fd(item.till, intl) }
                                    </div>
                                    <div style={{margin:10}} >
                                        <p style={{ fontSize:'1.5rem' }}>
                                            { formatPayNow(item) }
                                            <span className="mini-currency">{formatCurrency(item)}</span>
                                        </p>

                                        { item.intervalName && (
                                            <p style={{ fontSize:'1.0rem' }}>
                                                { formatPayThen(item) }
                                                <span className="mini-currency">{formatCurrency(item)}</span>
                                            </p>
                                        )}
                                    </div>

                                    <AsyncButton onClick={(e)=>handleOnUpgradeClick(e, item)} disabled={isProcessing || isLoading} type="submit" color="primary">
                                        <I18nMessages id="purchase-page.select" />
                                    </AsyncButton>

                                </a>
                            ))}

                        </div>

                    </CardBody>

                    <Button onClick={onToggle} type="submit" color="light">
                        <I18nMessages id="purchase-page.close" />
                    </Button>                

                </Card>
                
            </ModalBody>
        </Modal>

        <ConfirmationModal
            isOpen={selectedPlan != null}
            onCancel={handleOnCancelUpgrade}
            onConfirm={handleOnConfirmUpgrade} >
            {formatPayNowConfirm(selectedPlan) }
        </ConfirmationModal>

        </React.Fragment>
    )
}

export default withBenHelper( withBenNotification( withRouter( injectIntl( withBenService(withBenAccount(withI18nProvider(UpgradePage))))) ) )
