function prepareColors () {
  const rootStyle = window.getComputedStyle(document.body)

  return {
    blue: rootStyle.getPropertyValue('--blue').trim(),
    indigo: rootStyle.getPropertyValue('--indigo').trim(),
    purple: rootStyle.getPropertyValue('--purple').trim(),
    pink: rootStyle.getPropertyValue('--pink').trim(),
    red: rootStyle.getPropertyValue('--red').trim(),
    orange: rootStyle.getPropertyValue('--orange').trim(),
    yellow: rootStyle.getPropertyValue('--yellow').trim(),
    green: rootStyle.getPropertyValue('--green').trim(),
    teal: rootStyle.getPropertyValue('--teal').trim(),
    cyan: rootStyle.getPropertyValue('--cyan').trim(),
    themeColor1: rootStyle.getPropertyValue('--theme-color-1').trim(),
    themeColor2: rootStyle.getPropertyValue('--theme-color-2').trim(),
    themeColor3: rootStyle.getPropertyValue('--theme-color-3').trim(),
    themeColor4: rootStyle.getPropertyValue('--theme-color-4').trim(),
    themeColor5: rootStyle.getPropertyValue('--theme-color-5').trim(),
    themeColor6: rootStyle.getPropertyValue('--theme-color-6').trim(),
    themeColor1_10: rootStyle.getPropertyValue('--theme-color-1-10').trim(),
    themeColor2_10: rootStyle.getPropertyValue('--theme-color-2-10').trim(),
    themeColor3_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor4_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor5_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor6_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    primaryColor: rootStyle.getPropertyValue('--primary-color').trim(),
    foregroundColor: rootStyle.getPropertyValue('--foreground-color').trim(),
    separatorColor: rootStyle.getPropertyValue('--separator-color').trim()
  }
}

export const themeColors = prepareColors()

export function getColorForChartBackground (index: number, reverse = false): string {
  const colors = [
    '#ff8605',
    '#18a2b8',
    '#99e145',
    '#4e85e0',
    '#f77c60',
    '#ffc556',
    '#6aefb2',
    '#bd8bbc',
    '#9bb1bc',
    '#e6d7ae',
  ]

  let colorIndex: number = index

  if (reverse) colors.reverse()

  while (colorIndex > (colors.length - 1)) {
    colorIndex = colorIndex - (colors.length - 1)
  }

  return colors[colorIndex]
}
