import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Overlay from '../components/Overlay'
import I18nMessages from '../components/I18nMessages'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import AddDeviceContent from '../routes/account/AddDeviceContent'

interface EmptyDeviceOverlayProps extends InjectedBenAccountProps, RouteComponentProps {
  children: React.ReactNode,
  onShowAddDevice: () => void
}

const EmptyDeviceOverlay: React.FC<EmptyDeviceOverlayProps> = ({
  benAccount,
  history,
  children,
  onShowAddDevice
}) => {

  const [hasDevice, setHasDevice] = React.useState(true)
  const [hasProfile, setHasProfile] = React.useState(true)

  // function handleOnAddDeviceClick () {
  //   history.push('/account/add-device')
  //   onShowAddDevice()
  // }

  const emptyProfileComponent = (
    <I18nMessages id="overlay.empty-profiles-label" />
  )

  const emptyDeviceComponent = (
    <div className="text-center">
      {/* <I18nMessages id="overlay.empty-device-label" tagName="p" />
      <Button outline color="success" size="xl" onClick={handleOnAddDeviceClick}>
        <I18nMessages id="overlay.empty-device.add-device-label" />
      </Button>  */}

      <AddDeviceContent></AddDeviceContent>
    </div>
  )


  React.useEffect(() => {

    if( !benAccount.currentProfile.deviceId )
      setHasDevice(false)
    else
      setHasDevice(true)

    if( !benAccount.currentProfile.profileId )
      setHasProfile(false)
    else
      setHasProfile(true)
    
  }, [benAccount.currentProfile])


  if (!hasDevice) {
    return (
      <Overlay blurred contentComponent={emptyDeviceComponent}>
        {children}
      </Overlay>
    )
  }

  if (!hasProfile) {
    return (
      <Overlay blurred contentComponent={emptyProfileComponent}>
        {children}
      </Overlay>
    )
  }

  return <React.Fragment>{children}</React.Fragment>
}

export default withRouter(withBenAccount(EmptyDeviceOverlay))
