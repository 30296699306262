export function maxStringLength (value: string, maxLength: number): string {
  if (value === null) {
    return ''
  }

  if (value.length > maxLength) {
    return `${value.substring(0, maxLength)}...`
  }

  return value
}
