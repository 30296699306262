import React from 'react'
import classnames from 'classnames'
import { Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import I18nMessages from '../components/I18nMessages'
import { Device } from '../providers/benServiceProvider'



interface ProfileCardDevicesListProps extends RouteComponentProps {
  collapsed: boolean
  currentDeviceId: string | null
  devices: Device[]
  id: string
  onClick: (profileId: string, deviceId: string, device:Device|null) => void
  onDeleteDeviceClick: (deviceId: string) => void
  onMoveDeviceClick: (deviceId: string, profileId: string) => void
}

const ProfileCardDevicesList: React.FC<ProfileCardDevicesListProps> = ({
  collapsed,
  currentDeviceId,
  devices,
  history,
  id,
  onClick,
  onDeleteDeviceClick,
  onMoveDeviceClick
}: ProfileCardDevicesListProps) => {

  const deviceClassName = 'device-item'

  // function handleOnAddDeviceInfoClick () {
  //   history.push('/account/add-device')
  // }

  function isDeviceWindows(device:Device)
  {
    return device.appFeatures.indexOf("_WIN32_") >= 0
  }

  if (collapsed) {
    return (
      <span></span>
    )
  }

  return (

    <Card className="active-profile-card">
      <CardBody>

            { devices.length === 0 && (
              <div className="add-new-device-btn">
                <I18nMessages id="overlay.empty-device-label" tagName="p" />
                {/* <Button size="s" color="success" onClick={handleOnAddDeviceInfoClick}>
                  <I18nMessages id="profile-list.add-devices-label" />
                </Button> */}
              </div>
            )}

            { devices.length > 0 && (
                <div className="profile-devices">
                { devices.map(device => (
                  <div className={classnames(deviceClassName, { 'active': device.id === currentDeviceId })} key={device.id} onClick={() => onClick(id, device.id, device)}>
                      <div>
                        <button className="device-choose-button" type="button">
                            <i className="simple-icon-check"></i>
                        </button>
                      </div>
                      <h5 className="cursor-pointer" key={device.id} onClick={() => onClick(id, device.id, device)}>
                          <i className={'cursor-pointer ' + ( isDeviceWindows(device) ? 'simple-icon-screen-desktop' : 'simple-icon-screen-smartphone' ) }></i>
                          {device.brand} {device.model}
                          {device.friendlyName.length > 0 && (<br/>) }
                          {device.friendlyName}
                      </h5>
                      <div className="micro-btns">
                        <Button size="xs" id={'deleteDevice' + device.id} color="light" onClick={() => onDeleteDeviceClick(device.id)} >
                          <i className="simple-icon-trash" />
                        </Button>
                        <UncontrolledTooltip placement="right" target={'deleteDevice' + device.id} >
                            <I18nMessages id="profile-list.remove-device-label" />
                        </UncontrolledTooltip>

                        <Button size="xs" id={'moveDevice' + device.id} color="light" onClick={() => onMoveDeviceClick(device.id, id)}>
                          <i className="simple-icon-share-alt" />
                        </Button>
                        <UncontrolledTooltip placement="right" target={'moveDevice' + device.id} >
                            <I18nMessages id="profile-list.move-device-label" />
                        </UncontrolledTooltip>
                      </div>
                    </div>
                ))}
                </div>
            )}

    </CardBody>
    </Card>
  )

}

export default withRouter(ProfileCardDevicesList)
