import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Label, InputGroup, InputGroupAddon, Row, Card, CardBody, CardTitle } from 'reactstrap'
import { Formik, Form, Field } from 'formik'
import Colxx from '../../../components/Colxx'
import getMessage from '../../../language/getMessage'
import I18nMessages from '../../../components/I18nMessages'
import Spinner from '../../../components/Spinner'
import { InjectedBenServiceProps, withBenService, WebsitesLists } from '../../../providers/benServiceProvider'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../../providers/notificationProvider'
import Separator from '../../../components/Separator'
import AsyncButton from '../../../components/AsyncButton'
import { isValidDomain } from '../../../lib/validators'
import YesNoModal from '../../../containers/YesNoModal'

type WhiteBlackListPageProps = InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps

interface WhiteBlackListPageState {
  blackList: string[]
  isLoading: boolean
  isSubmitting: boolean
  whiteList: string[]
  removePrefixVisible: boolean
  prefix: string
  savedUrl: string
  savedIsWhite: boolean
}

// TODO: Refactor as React FC
class WhiteBlackListPage extends React.Component<WhiteBlackListPageProps, WhiteBlackListPageState> {

  state: WhiteBlackListPageState = {
    blackList: [],
    isLoading: false,
    isSubmitting: false,
    whiteList: [],
    removePrefixVisible: false,
    prefix: '',
    savedUrl: '',
    savedIsWhite: false
  }

  componentDidMount () {
    this.refresh()
  }

  componentDidUpdate (prevProps: WhiteBlackListPageProps) {
    if (this.props.benAccount.currentProfile.deviceId !== prevProps.benAccount.currentProfile.deviceId) {
      this.refresh()
    }
  }

  private refresh = () => {

    if (!this.props.benAccount.currentProfile.deviceId || !this.props.benAccount.currentProfile.profileId) {
      return
    }

    const device = this.props.benAccount.currentProfile.deviceId
    const profile = this.props.benAccount.currentProfile.profileId

    this.setState({ isLoading: true })

    this.props.benService.loadLists(profile, device, null)
      .then(result => this.setState({
        whiteList: result.data.whiteList,
        blackList: result.data.blackList
      }))

      .catch(() => this.props.benNotification.notify({
        hasHightPriority: true,
        title: getMessage('side-effect.internal-error-title', this.props.intl),
        message: getMessage('side-effect.internal-error-message', this.props.intl),
        type: 'error'
      }))

      .finally(() => this.setState({
        isLoading: false
      }))
  }

  private saveListsHelper = (lists: WebsitesLists, listIds: number[]) => {

    if (!this.props.benAccount.currentProfile.deviceId || !this.props.benAccount.currentProfile.profileId) {
      return
    }

    const profile = this.props.benAccount.currentProfile.profileId
    const device = this.props.benAccount.currentProfile.deviceId

    this.setState({ isSubmitting: true })

    this.props.benService.saveLists(profile, device, lists, listIds)
      .then(() => this.props.benNotification.notify({
        type: 'success',
        title: getMessage('side-effect.successful-title', this.props.intl),
        message: getMessage('side-effect.successful-message', this.props.intl)
      }))

      .then(() => this.refresh())

      .catch(() => this.props.benNotification.notify({
        hasHightPriority: true,
        title: getMessage('side-effect.internal-error-title', this.props.intl),
        message: getMessage('side-effect.internal-error-message', this.props.intl),
        type: 'error'
      }))

      .finally(() => this.setState({ isSubmitting: false }))
  }

  private handleRemoveWhiteList = async (website: string) => {
    const websitesList: WebsitesLists = {
      blackList: this.state.blackList,
      whiteList: [...this.state.whiteList.filter(item => item !== website)],
      videoIds: [],
      channelIds: []
    }

    await this.saveListsHelper(websitesList,[0])
  }

  private handleRemoveBlackList = async (website: string) => {
    const websitesList: WebsitesLists = {
      blackList: [...this.state.blackList.filter(item => item !== website)],
      whiteList: this.state.whiteList,
      videoIds: [],
      channelIds: []
    }

    await this.saveListsHelper(websitesList,[1])
  }

  private preformat = (url:string) =>
  {
    url = url.trim().toLowerCase()

    if( url.startsWith('http://') )
      url = url.substring(7)

    if( url.startsWith('https://') )
      url = url.substring(8)

    const idx = url.indexOf('/')

    if( idx > 0 )
      url = url.substring(0, idx)

    return url.trim()
  }


  private trimPrefix = (url:string) : string => 
  {
    url = url.trim()

    if( url.toLocaleLowerCase().startsWith('www.') )
      url = url.substring(4)

    if( url.toLocaleLowerCase().startsWith('m.') )
      url = url.substring(2)

    return url
  }


  private getPrefix = (url:string) : string => 
  {
    url = url.trim()

    if( url.toLocaleLowerCase().startsWith('www.') )
      return "www"

    if( url.toLocaleLowerCase().startsWith('m.') )
      return "m"

    return ""
  }

  private saveList = (isWhite: boolean, url: string ) => 
  {
    if( !url || url.length < 1 )
      return

    const websitesList: WebsitesLists = {
      blackList: isWhite ? this.state.blackList :  [...this.state.blackList,  url],
      whiteList: isWhite ? [...this.state.whiteList, url] : this.state.whiteList,
      videoIds: [],
      channelIds: [],
    }

    this.saveListsHelper( websitesList, isWhite ? [0] : [1] )
  }


  private handleOnRemovePrefix = () => 
  {
    var url = this.state.savedUrl
    url = this.trimPrefix(url)

    this.saveList(this.state.savedIsWhite, url)

    this.setState({
      removePrefixVisible: false,
      savedIsWhite: false,
      savedUrl: ''
    })
  }

  private handleOnLeavePrefix = () => 
  {
    var url = this.state.savedUrl

    this.saveList(this.state.savedIsWhite, url)

    this.setState({
      removePrefixVisible: false,
      savedIsWhite: false,
      savedUrl: ''
    })
  }


  private handleWhiteListFormOnSubmit = (values: { whiteListNewWebsiteValue: string }) => 
  {
    const v = this.preformat(values.whiteListNewWebsiteValue)
    if( v === '' )
      return

    const prefix = this.getPrefix(v)

    if( !prefix )
    {
      this.saveList(true, v)
    }
    else
    {
      this.setState({
        removePrefixVisible: true,
        prefix: prefix,
        savedIsWhite: true,
        savedUrl: v
      })
    }

    values.whiteListNewWebsiteValue=''
  }

  private handleBlackListFormOnSubmit = (values: { blackListNewWebsiteValue: string }) => 
  {
    const v = this.preformat(values.blackListNewWebsiteValue)
    if( v === '' )
      return

    const prefix = this.getPrefix(v)

    if( !prefix )
    {
      this.saveList(false, v)
    }
    else
    {
      this.setState({
        removePrefixVisible: true,
        prefix: prefix,
        savedIsWhite: false,
        savedUrl: v
      })
    }

    values.blackListNewWebsiteValue=''
  }

  private validateWhiteList = (value: string): string | undefined => 
  {
    value = this.preformat(value)
    if( value === '' )
      return

    if ( !isValidDomain(value) ) {
      return getMessage('domain-not-valid', this.props.intl)
    } else if (this.state.whiteList.indexOf(value) > -1) {
      return getMessage('domain-exist-label', this.props.intl)
    } else if (this.state.blackList.indexOf(value) > -1) {
      return getMessage('domain-exist-other-label', this.props.intl)
    }

    return
  }

  private validateBlackList = (value: string): string | undefined => 
  {
    value = this.preformat(value)

    if( value === '' )
      return

    if ( !isValidDomain(value) ) {
      return getMessage('domain-not-valid', this.props.intl)
    } else if (this.state.blackList.indexOf(value) > -1) {
      return getMessage('domain-exist-label', this.props.intl)
    } else if (this.state.whiteList.indexOf(value) > -1) {
      return getMessage('domain-exist-other-label', this.props.intl)
    }

    return
  }

  renderList = (list: string[], onRemoveClick: (value: string) => void) => {
    return list.map((item, index) => (
      <div key={String(index)} className="mb-3 d-flex pb-3 border-bottom">
        <div className="d-flex flex-fill align-items-center">
          <span className="list-item-heading align-middle">{item}</span>
        </div>
        <div className="align-self-center">
          <AsyncButton outline color="danger" onClick={() => onRemoveClick(item)}>
            <I18nMessages id="internet-page.lists.remove-button-label" />
          </AsyncButton>
        </div>
      </div>
    ))
  }

  render () {

    // if (this.state.isLoading) {
    //   return <Spinner />
    // }

    return (
      <>
        <YesNoModal
          isOpen={this.state.removePrefixVisible}
          onYes={this.handleOnRemovePrefix}
          onNo={this.handleOnLeavePrefix}
        >
          {this.props.intl.formatMessage({ id:'internet-page.lists.remove-prefix' }, { prefix:this.state.prefix })}
        </YesNoModal>
      
        <Row>
          <Colxx lg="6" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle tag="h3">
                  <I18nMessages id="internet-page.lists.white-list.card-title" />
                  {this.state.isLoading && (
                    <div style={{position:'absolute', left:'50%'}}>
                      <Spinner />
                    </div>
                  ) }
                </CardTitle>
                
                <Formik initialValues={{ whiteListNewWebsiteValue: '' }} onSubmit={this.handleWhiteListFormOnSubmit}>
                  {({ errors, touched, values }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Label>
                        <I18nMessages id="internet-page.lists.add-to-list-label" />
                      </Label>
                      <InputGroup>
                        <Field
                          name="whiteListNewWebsiteValue"
                          className="form-control"
                          validate={this.validateWhiteList}
                        />
                        <InputGroupAddon addonType="append">
                          <AsyncButton
                            outline
                            color="primary"
                            type="submit"
                            disabled={values.whiteListNewWebsiteValue.trim().length === 0}
                            showSpinner={this.state.isSubmitting}
                          >
                            <I18nMessages id="internet-page.lists.add-button-label" />
                          </AsyncButton>
                        </InputGroupAddon>
                      </InputGroup>
                      {(errors.whiteListNewWebsiteValue && touched.whiteListNewWebsiteValue) && (
                        <div className="invalid-feedback d-block">{errors.whiteListNewWebsiteValue}</div>
                      )}
                    </Form>
                  )}
                </Formik>

                <Separator className="mb-3 mt-3" />
                {this.renderList(this.state.whiteList, this.handleRemoveWhiteList)}
              </CardBody>
            </Card>
          </Colxx>

          <Colxx lg="6" className="mb-4">
            <Card>
              <CardBody>
                <CardTitle tag="h3">
                  <I18nMessages id="internet-page.lists.black-list.card-title" />
                  {this.state.isLoading && (
                    <div style={{position:'absolute', left:'50%'}}>
                      <Spinner />
                    </div>
                  ) }
                </CardTitle>

                <Formik initialValues={{ blackListNewWebsiteValue: '' }} onSubmit={this.handleBlackListFormOnSubmit}>
                  {({ errors, touched, values }) => (
                    <Form className="av-tooltip tooltip-label-bottom">
                      <Label>
                        <I18nMessages id="internet-page.lists.add-to-list-label" />
                      </Label>
                      <InputGroup>
                        <Field
                          name="blackListNewWebsiteValue"
                          className="form-control"
                          validate={this.validateBlackList}
                        />
                        <InputGroupAddon addonType="append">
                          <AsyncButton
                            outline
                            color="primary"
                            type="submit"
                            disabled={values.blackListNewWebsiteValue.trim().length === 0}
                            showSpinner={this.state.isSubmitting}
                          >
                            <I18nMessages id="internet-page.lists.add-button-label" />
                          </AsyncButton>
                        </InputGroupAddon>
                      </InputGroup>
                      {(errors.blackListNewWebsiteValue && touched.blackListNewWebsiteValue) && (
                        <div className="invalid-feedback d-block">{errors.blackListNewWebsiteValue}</div>
                      )}
                    </Form>
                  )}
                </Formik>

                <Separator className="mb-3 mt-3" />
                {this.renderList(this.state.blackList, this.handleRemoveBlackList)}
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </>
    )
  }
}

export default withBenNotification(withBenService(withBenAccount(injectIntl(WhiteBlackListPage))))
