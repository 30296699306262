import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Card, CardBody,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,} from 'reactstrap'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import AddProfileCard from './AddProfileCard'
import ProfileCardDevicesList from './ProfileCardDevicesList'
import ProfileTab from './ProfileTab'
import { Device } from '../providers/benServiceProvider'
import { HtmlEntities } from '../lib/HtmlEntities'
import { InjectedBenHelperProps, withBenHelper } from '../providers/benHelperProvider'
import I18nMessages from '../components/I18nMessages'

type ProfileListProps = InjectedBenHelperProps & InjectedBenAccountProps & RouteComponentProps & {
  onAddProfileClick?: () => void
  onEditProfileClick?: (profileId: string) => void
  onDeleteProfileClick?: (profileId: string) => void
  onDeleteDeviceClick: (deviceId: string) => void
  onMoveDeviceClick: (deviceId: string, profileId: string) => void
}

const entities = new HtmlEntities();

const ProfileLists: React.FC<ProfileListProps> = ({
  benAccount,
  benHelper,
  onAddProfileClick,
  onEditProfileClick,
  onDeleteProfileClick,
  onDeleteDeviceClick,
  onMoveDeviceClick
}) => {

  function handleOnProfileClick (profileId: string, deviceId: string, device: Device | null) {
    benAccount.changeProfile({ deviceId, profileId, device })
    benHelper.setCurrentProfileData({ deviceId, profileId, device })
  }


  function getFirstDevice (devices: Device[]): Device | null {
    if (devices.length === 0) {
      return null
    } else {
      return devices[0]
    }
  }

  function getFirstDeviceId (devices: Device[]): string {
    if (devices.length === 0) {
      return ''
    } else {
      return devices[0].id
    }
  }

  return (

    <div className="profile-device-selector">

      <div className="profile-selector">
        {benAccount.profiles.map(profile => (
            <ProfileTab
              key = {profile.id} 
              id = {profile.id}
              name = { entities.decode(profile.name) }
              avatar = {profile.img}
              currentProfileId={benAccount.currentProfile.profileId}
              firstDevice = {getFirstDeviceId(profile.devices)}
              firstDeviceDevice = {getFirstDevice(profile.devices)}
              isDeleteButtonEnabled={profile.devices.length === 0}
              onSelectProfileClick={handleOnProfileClick}
              onEditClick={onEditProfileClick}
              onDeleteClick={onDeleteProfileClick}
            />
          )
        )}

        <div className="mobile-selector">
          <UncontrolledDropdown>
            <DropdownToggle className="btn-sm" color="light" caret>
            </DropdownToggle>
            <DropdownMenu right>
              {benAccount.profiles.filter(profile => profile.id !== benAccount.currentProfile.profileId).map(profile => <DropdownItem key={profile.id} onClick={() => handleOnProfileClick(profile.id, getFirstDeviceId(profile.devices), getFirstDevice(profile.devices))}>{profile.name}</DropdownItem>)}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <AddProfileCard onClick={onAddProfileClick} />
      </div>

        { benAccount.profiles.length === 0 && (
          <Card className="active-profile-card">
            <CardBody>
              <div className="add-new-device-btn">
                <I18nMessages id="overlay.empty-profiles-label" tagName="p" />
              </div>
            </CardBody>
          </Card>          
        ) }

        {benAccount.profiles.map(profile => (
          <ProfileCardDevicesList
            id={profile.id}
            key={profile.id}
            collapsed={profile.id !== benAccount.currentProfile.profileId}
            currentDeviceId={benAccount.currentProfile.deviceId}
            devices={profile.devices}
            onClick={handleOnProfileClick}
            onDeleteDeviceClick={onDeleteDeviceClick}
            onMoveDeviceClick={onMoveDeviceClick}
          />
        ))}

    </div>
  )
}

export default withBenHelper(withBenAccount(withRouter(ProfileLists)))
