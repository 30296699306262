import React from 'react'
import classnames from 'classnames'
import { connect, FormikProps } from 'formik'
import I18nMessages from '../components/I18nMessages'

import { getAvatarUrl } from '../lib/utils'

// import avatar1 from '../assets/media/avatar1.png'
// import avatar2 from '../assets/media/avatar2.png'


type Props<T = any> = {
  field: any
  formik: FormikProps<T>
}

const ProfileImagePicker: React.FC<Props> = ({
  field,
  formik
}) => {

  const value: number | undefined = formik.values[field.name]

  const avatar1ProfileClassName = classnames('profile-avatar m-1', { 'selected': value === 1 })
  const avatar2ProfileClassName = classnames('profile-avatar m-1', { 'selected': value === 2 })
  

  function handleOnClick (profile: number) {
    formik.setFieldValue(field.name, profile)
  }

  return (
    <div className="d-flex justify-content-around">

      <div className="text-center">
        <div className={avatar2ProfileClassName} onClick={() => handleOnClick(2)}>
          <img className="img-thumbnail" src={ getAvatarUrl(2) } alt="avatar2" />
        </div>
        <I18nMessages id="profile-image-picker.girl-label" tagName="p" />
      </div>

      <div className="text-center">
        <div className={avatar1ProfileClassName} onClick={() => handleOnClick(1)}>
          <img className="img-thumbnail" src={ getAvatarUrl(1) } alt="avatar1" />
        </div>
        <I18nMessages id="profile-image-picker.boy-label" tagName="p" />        
      </div>

    </div>
  )
}

export default connect(ProfileImagePicker)
