
export class HtmlEntities
{
    entities : Map<string, string>

    constructor() {

        this.entities = new Map(
        [
            ["&quot;"    , "\""],
            ["&amp;"     , "&" ],
            ["&apos;"    , "'" ],
            ["&lt;"      , " " ],
            ["&gt;"      , " " ],
            ["&nbsp;"    , " " ],
            ["&iexcl;"   , "¡" ], 
            ["&cent;"    , "¢" ],
            ["&pound;"   , "£" ], 
            ["&curren;"  , "¤" ],  
            ["&yen;"     , "¥" ],
            ["&brvbar;"  , "¦" ],  
            ["&sect;"    , "§" ],
            ["&uml;"     , "¨" ],
            ["&copy;"    , "©" ],
            ["&ordf;"    , "ª" ],
            ["&laquo;"   , "«" ], 
            ["&not;"     , "¬" ],
            ["&reg;"     , "®" ],
            ["&macr;"    , "¯" ],
            ["&deg;"     , "°" ],
            ["&plusmn;"  , "±" ],  
            ["&sup2;"    , "²" ],
            ["&sup3;"    , "³" ],
            ["&acute;"   , "´" ], 
            ["&micro;"   , "µ" ], 
            ["&para;"    , "¶" ],
            ["&middot;"  , "·" ],  
            ["&cedil;"   , "¸" ], 
            ["&sup1;"    , "¹" ],
            ["&ordm;"    , "º" ],
            ["&raquo;"   , "»" ], 
            ["&frac14;"  , "¼" ],  
            ["&frac12;"  , "½" ],  
            ["&frac34;"  , "¾" ],  
            ["&iquest;"  , "¿" ],  
            ["&Agrave;"  , "À" ],  
            ["&Aacute;"  , "Á" ],  
            ["&Acirc;"   , "Â" ], 
            ["&Atilde;"  , "Ã" ],  
            ["&Auml;"    , "Ä" ],
            ["&Aring;"   , "Å" ], 
            ["&AElig;"   , "Æ" ], 
            ["&Ccedil;"  , "Ç" ],  
            ["&Egrave;"  , "È" ],  
            ["&Eacute;"  , "É" ],  
            ["&Ecirc;"   , "Ê" ], 
            ["&Euml;"    , "Ë" ],
            ["&Igrave;"  , "Ì" ],  
            ["&Iacute;"  , "Í" ],  
            ["&Icirc;"   , "Î" ], 
            ["&Iuml;"    , "Ï" ],
            ["&ETH;"     , "Ð" ],
            ["&Ntilde;"  , "Ñ" ],  
            ["&Ograve;"  , "Ò" ],  
            ["&Oacute;"  , "Ó" ],  
            ["&Ocirc;"   , "Ô" ], 
            ["&Otilde;"  , "Õ" ],  
            ["&Ouml;"    , "Ö" ],
            ["&times;"   , "×" ], 
            ["&Oslash;"  , "Ø" ],  
            ["&Ugrave;"  , "Ù" ],  
            ["&Uacute;"  , "Ú" ],  
            ["&Ucirc;"   , "Û" ], 
            ["&Uuml;"    , "Ü" ],
            ["&Yacute;"  , "Ý" ],  
            ["&THORN;"   , "Þ" ], 
            ["&szlig;"   , "ß" ], 
            ["&agrave;"  , "à" ],  
            ["&aacute;"  , "á" ],  
            ["&acirc;"   , "â" ], 
            ["&atilde;"  , "ã" ],  
            ["&auml;"    , "ä" ],
            ["&aring;"   , "å" ], 
            ["&aelig;"   , "æ" ], 
            ["&ccedil;"  , "ç" ],  
            ["&egrave;"  , "è" ],  
            ["&eacute;"  , "é" ],  
            ["&ecirc;"   , "ê" ], 
            ["&euml;"    , "ë" ],
            ["&igrave;"  , "ì" ],  
            ["&iacute;"  , "í" ],  
            ["&icirc;"   , "î" ], 
            ["&iuml;"    , "ï" ],
            ["&eth;"     , "ð" ],
            ["&ntilde;"  , "ñ" ],  
            ["&ograve;"  , "ò" ],  
            ["&oacute;"  , "ó" ],  
            ["&ocirc;"   , "ô" ], 
            ["&otilde;"  , "õ" ],  
            ["&ouml;"    , "ö" ],
            ["&divide;"  , "÷" ],  
            ["&oslash;"  , "ø" ],  
            ["&ugrave;"  , "ù" ],  
            ["&uacute;"  , "ú" ],  
            ["&ucirc;"   , "û" ], 
            ["&uuml;"    , "ü" ],
            ["&yacute;"  , "ý" ],  
            ["&thorn;"   , "þ" ], 
            ["&yuml;"    , "ÿ" ],
            ["&OElig;"   , "Œ" ], 
            ["&oelig;"   , "œ" ], 
            ["&Scaron;"  , "Š" ],  
            ["&scaron;"  , "š" ],  
            ["&Yuml;"    , "Ÿ" ],
            ["&fnof;"    , "ƒ" ],
            ["&circ;"    , "ˆ" ],
            ["&tilde;"   , "˜" ], 
            ["&Alpha;"   , "Α" ], 
            ["&Beta;"    , "Β" ],
            ["&Gamma;"   , "Γ" ], 
            ["&Delta;"   , "Δ" ], 
            ["&Epsilon;" , "Ε" ],   
            ["&Zeta;"    , "Ζ" ],
            ["&Eta;"     , "Η" ],
            ["&Theta;"   , "Θ" ], 
            ["&Iota;"    , "Ι" ],
            ["&Kappa;"   , "Κ" ], 
            ["&Lambda;"  , "Λ" ],  
            ["&Mu;"      , "Μ" ],
            ["&Nu;"      , "Ν" ],
            ["&Xi;"      , "Ξ" ],
            ["&Omicron;" , "Ο" ],   
            ["&Pi;"      , "Π" ],
            ["&Rho;"     , "Ρ" ],
            ["&Sigma;"   , "Σ" ], 
            ["&Tau;"     , "Τ" ],
            ["&Upsilon;" , "Υ" ],   
            ["&Phi;"     , "Φ" ],
            ["&Chi;"     , "Χ" ],
            ["&Psi;"     , "Ψ" ],
            ["&Omega;"   , "Ω" ], 
            ["&alpha;"   , "α" ], 
            ["&beta;"    , "β" ],
            ["&gamma;"   , "γ" ], 
            ["&delta;"   , "δ" ], 
            ["&epsilon;" , "ε" ],   
            ["&zeta;"    , "ζ" ],
            ["&eta;"     , "η" ],
            ["&theta;"   , "θ" ], 
            ["&iota;"    , "ι" ],
            ["&kappa;"   , "κ" ], 
            ["&lambda;"  , "λ" ],  
            ["&mu;"      , "μ" ],
            ["&nu;"      , "ν" ],
            ["&xi;"      , "ξ" ],
            ["&omicron;" , "ο" ],   
            ["&pi;"      , "π" ],
            ["&rho;"     , "ρ" ],
            ["&sigmaf;"  , "ς" ],  
            ["&sigma;"   , "σ" ], 
            ["&tau;"     , "τ" ],
            ["&upsilon;" , "υ" ],   
            ["&phi;"     , "φ" ],
            ["&chi;"     , "χ" ],
            ["&psi;"     , "ψ" ],
            ["&omega;"   , "ω" ], 
            ["&thetasym;", "ϑ" ],    
            ["&Upsih;"   , "ϒ" ], 
            ["&piv;"     , "ϖ" ],
            ["&ndash;"   , "–" ], 
            ["&mdash;"   , "—" ], 
            ["&lsquo;"   , "‘" ], 
            ["&rsquo;"   , "’" ], 
            ["&sbquo;"   , "‚" ], 
            ["&ldquo;"   , "“" ], 
            ["&rdquo;"   , "”" ], 
            ["&bdquo;"   , "„" ], 
            ["&dagger;"  , "†" ],  
            ["&Dagger;"  , "‡" ],  
            ["&bull;"    , "•" ],
            ["&hellip;"  , "…" ],  
            ["&permil;"  , "‰" ],  
            ["&prime;"   , "′" ], 
            ["&Prime;"   , "″" ], 
            ["&lsaquo;"  , "‹" ],  
            ["&rsaquo;"  , "›" ],  
            ["&oline;"   , "‾" ], 
            ["&frasl;"   , "⁄" ], 
            ["&euro;"    , "€" ],
            ["&image;"   , "ℑ" ], 
            ["&weierp;"  , "℘" ],  
            ["&real;"    , "ℜ" ],
            ["&trade;"   , "™" ], 
            ["&alefsym;" , "ℵ" ],   
            ["&larr;"    , "←" ],
            ["&uarr;"    , "↑" ],
            ["&rarr;"    , "→" ],
            ["&darr;"    , "↓" ],
            ["&harr;"    , "↔" ],
            ["&crarr;"   , "↵" ], 
            ["&lArr;"    , "⇐" ],
            ["&UArr;"    , "⇑" ],
            ["&rArr;"    , "⇒" ],
            ["&dArr;"    , "⇓" ],
            ["&hArr;"    , "⇔" ],
            ["&forall;"  , "∀" ],  
            ["&part;"    , "∂" ],
            ["&exist;"   , "∃" ], 
            ["&empty;"   , "∅" ], 
            ["&nabla;"   , "∇" ], 
            ["&isin;"    , "∈" ],
            ["&notin;"   , "∉" ], 
            ["&ni;"      , "∋" ],
            ["&prod;"    , "∏" ],
            ["&sum;"     , "∑" ],
            ["&minus;"   , "−" ], 
            ["&lowast;"  , "∗" ],  
            ["&radic;"   , "√" ], 
            ["&prop;"    , "∝" ],
            ["&infin;"   , "∞" ], 
            ["&ang;"     , "∠" ],
            ["&and;"     , "∧" ],
            ["&or;"      , "∨" ],
            ["&cap;"     , "∩" ],
            ["&cup;"     , "∪" ],
            ["&int;"     , "∫" ],
            ["&there4;"  , "∴" ],  
            ["&sim;"     , "∼" ],
            ["&cong;"    , "≅" ],
            ["&asymp;"   , "≈" ], 
            ["&ne;"      , "≠" ],
            ["&equiv;"   , "≡" ], 
            ["&le;"      , "≤" ],
            ["&ge;"      , "≥" ],
            ["&sub;"     , "⊂" ],
            ["&sup;"     , "⊃" ],
            ["&nsub;"    , "⊄" ],
            ["&sube;"    , "⊆" ],
            ["&supe;"    , "⊇" ],
            ["&oplus;"   , "⊕" ], 
            ["&otimes;"  , "⊗" ],  
            ["&perp;"    , "⊥" ],
            ["&sdot;"    , "⋅" ],
            ["&lceil;"   , "⌈" ], 
            ["&rceil;"   , "⌉" ], 
            ["&lfloor;"  , "⌊" ],  
            ["&rfloor;"  , "⌋" ],  
            ["&lang;"    , "⟨" ],
            ["&rang;"    , "⟩" ],
            ["&loz;"     , "◊" ],
            ["&spades;"  , "♠" ],   
            ["&clubs;"   , "♣" ],  
            ["&hearts;"  , "♥" ],   
            ["&diams;"   , "♦" ] ]);
    }

    decode(str: string | undefined) : string
    {
        if( str == null )
            return ""

        const MAX_LEN = 10

        let idx : number = 0
        let idx2: number        

        while(true)
        {
            idx = str.indexOf('&', idx)

            if( idx < 0 )
                break;

            idx2 = str.indexOf(';', idx)

            if(idx2 < 0)
                break

            if(idx2 - idx > MAX_LEN ) {
                idx = idx+1 > idx2-MAX_LEN ? idx+1 : idx2-MAX_LEN
                continue
            }

            let substr = str.substring(idx, idx2+1)

            let replace = this.entities.get(substr);

            if( replace != null && replace.length > 0 )
            {
                str = str.substr(0, idx) + replace + str.substr(idx2+1)
                idx += replace.length
            }
            else
            {
                idx = idx2+1
            }
        }

        return str
    }
}

