import React from 'react'

export function useEventListener (eventName: string, handler: any, element: any = window) {

  const savedHandler = React.useRef<any>()

  React.useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  React.useEffect(() => {

    const isSupported = element && element.addEventListener

    if (!isSupported) {
      return
    }

    const eventListener = (event: any) => savedHandler.current(event)

    element.addEventListener(eventName, eventListener)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  })
}
