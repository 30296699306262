import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import I18nMessages from '../../components/I18nMessages'
import BatteryChart from '../../containers/BatteryChart'


const BatteryPage: React.FC = () => {

  //const [dateTo, setDateTo] = React.useState( new Date() )
  const dateTo = new Date()

  return (
    <div className="dashboard-wrapper">

      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <h1 className="mb-0 p-0"><I18nMessages id="battery-page.headline-title" /></h1>
      </div>


      <Row className="mb-4">
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle tag="h3">
              <I18nMessages id="battery-page.chart-description" />
              </CardTitle>
              
              <BatteryChart dateTo={dateTo} />
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default BatteryPage
