import React from 'react'
//import I18nMessages from '../components/I18nMessages'
import { Button } from 'reactstrap'
import { InjectedIntlProps, injectIntl } from 'react-intl'


type Props = InjectedIntlProps & {
    buttonId: number
    active: boolean
    currentDay : boolean
    labelButton: string
    labelTime: string
    onClicked: (buttonId: number) => void    
  }
  
  const TimeButton: React.FC<Props> = ({
    intl,
    buttonId,
    active,
    currentDay, 
    labelButton,
    labelTime,
    onClicked

  }) => {

    return (
        <React.Fragment>
            <div className="rounded-button-item">
                <Button className={ currentDay && !active ? "rounded-button rounded-button-today" : "rounded-button" } color={ active ? "primary" : "light" }  size="xs" onClick={ () => onClicked(buttonId) }>
                    { intl.formatMessage( {id:labelButton} ) }
                </Button>
            </div>
            <div className="rounded-button-item">
                <span className="rounded-button-time">{ labelTime }</span>
            </div>
        </React.Fragment>
    )
  }

  export default injectIntl(TimeButton)
