import React from 'react'
import { NavLink, Switch, Redirect, Route } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'
import I18nMessages from '../../../components/I18nMessages'

import AppsListPage from './AppsListPage'
import StatisticsPage from './StatisticsPage'

const ApplicationPage: React.FC = () => {

  return (
    <div className="dashboard-wrapper">
      <I18nMessages id="applications-page.headline-title" tagName="h1" />

      <Nav tabs className="separator-tabs ml-0 mb-5">
        <NavItem>
          <NavLink className="nav-link" to="/panel/applications/statistics">
            <I18nMessages id="applications-page.nav-statistics-label" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="nav-link" to="/panel/applications/list">
            <I18nMessages id="applications-page.nav-apps-list-label" />
          </NavLink>
        </NavItem>
      </Nav>

      <Switch>
        <Redirect exact from="/panel/applications" to="/panel/applications/statistics" />
        <Route path="/panel/applications/statistics" component={StatisticsPage} />
        <Route path="/panel/applications/list" component={AppsListPage} />
        <Redirect to="/error" />
      </Switch>
    </div>
  )
}

export default ApplicationPage
