import React from 'react'

type Props = {
  value: string
  index: number
  onValueChange: any
}

const CodeVerificationInput: React.FC<Props> = ({
  value,
  index,
  onValueChange
}) => {

  const [isMounted, setMounted] = React.useState(false)
  const [inputValue, setInputValue] = React.useState<string>(value)

  function handleKeyDown(event: any) {
    // Handle the delete/backspace key
    if (event.keyCode === 8 || event.keyCode === 46) {
      setInputValue('')

      return;
    }

    // Handle the tab key
    if (event.keyCode === 9) {
      return;
    }

    // Handle numbers and characters
    // const key = String.fromCharCode(event.which);
    const key = event.target.value

    if (Number.isInteger(Number(key))) {
      setInputValue(key);
    }
  }

  React.useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [setMounted])

  React.useEffect(() => {
    if (Number.isInteger(Number(value))) {
      setInputValue(value);
    }
  }, [isMounted, value])

  React.useEffect(() => {
    onValueChange(index)
  }, [isMounted, inputValue, onValueChange, index])

  return (
    <React.Fragment>
      <input
        value={inputValue}
        onKeyDown={handleKeyDown}
        maxLength={1}
        size={1}
        autoComplete="off"
        min="0"
        max="9"
        type="tel"
        className="px-1 py-3 mx-1 px-md-3 py-md-3 mx-md-2"
        onChange={handleKeyDown}
      />
    </React.Fragment>
  )
}

export default CodeVerificationInput;
