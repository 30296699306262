import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import I18nMessages from '../../components/I18nMessages'
import Pagination from '../../components/Pagination'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, ScreenThumb, ScreenThumbItem, ScreenHeader } from '../../providers/benServiceProvider'
import Spinner from '../../components/Spinner'
import { injectIntl } from 'react-intl'
import { withI18nProvider } from '../../providers/i18nProvider'

type Props = InjectedBenAccountProps & InjectedBenServiceProps & {
   
}

// const cache = new Map<number, ScreenThumb>()
// let cachedDeviceId = ''

const itemsPerPage = 10

const ScreenGrabTestPage: React.FC<Props> = ({
  benAccount,
  benService
}) => {

  const [isLoading, setLoading] = React.useState(true)
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  const [thumbs, setThumbs] = React.useState<ScreenThumb | null>(null)
  const [encodedJpegData, setEncodedJpegData] = React.useState<string | null>(null)
  
  const [currentPage, setCurrentPage] = React.useState( 1 )
  const [pageCount, setPageCount] = React.useState( 0 )

  const [screenHeaderList, setScreenHeaderList] = React.useState<ScreenHeader | null>(null)

  const [sampleIds, setSampleIds] = React.useState<number[] | null>(null)
  const [sampleThumbs, setSampleThumbs] = React.useState<ScreenThumb | null>(null)

  function handleOnPaginationPageChange(cp: number)
  {
      setCurrentPage(cp) 
  }

  React.useEffect(() => 
  {
      const { deviceId, profileId } = benAccount.currentProfile
      let isMounted = true

      setLoading(true)
      setErrorMessage(null)
      setEncodedJpegData(null)
      
      
      if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' )
      {
        // if( cachedDeviceId !== deviceId )
        // {
        //   cachedDeviceId = deviceId
        //   cache.clear()
        // }

        // const cachedItem = cache.get(currentPage)

        // if( cachedItem != null )
        // {
        //   const pages = Math.ceil( cachedItem.total / itemsPerPage )

        //   setLoading(false)
        //   setThumbs(cachedItem);
        //   setPageCount(pages)

        //   return
        // }

        benService.screenList(profileId, deviceId, '', (currentPage-1)*itemsPerPage, itemsPerPage)
              .then(result => {

                  if (isMounted) {
                      
                      setLoading( false )

                      // uwaga na blad w C++ Boost, JSON wszystkie wartosci przesyla jako string
                      if( Number(result.code) !== 0 ) 
                      {
                          setErrorMessage(result.status)
                          setPageCount(0)
                          setThumbs(null)
                      }
                      else
                      {
                          const pages = Math.ceil( result.data.total / itemsPerPage )

                          setThumbs(result.data)
                          setPageCount(pages)
                          //cache.set(currentPage, result.data)
                      }
                  }
              })
              .catch(() => {
                  if (isMounted) {
                      setPageCount(0)
                      setThumbs(null)
                      setLoading( false )
                  }
              } )

        
        if(currentPage === 1)
        {
            benService.screenHeaderList(profileId, deviceId, '', 0, 1000000)
                .then( result => {

                    if (isMounted) {
                        
                        setScreenHeaderList(result.data)

                        if( result.data.items.length > 20 )
                        {
                            var ids : number[] = []

                            for( var i = 0; i<5; i++)
                                ids[i] = result.data.items[i+5].id

                            setSampleIds(ids)
                        }
                    }

                })
        }
        else
        {
          setScreenHeaderList(null)
        }
                      
      } else {
          console.log("Wrong deviceId")
      }
  
      return () => {

          isMounted = false

      }
    }, [benAccount, benService, currentPage])


    React.useEffect(() => 
    {
        const { deviceId, profileId } = benAccount.currentProfile

        let isMounted = true
        
        if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' && sampleIds !== null )
        {  
            benService.screenListByIds(profileId, deviceId, sampleIds )
                .then(result => {
  
                    if (isMounted) 
                    {                        
                        setSampleThumbs(result.data)
                    }
                })
                .catch(() => {

                })
        }

        return () => {
  
            isMounted = false
  
        }
    }, [benAccount, benService, sampleIds])


    function loadImg(item: ScreenThumbItem) : void
    {
      const { deviceId, profileId } = benAccount.currentProfile

      setLoading(true)

      if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' )
      {
          benService.screenGet(profileId, deviceId, item.id)
              .then(result => {
                  setLoading(false)

                  // uwaga na blad w C++ Boost, JSON wszystkie wartosci przesyla jako string
                  if( Number(result.code) !== 0 ) 
                  {
                      setErrorMessage(result.status)
                      setEncodedJpegData(null)
                  }
                  else
                  {
                      setEncodedJpegData(result.data.jpeg)
                  }
              
              })
              .catch(() => {
                setEncodedJpegData(null)
                setLoading(false)
              } )
                      
      } 

    }


  return (
    <div className="dashboard-wrapper">

      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
        <h1 className="mb-0 p-0"><I18nMessages id="screengrab-page.headline-title" /></h1>
      </div>


      <Row className="mb-4">
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle tag="h5">
              <I18nMessages id="screengrab-page.chart-description" />
              </CardTitle>

                    <Pagination
                            currentPage={currentPage}
                            pageCount={pageCount}
                            onChangePage={handleOnPaginationPageChange}
                    />
              

                    { isLoading && (
                      <div style={{position: "absolute", left:"50%"}} >
                        <Spinner/>
                      </div>
                    )}

                    { errorMessage && (
                        <h3> {errorMessage} </h3>
                    )}


                    <Row >
                      <Col md="2">
                        { thumbs != null && thumbs.items.map((item,i) => (
                            <div key={item.id}>
                              <div>
                                { item.userName } at { new Date(item.ts*1000).toLocaleString() } 
                                </div>
                                <div>
                                <img src={`data:image/jpeg;base64,${item.jpeg}`} onClick={ () => loadImg(item) }  style={{cursor:"pointer", width:"100%"}} alt="Icon" />
                              </div>
                            </div>
                        ))}
                      </Col>

                      <Col md="10">
                        { encodedJpegData != null && (
                            <img src={`data:image/jpeg;base64,${encodedJpegData}`} style={{width:"100%"}} alt="Preview" />
                        )}
                      </Col>
                    </Row>


                    <Pagination
                            currentPage={currentPage}
                            pageCount={pageCount}
                            onChangePage={handleOnPaginationPageChange}
                        />
                    
                    


            </CardBody>
          </Card>
        </Col>
      </Row>


      { sampleThumbs != null && sampleThumbs.items.map((item,i) => (
            <div key={item.id}>
                <div>
                { item.userName } at { new Date(item.ts*1000).toLocaleString() } 
                </div>
                <div>
                <img src={`data:image/jpeg;base64,${item.jpeg}`} onClick={ () => loadImg(item) }  alt="Icon" />
                </div>
            </div>
        ))}

      { screenHeaderList != null && (
        <div>
          Total items: {screenHeaderList.total}
          Got items: {screenHeaderList.items.length}
        </div>
      )}
      
      { screenHeaderList != null && screenHeaderList.items.map((item,i) => (
        <div>{i}. TS={item.ts}, ID={item.id}</div>
      ))}

    </div>
  )
}

export default injectIntl(withBenService(withBenAccount(withI18nProvider(ScreenGrabTestPage))))
