import React from 'react'
import { Button } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { InjectedI18nProviderProps, withI18nProvider } from '../providers/i18nProvider'
import { subtractDateByDays, dateToAllDayFromToDate, addDateByDays, getCurrentDate000 } from '../lib'

type Props = InjectedI18nProviderProps & {
  selectedDate: Date | null
  minDate?: Date
  excludeDates?: Date[]
  onSelectedDateChanged: (date: Date | null) => void
}

// TODO set from language
// const DATE_FORMAT = 'dd/MM/yyyy'

// let p = (new Intl.DateTimeFormat()).resolvedOptions()
// console.log(p)

const maxDate = getCurrentDate000()



const DatePickerWithButtons: React.FC<Props> = ({
                                      selectedDate,
                                      minDate,
                                      onSelectedDateChanged,
                                      i18nProvider,
                                      excludeDates
                                    }) => {

  const [ currentSelectedDay, setCurrentSelectedDay ] = React.useState( new Date() )

  function handleOnDateFromChanged (date: Date) {
    onSelectedDateChanged(date)
    setCurrentSelectedDay(date)
  }

  function handleNextDay ()
  {
    const fromTo = dateToAllDayFromToDate(addDateByDays(currentSelectedDay, 1))

    if (fromTo.from <= maxDate )
    {
      onSelectedDateChanged(fromTo.from)
      setCurrentSelectedDay(fromTo.from)
    }
  }

  function handlePrevDay ()
  {
    const fromTo = dateToAllDayFromToDate(subtractDateByDays(currentSelectedDay, 1))

    if( !minDate || fromTo.from >= minDate )
    {
      onSelectedDateChanged(fromTo.from)
      setCurrentSelectedDay(fromTo.from)
    }
  }

  React.useEffect(() => {
    const datePickers = Array.from(document.querySelectorAll('.react-datepicker__input-container input'))
    datePickers.map(datePicker =>
      datePicker.setAttribute('readonly', 'readonly')
    )
  }, [])

  return (
    <div className="datepicker-with-buttons">
        <div className="d-flex align-items-center">
            <Button color="light" size="s" onClick={handlePrevDay} className={ !minDate || currentSelectedDay >= minDate ? '' : 'disabled'}  ><i className="simple-icon-arrow-left"/></Button>
            <DatePicker
                locale={i18nProvider.locale}
                dateFormat="P"
                selected={selectedDate}
                onChange={handleOnDateFromChanged}
                dayClassName={date => (date > maxDate ||  ( minDate && date < minDate ) ) ? 'disabled-date' : ''}
                maxDate={ maxDate }
                minDate={ minDate }
                excludeDates={excludeDates ? excludeDates : []}
            />
            <Button color="light" size="s" onClick={handleNextDay} className={currentSelectedDay < maxDate ? '' : 'disabled'}><i className="simple-icon-arrow-right"/></Button>
        </div>
    </div>
  )
}

export default withI18nProvider(DatePickerWithButtons)
