import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/hu'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Minden rendben!',
  'side-effect.successful-message': 'Az adatokat helyesen mentettük',
  'side-effect.internal-error-title': 'Valami hiba történt',
  'side-effect.internal-error-message': 'Kérjük, próbálja meg később vagy ellenőrizze az internetkapcsolatát',

  'monday-sshort-label': 'H',
  'tuesday-sshort-label': 'K',
  'wednesday-sshort-label': 'Sz',
  'thursday-sshort-label': 'Cs',
  'friday-sshort-label': 'P',
  'saturday-sshort-label': 'Sz',
  'sunday-sshort-label': 'V',

  'monday-short-label': 'Hé',
  'tuesday-short-label': 'Ke',
  'wednesday-short-label': 'Sze',
  'thursday-short-label': 'Csü',
  'friday-short-label': 'Pé',
  'saturday-short-label': 'Szo',
  'sunday-short-label': 'Vas',

  'monday-label': 'Hétfő',
  'tuesday-label': 'Kedd',
  'wednesday-label': 'Szerda',
  'thursday-label': 'csütörtök',
  'friday-label': 'péntek',
  'saturday-label': 'szombat',
  'sunday-label': 'vasárnap',

  'january-label': 'január',
  'february-label': 'február',
  'march-label': 'március',
  'april-label': 'április',
  'may-label': 'május',
  'june-label': 'június',
  'july-label': 'július',
  'august-label': 'augusztus',
  'september-label': 'szeptember',
  'october-label': 'október',
  'november-label': 'november',
  'december-label': 'december',

  'interval-name-year': 'év',
  'interval-name-month': 'hónap',
  'interval-name-week': 'hét',
  'interval-name-day': 'nap',
  'interval-name-years': 'évek',
  'interval-name-months': 'hónapok',
  'interval-name-weeks': 'hetek',
  'interval-name-days': 'napok',

  'select-input.no-options-label': 'Nincs lehetőség',
  'filter-input.label': 'Szűrő:',

  'no-data.label': 'Nincs megjelenítendő adat. Változtassa meg a szűrőket vagy a kiválasztott dátumot.',
  'no-data.label-mid': 'Nincs megjelenítendő adat. Változtassa meg a kiválasztott dátumot.',
  'no-data.label-short': 'Nincs megjelenítendő adat.',
  'empty-list-label': 'Üres lista.',
  'overlay.empty-device-label': 'Ehhez a profilhoz még nem adtak hozzá eszközöket.',
  'overlay.empty-profiles-label': 'Még nincs hozzáadva profil.',
  'overlay.empty-device.add-device-label': 'Eszköz hozzáadása',

  'confirmation-modal.cancel-button': 'Mégse',
  'confirmation-modal.confirm-button': 'Megerősít',
  'confirmation-modal.button-yes': 'Igen',
  'confirmation-modal.button-no': 'Nem',

  // Date Picker
  'date-picker.select-date-label': 'Dátum kiválasztása',

  // Date Filter
  'date-filter.select-label': 'Tartomány kiválasztása',
  'date-filter.this-month': 'Ez a hónap',
  'date-filter.this.week': 'Ez a hét',
  'date-filter.this.day': '24 óra',
  'date-filter.this.custom': 'Válasszon napot',
  'date-filter.from-label': 'Ettől: ',
  'date-filter.to-label': 'Eddig: ',

  // Time Picker
  'time-picker.unlimied-label': 'Korlátlan',
  'time-picker.time-format': '{h}ó {m}perc',
  'time-picker.time-format-min': '{m} perc',
  'time-picker.time-format-hour': '{h} óra',

  'time-picker.add-bonus-title': 'Időbónusz hozzáadása csak mára',
  'time-picker.no-bonus': 'Nincs bónusz',
  'time-picker.bonus': 'Bónusz',
  'time-picker.add-bonus-button-set': 'Bónusz beállítása',
  'time-picker.add-bonus-button-cancel': 'Mégse',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Dátumtartomány',
  'profile-image-picker.girl-label': 'Lány',
  'profile-image-picker.boy-label': 'Fiú',

  // Profile List
  'profile-list.remove-device-label': 'Eszköz eltávolítása',
  'profile-list.move-device-label': 'Eszköz áthelyezése másik profilba',
  'profile-list.add-device-label': 'Eszköz hozzáadása',

  'profile-list.add-profile-label': 'Profil hozzáadása',
  'profile-list.edit-profile-label': 'Profil szerkesztése',
  'profile-list.add-devices-label': 'Új eszköz hozzáadása',
  'profile-list.remove-profile-label': 'Profil törlése',
  'profile-list.remove-profile-disabled-tooltip': 'A profil törléséhez először el kell távolítania az összes eszközt, amely a profilhoz van csatolva.',
  'profile-list.title': 'Profilok listája',

  // Profile Form
  'profile-form.full-name-label': 'Név',
  'profile-form.month-of-birth-label': 'Születési hónap',
  'profile-form.year-of-birth-label': 'Születési év',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Mégse',
  'profile-form-modal.create.submit-button': 'PROFIL HOZZÁADÁSA',
  'profile-form-modal.create.title': 'Gyerek profil hozzáadása',
  'profile-form-modal.edit.skip-button': 'Mégse',
  'profile-form-modal.edit.submit-button': 'MENTÉS',
  'profile-form-modal.edit.title': 'Gyerek profil szerkesztése',
  'profile-form-modal.max-profiles-error': 'Profil korlát elérve',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Eszköz áthelyezése másik profilba',
  'profile-move-device-modal.cancel-button': 'Mégse',
  'profile-move-device-modal.submit-button': 'Mentés',
  'profile-move-device-modal.select-profile-label': 'Profil kiválasztása',

  // CallLog
  'call-log.duration-label': 'Hívás időtartama:',
  'call-log.seconds-label': 'mp.',

  // Create Profile Page
  'create-profile-page.submit-button': 'PROFIL HOZZÁADÁSA',
  'create-profile-page.skip-button': 'Mégse',
  'create-profile-page.title': 'Gyermekprofil hozzáadása',
  'create-profile-page.description': 'Ki lesz megfigyelve',

  // Add device page
  'add-device-page.close-button': 'BEZÁR',
  'add-device-page.description': 'Válassza ki az eszköz típusát az alkalmazás telepítéséhez',
  'add-device-page.title': 'Telepítési útmutató',
  'add-device-page.check': 'Kézikönyv ellenőrzése',
  'add-device-page.mobile-title': 'Android okostelefon vagy tablet',
  'add-device-page.mobile-description': 'Android 6-os vagy újabb verzió',
  'add-device-page.desktop-title': 'Windows asztali vagy hordozható számítógép',
  'add-device-page.desktop-description': 'Windows 7-es vagy újabb verzió',


  // Purchase Page
  'purchase-page.skip-label': 'Menjen a vezérlőpultra',
  'purchase-page.title': 'Válassza ki a legjobb tervet',
  'purchase-page.title.features': 'Minden terv a következő funkciókat kínálja',
  'purchase-page.title.reviews': 'Miért szeretnek minket a felhasználók',
  'purchase-page.subtitle': 'Ha a program nem felel meg az elvárásainak, visszatérítjük a fizetést',
  'purchase-page.plan-highlight': 'Legjobb érték',
  'purchase-page.monthly': 'havonta',
  'purchase-page.select': 'Választ',
  'purchase-page.close': 'Bezár',
  'purchase-page.money-back.title': 'Kockázat nélkül',
  'purchase-page.money-back.desc': 'Ha nem elégedett, visszatérítjük a pénzét.',
  'purchase-page.header.check-1': 'Legjobb szülői felügyeleti alkalmazás',
  'purchase-page.header.check-2': 'Állíts be időkorlátot játékokhoz, YouTube-hoz és weboldalakhoz',
  'purchase-page.header.check-3': 'Több százezer elégedett ügyfél',
  'purchase-page.plan.year': 'év',
  'purchase-page.plan.optimal': 'Legjobb ár-érték arány',


  'purchase-page.modal.2y-header': 'Különleges ajánlat!',
  'purchase-page.modal.2y-sub-header': 'Válaszd a <b>2 éves tervet és spórolj</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Válaszd a <b>2 éves tervet és spórolj</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Védd meg akár {devices} eszközt',
  'purchase-page.modal.upgrade': 'Válaszd a kedvezményt',
  'purchase-page.modal.no-thanks': 'Folytatás az 1 éves tervvel',


  'purchase-plan.features.title.feature_1': 'Alkalmazások és játékok időkorlátjai',
  'purchase-plan.features.title.feature_2': 'Weboldal szűrés',
  'purchase-plan.features.title.feature_3': 'Alkalmazások, játékok és weboldalak blokkolása',
  'purchase-plan.features.title.feature_4': 'YouTube Megfigyelés',
  'purchase-plan.features.title.feature_5': 'Élő távoli képernyőnézés',
  'purchase-plan.features.title.feature_6': 'Távoli hozzáférés',
  'purchase-plan.features.title.feature_7': 'Részletes jelentések',
  'purchase-plan.features.title.feature_8': 'Helymeghatározás (csak telefon esetén)',
  'purchase-plan.features.title.feature_9': 'Üzenetek és hívások ellenőrzése',
  'purchase-plan.features.title.feature_10': 'Elsődleges műszaki támogatás',

  'purchase-plan.features.desc.feature_1': 'Szabj meg időkorlátokat az alkalmazások, játékok és az internet használatára vonatkozóan. Emellett ütemezd, hogy mely órákban férhet hozzájuk a gyermeked.',
  'purchase-plan.features.desc.feature_2': 'Minden nem megfelelő weboldal blokkolva van. A program egy olyan algoritmust használ, amely elemzi a weboldal tartalmát minden nem megfelelő anyagra. Az adatbázis óriási gyűjteményéből is összegyűjti a szükséges információkat, amelyek nem megfelelő weboldalakat és szavakat tartalmaznak, és ezeket rendszeresen frissítik.',
  'purchase-plan.features.desc.feature_3': 'Blokkold az alkalmazásokat és weboldalakat, amelyek negatív hatással lehetnek gyermekeidre. Ezenkívül minden újonnan telepített játékhoz a jóváhagyásodra lesz szükség.',
  'purchase-plan.features.desc.feature_4': 'A vezérlőpulton figyelheted az összes videót, amelyet gyermeked a YouTube-on néz. Blokkold bármelyik videót vagy teljesen tiltsd le a videószolgáltatásokhoz való hozzáférést.',
  'purchase-plan.features.desc.feature_5': 'Távolról hozzáférhetsz gyermeked képernyőjének élő közvetítéséhez és ellenőrizheted tevékenységét a nap folyamán. Ez lehetővé teszi, hogy szorosan figyelemmel kísérd a gyermeked által elért tartalmakat.',
  'purchase-plan.features.desc.feature_6': 'Miután bejelentkezett, lehetőség van bárhonnan és bármilyen eszközről elérni a szülői irányítópultot. Az irányítópulton beállításokat állíthat be, nyomon követheti a gyermekek eszközeit, és időkorlátokat állíthat be.',
  'purchase-plan.features.desc.feature_7': 'A szülői irányítópulton egyszerűen nyomon követheti a gyermek tevékenységét a számítógépén vagy telefonján, láthatja, mennyi időt töltöttek a képernyő előtt, mely weboldalakat látogatták meg, és még sok mást.',
  'purchase-plan.features.desc.feature_8': 'Ellenőrizze gyermekének aktuális helyzetét és korábbi helyzeteit a térképen. Nézze meg pontosan, hol volt a gyermeke bármely adott napon vagy bizonyos időben.',
  'purchase-plan.features.desc.feature_9': 'A szülői panel segítségével könnyedén ellenőrizheti gyermekének küldött és fogadott üzeneteit, telefonhívásait és elmentett kapcsolatait.',
  'purchase-plan.features.desc.feature_10': 'Ha segítségre van szüksége, mindig elérhetőek vagyunk. Számos videó útmutató is rendelkezésére áll, hogy segítsen a(z) {app} használatában.',



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Nagyon hatékony! Teljesen megvédi gyermekemet a káros internetes tartalmaktól.',
  'purchase-plan.raitings.desc.raiting_2': 'Pontosan erre volt szükségem! Egy órás napi időkorlátot állítottam be a lányaimnak az internet használatára, és az Avosmart gondoskodik róla helyettem.',
  'purchase-plan.raitings.desc.raiting_3': 'Azért ajánlom az Avosmartot, mert számos funkcióval rendelkezik. Gyermekeim védve vannak az internet használata során, és én is tudom szabályozni, hogy mennyi időt töltenek játékokkal, stb.',
  'purchase-plan.raitings.desc.raiting_4': 'Fontos volt számunkra a feleségemmel, hogy figyelemmel kísérhessük lányunk közösségi média szokásait. Az Avosmart segít abban, hogy lányunk digitális és valódi élete kiegyensúlyozott legyen.',
  'purchase-plan.raitings.desc.raiting_5': 'Fiam régebben túl sok időt töltött az interneten, laptopot, tabletet használva stb. Az Avosmart által biztosított jelentések segítségével pontosan tudom, mely weboldalakat látogatja a fiam és mennyi ideig.',
  'purchase-plan.raitings.desc.raiting_6': 'Most bármikor meg tudom nézni a fiam aktuális helyzetét. Kiváló szolgáltatás!',


  'account-settings-page.title': 'Fiókbeállítások',
  'account-settings-page.automatic-update': 'Windows alkalmazás automatikus frissítései',
  'account-settings-page.timezone': 'Időzóna: ',
  'account-settings-page.send-reports': 'Napi tevékenység jelentések küldése',


  'plans-info-page.title': 'Előfizetési információk',
  'plans-info-page.plan': 'Terv:',
  'plans-info-page.max-devices': 'Maximális eszközszám:',
  'plans-info-page.valid-till': 'Lejárati dátum:',

  'plans-info-page.your-plan': 'Az Ön terve:',
  'plans-info-page.subscription-information': 'Előfizetési információk',
  'plans-info-page.subscription-created': 'Előfizetés létrehozva: ',
  'plans-info-page.subscription-current-period': 'Jelenlegi előfizetési időszak: ',
  'plans-info-page.subscription-renews-on': 'A terve megújul ',
  'plans-info-page.subscription-cancel-renewal': 'Megújítás lemondása',
  'plans-info-page.subscription-not-renewed': 'A jelenlegi időszak végén a terve NEM lesz megújítva',
  'plans-info-page.subscription-enable-renewal': 'Megújítás engedélyezése',
  'plans-info-page.subscription-price': 'Előfizetés ára: ',
  'plans-info-page.subscription-price-amount': '{amount} minden {period}',


  'plans-info-page.upgrade-plan': 'Frissítse a csomagját',
  'plans-info-page.extend-lic': 'Hosszabbítsa meg a csomagját',
  'plans-info-page.subscriptions-portal': 'Fizetési módok kezelése',
  'plans-info-page.recent-payments': 'Legutóbbi tranzakciók',
  'plans-info-page.date-of-payment': 'Tranzakció dátuma:',
  'plans-info-page.transaction-status': 'Tranzakció állapota:',
  'plans-info-page.no-recent-payments': 'Nincsenek legutóbbi tranzakciók',
  'plans-info-page.btn-close': 'Bezárás',
  'plans-info-page.plan-devices': 'legfeljebb {devices} eszközre',
  'plans-info-page.plan-devices-types': 'számítógépek/tabletek/telefonok',


  'upgrade-page.title': 'Csomag frissítése',
  'upgrade-page.devices': 'Eszközök: ',
  'upgrade-page.valid-till': 'Csomagja érvényes eddig: ',
  'upgrade-page.confirm-upgrade': 'A csomagja frissítve lesz, {amount} összeget fogunk levonni',
  'upgrade-page.pay-now': 'Fizetés most {amount}',
  'upgrade-page.pay-then': 'Majd {amount} minden {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Előfizetés vásárlása',
  'purchase-checkout.up-to-devices': 'Legfeljebb {devices} eszközre',
  'purchase-checkout.subscribtion-price': '{amount} minden {period}',
  'purchase-checkout.subscribe': 'Előfizetés',
  'purchase-checkout.cancel': 'Mégse',


  // Purchase Status Page
  'purchase-status-page.title': 'Fizetés ellenőrzése',
  'purchase-status-page.status-pending': 'Függőben',
  'purchase-status-page.status-completed': 'Befejezve',
  'purchase-status-page.status-canceled': 'Megszakítva',
  'purchase-status-page.status-refund-finalized': 'Visszatérítés befejezve',
  'purchase-status-page.status-wating-for-conf': 'Megerősítésre vár',
  'purchase-status-page.status-created': 'Létrehozva',
  'purchase-status-page.status-unkown': 'Ismeretlen',
  'purchase-status-page.status-error': 'Hiba',
  'purchase-status-pag.back-button': 'Vissza a kezdőlapra',
  'purchase-status-pag.invoice-link': 'Áfás számlát kérek',

  // Login Page
  'login-page.brandline': 'Bejelentkezés',
  'login-page.brandline2': 'Weboldal szűrés',
  'login-page.email-label': 'Email cím',
  'login-page.login-button': 'BEJELENTKEZÉS',
  'login-page.password-label': 'Jelszó',
  'login-page.recovery-link': 'Elfelejtett jelszó?',
  'login-page.invalid-login-error': 'Az általad megadott email cím vagy jelszó érvénytelen',

  // Register Page
  'register-page.create-account-label': 'Nincs még fiókod? Hozz létre egy új fiókot',
  'register-page.description': 'Hozd létre az ingyenes fiókodat',
  'register-page.email-label': 'E-mail cím',
  'register-page.login-link-label': 'Van már fiókja? Jelentkezzen be',
  'register-page.marketing-checkbox-label': 'Elfogadom a marketing- és promóciós anyagok fogadását',
  'register-page.password-label': 'Jelszó',
  'register-page.phoneNumber-label': 'Telefonszám',
  'register-page.terms-privacy-checkbox-label': 'Elfogadom az adatvédelmi irányelveket és a felhasználási feltételeket',
  'register-page.register-button': 'REGISZTRÁCIÓ',
  'register-page.register-success-title': 'Siker',
  'register-page.register-success-message': 'A regisztráció sikeres volt. Ellenőrizze email fiókját az email megerősítéséhez',
  'register-page.register-error-captcha': 'Captcha hiba. Próbálja újra.',
  'register-page.register-error-already-registered-label': 'Már regisztrálva van. Próbáljon bejelentkezni vagy emlékeztesse a jelszavát.',
  'register-page.register-error-password-to-short-label': 'A jelszó túl rövid.',

  // Forgot Password Page
  'register-page.strong-pass': 'A jelszava túl egyszerű, és mások számára könnyen megfejthető lehet. A jelszónak tartalmaznia kell nagy- és kisbetűket, valamint számokat, és legalább 8 karakter hosszúnak kell lennie. Biztos, hogy egyszerű jelszót szeretne használni?',
  'forgot-password-page.headline-title': 'Jelszó emlékeztető',
  'forgot-password-page.email-label': 'e-mail cím',
  'forgot-password-page.reset-button': 'JELSZÓ EMLÉKEZTETŐ',
  'forgot-password-page.login-link-label': 'Vissza a bejelentkezési oldalra',
  'forgot-password-page.success-title': 'Jelszó visszaállítása',
  'forgot-password-page.success-message': 'Ellenőrizze email fiókját a jelszó visszaállítására vonatkozó utasításokért',
  'forgot-password-page.email-not-confirmed': 'Az e-mail címét nem erősítették meg. A jelszó visszaállítása nem elérhető.',

  // Update Password Page
  'update-password-page.headline-title': 'Új jelszó beállítása',
  'update-password-page.warning-title': 'MEGJEGYZÉS:',
  'update-password-page.warning-description': 'Új jelszó megadása a korábban elmentett helyadatok elvesztését eredményezi. A többi adat nem vész el.',
  'update-password-page.warning-agreement': 'Megértem és elfogadom a helyadatok elvesztését.',
  'update-password-page.new-password': 'Új jelszó:',
  'update-password-page.new-password-retype': 'Új jelszó megismétlése:',
  'update-password-page.submit': 'Új jelszó beállítása',
  'update-password-page.alert-weak-password': 'Jelszava túl egyszerű és könnyen kitalálható illetéktelen személyek által. A jelszónak tartalmaznia kell nagybetűket, kisbetűket, számokat és legalább 8 karakter hosszúnak kell lennie. Ennek ellenére egyszerű jelszót kíván használni?',
  'update-password-page.alert-agreement': 'Az adatvesztés elfogadása szükséges.',
  'update-password-page.error-message': 'Hiba! Az új jelszó beállítása nem sikerült.',
  'update-password-page.success-message': 'Új jelszó beállítva',
  'update-password-page.unknow-error-message': 'Valami rosszul sült el',
  'update-password-page.error-link': 'Hibás link',
  'update-password-page.error-posswords-not-equal': 'A jelszavak nem egyeznek',
  'update-password-page.error-posswords-length': 'A jelszavak túl rövidek',
  'update-password-page.error-wrong-code': 'Helytelen kód',
  'update-password-page.register-success-title': 'Siker!',
  'update-password-page.register-success-message': 'Új jelszó beállítva.',

  // Error Page
  'not-found-page.title': 'Hoppá... úgy tűnik, hiba történt!',
  'not-found-page.error-code-text': 'Hibakód',
  'not-found-page.go-back-home-button': 'VISSZA A FŐOLDALRA',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Terve lejár ekkor: {date}. Kérjük, fontolja meg az előfizetés megvásárlását még ma.',
  'panel-page.purchase-demo-alert': 'Jelenleg a próba verziót használja.',
  'panel-page.purchase-expired-alert': 'Jelenleg egy lejárt próba verziót használ. Vásároljon előfizetést a folytatáshoz.',
  'panel-page.purchase-lic-expired-alert': 'Az előfizetési terve lejárt. Vásároljon előfizetést a folytatáshoz.',
  'panel-page.email-alert': 'Nem erősítette meg az email címét',
  'panel-page.button-email-resend': 'Küldje újra az emailt',
  'panel-page.email-alert-resend': ' vagy küldje újra az emailt.',
  'panel-page.email-alert-resend-title': 'Megerősítés',
  'panel-page.email-alert-resend-msg': 'Email elküldve!',
  'panel-page.profile-delete-description': 'Biztos benne, hogy törölni kívánja a kiválasztott profilt?',
  'panel-page.profile-delete-device-description': 'Biztos benne, hogy törölni kívánja a kiválasztott eszközt? Az adatok elvesznek.',
  'panel-page.profile-delete-device-second-description': 'Az eszköz védelme le lesz tiltva. Biztos benne, hogy folytatni szeretné?',

  'panel-page.email-confirmation-overlay-close': 'Bezárás',
  'panel-page.email-confirmation-overlay-postpone': 'Később',
  'panel-page.email-confirmation-overlay-resend': 'Küldje újra az emailt',
  'panel-page.email-confirmation-overlay-header': 'Az alábbi e-mail címet még nem erősítette meg',
  'panel-page.email-confirmation-overlay-desc': "Ha hibázott a fenti e-mail címmel, kérjük, azonnal értesítsen minket.  <br>Ha nem kapta meg az emailt, kérjük kattintson a 'újraküld' gombra és ellenőrizze a többi e-mail mappáját (beleértve a kéretlen/spam mappát is).",

  'panel-page.email-confirmation-code-success-header': 'Köszönjük',
  'panel-page.email-confirmation-code-success-desc': 'Az email megerősítve!',
  'panel-page.email-confirmation-code-header': 'Kérjük, adja meg a 6-jegyű megerősítő kódot, amelyet <br> elküldtünk az email címére.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Az adott kód érvénytelen',
  'panel-page.email-confirmation-code-error': 'Váratlan hiba történt a kód érvényesítése közben',

  // Summary Page
  'summary-page.title': 'Irányítópult',
  'summary-page.top-apps.title': 'Legjobb alkalmazások',
  'summary-page.top-blocked.title': 'Legtöbbször blokkolt',
  'summary-page.top-domains.title': 'Legtöbbször látogatott weboldalak',
  'summary-page.top-search.title': 'Legjobb keresések',
  'summary-page.access-disable': 'Ideiglenes deaktiválás',
  'internet-page.access-time-limit-set-label': 'Napi hozzáférési limit az internethez:',
  'internet-page.access-time-limit-description': 'Állítson be napi időkorlátokat minden egyes eszközre, és válassza ki, hány órát lehet használni az internetet.',
  'disable-access.protection-enabled': 'A védelem aktív',
  'disable-access.protection-disabled-for': 'A védelem ideiglenesen letiltva, {time} perc van hátra',
  'disable-access.device-offline': 'Eszköz nem elérhető',
  'disable-access.protection-status': 'Védelmi állapot',


  // Internet Page
  'internet-page.headline-title': 'Weboldalak',
  'internet-page.access-time-page-title': 'Hozzáférési idő',
  'internet-page.settings-page-title': 'Beállítások',
  'internet-page.statistics-page-title': 'Statisztikák',
  'internet-page.searches-page-title': 'Keresések',

  'internet-page.statistics-page-www-ok': 'Jóváhagyott webhely',
  'internet-page.statistics-page-www-ok-white': 'Biztonságos webhely',
  'internet-page.statistics-page-www-timelimit': 'Időkorlátok miatt blokkolt webhely',
  'internet-page.statistics-page-www-userdef': 'Webhely blokkolva, felhasználói meghatározások',
  'internet-page.statistics-page-www-content': 'Webhely blokkolva, nem megfelelő tartalom',


  'internet-page.white-black-list-page-title': 'Biztonságos / Blokkolt Webhelyek',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Szűrők',
  'internet-page.settings-page.white-list-only-label': 'Csak a biztonságos listán szereplő oldalak elérése engedélyezett',
  'internet-page.settings-page.block-erotic-label': 'Erotikus oldalak elérésének blokkolása',
  'internet-page.settings-page.block-offensive-label': 'Sértő, agresszív webhelyek elérésének blokkolása',
  'internet-page.settings-page.block-file-sites-label': 'Az internetes fájlletöltést engedélyező oldalak elérésének blokkolása',
  'internet-page.settings-page.block-video-label': 'Videóportálok elérésének blokkolása',
  'internet-page.settings-page.block-social-label': 'Közösségi hálózatok elérésének blokkolása',
  'internet-page.settings-page.block-doc-label': 'Dokumentumok letöltésének blokkolása',
  'internet-page.settings-page.block-exe-label': 'Programok letöltésének blokkolása',
  'internet-page.settings-page.excludeYT-label': 'Ne kényszerítsd a biztonságos keresést a YouTube-on',
  'internet-page.settings-page.block-new-apps': 'Új alkalmazások blokkolása',
  'internet-page.settings-page.block-settings': 'Beállítások elérésének blokkolása',
  'internet-page.settings-page.custom-msg.card-tile': 'Üzenet a blokkolt oldalakhoz',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Személyre szabott üzenet használata a blokkolt webhelyekhez',
  'internet-page.settings-page.save-settings-button-label': 'Beállítások mentése',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Lista hozzáadása:',
  'internet-page.lists.add-button-label': 'Hozzáadás',
  'internet-page.lists.remove-button-label': 'Törlés',
  'internet-page.lists.white-list.card-title': 'Biztonságos Lista',
  'internet-page.lists.black-list.card-title': 'Tiltólista',
  'internet-page.lists.remove-prefix': 'A megadott cím "{prefix}"-szel kezdődik, ajánlott csak a domain nevet hozzáadni. Szeretné eltávolítani a "{prefix}"-t a címről?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Látogatott oldalak',

  'internet-page.searches.searches-pages-card-title': 'Keresett kifejezések',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Ütemezés',
  'internet-page.access-time.schedule-legend-label': 'Ütemezés jelmagyarázat',
  'internet-page.access-time.schedule-internet-available-label': 'Internet elérhető',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet blokkolva',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Hívások és szöveges üzenetek (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Hívások',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Szöveges üzenetek (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Utoljára módosítva:',
  'calls-text-messages-page.contacts-no-changes-label': 'Üres.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Kapcsolatok',

  // Localization Page
  'localization-page.headline-title': 'Helyszín',
  'localization-page.headline-title-off': 'Helymeghatározás, GPS követés ki van kapcsolva',
  'localization-page.map-hours-filter-label': 'Válassza ki az időtartományt a helytörténet megtekintéséhez',
  'localization-page.gps-tracking-on': 'GPS követés engedélyezve',
  'localization-page.gps-view-speed': 'Utazási sebesség megjelenítése',
  'localization-page.map-loading-label': 'Térképek betöltése...',
  'localization-page.map-no-data': 'NINCS ADAT',

  'localization-page.one-gps-was-off-since': 'Az eszköz helymeghatározási szolgáltatásai ki vannak kapcsolva {lastOffStr} óta',
  'localization-page.one-gps-was-off-for': 'Az eszköz helymeghatározási szolgáltatásai ki vannak kapcsolva {elapsedStr} ideje',
  'localization-page.many-gps-was-off-since': 'Az eszköz helymeghatározási szolgáltatásai ki vannak kapcsolva {lastOffStr} óta',
  'localization-page.many-gps-was-off-for': 'Az eszköz helymeghatározási szolgáltatásai ki vannak kapcsolva {elapsedStr} ideje',


  // Battery Page
  'battery-page.headline-title': 'Akkumulátor',
  'battery-page.chart-description': 'Akkumulátor szintje',

  // Access Time Page
  'access-time-page.headline-title': 'Hozzáférési idő',
  'access-time-page.nav-access-time-label-applications': 'Alkalmazások',
  'access-time-page.nav-access-time-label-internet': 'Weboldalak',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Videók listája',
  'youtube-page.no-videos': 'Nincs videó a kiválasztott dátumon',
  'youtube-page.block-videoid': 'Blokkolja ezt a videót',
  'youtube-page.block-channelid': 'Blokkolja ezt a csatornát',
  'youtube-page.video-blocked': 'Blokkolva',

  // Screen Pages
  'screenview-page.headline-title': 'Képernyőnézet',
  'screenview-page.reload-button': 'Újratöltés',
  'screenview-page.chart-description': 'Aktuális képernyőnézet',
  'screengrab-page.headline-title': 'Képernyőkép',
  'screengrab-page.chart-description': 'Képernyőkép',
  'screengrab-page.screen-save-time': 'Milyen gyakran készül a képernyőkép',
  'screengrab-page.screen-save-time-desc': 'Válassza ki, hogy milyen gyakran készüljön képernyőkép, vagy válassza a "letiltva" lehetőséget, hogy ne készüljön képernyőkép',
  'screengrab-page.screen-save-time-disabled': 'letiltva',
  'screengrab-page.no-screens-for-today': 'Ezen a napon nem készül képernyőkép',
  'screengrab-page.no-screens-for-filter': 'Nincs a kiválasztott kritériumoknak megfelelő képernyőkép',
  'screengrab-page.imgs-delete-description': 'Biztos, hogy törölni szeretné a kiválasztott képeket?',
  'screengrab-page.imgs-delete-description-one': 'Biztos, hogy törölni szeretné a kiválasztott képet?',
  'screengrab-page.imgs-delete-label': 'Törlés',
  'screengrab-page.imgs-delete-select': 'Kiválasztás törlésre',
  'screengrab-page.imgs-delete-selected': 'Kiválasztva:',
  'screengrab-page.imgs-delete-limit': 'A kiválasztott képernyőképek maximális száma elérve.',
  'screengrab-page.imgs-filter': 'Szűrő:',
  'screengrab-page.imgs-filter-communicators': 'Kommunikátorok',
  'screengrab-page.imgs-filter-social': 'Közösségi média',
  'screengrab-page.imgs-filter-video': 'Videó',
  'screengrab-page.imgs-filter-others': 'Egyéb',

  'screes-pages.device-offline': 'Az eszköz offline',
  'screes-pages.device-deactivated': 'A védelem ideiglenesen kikapcsolva',
  'screes-pages.device-timeout': 'Az eszköz nem válaszolt',
  'screes-pages.device-screenoff': 'A képernyő ki van kapcsolva',
  'screes-pages.device-notallowed': 'Képernyőkép készítése nem engedélyezett az eszközön',
  'screes-pages.device-error': 'Hiba történt',

  'lazy-image.img-delete-description': 'Biztosan törölni szeretné a kiválasztott képet?',

  // Applications Page
  'applications-page.headline-title': 'Alkalmazások',
  'applications-page.nav-access-time-label': 'Hozzáférési idő',
  'applications-page.nav-statistics-label': 'Összegzés',
  'applications-page.nav-apps-list-label': 'Alkalmazások listája',
  'applications-page.chart-activity-by-day-label': 'Napi tevékenység',
  'applications-page.chart-hour-label': 'Óra',
  'applications-page.chart-activity-by-hour-minutes-label': 'Napi tevékenység percekben',
  'applications-page.list-launched-count-label': 'Indítás száma:',
  'applications-page.list-elapsed-time-label': 'Futási idő:',
  'applications-page.introduction': 'Telepített alkalmazások',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Ütemezett időtartomány',
  'applications-page.settings.schedule-legend-label': 'Ütemezés jelmagyarázata',
  'applications-page.settings.schedule-blocked-label': 'Blokkolt alkalmazások',
  'applications-page.settings.schedule-available-label': 'Elérhető alkalmazások',
  'applications-page.time-limit.description': 'Állítsa be az időkorlátot konkrét alkalmazásokhoz. Válassza ki, hogy naponta hány órát lehet használni az alkalmazást.',
  'applications-page.apps-headline-title': 'Elérhető alkalmazások',
  'applications-page.apps-first-seen-label': 'Először láttuk:',
  'applications-page.apps-available-switch-label': 'Elérhető',
  'applications-page.apps-not-available-switch-label': 'Nem elérhető',
  'applications-page.apps-schedule-switch-label': 'Ütemezett',
  'applications-page.apps-no-schedule-switch-label': 'Nincs ütemezve',
  'applications-page.apps-time-limit-switch-label': 'Időkorlát',
  'applications-page.apps-no-time-limit-switch-label': 'Nincs időkorlát',
  'applications-page.apps-show-uninstalled-label': 'Nem telepített alkalmazások megjelenítése',
  'applications-page.apps-show-apps-list': 'Időkorlátos alkalmazások kiválasztása',

  'applications-page.used-count-zero-label': 'Nem indított',
  'applications-page.used-count-one-label': '{value} alkalommal indítva, összes idő: {totalTime}',
  'applications-page.used-count-many-label': '{value} alkalommal indítva, összes idő: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} ó {minutes} p',
  'applications-page.total-minutes-seconds-label': '{minutes} p {seconds} mp',
  'applications-page.total-minutes-label': '{minutes} p',
  'applications-page.total-seconds-label': '{seconds} mp',
  'applications-page.total-time-label': 'Összes idő ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Az aktivációs linket már felhasználták vagy érvénytelen',
  'email-confirmation-page.successful-confirm': 'Az email megerősítésre került. Köszönjük!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Jelszó megváltoztatása',
  'change-password-page.new-password-input-label': 'Új jelszó',
  'change-password-page.new-password-repeated-input-label': 'Új jelszó megismétlése',
  'change-password-page.current-password-input-label': 'Jelenlegi jelszó',
  'change-password-page.back-button-label': 'Vissza',
  'change-password-page.change-button-label': 'VÁLTOZTAT',
  'change-password-page.successful-change-label': 'A jelszó helyesen megváltozott',
  'change-password-page.wrong-current-password-label': 'Rossz jelenlegi jelszó',
  'change-password-page.password-too-short-label': 'Az új jelszó túl rövid',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENÜ',
  'top-navigation.user-menu.account-label': 'Fiók',
  'top-navigation.user-menu.sign-out': 'Kijelentkezés',
  'top-navigation.user-menu.subscription-label': 'Előfizetési terv',
  'top-navigation.user-menu.change-password-label': 'Jelszó megváltoztatása',
  'top-navigation.user-menu.add-device-label': 'Eszköz hozzáadása',

  // Sidebar
  'sidebar.menu-item.applications': 'Alkalmazások',
  'sidebar.menu-item.calls-and-text-messages': 'Hívások és SMS-ek',
  'sidebar.menu-item.internet': 'Weboldalak',
  'sidebar.menu-item.localizations': 'Helymeghatározások',
  'sidebar.menu-item.social-networks': 'Közösségi hálózatok',
  'sidebar.menu-item.summary': 'Műszerfal',
  'sidebar.menu-item.battery': 'Akkumulátor',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Képernyő nézet',
  'sidebar.menu-item.screengrab': 'Képernyőkép készítése',
  'sidebar.menu-item.access-time': 'Hozzáférési idő',

  // Validator
  'phone-number.not-valid': 'Adjon meg érvényes telefonszámot',
  'input.required-label': 'A mező kitöltése kötelező',
  'input.select-required-label': 'Kérem válasszon',
  'email.not-valid-label': 'Adjon meg érvényes e-mail címet',
  'domain-exist-label': 'A domain hozzá lett adva',
  'domain-exist-other-label': 'A domain már létezik másik listán',
  'domain-not-valid': 'A domain nem érvényes',
  'password-not-match-label': 'A jelszó nem egyezik',

  // misc
  'button-buy-label': 'Válasszon egy csomagot',

  'transactiion-status-0': 'Várakozás alatt',
  'transactiion-status-1': 'Befejezett',
  'transactiion-status--1': 'Törölve',
  'transactiion-status--2': 'Törölve – visszatérítés',
  'transactiion-status--3': 'Megerősítésre vár',
  'transactiion-status--100': 'Elindult',
  'transactiion-status-other': 'Egyéb',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Siker',
  'reward-page.error-already-used': 'Ezt a kódot már felhasználták',
  'reward-page.error-invalid-code': 'Helytelen kód',
  'reward-page.error-connection': 'Csatlakozási hiba',
  'reward-page.additional-bonus': 'Bónusz',
  'reward-page.get-additional-bonus': 'Promóciós kupon igénylése',
  'reward-page.get-now': 'Igénylés',
  'reward-page.lic-was-extended-by-days': 'Az előfizetési terved {rewardDays} nappal meghosszabbítva!',
  'reward-page.there-was-an-error': 'Sajnos hiba történt.',
  'reward-page.lic-not-extended': 'Az előfizetési terved nem lett meghosszabbítva.',
  'reward-page.button-close': 'Bezárás',

  // url item
  'url-item.copied': 'Vágólapra másolva',
  'url-item.copy-link': 'Link másolása',

  // APK howto

  'apk-howto-google-play': 'A Google Play Áruház korlátozza bizonyos alkalmazás engedélyeket, amelyek szükségesek e funkció használatához.',
  'apk-howto-on-the-phone': 'A gyerek telefonján az {applite} telepítve',
  'apk-howto-step1': 'Távolítsd el az {applite}-t, ha korábban a Google Play-en keresztül lett letöltve',
  'apk-howto-step2': 'Töltse le az alkalmazást közvetlenül a mi weboldalunkon keresztül a gyerek telefonjára',
  'apk-howto-step2B': 'vagy olvassa be az alábbi QR-kódot a gyerek telefonjával',
  'apk-howto-step3': 'Telepítse a letöltött alkalmazást az eszközre',
  'apk-howto-tutorial': 'Útmutató az alkalmazás telefonra vagy tabletre történő telepítéséhez',

  // Cookies

  'cookies-info-uses': 'Ez a weboldal sütiket használ.',
  'cookies-info-privacy-policy': 'További információ az adatvédelmi szabályzatban.',

  // Plan names

  'plan-name-demoExpired': 'A próbaidőszak lejárt',
  'plan-name-licExpired': 'A terv lejárt',
  'plan-name-reward': 'Bónusz',
  'plan-name-demo': 'Próbaidőszak',
  'plan-name-minimal': 'Alap',
  'plan-name-regular': 'Rendes',
  'plan-name-premium': 'Prémium',

  // agreements

  'agreement-will-inform-user': 'Kijelentem, hogy tájékoztatni fogom a felhasználót, akinek az eszközére az alkalmazás telepítve lesz, hogy az ő tevékenysége és helyzete meg lesz figyelve.',

  'agreement-privacy-policy': 'Kijelentem, hogy elolvastam és elfogadtam az <a href="{regulations}" target="_blank">feltételeket</a> és az <a href="{privacy}" target="_blank">adatvédelmi irányelveket</a>. Tanúsítom, hogy elmúltam 18 éves, és csak olyan gyerekeket adok hozzá a fiókomhoz, akik 18 év alattiak és a törvényes gyámja vagyok.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': 'Hozzájárulok, hogy marketing tartalmakat kapjak a(z) {domain} részéről a megadott email címre és telefonszámra vonatkozó promóciós ajánlatokról, valamint hogy az adatokat a webhely hirdetési partnerei számára továbbítsák digitális hirdetés céljából, ahogyan az az adatvédelmi irányelvekben szerepel. <a href="{marketing}" target="_blank">További információk</a>',


  'gdpr-page1-title': 'Értékeljük az Ön adatvédelmét',
  'gdpr-page1-content': 'Kattintson az "MINDEN ELFOGADÁSA" gombra, hogy hozzájáruljon a {domain} által az automatikus adatgyűjtés és személyes adatainak feldolgozásához sütik használatával. Sütiket használunk a böngészési élmény javítása érdekében, hirdetések vagy tartalmak megjelenítésére, amelyek megfelelnek az egyes felhasználók igényeinek, és a weboldalunk forgalmának elemzésére. Az "MINDEN ELFOGADÁSA" gombra kattintva hozzájárul a sütik használatához.',

  'gdpr-page1-advanced': 'További információ',
  'gdpr-page1-agree': 'MINDEN ELFOGADÁSA',

  'gdpr-page2-title': 'További információ',
  'gdpr-page2-content': 'Amikor böngészi weboldalunkat {domain} vagy {panel}, automatikusan gyűjthetünk adatokat az Ön tevékenységéről. Ezeket az adatokat sütik segítségével gyűjtjük. A sütik beágyazódnak a számítógépe vagy más eszköze böngészőjébe a webhely böngészése közben. A sütik lehetővé teszik azt is, hogy bejelentkezzen a {panel} felületére a fiókbeállítások megváltoztatásához és a védett felhasználók tevékenységének megtekintéséhez. A sütiket személyre szabásra és hirdetések profilozására is használjuk az Ön tevékenysége alapján az avosmart.com oldalon és minden aloldalán. Néhány süti elengedhetetlen a webhely megfelelő működéséhez, míg mások opcionálisak. Válassza ki, mely sütikhez járul hozzá. <a href="{privacy}" target="_blank">Bővebben az adatvédelmi irányelveinkben.</a>',

  'gdpr-page2-necessary': 'Csak Esszenciális',
  'gdpr-page2-agree': 'MIND ELFOGAD',
}

export default {
  locale: 'hu',
  name: 'Magyar',
  data: appLocalData,
  messages
} as LanguageLocale
