import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import Spinner from '../../../components/Spinner'
import { InjectedI18nProviderProps, withI18nProvider } from '../../../providers/i18nProvider'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, AppUsageData } from '../../../providers/benServiceProvider'
import { dateToAllDayFromToDate } from '../../../lib'
import DatePickerWithButtons from '../../../containers/DatePickerWithButtons'

import AppsUsagesChart, { AppsUsageChartData, AppsUsageChartRow } from '../../../containers/AppsUsageChart'
import AppsUsageList, { AppsUsageListData } from '../../../containers/AppsUsageList'
import I18nMessages from '../../../components/I18nMessages'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import {HtmlEntities} from '../../../lib/HtmlEntities'
import {describeTimeElapsedHMS}  from '../../../lib/utils'

type Props = InjectedI18nProviderProps & InjectedBenServiceProps & InjectedBenAccountProps & InjectedIntlProps


const ApplicationStatisticsPage: React.FC<Props> = ({
  benAccount,
  benService,
  i18nProvider,
  intl

}) => {

  const [selectedDate, setSelectedDate] = React.useState( new Date() )
  const [isLoading, setIsLoading] = React.useState(true)
  const [appsUsageData, setAppsUsageData] = React.useState<AppUsageData[]>([])

  const entities = new HtmlEntities()

  function handleDatePickerDateChange (date: Date | null) {
    if (date !== null) {
      setSelectedDate(date)
    }
  }

  function mapAppsUsageDataToAppsUsageChartData (appsUsageData: AppUsageData[]): AppsUsageChartData[] 
  {
    const fromToDate = dateToAllDayFromToDate(selectedDate)

    let r = appsUsageData.reduce<AppsUsageChartData[]>((prev, current) => {
      const index = prev.findIndex(item => item.pkg === current.pkg)
      const hour = Math.trunc((current.from - (fromToDate.from.getTime() / 1000)) / 3600)
      
      const row: AppsUsageChartRow = {
        count: current.count,
        elapsed: current.elapsed,
        from: current.from,
        to: current.to,
        hour
      }

      if (index !== -1) {
        prev[index].rows.push(row)
      } else {
        prev.push({
          name: entities.decode(current.name),
          pkg: current.pkg,
          rows: [row],
          usedCount: current.count
        })
      }

      return prev
    }, [])

    return r
  }

    function groupAppsUsageData (list: AppUsageData[], keyGetter: (i:AppUsageData)=>string ) : Map<string, AppsUsageListData[]>
    {
        const map = new Map()

        list.forEach((item: AppUsageData) => {

            const key = keyGetter(item)
        
            const collection = key ? map.get(key) : null

            if (!collection) 
            {
                let i : AppsUsageListData = {
                    pkg: item.pkg,
                    name: entities.decode(item.name),
                    ico: item.ico,
                    elapsed: item.elapsed,
                    runCount: item.count
                }
                
                map.set(key, [i]) 
            } 
            else 
            {
                collection[0].elapsed  = collection[0].elapsed + item.elapsed
                collection[0].runCount = collection[0].runCount + item.count
            }
        })

        return map
    }

    function mapAppsUsageDataToAppsUsageListData (appsUSageData: AppUsageData[]): AppsUsageListData[] 
    {
        const groupedData = groupAppsUsageData(appsUSageData, (item: AppUsageData) => item.pkg)

        let arr = Array.from( groupedData, item => item[1][0] )

        return arr.sort( (a, b) => b.elapsed - a.elapsed )
    }

    function getTotalTimeStr(appsUsageData: AppUsageData[]) : string
    {
      var elapsed = appsUsageData.reduce<number>( (p, c) => p + c.elapsed, 0)

      if( elapsed < 1 )
        return '---'

      return describeTimeElapsedHMS(elapsed, intl)
    }

  React.useEffect(() => {
    const { deviceId, profileId } = benAccount.currentProfile
    const fromToDate = dateToAllDayFromToDate(selectedDate)
    let isMounted = true

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {

      setIsLoading(true)

      benService.getAppsUsage(profileId, deviceId, fromToDate.from, fromToDate.to, 0, 100)
      .then(result => isMounted && setAppsUsageData(result.data))
      .finally(() => isMounted && setIsLoading(false))

    } else {
      setIsLoading(false)
    }

    return () => {
      isMounted = false
    }

  }, [selectedDate, benAccount, benService])

  return (
    <React.Fragment>


      {isLoading && (
        <Row style={{ position: 'absolute' }} >
          <Col sm="12">
            <Spinner />
          </Col>
        </Row>
      )}

      <Row className="mb-4">
        <Col sm="12" className="d-flex align-items-center justify-content-end">
          {/* <span className="mr-4"><I18nMessages id="date-picker.select-date-label" /></span> */}
          <DatePickerWithButtons onSelectedDateChanged = {handleDatePickerDateChange} selectedDate={selectedDate}/>
        </Col>
      </Row>

      {  (
        <React.Fragment>
          <Row className="mb-4">
            <Col sm="12">
              <Card>
                <CardBody>
                  <AppsUsagesChart data={mapAppsUsageDataToAppsUsageChartData(appsUsageData)}/>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
            
              <Card>
                <CardBody>
                    <h3> <I18nMessages id='applications-page.total-time-label' /> { getTotalTimeStr(appsUsageData) } </h3>
                    <div style={{ marginBottom:20 }} className="border-bottom"> </div>
                    <AppsUsageList data={mapAppsUsageDataToAppsUsageListData(appsUsageData)}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <div style={{ height:900 }}></div>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default injectIntl(withBenAccount(withBenService(withI18nProvider(ApplicationStatisticsPage))))
