import React from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import I18nMessages from '../components/I18nMessages'

import { getAvatarUrl } from '../lib/utils'
import { Device } from '../providers/benServiceProvider'


interface ProfileTabProps extends RouteComponentProps {
  id: string
  name: string
  avatar: number
  firstDevice: string
  firstDeviceDevice: Device | null
  currentProfileId: string | null
  onEditClick?: (profileId: string) => void
  onDeleteClick?: (profileId: string) => void
  onSelectProfileClick: (profileId: string, deviceId: string, device: Device | null) => void
  isDeleteButtonEnabled?: boolean
}



const ProfileTab: React.FC<ProfileTabProps> = ({
  id,
  name,
  avatar,
  firstDevice,
  firstDeviceDevice,
  onSelectProfileClick,
  currentProfileId,
  onEditClick,
  onDeleteClick,
  isDeleteButtonEnabled
}: ProfileTabProps) => {

  const [tooltipDeleteOpen, setTooltipDeleteOpen] = React.useState(false);


  return (
    <div className={currentProfileId === id ? 'active' : ''} onClick={() => currentProfileId !== id ? onSelectProfileClick(id, firstDevice, firstDeviceDevice) : null}>
      <img className="profile-icon" src={getAvatarUrl(avatar)} alt = {name}/>
      <h5>{name}</h5>

      {currentProfileId === id && <div className="micro-btns">
        {onEditClick && (
            <React.Fragment>
                <Button size="xs" 
                        className="remove-btn" 
                        color="light" 
                        id={'editProfile' + id} 
                        onClick={() => onEditClick(id)}>
                  <i className="simple-icon-pencil" />
                </Button>
                <UncontrolledTooltip placement="right" target={'editProfile' + id}>
                    <I18nMessages id="profile-list.edit-profile-label" />
                </UncontrolledTooltip>
            </React.Fragment> )}

        {onDeleteClick && (
            <Button size="xs" 
                    className="edit-btn" 
                    color="light" 
                    id={'deleteProfile' + id} 
                    disabled={!isDeleteButtonEnabled} 
                    onClick={() => onDeleteClick(id)}>
                <i className="simple-icon-trash" />
            </Button>)}

        {onDeleteClick && !isDeleteButtonEnabled && 
          <UncontrolledTooltip 
                  isOpen={tooltipDeleteOpen}
                  onClick={(e) => setTooltipDeleteOpen(!tooltipDeleteOpen) } 
                  toggle={ () => setTooltipDeleteOpen(!tooltipDeleteOpen) }
                  placement="right" 
                  target={'deleteProfile' + id}>
              <I18nMessages id="profile-list.remove-profile-disabled-tooltip" />
          </UncontrolledTooltip>}

          {onDeleteClick && isDeleteButtonEnabled && 
          <UncontrolledTooltip 
                  placement="right" 
                  target={'deleteProfile' + id}>
              <I18nMessages id="profile-list.remove-profile-label" />
          </UncontrolledTooltip>}


      </div>}
    </div>
  )

}

export default withRouter(ProfileTab)
