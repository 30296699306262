import React, { MouseEvent } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Row, Col, Card, CardBody, Label, FormGroup } from 'reactstrap'
import { Formik, Form, Field } from 'formik'
import { NavLink } from 'react-router-dom'
import ReCaptcha from 'react-google-recaptcha'
import AsyncButton from '../components/AsyncButton'
import I18nMessages from '../components/I18nMessages'
import { InjectedBenServiceProps, withBenService, ActionResponse, ActionCode } from '../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import { validateEmail, useMountedState, reCaptchaDefaults } from '../lib'
import getMessage from '../language/getMessage'

// Assets
import appLogo from '../assets/media/logo.svg'
import { isWebView, callWebViewClose, callWebViewSuccess } from '../lib/utils'
import MiniRodo from '../components/MiniRodo'
import LanguageDropdown from '../containers/LanguageDropdown'

type FormValues = {
  email: string
  reCaptcha: string
}

type Props = InjectedBenNotificationProps & InjectedBenServiceProps & InjectedIntlProps

const initialValues: FormValues = {
  email: '',
  reCaptcha: ''
}

const ForgotPassword: React.FC<Props> = ({
  benNotification,
  benService,
  intl
}) => {

  const reCaptchaRef = React.useRef<ReCaptcha>(null)
  const formikRef = React.useRef<Formik<FormValues>>(null)
  const isMounted = useMountedState()
  const [isLoading, setLoading] = React.useState(false)
  const [isSucceeded, setSucceeded] = React.useState(false)

  const [errorMessage, setErrorMessage] = React.useState('')

  const webView = isWebView()

  function handleLoginClicked(e: MouseEvent): boolean {
    if (callWebViewClose()) {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation();

      return false
    }

    return true
  }

  function handleOnSuccessResetPassword(): void {
    benNotification.notify({
      type: 'success',
      title: getMessage('forgot-password-page.success-title', intl),
      message: getMessage('forgot-password-page.success-message', intl)
    })

    if (webView) {
      setTimeout(() => { callWebViewSuccess() }, 3000);
    }

    isMounted() && setSucceeded(true)
  }

  function handleOnFailedResetPassword(result: ActionResponse): void {

    if (result && result.code) {
      if (result.code == ActionCode.CODE_ACCOUNT_NOT_CONFIRMED) {
        setErrorMessage( getMessage( 'forgot-password-page.email-not-confirmed', intl ) )
        return;
      }
    }


    benNotification.notify({
      type: 'error',
      title: getMessage('side-effect.internal-error-title', intl),
      message: getMessage('side-effect.internal-error-message', intl)
    })

  }

  function handleOnReCaptchaChange(value: string | null) {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reCaptcha', value)
      value !== null && formikRef.current.submitForm()
    }
  }

  function handleOnReCaptchaExpired() {
    if (formikRef.current) {
      formikRef.current.setFieldValue('reCaptcha', '')
    }
  }

  function handleFormikOnSubmit(values: FormValues) {
    if (!values.reCaptcha) {
      return
    }

    setLoading(true)

    const email = String(values.email)

    benService.resetPassword(email, intl.locale, values.reCaptcha)
      .then(handleOnSuccessResetPassword)
      .catch(result => handleOnFailedResetPassword(result))
      .finally(() => isMounted() && setLoading(false))
  }

  function handleOnSubmitClick() {
    if (reCaptchaRef.current) {
      reCaptchaRef.current.execute()
    }
  }

  function isSubmitBtnDisabled(email: string): boolean {
    return email.trim().length === 0
  }

  React.useEffect(() => {
    document.body.classList.add('background')

    return () => {
      document.body.classList.remove('background')
    }
  }, [])

  return (
    <div className="fixed-background ">

      <div className="fixed-top m-2"  >
          <LanguageDropdown/>
      </div>

      <ReCaptcha
        ref={reCaptchaRef}
        size="invisible"
        sitekey={reCaptchaDefaults.sitekey}
        onChange={handleOnReCaptchaChange}
        onExpired={handleOnReCaptchaExpired}
        badge="bottomright"
        hl={intl.locale}
      />

      <main>
        <div className="container">
          <Row className="h-100">
            <Col md="6" className="mx-auto my-auto">
              <Card>
                <CardBody>

                  {!webView && (
                    <div className="benjamin-logo-container">
                      <img src={appLogo} className="login-logo" alt="logo" />
                    </div>
                  )}

                  <p className="text-center mb-4 h5">
                    <I18nMessages id="forgot-password-page.headline-title" />
                  </p>

                  <Formik ref={formikRef} initialValues={initialValues} onSubmit={handleFormikOnSubmit}>
                    {({ errors, touched, values }) => (
                      <Form className="av-tooltip tooltip-label-bottom">
                        <FormGroup className="form-group has-float-label">
                          <Label><I18nMessages id="forgot-password-page.email-label" /></Label>
                          <Field
                            name="email"
                            type="email"
                            className="form-control"
                            validate={(v: string) => validateEmail(v, intl)} />
                          {(errors.email && touched.email) && (
                            <div className="invalid-feedback d-block">{errors.email}</div>
                          )}

                          {(errorMessage) && (
                            <div className="invalid-feedback d-block">{errorMessage}</div>
                          )}


                        </FormGroup>

                        <div className="d-flex justify-content-between align-items-center">
                          <NavLink to="/login" onClick={(e) => handleLoginClicked(e)} >
                            <I18nMessages id="forgot-password-page.login-link-label" />
                          </NavLink>
                          <AsyncButton
                            disabled={isSucceeded || isSubmitBtnDisabled(values.email)}
                            color="primary"
                            showSpinner={isLoading}
                            onClick={handleOnSubmitClick}
                          ><I18nMessages id="forgot-password-page.reset-button" /></AsyncButton>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
              <MiniRodo inline={true} />
            </Col>
          </Row>
        </div>
      </main>

    </div>
  )
}

export default injectIntl(withBenService(withBenNotification(ForgotPassword)))
