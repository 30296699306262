import React from 'react'
import { Col, Card, CardBody, Alert, Button } from 'reactstrap'
import Spinner from '../../components/Spinner'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import I18nMessages from '../../components/I18nMessages'
import appLogo from '../../assets/media/logo.svg'
import { ActionStatus, PurchaseStatus, InjectedBenServiceProps, withBenService } from '../../providers/benServiceProvider'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { useMountedState } from '../../lib'
//import TagManager from 'react-gtm-module'
//import ReactGA  from 'react-ga'
import { getBuildConfigStr } from '../../lib/utils'

type PurchaseStatusPageProps = RouteComponentProps & InjectedBenServiceProps & InjectedIntlProps

const PurchaseStatusPage: React.FC<PurchaseStatusPageProps> = ({
                                                     benService, history, intl
                                                   }) => {

  const [isLoading, setLoading] = React.useState(true)
  const [purchaseStatus, setPurchaseStatus] = React.useState(-100)
  const isMounted = useMountedState()
  const [showBackBtn, setShowbackBtn] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add('h-100')
    return () => document.body.classList.remove('h-100')
  }, [])

  React.useEffect(() => {
    checkPurchaseStatus(1)
    // eslint-disable-next-line
  }, [isMounted])


  // GTM / GA przeniesione do backendu
  function loadingDoneTM()
  {
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'LoadingDone'
    //   }
    // });
  }

  // GTM / GA przeniesione do backendu
  function updateTMFailed()
  {
    // ReactGA.event({
    //   category: 'Payment',
    //   action: 'Failed',
    // })
  }

  // GTM / GA przeniesione do backendu
  function updateTM( data: PurchaseStatus )
  {
    // TagManager.dataLayer({
    //   dataLayer: {
    //     TransactionTotal: result.data.price,
    //     Years: Math.round(result.data.days/365),
    //     Days: result.data.days,
    //     Upgrade: result.data.upgrade,
    //     PlanName: result.data.plan,
    //     CurrencyCode: result.data.currency,
    //     LanguageCode: result.data.lang
    //   }
    // });

    // console.log( 'GTM data: price ' + data.price + ' ' + data.currency + 
    //              ', plan '        + data.plan + 
    //              ', is upgrade '  + data.upgrade + 
    //              ', days '        + data.days + 
    //              ', status '      + data.status + 
    //              ', lang '        + data.lang )

    // const ID = Date.now();
    
    /*

    ReactGA.event({
      category: 'Payment',
      action: 'Success',
    })

    // ta wersja dziala,

    ReactGA.plugin.require('ec');

    ReactGA.plugin.execute(
        'ec',
        'addProduct',
        {
          'id': `${ID}`,                          // Transaction ID. Required for purchases and refunds.  Tu może być timestamp lub jakiś random.
          'name': `${data.plan}`,                 // Name or ID is required.
          'brand': getBuildConfigStr('NAME'),
          'variant': `${data.plan}`,
          'sku': `${data.plan}`,
          'price': `${data.price/100}`,              
          'currency': data.currency.toUpperCase(),
          'quantity': 1,  // ilość lat
          'category': data.upgrade ? '2' : '1',   // 1 jeśli główny zakup, 2 jeśli rozszerzenie.
          'coupon': 'NO_COUPON',                   // Optional fields may be omitted or set to empty string.
        } )

    ReactGA.plugin.execute(
        'ec',
        'setAction',
        'purchase',
        {
            'id': `${ID}`,                          // Transaction ID. Required for purchases and refunds.  Tu może być timestamp lub jakiś random.
            'affiliation': getBuildConfigStr('GTM_AFFILIATION'),
            'revenue': `${data.price/100}`,             // Total transaction value (incl. tax and shipping)
            'tax': '23',                            // vat
            'shipping': '0.0',
            'currency': data.currency.toUpperCase(),
            'coupon': 'NO_COUPON'
        })
    
    ReactGA.pageview('/order')
    ReactGA.plugin.execute('ec', 'clear', {} );
    */

    // TagManager.dataLayer({
    //   dataLayer: {
    //     ecommerce: null // Clear the previous ecommerce object.
    //   }
    // })

    // TagManager.dataLayer({
    //   dataLayer: {
    //     'ecommerce': {
    //       'purchase': {
    //         'actionField': {
    //           'id': `${ID}`,                          // Transaction ID. Required for purchases and refunds.  Tu może być timestamp lub jakiś random.
    //           'affiliation': getBuildConfigStr('GTM_AFFILIATION'),
    //           'revenue': `${data.price}`,             // Total transaction value (incl. tax and shipping)
    //           'tax': '23',                            // vat
    //           'shipping': '0.0',
    //           'coupon': 'NO_COUPON'
    //         },
    //         'products': [{                            // List of productFieldObjects.
    //           'name': `${data.plan}`,                 // Name or ID is required.
    //           'id': `${ID}`,                          // Transaction ID. Required for purchases and refunds.  Tu może być timestamp lub jakiś random.
    //           'price': `${data.price}`,
    //           'brand': getBuildConfigStr('NAME'),
    //           'category': data.upgrade ? '2' : '1',   // 1 jeśli główny zakup, 2 jeśli rozszerzenie.
    //           'variant': `${data.plan}`,
    //           'quantity': Math.round(data.days/365),  // ilość lat
    //           'coupon': 'NO_COUPON'                   // Optional fields may be omitted or set to empty string.
    //         }]
    //       }
    //     }
    //   }
    // })
  }

  
  function checkPurchaseStatus (count: number) 
  {
    benService.purchaseStatus()
      .then(result => 
      {
        setPurchaseStatus(result.data.status);

        if (result.status === ActionStatus.pending && count < 15 )
        {
          setTimeout(() => {
              checkPurchaseStatus(count + 1)
          }, 1000)
        } 
        else 
        {
          benService.getUser(intl.locale)
          if (result.status === ActionStatus.ok) 
          {
            loadingDoneTM()
            updateTM(result.data);
          }
          else
          {
            updateTMFailed()
          }
          setShowbackBtn(true)
          setLoading(false)
        }
      })
      .catch(() => {
        setLoading(false)
        setPurchaseStatus(-500)
        setShowbackBtn(true)
        //loadingDoneTM()
      })
  }

  function handleBackButtonClick () {
    history.push('/')
  }

  function openVAT(e: React.MouseEvent<HTMLAnchorElement, MouseEvent> ) 
  {
    const url = getBuildConfigStr('URL_INVOICE')
    window.open(url)
    e.preventDefault()
    e.stopPropagation()
    return false
  }

  return (
    <Col lg="12" className="mx-auto my-auto">
      <Card>
        <CardBody className="purchase-status-page">
          <div className="benjamin-logo-container">
            <img src={appLogo} className="login-logo" alt="logo" />
          </div>

          <div className="text-center">
            <I18nMessages tagName="h1" id="purchase-status-page.title" />
          </div>

          {isLoading && (
            <Spinner />
          )}

          {purchaseStatus === 0 && <Alert color="info"><I18nMessages id="purchase-status-page.status-pending" /></Alert>}
          {purchaseStatus === 1 && <Alert color="success"><I18nMessages  id="purchase-status-page.status-completed" /></Alert>}
          {purchaseStatus === -1 && <Alert color="danger"><I18nMessages id="purchase-status-page.status-canceled" /></Alert>}
          {purchaseStatus === -2 && <Alert color="danger"><I18nMessages id="purchase-status-page.status-refund-finalized" /></Alert>}
          {purchaseStatus === -3 && <Alert color="info"><I18nMessages  id="purchase-status-page.status-wating-for-conf" /></Alert>}
          {purchaseStatus === -100 && <Alert color="info"><I18nMessages id="purchase-status-page.status-created" /></Alert>}
          {purchaseStatus === -999 && <Alert color="danger"><I18nMessages id="purchase-status-page.status-unkown" /></Alert>}
          {purchaseStatus === -500 && <Alert color="danger"><I18nMessages id="purchase-status-page.status-error" /></Alert>}

          { showBackBtn && (
            <div className="return-button">
              <Button color="primary" onClick={handleBackButtonClick}>
                <I18nMessages id="purchase-status-pag.back-button" />
              </Button>
              <div className="text-center" style={{marginTop:'20px'}}>

              { purchaseStatus === 1 && getBuildConfigStr('URL_INVOICE') !== '' && (
                <a href={getBuildConfigStr('URL_INVOICE')} onClick={ (e)=>openVAT(e) }>
                  <I18nMessages id="purchase-status-pag.invoice-link" />
                </a>
              )}
              </div>
            </div> 
          )}

        </CardBody>
      </Card>
    </Col>
  )
}

export default withRouter(injectIntl(withBenService(PurchaseStatusPage)))
