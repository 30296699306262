import React from 'react'
import { Card, CardBody } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'
import ThumbnailLetters from '../components/ThumbnailLetters'
import { toLocaleDate, toLocaleDateTime, toLocaleTime } from '../lib/utils'
import { InjectedIntlProps, injectIntl } from 'react-intl'

type Props = InjectedIntlProps & {
  fullName: string
  lastUpdated?: Date
  lastContacted?: Date
  phoneNumbers: string[]
}

const ContactCard: React.FC<Props> = ({
  fullName,
  lastUpdated,
  lastContacted,
  phoneNumbers,
  intl
}) => {

  const LastUpdatedLabel: React.FC = () => {
    if (lastUpdated) {
      return <span>{ toLocaleDate(lastUpdated,intl) }</span>
    }

    return <I18nMessages id="calls-text-messages-page.contacts-no-changes-label" />
  }

  return (
    <Card className="mb-3">
      <CardBody className="d-flex align-item">
        <ThumbnailLetters rounded text={fullName} />
        <div className="ml-4">
          <p className="list-item-heading mb-0">{fullName}</p>
          <p className="text-muted">
            <I18nMessages id="calls-text-messages-page.contacts-last-changed-label" />
            <span className="ml-1">
              <LastUpdatedLabel />
            </span>
          </p>
          <ul className="list-unstyled">
            {phoneNumbers.map((phoneNumber, index) => (
              <li key={`${index}`}>
                <I18nMessages id="calls-text-messages-page.contacts-phone-label" />
                <span className="ml-1">{phoneNumber}</span>
              </li>
            ))}
          </ul>
        </div>
      </CardBody>
    </Card>
  )
}

export default injectIntl(ContactCard)
