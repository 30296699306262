import React from 'react'
import classnames from 'classnames'
import { Label } from 'reactstrap'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { toLocaleHour } from '../lib/utils'

export type DaysOfWeek = {
  mon: boolean[],
  tue: boolean[],
  wed: boolean[],
  thu: boolean[],
  fri: boolean[],
  sat: boolean[],
  sun: boolean[]
}

export type DaysOfWeekLabels = {
  mon: string,
  tue: string,
  wed: string,
  thu: string,
  fri: string,
  sat: string,
  sun: string
}

type MouseDownState = {
  isDragging: boolean
  value: boolean
}

interface ScheduleProps {
  daysOfWeek: DaysOfWeek
  daysOfWeekLabels: DaysOfWeekLabels,
  dayHourCount?: number
  onUpdate: (dayOfWeek: keyof DaysOfWeek, hour: number, value: boolean) => void
}



const DayOfWeek = ({
  dragging,
  hours,
  label,
  onStartDragging,
  onClick
}: {
  dragging: MouseDownState,
  label: string,
  hours: boolean[],
  onClick: (hour: number, value: boolean) => void
  onStartDragging: (value: boolean) => void
}) => (

  <div className="schedule-row">
    <Label className="schedule-label">{label}</Label>
    {hours.map((item, index) => (
      <div
        key={index}
        className={classnames('schedule-item', { 'selected': item })}
        onMouseDown={() => { onStartDragging(!item); !dragging.isDragging && onClick(index, !item) }}
        onMouseOver={() => dragging.isDragging && onClick(index, dragging.value)}
      />
    ))}
  </div>
)


const Schedule = ({
  daysOfWeek,
  daysOfWeekLabels,
  dayHourCount = 24,
  onUpdate,
  intl
}: ScheduleProps & InjectedIntlProps) => {

  const formatHour = (h : number) => {
    return toLocaleHour(h,  intl)
  }
  
  const ItemLabel = ({ dayHourCount }: { dayHourCount: number }) => (
  
    <div className="schedule-row header">
      <Label className="schedule-label empty" />

      {Array.from({ length: dayHourCount }, (_, i) => i).map(hour => (
        <div key={hour} className="schedule-item-label">{ formatHour(hour) }</div>
      ))}
    </div>
  )
  

  const [mouseDown, setMouseDown] = React.useState<MouseDownState>({
    isDragging: false,
    value: false
  })

  function handleStartDragging (value: boolean) {
    setMouseDown({ isDragging: true, value })
  }

  function handleStopDragging () {
    setMouseDown({ ...mouseDown, isDragging: false })
  }

  return (
    <div
      className="schedule-container"
      onMouseUp={handleStopDragging}
      onMouseLeave={handleStopDragging}
    >
      <ItemLabel dayHourCount={dayHourCount} />
      <DayOfWeek
        onStartDragging={handleStartDragging}
        dragging={mouseDown}
        label={daysOfWeekLabels.mon}
        onClick={(hour, value) => onUpdate('mon', hour, value)}
        hours={daysOfWeek.mon}
      />
      <DayOfWeek
        onStartDragging={handleStartDragging}
        dragging={mouseDown}
        label={daysOfWeekLabels.tue}
        onClick={(hour, value) => onUpdate('tue', hour, value)}
        hours={daysOfWeek.tue}
      />
      <DayOfWeek
        onStartDragging={handleStartDragging}
        dragging={mouseDown}
        label={daysOfWeekLabels.wed}
        onClick={(hour, value) => onUpdate('wed', hour, value)}
        hours={daysOfWeek.wed}
      />
      <DayOfWeek
        onStartDragging={handleStartDragging}
        dragging={mouseDown}
        label={daysOfWeekLabels.thu}
        onClick={(hour, value) => onUpdate('thu', hour, value)}
        hours={daysOfWeek.thu}
      />
      <DayOfWeek
        onStartDragging={handleStartDragging}
        dragging={mouseDown}
        label={daysOfWeekLabels.fri}
        onClick={(hour, value) => onUpdate('fri', hour, value)} hours={daysOfWeek.fri}
      />
      <DayOfWeek
        onStartDragging={handleStartDragging}
        dragging={mouseDown}
        label={daysOfWeekLabels.sat}
        onClick={(hour, value) => onUpdate('sat', hour, value)}
        hours={daysOfWeek.sat}
      />
      <DayOfWeek
        onStartDragging={handleStartDragging}
        dragging={mouseDown}
        label={daysOfWeekLabels.sun}
        onClick={(hour, value) => onUpdate('sun', hour, value)}
        hours={daysOfWeek.sun}
      />
    </div>
  )
}
export default injectIntl(Schedule)
