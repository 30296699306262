import React from 'react'
import { Modal, ModalHeader, ModalBody, NavLink, Button } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'

type Props = {
  title?: string
  isOpen: boolean
  reverse?: boolean
  onYes: () => void
  onNo: () => void
}

const YesNoModal: React.FC<Props> = ({
  children,
  title,
  isOpen,
  reverse,
  onYes,
  onNo
}) => {

  return (
    <Modal isOpen={isOpen} toggle={onNo}>
      {title && (
        <ModalHeader>{title}</ModalHeader>
      )}

      <ModalBody>
        <div className="mb-4">
          {children}
        </div>

        <div className="d-flex align-items-center justify-content-between">

          {reverse ? (
            <React.Fragment>
                <NavLink onClick={onYes} className="cursor-pointer">
                    <I18nMessages id="confirmation-modal.button-yes"/>
                </NavLink>
                <Button color="danger" onClick={onNo}>
                    <I18nMessages id="confirmation-modal.button-no" />
                </Button>
            </React.Fragment>
          
            ) : (

            <React.Fragment>
                <NavLink onClick={onNo} className="cursor-pointer">
                    <I18nMessages id="confirmation-modal.button-no"/>
                </NavLink>
                <Button color="danger" onClick={onYes}>
                    <I18nMessages id="confirmation-modal.button-yes" />
                </Button>
            </React.Fragment>
          )}

        </div>
      </ModalBody>
    </Modal>
  )
}

export default YesNoModal
