import React from 'react'

interface SeparatorProps {
  className?: string
}

const Separator = ({
  className = 'mb-5'
}: SeparatorProps) => (

  <div className={`separator ${className}`} />
)

export default Separator
