import React, { CSSProperties } from 'react';
import VizSensor from 'react-visibility-sensor';
import Spinner from '../../components/Spinner'
import { ScreenThumbItem, ScreenHeaderItem } from '../../providers/benServiceProvider';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { toLocaleTime } from '../../lib/utils';


interface ThumbImgClicked { (imgId: number): void }

interface ThumbRequestImg { (imgId: number, isVisible: boolean): void }

interface ThumbRequestDelete { (imgId: number): void }

interface ThumbSelected { (imgId: number, isSelected: boolean ): boolean }


type Props = InjectedIntlProps &
{
    header : ScreenHeaderItem
    selectMode: boolean
    thumbsCache:  Map<number, ScreenThumbItem>
    onThumbRequestImg: ThumbRequestImg
    onThumbImgClicked: ThumbImgClicked
    onThumbRequestDelete: ThumbRequestDelete
    onThumbSelected: ThumbSelected
}
  

const imgStyle: CSSProperties = {
    margin: '10px',
    cursor: 'pointer'
}


const ScreenGrabThumb: React.FC<Props> = ({
    header,
    selectMode,
    thumbsCache,
    onThumbRequestImg,
    onThumbImgClicked,
    onThumbRequestDelete,
    onThumbSelected,
    intl
}) => {

    const [isSelected, setSelected] = React.useState(false)
    const [sayNo, setSayNo] = React.useState(false)
    const [thumb, setThumb] = React.useState<ScreenThumbItem | null>(null)

    const onVisibilityChange = (isVisible : boolean) => {

        if( thumb === null )
        {
            if( isVisible )
            {
                const t = thumbsCache.get(header.id)
                if( t !== undefined )
                {
                    setThumb(t)
                    return
                }
            }

            onThumbRequestImg(header.id, isVisible)
        }
    }

    const onImageClick = () => {

        if( selectMode )
        {
            if( onThumbSelected(header.id, !isSelected) )
            {
                setSelected(!isSelected)
            }
            else
            {
                if( !isSelected )
                {
                    setSayNo(true)
                    setTimeout( ()=> { setSayNo(false) }, 1000 )
                }
            }
        }
        else
        {
            onThumbImgClicked(header.id)
        }
    }

    const onImageDelete = () => {
        onThumbRequestDelete(header.id)
    }

    React.useEffect( () => {

        setSelected(false)

    }, [selectMode] )


    React.useEffect( () => {

        if( thumb === null )
        {
            const t = thumbsCache.get(header.id)

            if( t !== undefined )
            {
                setThumb(t)
            }
        }

    }, [thumbsCache] )
    

    return (

        <VizSensor
            intervalDelay={1000}
            onChange={onVisibilityChange}
            active={thumb === null}
            partialVisibility >

            <React.Fragment>

                {thumb === null && (

                    <div className="col-lg-2 col-md-3 col-sm-auto" >
                        <Spinner />
                    </div>

                )}

                { thumb !== null && (

                    <div className='col-lg-2 col-md-3 col-sm-auto screen-list-image' >

                        <img alt="" className={'img-fluid border-radius ' + (selectMode && isSelected ? 'selected' : '') + (selectMode && sayNo ? ' say-no' : '')}
                            src={`data:image/jpeg;base64,${thumb.jpeg}`}
                            style={imgStyle}
                            onClick={onImageClick} />

                        <span className="img-time">
                            { toLocaleTime( new Date(thumb.ts * 1000), intl) }
                        </span>

                        <span className="img-delete simple-icon-trash"  onClick={onImageDelete} />

                    </div>

                )}

            </React.Fragment>

        </VizSensor>
    )
}

export default injectIntl(ScreenGrabThumb)
