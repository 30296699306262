import React, { SyntheticEvent } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

import I18nMessages from '../../components/I18nMessages';
import Spinner from '../../components/Spinner';
import { shouldDisplaySmsScreensAnyway, toLocaleDateTime, toLocaleTime, translateScreenErrorMessage } from '../../lib/utils';
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider';
import { InjectedBenServiceProps, ScreenItem, withBenService } from '../../providers/benServiceProvider';
import { withI18nProvider } from '../../providers/i18nProvider';

import ApkHowto from './ApkHowto';


type Props = InjectedBenAccountProps & InjectedBenServiceProps & InjectedIntlProps


const RELOAD_SCREEN_INTERVAL = 1000 * 60 * 2;
const ScreenViewPage: React.FC<Props> = ({
    benAccount,
    benService,
    intl
  }) => {

    const [isLoading, setLoading] = React.useState(true)
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
    const [screenItem, setScreenItem] = React.useState<ScreenItem | null>(null)
    const [isPortrait, setIsPortrait] = React.useState<boolean>(false)

    const getScreenItemInfo = ( item : ScreenItem  ) =>
    {
      const d = new Date(item.ts * 1000)

      if( item.userName )
        return item.userName + ', ' + toLocaleDateTime(d, intl)

      return toLocaleDateTime(d, intl)
    }

    function checkIsPortrait(data: SyntheticEvent) {
      const img: any = data.currentTarget;

      const w = img.naturalWidth || img.width,
      h = img.naturalHeight || img.height;

      setIsPortrait(h > w)
    }

    React.useEffect(() => 
    {
      if( shouldDisplaySmsScreensAnyway(benAccount) ) 
        return

      const interval = setInterval(() => {
        reloadScreen();
      }, RELOAD_SCREEN_INTERVAL);

      return () => clearInterval(interval);

    }, [screenItem]);

    React.useEffect(() =>
    {
        let isMounted = true

        if( shouldDisplaySmsScreensAnyway(benAccount) ) 
          return

        setLoading(true)
        setErrorMessage(null)
        setScreenItem(null)

        reloadScreen()

        return () => {
            isMounted = false
        }
      }, [benAccount, benService])


    function reloadScreen() {
      const { deviceId, profileId } = benAccount.currentProfile

      setLoading(true)
      setErrorMessage(null)

      if (deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' )
      {
          benService.screenGrab(profileId, deviceId)
              .then(result => {

                    setLoading( false )

                    if( Number(result.code) !== 0 )
                    {
                        setScreenItem(null)
                        setErrorMessage(result.status)
                    }
                    else
                    {
                        setScreenItem(result.data)
                        setErrorMessage(null)
                    }
              })
              .catch(() => {
                setScreenItem(null)
                setLoading( false )
              } )

      } else {
          //console.log("Wrong deviceId")
      }
    }
    

    if( shouldDisplaySmsScreensAnyway(benAccount) )
    {
      return (
        <ApkHowto labelMessageId="screenview-page.headline-title"/>
      )
    }


    return (
        <div className="dashboard-wrapper">

        <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
            <h1 className="mb-0 p-0"><I18nMessages id="screenview-page.headline-title" /></h1>
        </div>


        <Row className="mb-4">
            <Col lg="12">
            <Card>
                <CardBody>
                    <CardTitle tag="h3">
                        <I18nMessages id="screenview-page.chart-description" />

                        <button className="btn btn-light ml-3" onClick={reloadScreen} > <I18nMessages id="screenview-page.reload-button" /> </button>
                        <br/><br/>
                        { screenItem != null && (
                            <div>
                                { getScreenItemInfo( screenItem) }
                            </div>
                        )}

                        { isLoading && (
                            <Spinner />
                        )}

                    </CardTitle>

                    { errorMessage && (
                        <h3> { translateScreenErrorMessage(errorMessage, intl) } </h3>
                    )}

                    { screenItem != null && (
                      <div className="screen-image">
                        <img src={`data:image/jpeg;base64,${screenItem.jpeg}`} style={{width:"100%"}} alt="Preview" className={isPortrait ? 'portrait' : ''} onLoad={(e) => {
                          checkIsPortrait(e)
                        }} />
                      </div>
                    )}

                </CardBody>
            </Card>
            </Col>
        </Row>

        </div>
    )
}

export default injectIntl(withBenService(withBenAccount(withI18nProvider(ScreenViewPage))))
