import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { InjectedBenHelperProps, withBenHelper } from '../providers/benHelperProvider'
import { InjectedBenServiceProps, withBenService } from '../providers/benServiceProvider'
import queryString from 'querystring'

type Props = InjectedBenHelperProps &  RouteComponentProps & InjectedBenServiceProps 

const PromoCodePage: React.FC<Props> = ({
    benHelper,
    benService,
    history,

    location

}) => {

    var promoCode : string = ''
    var redirectRegister : boolean = false

    function parseParams() 
    {
        var search = location.search
        if( search.startsWith('?'))
            search = search.substr(1)

        const params = queryString.parse(search)

        if( params['p'] ) 
        {
            var p = String( params['p'] )

            if( p.length > 100 )
                p = p.substr(0,100)

            promoCode = p
        }

        if( params['r'] ) 
        {
            var r = Number( params['r'] )

            redirectRegister = (r > 0)
        }
        
    }

    React.useEffect(() => {
        
        parseParams()

        if( promoCode ) {
            benHelper.setPromoCode(promoCode)
            //console.log('setting pc='+p)
        }

        if( !benService.isAuthorized ) {

            if(redirectRegister)
                history.push('/register')
            else
                history.push('/login')
        }
        else {

            history.push('/account/purchase')
        }
    
    }, [benService])


    if( !benService.isAuthorized ) 
    {
        return (
            <div>
                Login...
            </div>
        )
    }

    return (

        <div>
            PromoCode...
        </div>
    )
    
}

export default withBenHelper( withRouter(withBenService(PromoCodePage)))
