import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/fr'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Tout va bien!',
  'side-effect.successful-message': 'Les données ont été enregistrées correctement',
  'side-effect.internal-error-title': "Quelque chose s'est mal passé",
  'side-effect.internal-error-message': 'Veuillez réessayer plus tard ou vérifier votre connexion Internet',

  'monday-sshort-label': 'Lu',
  'tuesday-sshort-label': 'Ma',
  'wednesday-sshort-label': 'Me',
  'thursday-sshort-label': 'Je',
  'friday-sshort-label': 'Ve',
  'saturday-sshort-label': 'Sa',
  'sunday-sshort-label': 'Di',

  'monday-short-label': 'Lun',
  'tuesday-short-label': 'Mar',
  'wednesday-short-label': 'Mer',
  'thursday-short-label': 'Jeu',
  'friday-short-label': 'Ven',
  'saturday-short-label': 'Sam',
  'sunday-short-label': 'Dim',

  'monday-label': 'Lundi',
  'tuesday-label': 'Mardi',
  'wednesday-label': 'Mercredi',
  'thursday-label': 'Jeudi',
  'friday-label': 'Vendredi',
  'saturday-label': 'Samedi',
  'sunday-label': 'Dimanche',

  'january-label': 'Janvier',
  'february-label': 'Février',
  'march-label': 'Mars',
  'april-label': 'Avril',
  'may-label': 'Mai',
  'june-label': 'Juin',
  'july-label': 'Juillet',
  'august-label': 'Août',
  'september-label': 'Septembre',
  'october-label': 'Octobre',
  'november-label': 'Novembre',
  'december-label': 'Décembre',

  'interval-name-year': 'année',
  'interval-name-month': 'mois',
  'interval-name-week': 'semaine',
  'interval-name-day': 'jour',
  'interval-name-years': 'années',
  'interval-name-months': 'mois',
  'interval-name-weeks': 'semaines',
  'interval-name-days': 'jours',

  'select-input.no-options-label': "Pas d'options",
  'filter-input.label': 'Filtre:',

  'no-data.label': 'Aucune donnée à afficher. Changez les filtres ou la date sélectionnée.',
  'no-data.label-mid': 'Aucune donnée à afficher. Changez la date sélectionnée.',
  'no-data.label-short': 'Aucune donnée à afficher.',
  'empty-list-label': 'Liste vide.',
  'overlay.empty-device-label': "Aucun appareil n'a encore été ajouté à ce profil.",
  'overlay.empty-profiles-label': 'Aucun profil ajouté pour le moment.',
  'overlay.empty-device.add-device-label': 'Ajouter un appareil',

  'confirmation-modal.cancel-button': 'Annuler',
  'confirmation-modal.confirm-button': 'CONFIRMER',
  'confirmation-modal.button-yes': 'Oui',
  'confirmation-modal.button-no': 'Non',

  // Date Picker
  'date-picker.select-date-label': 'Sélectionner une date',

  // Date Filter
  'date-filter.select-label': 'Sélectionner une période',
  'date-filter.this-month': 'Ce mois-ci',
  'date-filter.this.week': 'Cette semaine',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Sélectionner un jour',
  'date-filter.from-label': 'De: ',
  'date-filter.to-label': 'À: ',

  // Time Picker
  'time-picker.unlimied-label': 'Illimité',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',

  'time-picker.add-bonus-title': "Ajouter un bonus de temps uniquement pour aujourd'hui",
  'time-picker.no-bonus': 'Aucun bonus',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Définir un bonus',
  'time-picker.add-bonus-button-cancel': 'Annuler',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Choix de la période',
  'profile-image-picker.girl-label': 'Fille',
  'profile-image-picker.boy-label': 'Garçon',

  // Profile List
  'profile-list.remove-device-label': "Supprimer l'appareil",
  'profile-list.move-device-label': "Déplacer l'appareil vers un autre profil",
  'profile-list.add-device-label': 'Ajouter un appareil',

  'profile-list.add-profile-label': 'Ajouter un profil',
  'profile-list.edit-profile-label': 'Modifier le profil',
  'profile-list.add-devices-label': 'Ajouter un nouvel appareil',
  'profile-list.remove-profile-label': 'Supprimer le profil',
  'profile-list.remove-profile-disabled-tooltip': "Pour supprimer votre profil, vous devez d'abord supprimer tous les appareils associés à celui-ci.",
  'profile-list.title': 'Liste des profils',

  // Profile Form
  'profile-form.full-name-label': 'Nom',
  'profile-form.month-of-birth-label': 'Mois de naissance',
  'profile-form.year-of-birth-label': 'Année de naissance',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Annuler',
  'profile-form-modal.create.submit-button': 'AJOUTER PROFIL',
  'profile-form-modal.create.title': 'Ajouter un profil enfant',
  'profile-form-modal.edit.skip-button': 'Annuler',
  'profile-form-modal.edit.submit-button': 'SAUVER',
  'profile-form-modal.edit.title': "Modifier le profil de l'enfant",
  'profile-form-modal.max-profiles-error': 'Limite de profil atteinte',

  // Profile Move Device Form
  'profile-move-device-modal.title': "Déplacer l'appareil vers un autre profil",
  'profile-move-device-modal.cancel-button': 'Annuler',
  'profile-move-device-modal.submit-button': 'Sauvegarder',
  'profile-move-device-modal.select-profile-label': 'Choisir un profil',

  // CallLog
  'call-log.duration-label': "Durée de l'appel :",
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'AJOUTER UN PROFIL',
  'create-profile-page.skip-button': 'Annuler',
  'create-profile-page.title': "Ajouter un profil d'enfant",
  'create-profile-page.description': 'Qui sera surveillé',

  // Add device page
  'add-device-page.close-button': 'FERMER',
  'add-device-page.description': "Choisissez le type d'appareil pour installer l'application",
  'add-device-page.title': "Guide d'installation",
  'add-device-page.check': 'Consulter le manuel',
  'add-device-page.mobile-title': 'Smartphone ou tablette Android',
  'add-device-page.mobile-description': 'Version Android 6 ou supérieure',
  'add-device-page.desktop-title': 'Ordinateur de bureau ou portable Windows',
  'add-device-page.desktop-description': 'Version Windows 7 ou supérieure',


  // Purchase Page
  'purchase-page.skip-label': 'Allez au tableau de bord',
  'purchase-page.title': 'Choisissez le meilleur plan',
  'purchase-page.title.features': 'Tous les plans offrent les fonctionnalités suivantes',
  'purchase-page.title.reviews': 'Pourquoi nos utilisateurs nous adorent',
  'purchase-page.subtitle': 'Si le programme ne répond pas à vos attentes, nous vous rembourserons le paiement',
  'purchase-page.plan-highlight': 'Meilleure valeur',
  'purchase-page.monthly': 'mensuel',
  'purchase-page.select': 'Choisir',
  'purchase-page.close': 'Fermer',
  'purchase-page.money-back.title': 'Sans risque',
  'purchase-page.money-back.desc': "Si vous n'êtes pas satisfait, vous serez remboursé.",
  'purchase-page.header.check-1': 'Meilleure application de contrôle parental',
  'purchase-page.header.check-2': 'Définir une limite de temps pour les jeux, YouTube et les sites web',
  'purchase-page.header.check-3': 'Des centaines de milliers de clients satisfaits',
  'purchase-page.plan.year': 'année',
  'purchase-page.plan.optimal': 'Meilleure valeur',


  'purchase-page.modal.2y-header': 'Offre spéciale!',
  'purchase-page.modal.2y-sub-header': 'Choisissez le <b>plan de 2 ans et économisez</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Choisissez le <b>plan de 2 ans et économisez</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': "Protégez jusqu'à {devices} appareils",
  'purchase-page.modal.upgrade': 'Choisissez la réduction',
  'purchase-page.modal.no-thanks': 'Continuez avec le plan de 1 an',


  'purchase-plan.features.title.feature_1': 'Limite de temps pour les applications et les jeux',
  'purchase-plan.features.title.feature_2': 'Filtrage des sites web',
  'purchase-plan.features.title.feature_3': 'Bloquez les applications, les jeux et les sites web',
  'purchase-plan.features.title.feature_4': 'Surveillance de Youtube',
  'purchase-plan.features.title.feature_5': "Visualisation d'écran à distance en direct",
  'purchase-plan.features.title.feature_6': 'Accès à distance',
  'purchase-plan.features.title.feature_7': 'Rapports détaillés',
  'purchase-plan.features.title.feature_8': 'Localisation (téléphone uniquement)',
  'purchase-plan.features.title.feature_9': 'Vérifiez les messages et les appels',
  'purchase-plan.features.title.feature_10': 'Support technique prioritaire',

  'purchase-plan.features.desc.feature_1': "Définissez des limites de temps pour les applications, les jeux et l'accès à Internet. De plus, planifiez les heures auxquelles votre enfant est autorisé à les utiliser",
  'purchase-plan.features.desc.feature_2': "Tous les sites inappropriés sont bloqués. Le programme utilise un algorithme qui analyse le contenu du site pour tout matériel inapproprié. Il recueille également des informations à partir d'une immense collection de bases de données de sites et de mots inappropriés, qui sont régulièrement mises à jour.",
  'purchase-plan.features.desc.feature_3': 'Bloquez les applications et sites web qui pourraient avoir un impact négatif sur vos enfants. De plus, tout nouveau jeu installé nécessitera votre autorisation.',
  'purchase-plan.features.desc.feature_4': "Dans le tableau de bord, surveillez toutes les vidéos que votre enfant regarde sur YouTube. Bloquez n'importe quelle vidéo ou bloquez complètement l'accès aux services de vidéo.",
  'purchase-plan.features.desc.feature_5': "Accédez à distance à un flux en direct de l'écran de votre enfant et vérifiez son activité tout au long de la journée. Cela permet de garder un œil attentif sur le contenu auquel votre enfant accède.",
  'purchase-plan.features.desc.feature_6': "Une fois connecté, il est possible d'accéder au tableau de bord parental de n'importe où et depuis n'importe quel appareil. Dans le tableau de bord, ajustez les paramètres, surveillez les appareils des enfants et définissez des limites de temps.",
  'purchase-plan.features.desc.feature_7': "Dans le tableau de bord parental, surveillez facilement l'activité d'un enfant sur son ordinateur ou son téléphone, voyez combien de temps d'écran il a passé, quels sites web il a visités, et bien plus encore.",
  'purchase-plan.features.desc.feature_8': "Vérifiez l'emplacement actuel de votre enfant et ses emplacements passés sur la carte. Voyez exactement où se trouvait votre enfant à un jour donné ou à un certain moment.",
  'purchase-plan.features.desc.feature_9': 'Vous pouvez facilement vérifier les messages envoyés et reçus de votre enfant, ses appels téléphoniques et les contacts enregistrés en utilisant le panneau parental.',
  'purchase-plan.features.desc.feature_10': "Si vous avez besoin d'aide, nous sommes toujours disponibles. Il existe également de nombreux guides vidéo pour vous aider à utiliser {app}.",



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Très efficace ! Protège complètement mon enfant des contenus internet nocifs.',
  'purchase-plan.raitings.desc.raiting_2': "Exactement ce que je cherchais ! J'ai fixé une limite d'une heure par jour pour que mes filles accèdent à internet et Avosmart s'en occupe pour moi.",
  'purchase-plan.raitings.desc.raiting_3': "Je recommande Avosmart car il offre une variété de fonctions. Mes enfants sont protégés lorsqu'ils utilisent internet et je peux contrôler le temps qu'ils passent à jouer, etc.",
  'purchase-plan.raitings.desc.raiting_4': 'Il était important pour ma femme et moi de pouvoir surveiller les habitudes de notre fille sur les réseaux sociaux. Avosmart nous aide à garder un équilibre entre la vie numérique et réelle de notre fille.',
  'purchase-plan.raitings.desc.raiting_5': "Mon fils avait l'habitude de passer trop de temps sur internet, utilisant un ordinateur portable, une tablette, etc. Avec les rapports fournis par Avosmart, je sais exactement quels sites mon fils visite et pendant combien de temps.",
  'purchase-plan.raitings.desc.raiting_6': "Je peux maintenant vérifier l'emplacement actuel de mon fils à tout moment. Un excellent service !",


  'account-settings-page.title': 'Paramètres du compte',
  'account-settings-page.automatic-update': "Mises à jour automatiques de l'application Windows",
  'account-settings-page.timezone': 'Fuseau horaire : ',
  'account-settings-page.send-reports': "Envoyer des rapports d'activité quotidiens",


  'plans-info-page.title': "Informations sur l'abonnement",
  'plans-info-page.plan': ' Plan :',
  'plans-info-page.max-devices': "Nombre maximum d'appareils :",
  'plans-info-page.valid-till': "Date d'expiration :",

  'plans-info-page.your-plan': 'Votre plan :',
  'plans-info-page.subscription-information': "Informations sur l'abonnement",
  'plans-info-page.subscription-created': 'Abonnement créé : ',
  'plans-info-page.subscription-current-period': "Période d'abonnement actuelle : ",
  'plans-info-page.subscription-renews-on': 'Votre plan se renouvellera le ',
  'plans-info-page.subscription-cancel-renewal': 'Annuler le renouvellement',
  'plans-info-page.subscription-not-renewed': 'Votre plan NE sera PAS renouvelé à la fin de la période actuelle',
  'plans-info-page.subscription-enable-renewal': 'Activer le renouvellement',
  'plans-info-page.subscription-price': "Prix de l'abonnement : ",
  'plans-info-page.subscription-price-amount': '{amount} tous les {period}',


  'plans-info-page.upgrade-plan': 'Améliorer votre plan',
  'plans-info-page.extend-lic': 'Prolonger votre plan',
  'plans-info-page.subscriptions-portal': 'Gérer vos méthodes de paiement',
  'plans-info-page.recent-payments': 'Dernières transactions',
  'plans-info-page.date-of-payment': 'Date de la transaction :',
  'plans-info-page.transaction-status': 'Statut de la transaction :',
  'plans-info-page.no-recent-payments': 'Aucune transaction récente',
  'plans-info-page.btn-close': 'Fermer',
  'plans-info-page.plan-devices': "jusqu'à {devices} appareils",
  'plans-info-page.plan-devices-types': 'ordinateurs/tablettes/téléphones',


  'upgrade-page.title': 'Améliorer le plan',
  'upgrade-page.devices': 'Appareils : ',
  'upgrade-page.valid-till': "Votre plan est valide jusqu'au : ",
  'upgrade-page.confirm-upgrade': 'Votre plan sera amélioré, vous serez facturé de {amount}',
  'upgrade-page.pay-now': 'Payer maintenant {amount}',
  'upgrade-page.pay-then': 'Puis {amount} tous les {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Acheter un abonnement',
  'purchase-checkout.up-to-devices': "Jusqu'à {devices} appareils",
  'purchase-checkout.subscribtion-price': '{amount} tous les {period}',
  'purchase-checkout.subscribe': "S'abonner",
  'purchase-checkout.cancel': 'Annuler',


  // Purchase Status Page
  'purchase-status-page.title': 'Vérification du paiement',
  'purchase-status-page.status-pending': 'En attente',
  'purchase-status-page.status-completed': 'Complété',
  'purchase-status-page.status-canceled': 'Annulé',
  'purchase-status-page.status-refund-finalized': 'Remboursement finalisé',
  'purchase-status-page.status-wating-for-conf': 'En attente de confirmation',
  'purchase-status-page.status-created': 'Créé',
  'purchase-status-page.status-unkown': 'Inconnu',
  'purchase-status-page.status-error': 'Erreur',
  'purchase-status-pag.back-button': "Retour à la page d'accueil",
  'purchase-status-pag.invoice-link': 'Je souhaite recevoir une facture TVA',

  // Login Page
  'login-page.brandline': 'Se connecter',
  'login-page.brandline2': 'Filtrage de site web',
  'login-page.email-label': 'Adresse e-mail',
  'login-page.login-button': 'SE CONNECTER',
  'login-page.password-label': 'Mot de passe',
  'login-page.recovery-link': 'Mot de passe oublié ?',
  'login-page.invalid-login-error': "L'adresse e-mail ou le mot de passe que vous avez entré n'est pas valide",

  // Register Page
  'register-page.create-account-label': "Besoin d'un compte ? Créez un nouveau compte",
  'register-page.description': 'Créez votre compte gratuit',
  'register-page.email-label': 'Adresse e-mail',
  'register-page.login-link-label': 'Vous avez un compte ? Connectez-vous',
  'register-page.marketing-checkbox-label': 'Je consens à recevoir des informations et du matériel promotionnel',
  'register-page.password-label': 'Mot de passe',
  'register-page.phoneNumber-label': 'Numéro de téléphone',
  'register-page.terms-privacy-checkbox-label': "J'accepte la politique de confidentialité et les conditions d'utilisation",
  'register-page.register-button': 'INSCRIPTION',
  'register-page.register-success-title': 'Succès',
  'register-page.register-success-message': "Inscription réussie. Vérifiez votre boîte de réception pour confirmer l'e-mail",
  'register-page.register-error-captcha': 'Erreur de Captcha. Réessayez.',
  'register-page.register-error-already-registered-label': 'Déjà enregistré. Essayez de vous connecter ou de rappeler le mot de passe.',
  'register-page.register-error-password-to-short-label': 'Mot de passe trop court.',

  // Forgot Password Page
  'register-page.strong-pass': "Votre mot de passe est trop simple et peut être facilement déchiffré par d'autres personnes. Votre mot de passe doit contenir des lettres majuscules et minuscules ainsi que des chiffres, et doit comporter au moins 8 caractères. Êtes-vous sûr de vouloir utiliser un mot de passe simple ?",
  'forgot-password-page.headline-title': 'Rappel mot de passe',
  'forgot-password-page.email-label': 'adresse e-mail',
  'forgot-password-page.reset-button': 'RAPPELER MOT DE PASSE',
  'forgot-password-page.login-link-label': 'Retour à la page de connexion',
  'forgot-password-page.success-title': 'Réinitialisation de mot de passe',
  'forgot-password-page.success-message': 'Vérifiez votre boîte de réception pour les instructions sur la façon de réinitialiser votre mot de passe',
  'forgot-password-page.email-not-confirmed': "Votre adresse e-mail n'est pas vérifiée. La réinitialisation du mot de passe n'est pas disponible.",

  // Update Password Page
  'update-password-page.headline-title': 'Définir un nouveau mot de passe',
  'update-password-page.warning-title': 'REMARQUE :',
  'update-password-page.warning-description': 'Définir un nouveau mot de passe entraînera la perte des données de localisation précédemment enregistrées. Les autres données ne seront pas perdues.',
  'update-password-page.warning-agreement': "Je comprends et j'accepte la perte des données de localisation.",
  'update-password-page.new-password': 'Nouveau mot de passe :',
  'update-password-page.new-password-retype': 'Répétez le nouveau mot de passe :',
  'update-password-page.submit': 'Définir un nouveau mot de passe',
  'update-password-page.alert-weak-password': 'Votre mot de passe est trop simple et peut être facilement deviné par des personnes non autorisées. Le mot de passe doit contenir des lettres majuscules, des lettres minuscules, des chiffres et doit comporter au moins 8 caractères. Voulez-vous tout de même utiliser un mot de passe simple?',
  'update-password-page.alert-agreement': "L'acceptation de la perte de données est requise.",
  'update-password-page.error-message': "Erreur! La définition d'un nouveau mot de passe a échoué.",
  'update-password-page.success-message': 'Nouveau mot de passe défini',
  'update-password-page.unknow-error-message': "Quelque chose s'est mal passé",
  'update-password-page.error-link': 'Lien incorrect',
  'update-password-page.error-posswords-not-equal': 'Les mots de passe ne sont pas identiques',
  'update-password-page.error-posswords-length': 'Les mots de passe sont trop courts',
  'update-password-page.error-wrong-code': 'Code incorrect',
  'update-password-page.register-success-title': 'Réussi!',
  'update-password-page.register-success-message': 'Nouveau mot de passe défini.',

  // Error Page
  'not-found-page.title': "Oups... on dirait qu'une erreur s'est produite!",
  'not-found-page.error-code-text': "Code d'erreur",
  'not-found-page.go-back-home-button': "RETOUR À L'ACCUEIL",

  // Panel Page
  'panel-page.purchase-ending-soon-alert': "Votre plan se termine le {date}. Veuillez envisager de souscrire à un abonnement dès aujourd'hui",
  'panel-page.purchase-demo-alert': "Vous utilisez actuellement la version d'essai.",
  'panel-page.purchase-expired-alert': "Vous utilisez actuellement une version d'essai expirée. Achetez des abonnements pour continuer.",
  'panel-page.purchase-lic-expired-alert': "Votre plan d'abonnement a expiré. Achetez des abonnements pour continuer.",
  'panel-page.email-alert': "Vous n'avez pas confirmé votre adresse e-mail",
  'panel-page.button-email-resend': "Renvoyer l'e-mail",
  'panel-page.email-alert-resend': " ou renvoyer l'e-mail une fois de plus.",
  'panel-page.email-alert-resend-title': 'Confirmation',
  'panel-page.email-alert-resend-msg': "L'e-mail a été envoyé !",
  'panel-page.profile-delete-description': 'Êtes-vous sûr de vouloir supprimer le profil sélectionné ?',
  'panel-page.profile-delete-device-description': "Êtes-vous sûr de vouloir supprimer l'appareil sélectionné ? Les données seront perdues.",
  'panel-page.profile-delete-device-second-description': "La protection sur l'appareil sera désactivée. Êtes-vous sûr de vouloir continuer ?",

  'panel-page.email-confirmation-overlay-close': 'Fermer',
  'panel-page.email-confirmation-overlay-postpone': 'Plus tard',
  'panel-page.email-confirmation-overlay-resend': "Renvoyer l'e-mail",
  'panel-page.email-confirmation-overlay-header': "Votre adresse e-mail ci-dessous n'a pas été confirmée",
  'panel-page.email-confirmation-overlay-desc': "Si vous avez fait une erreur avec l'adresse e-mail ci-dessus, veuillez nous en informer immédiatement. <br>Si vous n'avez pas reçu l'e-mail, veuillez cliquer sur 'renvoyer' et vérifier vos autres dossiers de courrier (y compris les spams/indésirables).",

  'panel-page.email-confirmation-code-success-header': 'Merci',
  'panel-page.email-confirmation-code-success-desc': "L'e-mail a été confirmé !",
  'panel-page.email-confirmation-code-header': 'Veuillez entrer le code de confirmation à 6 chiffres qui <br> a été envoyé à votre adresse e-mail.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Le code fourni est invalide',
  'panel-page.email-confirmation-code-error': 'Une erreur inattendue est survenue lors de la validation du code',

  // Summary Page
  'summary-page.title': 'Tableau de bord',
  'summary-page.top-apps.title': 'Meilleures applications',
  'summary-page.top-blocked.title': 'Plus bloqué',
  'summary-page.top-domains.title': 'Pages web les plus visitées',
  'summary-page.top-search.title': 'Meilleure recherche',
  'summary-page.access-disable': 'Désactivation temporaire',
  'internet-page.access-time-limit-set-label': "Limite quotidienne pour l'accès à Internet :",
  'internet-page.access-time-limit-description': "Définir des limites de temps quotidiennes pour chaque appareil et choisir le nombre d'heures d'utilisation d'internet autorisé.",
  'disable-access.protection-enabled': 'La protection est active',
  'disable-access.protection-disabled-for': 'La protection est temporairement désactivée, {time} minutes restantes',
  'disable-access.device-offline': 'Appareil indisponible',
  'disable-access.protection-status': 'Statut de la protection',


  // Internet Page
  'internet-page.headline-title': 'Pages web',
  'internet-page.access-time-page-title': "Temps d'accès",
  'internet-page.settings-page-title': 'Paramètres',
  'internet-page.statistics-page-title': 'Statistiques',
  'internet-page.searches-page-title': 'Recherches',

  'internet-page.statistics-page-www-ok': 'Site web approuvé',
  'internet-page.statistics-page-www-ok-white': 'Site web sûr',
  'internet-page.statistics-page-www-timelimit': 'Site web bloqué en raison des limites de temps',
  'internet-page.statistics-page-www-userdef': 'Site web bloqué, définitions utilisateur',
  'internet-page.statistics-page-www-content': 'Site web bloqué, contenu inapproprié',


  'internet-page.white-black-list-page-title': 'Sites web sûrs / Bloqués',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filtres',
  'internet-page.settings-page.white-list-only-label': "N'autoriser l'accès qu'aux sites de la liste sûre",
  'internet-page.settings-page.block-erotic-label': "Bloquer l'accès aux sites web érotiques",
  'internet-page.settings-page.block-offensive-label': "Bloquer l'accès aux sites web offensants, agressifs",
  'internet-page.settings-page.block-file-sites-label': "Bloquer l'accès aux pages permettant de télécharger des fichiers d'internet",
  'internet-page.settings-page.block-video-label': "Bloquer l'accès aux portails vidéo",
  'internet-page.settings-page.block-social-label': "Bloquer l'accès aux réseaux sociaux",
  'internet-page.settings-page.block-doc-label': 'Empêcher le téléchargement de documents',
  'internet-page.settings-page.block-exe-label': 'Empêcher le téléchargement de programmes',
  'internet-page.settings-page.excludeYT-label': 'Ne pas imposer la recherche sécurisée sur YouTube',
  'internet-page.settings-page.block-new-apps': 'Bloquer les nouvelles applications',
  'internet-page.settings-page.block-settings': "Bloquer l'accès aux paramètres",
  'internet-page.settings-page.custom-msg.card-tile': 'Message pour les pages bloquées',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Utiliser un message personnalisé pour les sites web bloqués',
  'internet-page.settings-page.save-settings-button-label': 'Enregistrer les paramètres',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Ajouter à la liste :',
  'internet-page.lists.add-button-label': 'Ajouter',
  'internet-page.lists.remove-button-label': 'Supprimer',
  'internet-page.lists.white-list.card-title': 'Liste sûre',
  'internet-page.lists.black-list.card-title': 'Liste bloquée',
  'internet-page.lists.remove-prefix': "L'adresse saisie commence par \"{prefix}\", il est recommandé d'ajouter uniquement le nom de domaine. Voulez-vous supprimer \"{prefix}\" de l'adresse?",

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Pages visitées',

  'internet-page.searches.searches-pages-card-title': 'Phrases recherchées',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Calendrier',
  'internet-page.access-time.schedule-legend-label': 'Légende du calendrier',
  'internet-page.access-time.schedule-internet-available-label': 'Internet disponible',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet bloqué',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Appels & Messages Textuels (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Appels',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Messages Textuels (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Dernier changement :',
  'calls-text-messages-page.contacts-no-changes-label': 'Vide.',
  'calls-text-messages-page.contacts-phone-label': 'tél.',
  'calls-text-messages-page.contacts-title': 'Contacts',

  // Localization Page
  'localization-page.headline-title': 'Localisation',
  'localization-page.headline-title-off': 'Localisations, le suivi GPS est désactivé',
  'localization-page.map-hours-filter-label': "Sélectionnez une plage horaire pour voir l'historique des localisations",
  'localization-page.gps-tracking-on': 'Suivi GPS activé',
  'localization-page.gps-view-speed': 'Afficher la vitesse de déplacement',
  'localization-page.map-loading-label': 'Chargement des cartes...',
  'localization-page.map-no-data': 'PAS DE DONNÉES',

  'localization-page.one-gps-was-off-since': "Les services de localisation de l'appareil sont désactivés depuis {lastOffStr}",
  'localization-page.one-gps-was-off-for': "Les services de localisation de l'appareil sont désactivés depuis {elapsedStr}",
  'localization-page.many-gps-was-off-since': "Les services de localisation de l'appareil sont désactivés depuis {lastOffStr}",
  'localization-page.many-gps-was-off-for': "Les services de localisation de l'appareil sont désactivés depuis {elapsedStr}",


  // Battery Page
  'battery-page.headline-title': 'Batterie',
  'battery-page.chart-description': 'Niveau de la batterie',

  // Access Time Page
  'access-time-page.headline-title': "Heure d'accès",
  'access-time-page.nav-access-time-label-applications': 'Applications',
  'access-time-page.nav-access-time-label-internet': 'Pages web',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Liste des vidéos',
  'youtube-page.no-videos': 'Aucune vidéo à la date sélectionnée',
  'youtube-page.block-videoid': 'Bloquer cette vidéo',
  'youtube-page.block-channelid': 'Bloquer cette chaîne',
  'youtube-page.video-blocked': 'Bloqué',

  // Screen Pages
  'screenview-page.headline-title': "Affichage de l'écran",
  'screenview-page.reload-button': 'Recharger',
  'screenview-page.chart-description': "Affichage actuel de l'écran",
  'screengrab-page.headline-title': "Capture d'écran",
  'screengrab-page.chart-description': "Capture d'écran",
  'screengrab-page.screen-save-time': "Fréquence de la capture d'écran",
  'screengrab-page.screen-save-time-desc': "Choisissez la fréquence des captures d'écran ou choisissez \"désactivé\" pour ne pas prendre de captures d'écran",
  'screengrab-page.screen-save-time-disabled': 'désactivé',
  'screengrab-page.no-screens-for-today': "Aucune capture d'écran prise ce jour-là",
  'screengrab-page.no-screens-for-filter': 'Aucun écran ne correspond aux critères sélectionnés',
  'screengrab-page.imgs-delete-description': 'Êtes-vous sûr de vouloir supprimer les images sélectionnées ?',
  'screengrab-page.imgs-delete-description-one': "Êtes-vous sûr de vouloir supprimer l'image sélectionnée ?",
  'screengrab-page.imgs-delete-label': 'Supprimer',
  'screengrab-page.imgs-delete-select': 'Sélectionnez pour supprimer',
  'screengrab-page.imgs-delete-selected': 'Sélectionné :',
  'screengrab-page.imgs-delete-limit': "La limite de captures d'écran sélectionnée a été atteinte.",
  'screengrab-page.imgs-filter': 'Filtrer :',
  'screengrab-page.imgs-filter-communicators': 'Communicateurs',
  'screengrab-page.imgs-filter-social': 'Réseaux sociaux',
  'screengrab-page.imgs-filter-video': 'Vidéo',
  'screengrab-page.imgs-filter-others': 'Autres',

  'screes-pages.device-offline': "L'appareil est hors ligne",
  'screes-pages.device-deactivated': 'La protection a été temporairement désactivée',
  'screes-pages.device-timeout': "L'appareil n'a pas répondu",
  'screes-pages.device-screenoff': "L'écran est éteint",
  'screes-pages.device-notallowed': "Capture d'écran non autorisée sur l'appareil",
  'screes-pages.device-error': "Une erreur s'est produite",

  'lazy-image.img-delete-description': "Êtes-vous sûr de vouloir supprimer l'image sélectionnée ?",

  // Applications Page
  'applications-page.headline-title': 'Applications',
  'applications-page.nav-access-time-label': "Heure d'accès",
  'applications-page.nav-statistics-label': 'Résumé',
  'applications-page.nav-apps-list-label': 'Liste des applications',
  'applications-page.chart-activity-by-day-label': 'Activité pendant la journée',
  'applications-page.chart-hour-label': 'Heure',
  'applications-page.chart-activity-by-hour-minutes-label': 'Activité pendant la journée en minutes',
  'applications-page.list-launched-count-label': 'Nombre de démarrages :',
  'applications-page.list-elapsed-time-label': "Temps d'exécution :",
  'applications-page.introduction': 'Applications installées',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Plage horaire planifiée',
  'applications-page.settings.schedule-legend-label': 'Légende du planning',
  'applications-page.settings.schedule-blocked-label': 'Applications bloquées',
  'applications-page.settings.schedule-available-label': 'Applications disponibles',
  'applications-page.time-limit.description': "Définir une limite de temps pour des applications spécifiques. Choisissez combien d'heures par jour l'utilisation de l'application est autorisée.",
  'applications-page.apps-headline-title': 'Applications disponibles',
  'applications-page.apps-first-seen-label': 'Vu pour la première fois :',
  'applications-page.apps-available-switch-label': 'Disponible',
  'applications-page.apps-not-available-switch-label': 'Indisponible',
  'applications-page.apps-schedule-switch-label': 'Programmé',
  'applications-page.apps-no-schedule-switch-label': 'Non programmé',
  'applications-page.apps-time-limit-switch-label': 'Limite de temps',
  'applications-page.apps-no-time-limit-switch-label': 'Aucune limite de temps',
  'applications-page.apps-show-uninstalled-label': 'Afficher les applications désinstallées',
  'applications-page.apps-show-apps-list': 'Choisissez des applications à durée limitée',

  'applications-page.used-count-zero-label': 'Non lancé',
  'applications-page.used-count-one-label': 'Lancé {value} fois, temps total : {totalTime}',
  'applications-page.used-count-many-label': 'Lancé {value} fois, temps total : {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sec',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sec',
  'applications-page.total-time-label': 'Temps total ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': "Le lien d'activation a été utilisé ou est invalide",
  'email-confirmation-page.successful-confirm': "L'e-mail a été confirmé. Merci!",
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Changer le mot de passe',
  'change-password-page.new-password-input-label': 'Nouveau mot de passe',
  'change-password-page.new-password-repeated-input-label': 'Répétez le nouveau mot de passe',
  'change-password-page.current-password-input-label': 'Mot de passe actuel',
  'change-password-page.back-button-label': 'Retour',
  'change-password-page.change-button-label': 'CHANGER',
  'change-password-page.successful-change-label': 'Le mot de passe a été changé correctement',
  'change-password-page.wrong-current-password-label': 'Mot de passe actuel incorrect',
  'change-password-page.password-too-short-label': 'Le nouveau mot de passe est trop court',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENU',
  'top-navigation.user-menu.account-label': 'Compte',
  'top-navigation.user-menu.sign-out': 'Déconnexion',
  'top-navigation.user-menu.subscription-label': "Plan d'abonnement",
  'top-navigation.user-menu.change-password-label': 'Changer le mot de passe',
  'top-navigation.user-menu.add-device-label': 'Ajouter un appareil',

  // Sidebar
  'sidebar.menu-item.applications': 'Applications',
  'sidebar.menu-item.calls-and-text-messages': 'Appels & Textes(SMS)',
  'sidebar.menu-item.internet': 'Pages Web',
  'sidebar.menu-item.localizations': 'Localisations',
  'sidebar.menu-item.social-networks': 'Réseaux sociaux',
  'sidebar.menu-item.summary': 'Tableau de bord',
  'sidebar.menu-item.battery': 'Batterie',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': "Vue de l'écran",
  'sidebar.menu-item.screengrab': "Capture de l'écran",
  'sidebar.menu-item.access-time': "Temps d'accès",

  // Validator
  'phone-number.not-valid': 'Entrez un numéro de téléphone valide',
  'input.required-label': 'Le champ est requis',
  'input.select-required-label': 'Veuillez sélectionner',
  'email.not-valid-label': 'Entrez une adresse e-mail valide',
  'domain-exist-label': 'Le domaine a été ajouté',
  'domain-exist-other-label': 'Le domaine existe déjà sur une autre liste',
  'domain-not-valid': "Le domaine n'est pas valide",
  'password-not-match-label': 'Les mots de passe ne correspondent pas',

  // misc
  'button-buy-label': 'Choisissez un plan',

  'transactiion-status-0': 'En attente',
  'transactiion-status-1': 'Terminé',
  'transactiion-status--1': 'Annulé',
  'transactiion-status--2': 'Annulé – remboursement',
  'transactiion-status--3': 'En attente de confirmation',
  'transactiion-status--100': 'Commencé',
  'transactiion-status-other': 'Autre',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Succès',
  'reward-page.error-already-used': 'Ce code a déjà été utilisé',
  'reward-page.error-invalid-code': 'Code incorrect',
  'reward-page.error-connection': 'Erreur de connexion',
  'reward-page.additional-bonus': 'Bonus',
  'reward-page.get-additional-bonus': 'Réclamer le coupon promotionnel',
  'reward-page.get-now': 'Réclamer',
  'reward-page.lic-was-extended-by-days': "Votre plan d'abonnement a été prolongé de {rewardDays} jours !",
  'reward-page.there-was-an-error': "Malheureusement, une erreur s'est produite.",
  'reward-page.lic-not-extended': "Votre plan d'abonnement n'a pas été prolongé.",
  'reward-page.button-close': 'Fermer',

  // url item
  'url-item.copied': 'Copié dans le presse-papiers',
  'url-item.copy-link': 'Copier le lien',

  // APK howto

  'apk-howto-google-play': "Le Google Play Store restreint certaines permissions d'application nécessaires pour utiliser cette fonction.",
  'apk-howto-on-the-phone': "Sur le téléphone de l'enfant avec {applite} installé",
  'apk-howto-step1': 'Désinstaller {applite} si précédemment téléchargé via Google Play',
  'apk-howto-step2': "Téléchargez l'application directement sur le téléphone de l'enfant depuis notre site web",
  'apk-howto-step2B': "ou scannez le code QR ci-dessous sur le téléphone de l'enfant",
  'apk-howto-step3': "Installer l'application téléchargée sur l'appareil",
  'apk-howto-tutorial': "Un guide sur la façon d'installer l'application sur un téléphone ou une tablette",

  // Cookies

  'cookies-info-uses': 'Ce site web utilise des cookies.',
  'cookies-info-privacy-policy': 'Plus dans la politique de confidentialité.',

  // Plan names

  'plan-name-demoExpired': 'Essai expiré',
  'plan-name-licExpired': 'Plan expiré',
  'plan-name-reward': 'Bonus',
  'plan-name-demo': 'Essai',
  'plan-name-minimal': 'De base',
  'plan-name-regular': 'Régulier',
  'plan-name-premium': 'Premium',

  // agreements

  'agreement-will-inform-user': "Je déclare que j'informerai l'utilisateur sur l'appareil duquel l'application sera installée que son activité et sa localisation seront surveillées.",

  'agreement-privacy-policy': 'Je déclare avoir lu et accepté les <a href="{regulations}" target="_blank">conditions</a> et la <a href="{privacy}" target="_blank">politique de confidentialité</a>. Je certifie avoir plus de 18 ans et ajouterai à mon compte uniquement des enfants de moins de 18 ans qui sont mes ayants droit légaux.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': "Je consens à recevoir du contenu marketing de {domain} concernant des offres promotionnelles à l'adresse e-mail et au numéro de téléphone que j'ai fournis, ainsi qu'à transmettre les détails aux partenaires publicitaires du site mentionnés dans la politique de confidentialité à des fins de publicité numérique. <a href=\"{marketing}\" target=\"_blank\">Plus</a>",


  'gdpr-page1-title': 'Nous Valorisons Votre Vie Privée',
  'gdpr-page1-content': "Cliquez sur \"ACCEPTER TOUT\" pour consentir à l'utilisation de Cookies pour la collecte et le traitement automatiques de vos données personnelles par {domain}. Nous utilisons des Cookies pour améliorer la qualité de la navigation, afficher des publicités ou des contenus adaptés aux besoins individuels des utilisateurs et analyser le trafic sur notre site web. En cliquant sur le bouton \"ACCEPTER TOUT\", vous consentez à notre utilisation des Cookies.",

  'gdpr-page1-advanced': "Plus d'Informations",
  'gdpr-page1-agree': 'ACCEPTER TOUT',

  'gdpr-page2-title': "Plus d'informations",
  'gdpr-page2-content': "Lorsque vous naviguez sur notre site web {domain} ou {panel}, nous pouvons automatiquement collecter des données sur votre activité. Ces données sont collectées à l'aide de Cookies. Les Cookies sont intégrés dans le navigateur de votre ordinateur ou d'un autre appareil lors de la navigation sur le site. Les Cookies vous permettent également de vous connecter à {panel} pour modifier les paramètres du compte et afficher l'activité des Utilisateurs protégés. Les Cookies sont également utilisés pour la personnalisation et le profilage des annonces en fonction de votre activité sur avosmart.com et toutes ses sous-pages. Certains Cookies sont essentiels au bon fonctionnement du site, tandis que d'autres sont facultatifs. Choisissez les Cookies auxquels vous consentez. <a href=\"{privacy}\" target=\"_blank\">Plus dans notre politique de confidentialité.</a>",

  'gdpr-page2-necessary': 'Essentiels uniquement',
  'gdpr-page2-agree': 'ACCEPTER TOUT',
}

export default {
  locale: 'fr',
  name: 'Français',
  data: appLocalData,
  messages
} as LanguageLocale
