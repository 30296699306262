import { themeColors } from './themeColors'
import { getBuildConfigStr } from './utils'

export const gMapKEY = getBuildConfigStr('MAPS') //  'AIzaSyCVdZ67KJ52dsr5_9D5eJixE0VCc2aOKeE'

export const gMapURL = 'https://maps.googleapis.com/maps/api/js?key=' + gMapKEY + '&v=3.exp&libraries=geometry,drawing,places'

export const gMapCircleDefault: google.maps.CircleOptions = {
  strokeColor: themeColors.themeColor4_10,
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: themeColors.themeColor4,
  radius: 16,
  zIndex: 10
}

export const gMapPolylineDefault: google.maps.PolylineOptions = {
  strokeColor: '#63aefd', //themeColors.blue,
  strokeOpacity: 1.0,
  strokeWeight: 8,
  zIndex: 9
}


export const gMapMarkerLongStay: google.maps.MarkerOptions = {

  icon: {

    // 'google' is not defined  no-undef
    // path: google.maps.SymbolPath.CIRCLE

    path: 0,

    scale: 10,

    fillColor: '#47c949',
    fillOpacity: 0.7,

    strokeColor: '#f7c707',
    strokeOpacity: 0.9,
    strokeWeight: 1
  },

  zIndex: 20,
  draggable: false,
}


export const gMapCircleLongStay: google.maps.CircleOptions = {
  strokeColor: '#47c949',
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: '#47c949',
  fillOpacity: 0.3,
  zIndex: 8,
}
