import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Modal, ModalHeader, ModalBody, NavLink } from 'reactstrap'
import AsyncButton from '../components/AsyncButton'
import I18nMessages from '../components/I18nMessages'
import ProfileMoveDeviceForm from './ProfileMoveDeviceForm'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import { InjectedBenServiceProps, withBenService } from '../providers/benServiceProvider'
import getMessage from '../language/getMessage'

type InjectedProps = InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps

type ProfileMoveDeviceModalProps = InjectedProps & {
  isOpen: boolean
  currentProfileId?: string
  deviceId: string | null
  onToggle: () => void
}

const ProfileMoveDeviceModal: React.FC<ProfileMoveDeviceModalProps> = ({
  benAccount,
  benNotification,
  benService,
  intl,
  isOpen,
  deviceId,
  onToggle,
  currentProfileId
}) => {

  const [isSubmitting, setSubmittingState] = React.useState(false)
  const [isMounted, setMounted] = React.useState(false)

  function handleOnFormSubmit (profileId: string) {
    setSubmittingState(true)

    benService.updateDevice(deviceId, profileId)
      .then(() => benAccount.refreshProfiles().then(() => isMounted && onToggle()))

      .catch(() => benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
      }))

      .finally(() => isMounted && setSubmittingState(false))
  }

  function getProfiles () {
    return benAccount.profiles.filter(function (profile) {
      return profile.id !== currentProfileId
    }).map(profile => ({ label: profile.name, value: profile.id }))
  }

  React.useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [setMounted])

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader>
        <I18nMessages id={'profile-move-device-modal.title'} />
      </ModalHeader>

      <ModalBody>
        <ProfileMoveDeviceForm
          defaultDeviceId={deviceId}
          profileId={getProfiles()}
          onSubmit={handleOnFormSubmit}
        >
          <div className="d-flex align-items-center justify-content-between">
            <NavLink onClick={onToggle} className="cursor-pointer">
              <I18nMessages
                id={'profile-move-device-modal.cancel-button'}
              />
            </NavLink>
            <AsyncButton showSpinner={isSubmitting} type="submit" color="primary">
              <I18nMessages
                id={'profile-move-device-modal.submit-button'}
              />
            </AsyncButton>
          </div>
        </ProfileMoveDeviceForm>
      </ModalBody>
    </Modal>
  )
}

export default injectIntl(withBenNotification(withBenService(withBenAccount(ProfileMoveDeviceModal))))
