import { InjectedIntl } from "react-intl";
import { GPSDataWithDate } from "../../../containers/LocalizationMap";
import { describeTimeElapsedHM, formatHourHHMM, toLocaleDate, toLocaleDateTime, toLocaleHour, toLocaleSpeed, toLocaleTime } from "../../../lib/utils";
import { GPSDataEx } from "../../../providers/benServiceProvider";

export class InfoWindow {
  private gmInfoWIndow: google.maps.InfoWindow;
  private infoWindowContent: HTMLDivElement;

  constructor(
    private GMInfoWIndow: any
  ) {
    this.gmInfoWIndow = new GMInfoWIndow({
      maxWidth: 110,
      zIndex: 30
    })

    this.infoWindowContent = document.createElement('div');
  }

  // LABELS - start
  getSpeedLabel(item: GPSDataWithDate, intl: InjectedIntl): string {
    return `${toLocaleTime(new Date(item.ts * 1000), intl)} <br> ${toLocaleSpeed(item.v, intl)}`
  }

  getTootlipDateTimeLabel(ts: number, intl: InjectedIntl): string {
    return toLocaleTime(new Date(ts*1000), intl)
  }

  getTootlipDateTimeLabelWithDay(ts: number, intl: InjectedIntl): string {
    return `${toLocaleTime(new Date(ts*1000), intl)} ${toLocaleDate(new Date(ts*1000), intl)} `
  }

  getToolTipLongStayLabel(item: GPSDataEx, intl: InjectedIntl) {
    let d1 = new Date(item.ts1 * 1000);
    let d2 = new Date(item.ts2 * 1000);

    let label = toLocaleTime(d2, intl) + ' - ' + toLocaleTime(d1, intl) + '<br>' + describeTimeElapsedHM(item.elapsed, intl);

    return label;
  }

  // LABELS - end

  set(content: string, map: any, position: any) {
    this.gmInfoWIndow.close();

    this.infoWindowContent.innerHTML = content;

    this.gmInfoWIndow.setContent(this.infoWindowContent);
    this.gmInfoWIndow.setOptions({
      maxWidth: 200
    });
    this.gmInfoWIndow.setPosition(position);
  }

  setEvents(element: any, label: string, map: any, position?: any) {
    const self = this;
    element.addListener('click', function(e: any) {
      self.set(label, map, position ? position : e.latLng);
      self.gmInfoWIndow.open(map, this);
    });

    element.addListener('mouseover', function(e: any) {
      self.set(label, map, position ? position : e.latLng);
      self.gmInfoWIndow.open(map, this);
    });

    element.addListener('mouseout', () => {
      this.close();
    });
  }

  close() {
    this.gmInfoWIndow.close();
  }

}
