



export function minutesToDecimal (minutes: number): number {
  return minutes / 60
}

export function numberToClockFormat (value: number): string {
  const date = new Date(0, 0)

  date.setSeconds(value * 60 * 60)

  return date.toTimeString().slice(0, 5)
}

export function minutesToClockFormat (value: number): string {
  const date = new Date(0, 0)

  date.setSeconds(value * 60)

  return date.toTimeString().slice(0, 5)
}

export function timeFormat (value: string): string {

  if (value.length === 1) {
    return `0${value}:00`
  }

  return `${value}:00`
}
