export function subtractDateByYears (date: Date, years: number): Date {
  const subtractDate = new Date(date)

  subtractDate.setFullYear(subtractDate.getFullYear() - years)

  return subtractDate
}

export function subtractDateByMonth (date: Date, count: number): Date {
  const subtractDate = new Date(date)

  subtractDate.setMonth(subtractDate.getMonth() - count)

  return subtractDate
}

export function subtractDateByDays (date: Date, count: number): Date {
  const subtractDate = new Date(date)

  subtractDate.setDate(subtractDate.getDate() - count)

  return subtractDate
}

export function addDateByDays (date: Date, count: number): Date {
  const subtractDate = new Date(date)

  subtractDate.setDate(subtractDate.getDate() + count)

  return subtractDate
}

export function addDateByHours (date: Date, count: number): Date {
  const subtractDate = new Date(date)

  subtractDate.setHours(subtractDate.getHours() + count)

  return subtractDate
}

export function getFromToDateFromFirstLastDateOfMonth (date: Date): { from: Date, to: Date } {
  const from = new Date(date.getFullYear(), date.getMonth(), 1)
  const to = new Date(date.getFullYear(), date.getMonth() + 1, 0)

  return { from, to }
}

export function getCurrentDate000()
{
  let currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  return currentDate
}

export function getCurrentDate000WithOffset(days: number) : Date
{
  let d = getCurrentDate000();
  return addDateByDays(d, days)
}

export function dateToAllDayFromToDate (date: Date): { from: Date, to: Date } {
  const fromDate = new Date(date.getTime())
  const toDate = new Date(date.getTime())

  fromDate.setHours(0, 0, 0, 0)
  toDate.setHours(23, 59, 59, 999)

  return {
    from: fromDate,
    to: toDate
  }
}


export function getCurrentDateFixed() : Date
{
  let date = new Date()
  date.setHours(23,59,59)
  return date
}
