import React from 'react'
import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'
import DateRange from './DateRange'

type Props = {
  dateFrom: Date | null
  dateTo: Date | null
  filter: string
  onDateFromChanged: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void
  onDateToChanged: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void
  onSearchChanged: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FilterWithDateRange: React.FC<Props> = ({
  dateFrom,
  dateTo,
  filter,
  onDateFromChanged,
  onDateToChanged,
  onSearchChanged,
}) => (

  <Row className="justify-content-between">
    <Col sm="4">
      <FormGroup row className="align-items-center">
        <Label sm="2">
          <I18nMessages id="filter-input.label" />
        </Label>
        <Col sm="9">
          <Input value={filter} onChange={onSearchChanged} />
        </Col>
      </FormGroup>
    </Col>

    <Col sm="4">
      <DateRange
        className="mb-sm-4"
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDateFromChanged={onDateFromChanged}
        onDateToChanged={onDateToChanged}
      />
    </Col>
  </Row>
)

export default FilterWithDateRange
