import React from 'react'

import I18nMessages from '../components/I18nMessages'
import { TopApps, TopBundle } from '../providers/benServiceProvider'
import { getColorForChartBackground } from '../lib'
import { expandIcoFileName, describeTimeElapsedHMS } from '../lib/utils'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import Spinner from '../components/Spinner'
import { Col, Row } from 'reactstrap'

//import { Row, Col } from 'reactstrap'

type Props = InjectedIntlProps & {
    topBundle: TopBundle | null | undefined
}

const TopAppsChart: React.FC<Props> = ({
    topBundle,
    intl
}) => {

    function getBarPercent(item: TopApps): string {
        if (topBundle == null || topBundle.apps.max < 1)
            return '0%'

        return (100 * (item.percent / topBundle.apps.max)) + '%'
    }


    return (
        <div >

            {topBundle === undefined && (
                <div className="chart-container d-flex align-items-center justify-content-center text-center">
                    <Spinner />
                </div>
            )}

            {(topBundle && topBundle.apps.data.length === 0) && (
                <div className="chart-container d-flex align-items-center justify-content-center text-center">
                    <I18nMessages id="no-data.label-mid" />
                </div>
            )}

            {topBundle != null && topBundle.apps.data.map((item, i) => (
                <Row key={i} className="mb-1  border-bottom">
                    <Col sm="5" className="d-flex align-items-center">
                        <div className="app-icon">
                            <img src={expandIcoFileName(item.ico)} alt={item.app} />
                        </div>
                        <div className="ml-2">
                            <p className="list-item-heading mb-0">{item.app}</p>
                            <p className="text-muted mb-1 app-list-name white-space-nowrap">{describeTimeElapsedHMS(item.elapsed, intl)}</p>
                        </div>
                    </Col>

                    <Col sm="7" className="d-flex align-items-center">
                        <div className="progress topAppsTableProgress">
                            <div className="progress-bar topAppsTableProgressBar" role="progressbar"
                                aria-valuenow={item.percent} aria-valuemin={0} aria-valuemax={topBundle.apps.max}
                                style={{ width: getBarPercent(item), backgroundColor: getColorForChartBackground(i) }} >
                            </div>
                        </div>
                    </Col>
                </Row>
            ))}

        </div>
    )
}

export default injectIntl(TopAppsChart)
