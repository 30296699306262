import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Label, FormGroup, Col } from 'reactstrap'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import ProfileImagePicker  from './ProfileImagePicker'
//import SelectField from './SelectField'
//import SelectFieldAsync from './SelectFieldAsync'
import I18nMessages from '../components/I18nMessages'
import getMessage from '../language/getMessage'
import { validateProfileName, validateProfileImage, validateNotEmpty } from '../lib/validators'
import {HtmlEntities} from '../lib/HtmlEntities'

export type DateOfBirth = {
  month?: number
  year?: number
}

type ProfileFormProps = InjectedIntlProps & {
  defaultName?: string
  defaultDateOfBirth?: DateOfBirth
  defaultProfileImage?: number
  onSubmit: (name: string, dateOfBirth: DateOfBirth, profileImage: number) => void
}

const CURRENT_YEAR = new Date().getFullYear()

const ProfileForm: React.FC<ProfileFormProps> = ({
  intl,
  children,
  defaultName,
  defaultProfileImage,
  defaultDateOfBirth,
  onSubmit
}) => {

  const monthOptions = [
    { label: getMessage('january-label', intl), value: 1 },
    { label: getMessage('february-label', intl), value: 2 },
    { label: getMessage('march-label', intl), value: 3 },
    { label: getMessage('april-label', intl), value: 4 },
    { label: getMessage('may-label', intl), value: 5 },
    { label: getMessage('june-label', intl), value: 6 },
    { label: getMessage('july-label', intl), value: 7 },
    { label: getMessage('august-label', intl), value: 8 },
    { label: getMessage('september-label', intl), value: 9 },
    { label: getMessage('october-label', intl), value: 10 },
    { label: getMessage('november-label', intl), value: 11 },
    { label: getMessage('december-label', intl), value: 12 }
  ]

  const yearSelectOptions = Array.from(Array(20).keys()).reverse().map(key => ({
    label: String(CURRENT_YEAR - key),
    value: (CURRENT_YEAR - key)
  }))

  // const loadYearSelectOptions = (inputValue: any, callback: any) => {
  //   if (isNaN(inputValue) || inputValue.length > 4 || ((inputValue.length === 1 && Number(inputValue) < 1)) || ((inputValue.length === 2 && Number(inputValue) < 19)) || (inputValue.length === 3 && Number(inputValue) < 190) || ((inputValue.length === 4 && Number(inputValue) < 1900))) {
  //     return callback([])
  //   } else if (inputValue.length === 0) {
  //     callback(yearSelectOptions)
  //   } else {
  //     let yearString = null
  //     switch (inputValue.length) {
  //       case 1:
  //         yearString = inputValue + '999'
  //         break
  //       case 2:
  //         yearString = inputValue + '99'
  //         break
  //       case 3:
  //         yearString = inputValue + '9'
  //         break
  //       case 4:
  //         yearString = inputValue

  //         if (Number(inputValue) > CURRENT_YEAR ) {
  //           callback([{
  //             label: String( CURRENT_YEAR ),
  //             value: CURRENT_YEAR
  //           }])

  //           break
  //         }

  //         callback([{
  //           label: inputValue,
  //           value: Number(inputValue)
  //         }])
  //         break
  //     }

  //     let year = Number(yearString)

  //     if (year > CURRENT_YEAR ) {
  //       year = CURRENT_YEAR
  //     }

  //     callback(Array.from(Array(20).keys()).map(key => ({
  //       label: String(year - key),
  //       value: (year - key)
  //     })))
  //   }
  // }

  function decodeName( name : string | undefined ) : string
  {
    if( !name )
      return ''

    const entities = new HtmlEntities()
    name = entities.decode(name)
    return name
  }

  const initValues = {
    profileName: decodeName(defaultName),
    profileImage: defaultProfileImage,
    year: (defaultDateOfBirth && defaultDateOfBirth.year) || 0,
    month: (defaultDateOfBirth && defaultDateOfBirth.month) || 0
  }

  function handleOnFormikSubmit (values: { profileName: string, profileImage?: number, month: number, year: number }) {
    if (values.profileImage) {
      //const valueDateOfBirth = { month: values.month, year: values.year }
      const valueDateOfBirth = { month: 0, year: 0 }
      onSubmit(values.profileName, valueDateOfBirth, values.profileImage)
    }
  }

  return (
    <Formik initialValues={initValues} onSubmit={handleOnFormikSubmit}>
      {({ errors, touched }) => (
        <Form className="av-tooltip tooltip-label-bottom">
          <FormGroup className="form-group has-float-label">
            <Label><I18nMessages id="profile-form.full-name-label" /></Label>
            <Field
              name="profileName"
              className="form-control"
              validate={(v: string) => validateProfileName(v, intl)}
            />
            {(errors.profileName && touched.profileName) && (
              <div className="invalid-feedback d-block">{errors.profileName}</div>
            )}
          </FormGroup>

          <FormGroup row >

            
            {/* <Col sm="8">
              <Label className="form-group has-float-label mb-4">
                <I18nMessages id="profile-form.month-of-birth-label" />
                <Field
                  name="month"
                  component={SelectField}
                  noOptionsMessage={() => getMessage('select-input.no-options-label', intl)}
                  options={monthOptions}
                  placeholder=""
                  validate={(v: number) => validateNotEmpty(v, intl)}
                />
                {(errors.month && touched.year) && (
                  <div className="invalid-feedback d-block">{errors.month}</div>
                )}
              </Label>
            </Col>

            <Col sm="4">
              <Label className="form-group has-float-label mb-4">
                <I18nMessages id="profile-form.year-of-birth-label" />
                <Field
                  name="year"
                  component={SelectFieldAsync}
                  noOptionsMessage={() => getMessage('select-input.no-options-label', intl)}
                  options={yearSelectOptions}
                  loadOptions = {loadYearSelectOptions}
                  placeholder=""
                  validate={(v: number) => validateNotEmpty(v, intl)}
                />
                {(errors.year && touched.year) && (
                  <div className="invalid-feedback d-block">{errors.year}</div>
                )}
              </Label>
            </Col> */}

            <div className="mb4 w-100">
              <Field
                name="profileImage"
                component={ProfileImagePicker}
                validate={(v: any) => validateProfileImage(v, intl)}
              />
              {(errors.profileImage && touched.profileImage) && (
                <div className="invalid-feedback d-block">{errors.profileImage}</div>
              )}
            </div>
          </FormGroup>

          <div className="form-bottom">
            {children}
          </div>
        </Form>
      )}
    </Formik>
  )

}

export default injectIntl(ProfileForm)
