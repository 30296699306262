import { CalculatedMinMaxCoords } from "../containers/LocalizationMapV2";
import { GPSData, GPSDataEx } from "../providers/benServiceProvider";import { describeTimeElapsedHM, formatHourHHMM } from "./utils";

export function speedToColorIdx(v: number, vMin: number, vMax: number, points: number) {
  if (vMax <= vMin)
    return 0;

  var idx = (v - vMin) / (vMax - vMin);

  idx = Math.round(idx * points) / points;

  return idx;
}

export function calculateMinMaxCoordinates(data: GPSData[]): CalculatedMinMaxCoords {
  if (data.length === 0) {
    return { maxLat: 0, maxLng: 0, minLat: 0, minLng: 0 }
  }

  var mm: CalculatedMinMaxCoords = {
    maxLat: data[0].lat,
    maxLng: data[0].lng,
    minLat: data[0].lat,
    minLng: data[0].lng
  }

  data.forEach(e => {
    if (mm.maxLat < e.lat) mm.maxLat = e.lat
    if (mm.maxLng < e.lng) mm.maxLng = e.lng
    if (mm.minLat > e.lat) mm.minLat = e.lat
    if (mm.minLng > e.lng) mm.minLng = e.lng
  });

  return mm
}

export function getColorForIdx2(i: any) {
  if (i < 0.0) i = 0.0;
  if (i > 1.0) i = 1.0;

  i = Math.round(i * 18);

  switch (i) {

    case 0: return '#0000ff';
    case 1: return '#0046ff';
    case 2: return '#008dff';
    case 3: return '#00d4ff';
    case 4: return '#00ffe2';
    case 5: return '#00ff9b';
    case 6: return '#00ff55';
    case 7: return '#00ff0e';
    case 8: return '#38ff00';
    case 9: return '#7fff00';
    case 10: return '#c6ff00';
    case 11: return '#fffa00';
    case 12: return '#ffe100';
    case 13: return '#ffc800';
    case 14: return '#ffaf00';
    case 15: return '#ff8900';
    case 16: return '#ff5b00';
    case 17: return '#ff2d00';
    default: return '#ff0000';
  }
}

export function getRadiusStay(item: GPSDataEx): number {

  let r = 50 + (item.lat + item.lngLen) * 0.2;

  if (item.elapsed > 60) r += 5;
  if (item.elapsed > 180) r += 5;
  if (item.elapsed > 360) r += 5;

  return r;
}

export function removeCircle(circle: any) {
  // remove event listers
  google.maps.event.clearListeners(circle, 'click');
  google.maps.event.clearListeners(circle, 'mouseover');
  google.maps.event.clearListeners(circle, 'mouseout');
  circle.setRadius(0);
  circle.setMap(null);
}