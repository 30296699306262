import React from 'react'
import { NavLink, Col, Card, CardBody } from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import AsyncButton from '../../components/AsyncButton'
import I18nMessages from '../../components/I18nMessages'
import ProfileForm, { DateOfBirth } from '../../containers/ProfileForm'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import { ANALYTICS_NAMES, ANALYTICS_VALUES, InjectedBenServiceProps, withBenService } from '../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'
import getMessage from '../../language/getMessage'

import appLogo from '../../assets/media/logo.svg'

type CreateProfilePageProps = InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps
  & RouteComponentProps

const CreateProfilePage: React.FC<CreateProfilePageProps> = ({
  benAccount,
  benNotification,
  benService,
  intl,
  history
}) => {

  const [isSubmitting, setSubmitting] = React.useState(false)
  const [isMounted, setMounted] = React.useState(false)

  function handleOnSkip () {
    benAccount.changeSettings({ ...benAccount.settings, skipProfileCreator: true })
    history.push('/')
  }

  function handleOnSubmit (name: string, dateOfBirth: DateOfBirth, profileImage: number) {
    setSubmitting(true)

    benService.createProfile( name, profileImage, dateOfBirth.year || 0, dateOfBirth.month || 0 )
      .then(() => benAccount.refreshProfiles().then(() => isMounted && history.push('/')))

      .catch(() => benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
      }))

      .finally(() => isMounted && setSubmitting(false))
  }

  React.useEffect(() => {
    document.body.classList.add('h-100')
    return () => document.body.classList.remove('h-100')
  }, [])


  React.useEffect(() => {
    benService.analyticsEvent(-1, ANALYTICS_NAMES.CREATE_PROFILE, ANALYTICS_VALUES.PAGE_DISPLAY ).catch( () => {} )
  }, [benService])  

  React.useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [setMounted])

  return (
    <Col lg="6" className="mx-auto my-auto">
      <Card>
        <CardBody>
          <div className="benjamin-logo-container">
            <img src={appLogo} className="login-logo" alt="logo" />
          </div>

          <div className="text-center">
            <I18nMessages tagName="h1" id="create-profile-page.title" />
          </div>
          <div className="text-muted mb-5">
            <I18nMessages id="create-profile-page.description" />
          </div>

          <ProfileForm onSubmit={handleOnSubmit}>
            <div className="d-flex align-items-center justify-content-between">
              <NavLink onClick={handleOnSkip} className="cursor-pointer">
                <I18nMessages id="create-profile-page.skip-button" />
              </NavLink>
              <AsyncButton showSpinner={isSubmitting} type="submit" color="primary">
                <I18nMessages id="create-profile-page.submit-button" />
              </AsyncButton>
            </div>
          </ProfileForm>
        </CardBody>
      </Card>
    </Col>
  )
}

export default withBenNotification(withBenService(withBenAccount(injectIntl(withRouter(CreateProfilePage)))))
