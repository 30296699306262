export class AvgHelper {
  public sum: any;
  public cnt: any;
  public min: any;
  public max: any;

  constructor() {
    this.sum = 0.0;
    this.cnt = 0;
    this.min = 0.0;
    this.max = 0.0;
  }

  add(v: any) {
    this.sum += v;

    if (this.cnt === 0) {
      this.min = v;
      this.max = v;
    }
    else {

      if (v < this.min)
        this.min = v;

      if (v > this.max)
        this.max = v;
    }

    this.cnt++;
  }

  get avg() {

    if (this.cnt > 0)
      return this.sum / this.cnt;
    else
      return 0.0;
  }
}
