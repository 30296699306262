import React from 'react'
import Spinner from '../components/Spinner'
import { Profiles, Profile, BenServiceContextValue, AccountType, Privileges, Device } from './benServiceProvider'

type BenAccountProviderProps = {
  benService: BenServiceContextValue
}

type CurrentProfile = {
  deviceId: string | null
  profileId: string | null
  device: Device | null
}

type AccountSettings = {
  skipProfileCreator: boolean
  skipPurchase: boolean
  selectedPlan: number
  selectedPlanYears:number 
}

export interface BenAccountContextValue {
  accountType?: AccountType
  currentProfile: CurrentProfile
  email?: string
  emailConfirmed: boolean
  hasValidPlan: boolean
  planValidTill: number
  cancelAtPeriodEnd: boolean
  isSubscription: boolean
  profile: (profileId: string) => Profile | undefined
  profiles: Profiles
  settings: AccountSettings
  userName?: string
  privileges: Privileges
  // Callbacks
  changeProfile: (value: CurrentProfile) => void
  changeSettings: (value: AccountSettings) => void
  refreshProfiles: () => Promise<void>
  changeEmailConfirmed: (value: boolean) => void
}

export interface InjectedBenAccountProps {
  benAccount: BenAccountContextValue
}

export const BenAccountContext = React.createContext<BenAccountContextValue>({
  currentProfile: { profileId: null, deviceId: null, device: null },
  profiles: [],
  privileges: { getApps: 0, getAppsUsage: 0, getCalls: 0, getContacts: 0, getGPS: 0, getHealth: 0, getKeywords: 0, getSmses: 0, getTop: 0, getTopApps: 0, getTopBlocked: 0, getTopDomains: 0, getTopSearch: 0, getUrls: 0, loadLists: 0, loadSettings: 0, saveLists: 0, saveSettings: 0, setApps: 0 },
  hasValidPlan: false,
  planValidTill: 0,
  cancelAtPeriodEnd: true,
  isSubscription: false,
  emailConfirmed: true,
  profile: () => undefined,
  settings: { skipProfileCreator: false, skipPurchase: false, selectedPlan:0, selectedPlanYears:0 },
  changeProfile: () => undefined,
  changeSettings: () => undefined,
  refreshProfiles: () => Promise.reject(),
  changeEmailConfirmed: (value: boolean) => undefined
})

const BenAccountProvider: React.FC<BenAccountProviderProps> = ({
  benService,
  children
}) => {

  const [isLoading, setLoading] = React.useState(true)
  const [profiles, setProfiles] = React.useState<Profiles>([])
  const [privileges, setPrivileges] = React.useState<Privileges>({ getApps: 0, getAppsUsage: 0, getCalls: 0, getContacts: 0, getGPS: 0, getHealth: 0, getKeywords: 0, getSmses: 0, getTop: 0, getTopApps: 0, getTopBlocked: 0, getTopDomains: 0, getTopSearch: 0, getUrls: 0, loadLists: 0, loadSettings: 0, saveLists: 0, saveSettings: 0, setApps: 0 })
  const [settings, setSettings] = React.useState<AccountSettings>({ skipProfileCreator: false, skipPurchase: false, selectedPlan:0, selectedPlanYears:0 })
  const [currentProfile, setCurrentProfile] = React.useState<CurrentProfile>({ deviceId: null, profileId: null, device: null })
  const [emailConfirmed, setEmailConfirmed] = React.useState(true)

  function hasValidPlan (accountType: AccountType): boolean {
    //return accountType === AccountType.minimal || accountType === AccountType.regular || accountType === AccountType.premium
    return accountType >= AccountType.minimal 
  }

  // function profileIdExist (profileList: Profile[], profileId: string | null): boolean {
  //   return profileId !== null && profileList && profileList.some(item => item.id === profileId)
  // }

  // function deviceIdExist (profileList: Profile[], profileId: string | null, deviceId: string | null): boolean {
  //   return profileId !== null && profileList && profileList.some((profile) => {
  //     return profile.devices && profile.devices.some(device => device.id === deviceId)
  //   })
  // }


  function findProfileOrFirst( profileList: Profile[], profileId: string | null ) : Profile | null 
  {
    if( !profileList )
      return null

    if( !profileId )
    {
      if( profileList.length > 0 )
        return profileList[0]
      
      return null
    }

    const v = profileList.find( item => Number(item.id) === Number(profileId) )

    if( v )
      return v

    if( profileList.length > 0 )
      return profileList[0]
    
    return null
  }

  function findDeviceOrFirst( profile: Profile | null, deviceId: string | null ) : Device | null
  {
    if( !profile )
      return null

    if( !deviceId )
    {
      if( profile.devices.length > 0 )
        return profile.devices[0]

      return null
    }

    const v = profile.devices.find( item => Number(item.id) ===  Number(deviceId) )

    if(v)
      return v

    if( profile.devices.length > 0 )
      return profile.devices[0]

    return null
  }  


  function refreshProfiles (): Promise<void> {
    return benService.getProfiles()
      .then(result => 
      {
        const accountType = (benService.userData && benService.userData.lic) ? benService.userData.lic.accountType : AccountType.demoExpired
        
        const hasAnyProfile = result.data && result.data.length > 0
        //const hasAnyDevice = hasAnyProfile ? !!result.data.find(i => i.devices.length > 0) : false
        //const firstProfile = hasAnyProfile ? result.data[0] : null
        //const firstDevice = firstProfile && firstProfile.devices.length > 0 ? firstProfile.devices[0] : null
        //const prevProfile = { ...currentProfile }
        //setCurrentProfile({ deviceId: null, profileId: null, device: null })

        setProfiles(result.data)

        setSettings({
          ...settings,
          skipProfileCreator: hasAnyProfile,
          skipPurchase: settings.skipPurchase || hasValidPlan(accountType),
          selectedPlan: 0, selectedPlanYears:0
        })

        //const activeProfile = profileIdExist(result.data, currentProfile.profileId) ? currentProfile : getCurrentProfileData()
        const activeProfile = currentProfile.profileId ? currentProfile : getCurrentProfileData()

        const { profileId, deviceId, device } = activeProfile // getCurrentProfileData()

        // console.log( profileId, deviceId, device )

        const profileObj = findProfileOrFirst(result.data, profileId)
        const deviceObj  = findDeviceOrFirst(profileObj, deviceId)

        setCurrentProfile({ 
          deviceId: deviceObj ? deviceObj.id : null, 
          profileId: profileObj ? profileObj.id : null, 
          device: deviceObj })

      })

      .finally(() => setLoading(false))
  }

  function getCurrentProfileData () 
  {
    if (window.localStorage && window.localStorage.getItem('benHelper')) 
    {
      return JSON.parse(window.localStorage.getItem('benHelper')!).currentProfileData;
    }

    return {
      profileId: null,
      deviceId: null,
      device: null
    }
  }

  function changeProfile (value: CurrentProfile) {
    setCurrentProfile(value)
  }

  function changeSettings (value: AccountSettings) {
    setSettings(value)
  }

  function changeEmailConfirmed(value: boolean) {
    setEmailConfirmed(value)
  }

  function profile (profileId: string): Profile | undefined {
    return profiles.find(item => item.id === profileId)
  }

  const refreshProfilesCallback = React.useCallback(refreshProfiles, [])

  const contextValue: BenAccountContextValue = {
    changeProfile,
    changeSettings,
    changeEmailConfirmed,
    currentProfile,
    email: benService.userData && benService.userData.email,
    emailConfirmed,
    hasValidPlan: hasValidPlan((benService.userData && benService.userData.lic) ? benService.userData.lic.accountType : AccountType.demoExpired),
    planValidTill: benService.userData && benService.userData.lic && benService.userData.lic.validTo ? Number(benService.userData.lic.validTo) : 0,
    cancelAtPeriodEnd: benService.userData && benService.userData.lic && benService.userData.lic.cancel ? benService.userData.lic.cancel > 0 : false,
    isSubscription: benService.userData && benService.userData.lic && benService.userData.lic.subscription ? benService.userData.lic.subscription > 0 : false,
    profiles,
    privileges,
    profile,
    refreshProfiles,
    settings,
    userName: benService.userData && benService.userData.userName,
    accountType: (benService.userData && benService.userData.lic) && benService.userData.lic.accountType
  }

  React.useEffect(() => {
    if (!benService.userData) {
      // Empty data
      setProfiles([])
      setPrivileges({ getApps: 0, getAppsUsage: 0, getCalls: 0, getContacts: 0, getGPS: 0, getHealth: 0, getKeywords: 0, getSmses: 0, getTop: 0, getTopApps: 0, getTopBlocked: 0, getTopDomains: 0, getTopSearch: 0, getUrls: 0, loadLists: 0, loadSettings: 0, saveLists: 0, saveSettings: 0, setApps: 0 })
      setCurrentProfile({ deviceId: null, profileId: null, device: null })
      setSettings(settingsState => ({ ...settingsState, skipProfileCreator: false, skipPurchase: false, selectedPlan: 0, selectedPlanYears:0 }))
      setEmailConfirmed( true )
    } else if (benService.userData) {
      setPrivileges(benService.userData.privileges)
      setEmailConfirmed( benService.userData.confirmed )
    }
  }, [benService.userData, setProfiles])

  React.useEffect(() => {
    setLoading(true)

    if (benService.isAuthorized) {
      refreshProfilesCallback()
    } else {
      setLoading(false)
    }

  }, [benService.isAuthorized, setLoading, refreshProfilesCallback])

  return (
    <BenAccountContext.Provider value={contextValue}>
      { isLoading ? <Spinner /> : children }
    </BenAccountContext.Provider>
  )
}

export function withBenAccount <P extends InjectedBenAccountProps> (Component: React.ComponentType<P>) {
  return (props: Omit<P, keyof InjectedBenAccountProps>) => (
    <BenAccountContext.Consumer>
      {state => <Component {...props as P} benAccount={state} />}
    </BenAccountContext.Consumer>
  )
}

export default BenAccountProvider
