import React from 'react'
import { Row, Col, Label, CustomInput } from 'reactstrap'
import Switch from 'rc-switch'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import I18nMessages, { getI18nMessage } from '../components/I18nMessages'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, AppData, AppSettingData, Settings } from '../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import Spinner from '../components/Spinner'
import getMessage from '../language/getMessage'

import { getPkgStoreOpen, expandIcoFileName, toLocaleDate } from '../lib/utils'
import {HtmlEntities} from '../lib/HtmlEntities'
import AppsFilter from './AppsFilter'


type Props = (
  InjectedBenAccountProps &
  InjectedBenNotificationProps &
  InjectedBenServiceProps &
  InjectedIntlProps
)

type SwitchType = 'allowed' | 'schedule' | 'timeLimit'

const SET_APPS_DELAY = 1500

const entities = new HtmlEntities()

function mapBooleanToNumber (value: boolean): number {
  return value ? 1 : 0
}

const AppsSettings: React.FC<Props> = ({
  benAccount,
  benNotification,
  benService,
  intl
}) => {

  const [appsList, setAppsList] = React.useState<AppData[]>([])
  const [isLoading, setLoadingState] = React.useState(false)
  const [blockNewApps, setBlockNewApps] = React.useState(false)
  const [blockSettings, setBlockSettings] = React.useState(false)
  const [filter, setFilter] = React.useState("")
  const reRender = React.useState()[1]
  const isMounted = React.useRef(false)
  const timer = React.useRef<NodeJS.Timeout | undefined>(undefined)

  const isWindows = benAccount.currentProfile.device != null && benAccount.currentProfile.device.osName === 'Windows'

  function handleOnSwitchChange (id: number, checked: boolean, type: SwitchType) {
    const value = mapBooleanToNumber(checked)
    const updateAppList = appsList.map<AppData>(item => {
      if (item.id === id) {
        switch (type) {
          case 'allowed': return { ...item, allowed: value }
          case 'schedule': return { ...item, schedule: value }
          case 'timeLimit': return { ...item, timeLimit: value }
          default:
            return item
        }
      }

      return item
    })

    setAppsList(updateAppList)

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => updateApps(updateAppList), SET_APPS_DELAY)
  }
  
  function handleOnFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
    const filter = event.target.value
    
    setFilter(filter)
  }


  function updateApps (appData: AppData[]) {
    const { deviceId, profileId } = benAccount.currentProfile

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {
      const data = appData.map<AppSettingData>(item => ({
        allowed: item.allowed,
        id: item.id,
        schedule: item.schedule,
        timeLimit: item.timeLimit
      }))

      benService.setApps(profileId, deviceId, data)
        .then(() => benNotification.notify({
          type: 'success',
          title: getMessage('side-effect.successful-title', intl),
          message: getMessage('side-effect.successful-message', intl)
        }))

        .catch(() => benNotification.notify({
          type: 'error',
          title: getMessage('side-effect.internal-error-title', intl),
          message: getMessage('side-effect.internal-error-message', intl)
        }))

        .finally(() => isMounted && reRender(undefined))
    }
  }

  function updateSettings (blNewApps: boolean, blSettings: boolean) {

    const { deviceId, profileId } = benAccount.currentProfile

    if (profileId && deviceId) {
      setBlockNewApps(blNewApps)
      setBlockSettings(blSettings)
      benService.saveSettings(profileId, deviceId, {
        blockNewApps: blNewApps,
        blockSettings: blSettings
      })

      .then(() => benNotification.notify({
        className: 'filled',
        title: getI18nMessage('side-effect.successful-title', intl),
        message: getI18nMessage('side-effect.successful-message', intl),
        type: 'success'
      }))

      .catch(() => benNotification.notify({
        className: 'filled',
        title: getI18nMessage('side-effect.internal-error-title', intl),
        message: getI18nMessage('side-effect.internal-error-message', intl),
        type: 'error'
      }))
    }
  }

  function initSettings (settings: Settings) {
    setBlockNewApps(settings.blockNewApps)
    setBlockSettings(settings.blockSettings)
  }

  function getVisibility(appname: string,pkgname: string){
    const f = filter.toLowerCase().trim()

    if (f==="")
      return "flex"
    if (appname.toLowerCase().includes(f) || pkgname.toLowerCase().includes(f)) 
      return "flex"
    return "none"
  }


  React.useEffect(() => {
    const { profileId, deviceId } = benAccount.currentProfile

    isMounted.current = true

    if (profileId !== null && deviceId !== null) {
      setLoadingState(true)
      benService.getApps(profileId, deviceId, 1)
        .then(result => isMounted.current && setAppsList(result.data))
        .finally(() => isMounted.current && setLoadingState(false))

      benService.loadSettings(profileId, deviceId)
        .then(result => initSettings(result.data) )

    } else {
      setLoadingState(false)
    }

    return () => {
      isMounted.current = false
    }
  }, [benAccount, benService, reRender])

  if (appsList.length === 0) {
    return (

        <React.Fragment>

            {isLoading && (
            <Row  >
                <Col sm="12">
                    <Spinner />
                </Col>
            </Row>
            )}

            {!isLoading && (
                <div className="d-flex p-4 align-items-center justify-content-center text-center">
                    <I18nMessages id="empty-list-label" />
                </div>
            )}

        </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <p><I18nMessages id="applications-page.introduction" /></p>

      <div className="block-new-apps-checkbox">

      <CustomInput
          id="blockNewApps"
          name="blockNewApps"
          type="checkbox"
          label={<I18nMessages id="internet-page.settings-page.block-new-apps" />}
          checked={blockNewApps}
          onChange={v => updateSettings(v.target.checked, blockSettings)}
        />

      { !isWindows && (
          <CustomInput
            id="blockSettings"
            name="blockSettings"
            type="checkbox"
            label={<I18nMessages id="internet-page.settings-page.block-settings" />}
            checked={blockSettings}
            onChange={v => updateSettings(blockNewApps, v.target.checked)}
          />
      )}

      </div>

      <div className="block-new-apps-filter">
        <AppsFilter
          filter = {filter}
          onSearchChanged={handleOnFilterChange}
        />  
      </div>

      {appsList
        .map(app => (
        <Row key={app.id} className="mb-4 pb-4 border-bottom" style={{display:getVisibility(app.name,app.pkg)}}>
          <Col md="5" lg="6" className="mb-3 mb-md-0" >
            <Row>
              <Col lg="8" className="d-flex align-items-center">
                <div className="app-icon">
                  <img src={ expandIcoFileName(app.ico) } alt={app.name} />
                </div>
                {/* <a href={getPkgStoreLink(app.pkg)} onClick={ (e) => getPkgStoreOpen(e, app.pkg) }>
                    <div className="ml-2">
                    <p className="list-item-heading mb-0">{ entities.decode(app.name) }</p>
                    <p className="text-muted mb-1">{app.pkg}</p>
                    </div>
                </a> */}


                <div className={!isWindows ? "div-as-a" : ""} onClick={ (e) => { if(!isWindows) getPkgStoreOpen(e, app.pkg) } }>
                    <div className="ml-2">
                    <p className="list-item-heading mb-0">{ entities.decode(app.name) }</p>
                    <p className="text-muted mb-1 app-list-name">{app.pkg}</p>
                    </div>
                </div>


              </Col>
              <Col lg="4">
                <p className="mb-0">
                  <I18nMessages id="applications-page.apps-first-seen-label" />
                  <span className="ml-1">
                    { toLocaleDate( new Date(app.firstSeen * 1000), intl) }
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="7" lg="6">
            <Row>
              <Col sm="4">
                <Label>
                  {app.allowed === 1
                    ? <I18nMessages id="applications-page.apps-available-switch-label" />
                    : <I18nMessages id="applications-page.apps-not-available-switch-label" />
                  }
                </Label>
                <Switch
                  disabled={isLoading}
                  className="custom-switch custom-switch-primary"
                  checked={app.allowed === 1}
                  onChange={checked => handleOnSwitchChange(app.id, checked, 'allowed')}
                  checkedChildren={false}
                  unCheckedChildren={false}
                />
              </Col>
              <Col sm="4">
                <Label>
                  {app.schedule === 1
                    ? <I18nMessages id="applications-page.apps-schedule-switch-label" />
                    : <I18nMessages id="applications-page.apps-no-schedule-switch-label" />
                  }
                </Label>
                <Switch
                  disabled={isLoading}
                  className="custom-switch custom-switch-primary"
                  checked={app.schedule === 1}
                  onChange={checked => handleOnSwitchChange(app.id, checked, 'schedule')}
                  checkedChildren={false}
                  unCheckedChildren={false}
                />
              </Col>
              <Col sm="4">
                <Label>
                  {app.timeLimit === 1
                    ? <I18nMessages id="applications-page.apps-time-limit-switch-label" />
                    : <I18nMessages id="applications-page.apps-no-time-limit-switch-label" />
                  }
                </Label>
                <Switch
                  disabled={isLoading}
                  className="custom-switch custom-switch-primary"
                  checked={app.timeLimit === 1}
                  onChange={checked => handleOnSwitchChange(app.id, checked, 'timeLimit')}
                  checkedChildren={false}
                  unCheckedChildren={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </React.Fragment>
  )
}

export default injectIntl(withBenService(withBenNotification(withBenAccount(AppsSettings))))
