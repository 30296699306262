import React from 'react'
import { ChartData, ChartOptions } from 'chart.js'
import Spinner from '../components/Spinner'
import Chart from 'react-chartjs-2'
import I18nMessages from '../components/I18nMessages'
import { InjectedBenServiceProps, withBenService,  HealthItem } from '../providers/benServiceProvider'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { toLocaleTime } from '../lib/utils'


type Props = InjectedIntlProps & InjectedBenAccountProps & InjectedBenServiceProps & {
  dateTo: Date
}

class AvgHealthItem 
{
    x : number = 0
    y : number[] = new Array<number>()

    public getAvg() : number
    {
        if( this.y.length < 1 )
            return 0

        let sum = this.y.reduce( (p,c) => p+c )

        return Math.round( sum / this.y.length )
    }

    public add(v : number)
    {
        this.y.push(v)
    }
}


function fillGaps(from : number, to: number, values : number[])
{
    const valA = values[from]
    const valB = values[to]
    const len  = to - from
    const diff = (valB-valA) / len
    
    for(let i = 1; i<len; i++)
        values[from+i] = Math.round(  valA + (diff * i) )
}

// reverse array, group data in 30 min buckets (with average), fill gaps by linear approximation
// returns array of timestamps, and array of values
function getChartPoints2(items : HealthItem[] ) : [ number[], number[] ]
{
    if( items.length < 1 )
        return [ [0], [0] ]

    const step = 30*60

    let map : Map<number, AvgHealthItem> = new Map<number, AvgHealthItem>()
    let min : number = 0
    let max : number = 0

    let i = items.length

    while(i-- > 0)
    {
        let item = items[i]
        let ts = Math.floor( item.ts / step ) * step

        if( !min || min > ts ) min = ts
        if( !max || max < ts ) max = ts

        let obj = map.get(ts)

        if( !obj ) {
            obj = new AvgHealthItem()
            map.set(ts,obj)
        }

        obj.add(item.battery)
    } 
    
    let timestamps    : number[] = []
    let batteryLevels : number[] = []

    let lastFilled : number = 0
    let gap : boolean = false
    let idx : number = 0
     
    for(let i = min; i<=max; i += step)
    {
        timestamps.push(i)

        const v = map.get(i)

        if( v )
        {
            batteryLevels.push(v.getAvg())

            if(gap) {
                fillGaps( lastFilled, idx, batteryLevels )
            }

            lastFilled = idx
            gap = false
        }
        else
        {
            batteryLevels.push(0)
            gap = true
        }

        idx++
    }

    return [ timestamps,batteryLevels ];
}


const BatteryChart: React.FC<Props> = ({
  benAccount,
  benService,
  dateTo,
  intl
}) => {

  const [healthData, setHealthData] = React.useState<HealthItem[]>([])

  const [isLoading, setLoading] = React.useState(true)

  const processedData : [ number[], number[] ] = getChartPoints2(healthData)

  function pseudoDayOfYear(date : Date) 
  {
    return date.getDate() + date.getMonth() * 32

    // 32, bo z jakiegos powodu ponizsze maja inny Timezone Offset
    // console.log( new Date(2021,0,0).getTimezoneOffset() )
    // -60
    // console.log( new Date(1623000000 * 1000).getTimezoneOffset() )
    // -120
  }

  const data: ChartData = {
    labels: processedData[0].map( x => toLocaleTime( new Date(x * 1000), intl ) ),
    datasets: [{
      borderWidth: 0,
      label: 'Percent',
      //backgroundColor: '#2a922c',
      backgroundColor: processedData[0].map( x => pseudoDayOfYear(new Date(x * 1000)) % 2 === 0 ? '#2a922c' : '#47bb49' ),
      data: processedData[1]
    }]
  }

  const options: ChartOptions = {
    showLines: false,
    responsive: true,
    maintainAspectRatio: false,
    title: {
        display: false,
        text: 'Battery'
    },
    legend: {
        display: false,
        labels: {
            boxWidth: 50,
            fontSize: 10,
            fontColor: '#bbb',
            padding: 5,
        }
    },
    scales: {
        xAxes: [{
          ticks: {
            autoSkip: true,
            autoSkipPadding: 10
          }
        }],
        yAxes: [{
          ticks: {
              beginAtZero:true,
              min: 0,
              max: 100    
          }
        }]
     }
  }

  React.useEffect(() => {
    const { deviceId, profileId } = benAccount.currentProfile
    let isMounted = true

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {
      setLoading(true)
      benService.getHealth(profileId, deviceId, dateTo)
        .then(result => isMounted &&  setHealthData(result.data))
        .finally(() => isMounted && setLoading(false))
    }

    return () => {
      isMounted = false
    }

  }, [benAccount.currentProfile, benService, dateTo])

  if ( healthData.length === 0) {

    if(isLoading )
      return (
          <Spinner />
      )

    return (
      <div className="chart-container d-flex align-items-center justify-content-center text-center">
        <I18nMessages id="no-data.label-short" />
      </div>
    )
  }

  return (

    <React.Fragment>

      {isLoading && (
        <Spinner />
      )}

      <div className="chart-container">
        <Chart
          type="bar"
          options={options}
          data={data}
        />
      </div>

    </React.Fragment>
  )
}

export default injectIntl(withBenService(withBenAccount(BatteryChart)))
