import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import Separator from '../../components/Separator'
import I18nMessages from '../../components/I18nMessages'
import DateRange from '../../containers/DateRange'
import TopBlockedChart from '../../containers/TopBlockedChart'
import TopDomainsChart from '../../containers/TopDomainsChart'
import DisableAccess from '../../containers/DisableAccess'
import TopSearchChart from '../../containers/TopSearchChart'
import { subtractDateByDays } from '../../lib'
import TopAppsChart from '../../containers/TopAppsChart'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import { ANALYTICS_NAMES, ANALYTICS_VALUES, InjectedBenServiceProps, TopBundle, withBenService } from '../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../providers/notificationProvider'
import getMessage from '../../language/getMessage'
import { injectIntl, InjectedIntlProps } from 'react-intl'
import { hasDeviceFeature, isDeviceAndroid } from '../../lib/utils'


type Props =  InjectedBenAccountProps & InjectedBenServiceProps & InjectedBenNotificationProps & InjectedIntlProps & {
  
}

const SummaryPage: React.FC<Props> = ({
    benAccount,
    benNotification,
    intl,
    benService
}) => {

  const [dateFrom, setDateFrom] = React.useState( subtractDateByDays( new Date(), 7) )
  const [dateTo, setDateTo] = React.useState( new Date() )
  const [topBundle, setTopBundle] = React.useState<TopBundle | null | undefined>( undefined )

  
  function handleOnDateFormChange (date: Date | null) {
    if (date) {
      setDateFrom(date)
    }
  }

  function handleOnDateToChange (date: Date | null) {
    if (date) {
      setDateTo(date)
    }
  }

  React.useEffect(() => {

      const { deviceId, profileId } = benAccount.currentProfile
      let isMounted = true

      setTopBundle(undefined)

      if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' )
      {
          benService.getTop(profileId, deviceId, dateFrom, dateTo)
              .then(result => {
                  if(isMounted) {
                      setTopBundle(result.data)
                  } } )
              .catch(() => {
                  benNotification.notify({
                        type: 'error',
                        title: getMessage('side-effect.internal-error-title', intl),
                        message: getMessage('side-effect.internal-error-message', intl)
                        })
                } )
      }
      else
      {
        benService.analyticsEvent(0, ANALYTICS_NAMES.SUMMARY, ANALYTICS_VALUES.PAGE_DISPLAY ).catch( () => {} )
        
        setTopBundle(null)
      }



      return () => {
          isMounted = false
      }

  }, [benAccount.currentProfile, benService, dateFrom, dateTo])


  function getVer()
  {
    const { deviceId, profileId } = benAccount.currentProfile
  
    if ( deviceId === null || profileId === null || deviceId === '' || profileId === '' )
      return ''

    const device = benAccount.currentProfile.device

    if( device )
    {
      const android = isDeviceAndroid( benAccount )
      const hasSMS = hasDeviceFeature( benAccount, '_SMS_', true )

      if( !android )
        return device.appVer

      if( hasSMS )
        return device.appVer
      else
        return device.appVer + ' lite'
    }
    else
      return ''    
  }

  function isEnableDisableSupported() : boolean
  {
    if(benAccount.currentProfile.device === null)
      return false

    // return benAccount.currentProfile.device.osName === 'Windows'
    return true
  }


  // console.log( 'QQQ=' + process.env.NODE_ENV )
  // const procEnv : any =  process.env;
  // console.log( 'QQQ=' + procEnv['REACT_APP_THE_TITLE'] )


  return (
    <div className="dashboard-wrapper">
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
      <h1 className="mb-0 p-0"><I18nMessages id="summary-page.title" /></h1>

      <DateRange
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDateFromChanged={handleOnDateFormChange}
        onDateToChanged={handleOnDateToChange}
      />
      </div>

      <Separator />

      <Row className="mb-4">
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle tag="h3">
                <I18nMessages id="summary-page.top-apps.title" />
              </CardTitle>

              <TopAppsChart topBundle={topBundle} />
              
              <div className="mt-4 small" >
                App Ver: { getVer() }
              </div>

            </CardBody>
          </Card>
        </Col>
      </Row>      

      <Row>
        <Col lg="6" className="mb-4">
          <Card>
            <CardBody>
              <CardTitle tag="h3">
                <I18nMessages id="summary-page.top-domains.title" />
              </CardTitle>

              <TopDomainsChart topBundle={topBundle} />
            </CardBody>
          </Card>
        </Col>

        <Col lg="6" className="mb-4">
          <Card>
            <CardBody>
              <CardTitle tag="h3">
                <I18nMessages id="summary-page.top-search.title" />
              </CardTitle>

              <TopSearchChart topBundle={topBundle} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col lg="12">
          <Card>
            <CardBody>
              <CardTitle tag="h3">
                <I18nMessages id="summary-page.top-blocked.title" />
              </CardTitle>

              <TopBlockedChart topBundle={topBundle} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      { isEnableDisableSupported() && (
        <Row className="mb-4">
          <Col lg="12">
            <Card>
              <CardBody >
                <CardTitle tag="h3">
                  <I18nMessages id="summary-page.access-disable" />
                </CardTitle>
                  <DisableAccess/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      
      
    </div>
  )
}

export default withBenNotification(injectIntl(withBenService(withBenAccount(SummaryPage))))
