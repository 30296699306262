import React, { CSSProperties } from 'react'
import { Badge } from 'reactstrap'

type Props = {
  x: number
  y: number
  visible: boolean
  label: string
}

const LocalizationHint: React.FC<Props> = ({
  label,
  visible = true,
  x,
  y
}) => {

  const style: CSSProperties = {
    fontSize: '1.2rem',
    left: `${x + 10}px`,
    position: 'fixed',
    top: `${y + 10}px`,
    zIndex: 10000,
    fontFamily: '"Nunito", sans-serif',
    boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.3)"
  }


  return (
      
      <div className="badge-radius">

        { visible && (
      
          <div className="badge-radius" style={style} >

            <Badge color="primary">

            { label.split('\n').map((item, i) => (

              <div key={i}>{item}</div>

            ))}
            
            </Badge>

          </div>

        )}

      </div>
    )
  
}

export default LocalizationHint
