import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/cs'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Vše v pořádku!',
  'side-effect.successful-message': 'Data byla úspěšně uložena',
  'side-effect.internal-error-title': 'Něco se nepovedlo',
  'side-effect.internal-error-message': 'Zkuste to prosím později nebo zkontrolujte své internetové připojení',

  'monday-sshort-label': 'Po',
  'tuesday-sshort-label': 'Út',
  'wednesday-sshort-label': 'St',
  'thursday-sshort-label': 'Čt',
  'friday-sshort-label': 'Pá',
  'saturday-sshort-label': 'So',
  'sunday-sshort-label': 'Ne',

  'monday-short-label': 'Po',
  'tuesday-short-label': 'Út',
  'wednesday-short-label': 'St',
  'thursday-short-label': 'Čt',
  'friday-short-label': 'Pá',
  'saturday-short-label': 'So',
  'sunday-short-label': 'Ne',

  'monday-label': 'Pondělí',
  'tuesday-label': 'Úterý',
  'wednesday-label': 'Středa',
  'thursday-label': 'Čtvrtek',
  'friday-label': 'Pátek',
  'saturday-label': 'Sobota',
  'sunday-label': 'Neděle',

  'january-label': 'Leden',
  'february-label': 'Únor',
  'march-label': 'Březen',
  'april-label': 'Duben',
  'may-label': 'Květen',
  'june-label': 'Červen',
  'july-label': 'Červenec',
  'august-label': 'Srpen',
  'september-label': 'Září',
  'october-label': 'Říjen',
  'november-label': 'Listopad',
  'december-label': 'Prosinec',

  'interval-name-year': 'rok',
  'interval-name-month': 'měsíc',
  'interval-name-week': 'týden',
  'interval-name-day': 'den',
  'interval-name-years': 'roky',
  'interval-name-months': 'měsíce',
  'interval-name-weeks': 'týdny',
  'interval-name-days': 'dny',

  'select-input.no-options-label': 'Žádné možnosti',
  'filter-input.label': 'Filtr:',

  'no-data.label': 'Žádná data k zobrazení. Změňte filtry nebo vybraný datum.',
  'no-data.label-mid': 'Žádná data k zobrazení. Změňte vybraný datum.',
  'no-data.label-short': 'Žádná data k zobrazení.',
  'empty-list-label': 'Prázdný seznam.',
  'overlay.empty-device-label': 'Žádná zařízení přidaná k tomuto profilu.',
  'overlay.empty-profiles-label': 'Žádný profil zatím nebyl přidán.',
  'overlay.empty-device.add-device-label': 'Přidat zařízení',

  'confirmation-modal.cancel-button': 'Zrušit',
  'confirmation-modal.confirm-button': 'POTVRDIT',
  'confirmation-modal.button-yes': 'Ano',
  'confirmation-modal.button-no': 'Ne',

  // Date Picker
  'date-picker.select-date-label': 'Vyberte datum',

  // Date Filter
  'date-filter.select-label': 'Vyberte rozsah',
  'date-filter.this-month': 'Tento měsíc',
  'date-filter.this.week': 'Tento týden',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Vyberte den',
  'date-filter.from-label': 'Od: ',
  'date-filter.to-label': 'Do: ',

  // Time Picker
  'time-picker.unlimied-label': 'Neomezeně',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',

  'time-picker.add-bonus-title': 'Přidat časový bonus pouze na dnešní den',
  'time-picker.no-bonus': 'Žádný bonus',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Nastavit bonus',
  'time-picker.add-bonus-button-cancel': 'Zrušit',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Rozsah dat',
  'profile-image-picker.girl-label': 'Holka',
  'profile-image-picker.boy-label': 'Kluk',

  // Profile List
  'profile-list.remove-device-label': 'Odebrat zařízení',
  'profile-list.move-device-label': 'Přesunout zařízení na jiný profil',
  'profile-list.add-device-label': 'Přidat zařízení',

  'profile-list.add-profile-label': 'Přidat profil',
  'profile-list.edit-profile-label': 'Upravit profil',
  'profile-list.add-devices-label': 'Přidat nové zařízení',
  'profile-list.remove-profile-label': 'Odebrat profil',
  'profile-list.remove-profile-disabled-tooltip': 'Pro smazání profilu musíte nejdříve odebrat všechna zařízení připojená k profilu.',
  'profile-list.title': 'Seznam profilů',

  // Profile Form
  'profile-form.full-name-label': 'Jméno',
  'profile-form.month-of-birth-label': 'Měsíc narození',
  'profile-form.year-of-birth-label': 'Rok narození',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Zrušit',
  'profile-form-modal.create.submit-button': 'PŘIDAT PROFIL',
  'profile-form-modal.create.title': 'Přidat dětský profil',
  'profile-form-modal.edit.skip-button': 'Zrušit',
  'profile-form-modal.edit.submit-button': 'ULOŽIT',
  'profile-form-modal.edit.title': 'Upravit profil dítěte',
  'profile-form-modal.max-profiles-error': 'Maximální počet profilů dosažen',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Přesunout zařízení do jiného profilu',
  'profile-move-device-modal.cancel-button': 'Zrušit',
  'profile-move-device-modal.submit-button': 'Uložit',
  'profile-move-device-modal.select-profile-label': 'Vyberte profil',

  // CallLog
  'call-log.duration-label': 'Délka hovoru:',
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'PŘIDAT PROFIL',
  'create-profile-page.skip-button': 'Zrušit',
  'create-profile-page.title': 'Přidat profil dítěte',
  'create-profile-page.description': 'Kdo bude sledován',

  // Add device page
  'add-device-page.close-button': 'ZAVŘÍT',
  'add-device-page.description': 'Vyberte typ zařízení pro instalaci aplikace',
  'add-device-page.title': 'Průvodce instalací',
  'add-device-page.check': 'Zkontrolujte manuál',
  'add-device-page.mobile-title': 'Android smartphone nebo tablet',
  'add-device-page.mobile-description': 'Android verze 6 nebo vyšší',
  'add-device-page.desktop-title': 'Windows stolní počítač nebo notebook',
  'add-device-page.desktop-description': 'Windows verze 7 nebo vyšší',


  // Purchase Page
  'purchase-page.skip-label': 'Přejděte na dashboard',
  'purchase-page.title': 'Vyberte nejlepší plán',
  'purchase-page.title.features': 'Všechny plány nabízejí následující funkce',
  'purchase-page.title.reviews': 'Proč nás naši uživatelé milují',
  'purchase-page.subtitle': 'Pokud program nesplní vaše očekávání, vrátíme vám platbu',
  'purchase-page.plan-highlight': 'Nejlepší hodnota',
  'purchase-page.monthly': 'měsíčně',
  'purchase-page.select': 'Vybrat',
  'purchase-page.close': 'Zavřít',
  'purchase-page.money-back.title': 'Bez rizika',
  'purchase-page.money-back.desc': 'Pokud nebudete spokojeni, vrátíme vám peníze.',
  'purchase-page.header.check-1': 'Nejlepší aplikace pro rodičovskou kontrolu',
  'purchase-page.header.check-2': 'Nastavte časový limit pro hry, YouTube a webové stránky',
  'purchase-page.header.check-3': 'Statisíce spokojených zákazníků',
  'purchase-page.plan.year': 'rok',
  'purchase-page.plan.optimal': 'Nejlepší hodnota',


  'purchase-page.modal.2y-header': 'Speciální nabídka!',
  'purchase-page.modal.2y-sub-header': 'Vyberte <b>plán na 2 roky a ušetřete</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Zvolte <b>2letý plán a ušetřete</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Chraňte až {devices} zařízení',
  'purchase-page.modal.upgrade': 'Vyberte slevu',
  'purchase-page.modal.no-thanks': 'Pokračovat s ročním plánem',


  'purchase-plan.features.title.feature_1': 'Časový limit pro aplikace a hry',
  'purchase-plan.features.title.feature_2': 'Filtrovaní webových stránek',
  'purchase-plan.features.title.feature_3': 'Blokování aplikací, her a webových stránek',
  'purchase-plan.features.title.feature_4': 'Sledování na Youtube',
  'purchase-plan.features.title.feature_5': 'Dálkové zobrazení obrazovky živě',
  'purchase-plan.features.title.feature_6': 'Dálkový přístup',
  'purchase-plan.features.title.feature_7': 'Podrobné zprávy',
  'purchase-plan.features.title.feature_8': 'Poloha (jen telefon)',
  'purchase-plan.features.title.feature_9': 'Kontrola zpráv a hovorů',
  'purchase-plan.features.title.feature_10': 'Prioritní technická podpora',

  'purchase-plan.features.desc.feature_1': 'Nastavte časové limity pro aplikace, hry a přístup na internet. Navíc naplánujte, v jaké hodiny má vaše dítě povolený přístup k nim.',
  'purchase-plan.features.desc.feature_2': 'Všechny nevhodné webové stránky jsou blokovány. Program používá algoritmus, který analyzuje obsah webové stránky na jakýkoli nevhodný materiál. Také sbírá data z obrovské databáze nevhodných webových stránek a slov, která je pravidelně aktualizována.',
  'purchase-plan.features.desc.feature_3': 'Blokujte aplikace a webové stránky, které by mohly mít negativní dopad na vaše děti. Jakákoli nová hra nainstalovaná bude vyžadovat vaše schválení.',
  'purchase-plan.features.desc.feature_4': 'Na ovládacím panelu můžete sledovat všechna videa, která vaše dítě sleduje na YouTube. Zablokujte jakékoli video nebo úplně zablokujte přístup k video službám.',
  'purchase-plan.features.desc.feature_5': 'Dálkově přistupujte k živému přenosu obrazovky vašeho dítěte a kontrolujte jeho aktivitu během dne. To umožňuje pečlivě sledovat obsah, ke kterému má vaše dítě přístup.',
  'purchase-plan.features.desc.feature_6': 'Jakmile se přihlásíte, je možné přistupovat k rodičovskému ovládacímu panelu odkudkoli a z jakéhokoli zařízení. Na ovládacím panelu upravujte nastavení, sledujte zařízení dětí a nastavujte časové limity.',
  'purchase-plan.features.desc.feature_7': 'Na rodičovském ovládacím panelu snadno sledujte aktivitu dítěte na jeho počítači nebo telefonu, zjistěte, kolik času strávilo na obrazovce, které webové stránky navštívilo a mnoho dalšího.',
  'purchase-plan.features.desc.feature_8': 'Sledujte aktuální polohu dítěte a minulá umístění na mapě. Zjistěte přesně, kde dítě bylo každý den nebo v určitý čas.',
  'purchase-plan.features.desc.feature_9': 'Pomocí rodičovského panelu můžete snadno kontrolovat odeslané a přijaté zprávy, telefonní hovory a uložené kontakty vašeho dítěte.',
  'purchase-plan.features.desc.feature_10': 'Pokud budete potřebovat jakoukoli pomoc, jsme vám vždy k dispozici. K dispozici je také mnoho videonávodů, které vám pomohou používat {app}.',



  'purchase-plan.raitings.desc.name_1': 'Sofie',
  'purchase-plan.raitings.desc.name_2': 'Olívie',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lukas',
  'purchase-plan.raitings.desc.name_5': 'Ema',
  'purchase-plan.raitings.desc.name_6': 'Ema',

  'purchase-plan.raitings.desc.raiting_1': 'Velmi efektivní! Úplně chrání mé dítě před škodlivým internetovým obsahem.',
  'purchase-plan.raitings.desc.raiting_2': 'Přesně to, co jsem hledal! Nastavil jsem denní limit jedné hodiny pro mé dcery na přístup k internetu a Avosmart se o to stará za mě.',
  'purchase-plan.raitings.desc.raiting_3': 'Doporučuji Avosmart, protože má mnoho funkcí. Moje děti jsou chráněné při používání internetu a já mohu kontrolovat, kolik času tráví hraním her, atd.',
  'purchase-plan.raitings.desc.raiting_4': 'Bylo důležité pro mě a mou manželku, abychom mohli sledovat návyky na sociálních sítích naší dcery. Avosmart nám pomáhá udržovat rovnováhu mezi digitálním a reálným životem naší dcery.',
  'purchase-plan.raitings.desc.raiting_5': 'Můj syn dříve trávil příliš mnoho času na internetu, používal laptop, tablet atd. Díky zprávám poskytovaným Avosmartem přesně vím, které weby můj syn navštěvuje a jak dlouho.',
  'purchase-plan.raitings.desc.raiting_6': 'Teď mohu kdykoli zkontrolovat aktuální polohu mého syna. Vynikající služba!',


  'account-settings-page.title': 'Nastavení účtu',
  'account-settings-page.automatic-update': 'Automatické aktualizace Windows aplikace',
  'account-settings-page.timezone': 'Časové pásmo : ',
  'account-settings-page.send-reports': 'Odesílat denní zprávy o aktivitě',


  'plans-info-page.title': 'Informace o předplatném',
  'plans-info-page.plan': ' Plán:',
  'plans-info-page.max-devices': 'Maximální počet zařízení:',
  'plans-info-page.valid-till': 'Datum vypršení:',

  'plans-info-page.your-plan': 'Váš plán:',
  'plans-info-page.subscription-information': 'Informace o předplatném',
  'plans-info-page.subscription-created': 'Předplatné vytvořeno: ',
  'plans-info-page.subscription-current-period': 'Aktuální období předplatného: ',
  'plans-info-page.subscription-renews-on': 'Váš plán se obnoví dne ',
  'plans-info-page.subscription-cancel-renewal': 'Zrušit obnovení',
  'plans-info-page.subscription-not-renewed': 'Váš plán NEBUDE obnoven na konci aktuálního období',
  'plans-info-page.subscription-enable-renewal': 'Povolit obnovení',
  'plans-info-page.subscription-price': 'Cena předplatného: ',
  'plans-info-page.subscription-price-amount': '{amount} každých {period}',


  'plans-info-page.upgrade-plan': 'Upgradujte svůj plán',
  'plans-info-page.extend-lic': 'Prodloužit váš plán',
  'plans-info-page.subscriptions-portal': 'Spravujte své platební metody',
  'plans-info-page.recent-payments': 'Poslední transakce',
  'plans-info-page.date-of-payment': 'Datum transakce:',
  'plans-info-page.transaction-status': 'Stav transakce:',
  'plans-info-page.no-recent-payments': 'Žádné poslední transakce',
  'plans-info-page.btn-close': 'Zavřít',
  'plans-info-page.plan-devices': 'až {devices} zařízení',
  'plans-info-page.plan-devices-types': 'počítače/tablety/telefony',


  'upgrade-page.title': 'Upgrade plánu',
  'upgrade-page.devices': 'Zařízení: ',
  'upgrade-page.valid-till': 'Váš plán je platný do: ',
  'upgrade-page.confirm-upgrade': 'Váš plán bude upgradován, budete účtováni {amount}',
  'upgrade-page.pay-now': 'Zaplatit nyní {amount}',
  'upgrade-page.pay-then': 'Poté {amount} každých {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Koupit předplatné',
  'purchase-checkout.up-to-devices': 'Až {devices} zařízení',
  'purchase-checkout.subscribtion-price': '{amount} každých {period}',
  'purchase-checkout.subscribe': 'Předplatit',
  'purchase-checkout.cancel': 'Zrušit',


  // Purchase Status Page
  'purchase-status-page.title': 'Ověřování platby',
  'purchase-status-page.status-pending': 'Čekající',
  'purchase-status-page.status-completed': 'Dokončeno',
  'purchase-status-page.status-canceled': 'Zrušeno',
  'purchase-status-page.status-refund-finalized': 'Vrácení peněz dokončeno',
  'purchase-status-page.status-wating-for-conf': 'Čeká na potvrzení',
  'purchase-status-page.status-created': 'Vytvořeno',
  'purchase-status-page.status-unkown': 'Neznámé',
  'purchase-status-page.status-error': 'Chyba',
  'purchase-status-pag.back-button': 'Zpět na domovskou stránku',
  'purchase-status-pag.invoice-link': 'Chci obdržet fakturu s DPH',

  // Login Page
  'login-page.brandline': 'Přihlásit se',
  'login-page.brandline2': 'Filtrace webových stránek',
  'login-page.email-label': 'e-mailová adresa',
  'login-page.login-button': 'PŘIHLÁSIT SE',
  'login-page.password-label': 'Heslo',
  'login-page.recovery-link': 'Zapomněli jste heslo?',
  'login-page.invalid-login-error': 'E-mailová adresa nebo heslo, které jste zadali, není platné',

  // Register Page
  'register-page.create-account-label': 'Potřebujete účet? Vytvořte si nový účet',
  'register-page.description': 'Vytvořte si svůj zdarma účet',
  'register-page.email-label': 'E-mailová adresa',
  'register-page.login-link-label': 'Máte účet? Přihlaste se',
  'register-page.marketing-checkbox-label': 'Souhlasím s přijímáním marketingových a propagačních materiálů',
  'register-page.password-label': 'Heslo',
  'register-page.phoneNumber-label': 'Telefonní číslo',
  'register-page.terms-privacy-checkbox-label': 'Souhlasím s podmínkami ochrany soukromí a podmínkami užití',
  'register-page.register-button': 'REGISTROVAT',
  'register-page.register-success-title': 'Úspěch',
  'register-page.register-success-message': 'Registrace byla úspěšná. Zkontrolujte svou schránku pro potvrzení e-mailu',
  'register-page.register-error-captcha': 'Chyba Captcha. Zkuste to znovu.',
  'register-page.register-error-already-registered-label': 'Již jste registrováni. Zkuste se přihlásit nebo připomenout heslo.',
  'register-page.register-error-password-to-short-label': 'Heslo je příliš krátké.',

  // Forgot Password Page
  'register-page.strong-pass': 'Vaše heslo je příliš jednoduché a může být snadno rozluštěno jinými lidmi. Vaše heslo by mělo obsahovat velká i malá písmena a čísla a mělo by mít alespoň 8 znaků. Jste si jisti, že chcete použít jednoduché heslo?',
  'forgot-password-page.headline-title': 'Připomenout heslo',
  'forgot-password-page.email-label': 'e-mailová adresa',
  'forgot-password-page.reset-button': 'PŘIPOMENOUT HESLO',
  'forgot-password-page.login-link-label': 'Zpět na přihlašovací stránku',
  'forgot-password-page.success-title': 'Obnovení hesla',
  'forgot-password-page.success-message': 'Zkontrolujte svou schránku pro pokyny k obnovení hesla',
  'forgot-password-page.email-not-confirmed': 'Vaše e-mailová adresa není ověřena. Obnovení hesla není dostupné.',

  // Update Password Page
  'update-password-page.headline-title': 'Nastavení nového hesla',
  'update-password-page.warning-title': 'POZNÁMKA:',
  'update-password-page.warning-description': 'Nastavení nového hesla povede ke ztrátě dříve uložených dat o poloze. Ostatní data nebudou ztracena.',
  'update-password-page.warning-agreement': 'Rozumím a přijímám ztrátu dat o poloze.',
  'update-password-page.new-password': 'Nové heslo:',
  'update-password-page.new-password-retype': 'Zopakujte nové heslo:',
  'update-password-page.submit': 'Nastavit nové heslo',
  'update-password-page.alert-weak-password': 'Vaše heslo je příliš jednoduché a mohli by ho snadno uhodnout neoprávněné osoby. Heslo by mělo obsahovat velká písmena, malá písmena, čísla a mělo by být dlouhé alespoň 8 znaků. Chcete přesto použít jednoduché heslo?',
  'update-password-page.alert-agreement': 'Je vyžadován souhlas se ztrátou dat.',
  'update-password-page.error-message': 'Chyba! Nastavení nového hesla selhalo.',
  'update-password-page.success-message': 'Nové heslo nastaveno',
  'update-password-page.unknow-error-message': 'Něco se pokazilo',
  'update-password-page.error-link': 'Špatný odkaz',
  'update-password-page.error-posswords-not-equal': 'Hesla nejsou stejná',
  'update-password-page.error-posswords-length': 'Hesla jsou příliš krátká',
  'update-password-page.error-wrong-code': 'Špatný kód',
  'update-password-page.register-success-title': 'Úspěch!',
  'update-password-page.register-success-message': 'Nové heslo nastaveno.',

  // Error Page
  'not-found-page.title': 'Oops... vypadá to, že došlo k chybě!',
  'not-found-page.error-code-text': 'Chybový kód',
  'not-found-page.go-back-home-button': 'VRÁTIT SE DOMŮ',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Váš plán končí dne {date}. Zvažte prosím nákup předplatného již dnes',
  'panel-page.purchase-demo-alert': 'Momentálně používáte zkušební verzi.',
  'panel-page.purchase-expired-alert': 'Momentálně používáte expirující zkušební verzi. Nakupte si předplatné, abyste mohli pokračovat',
  'panel-page.purchase-lic-expired-alert': 'Váš předplatný plán expiroval. Nakupte si předplatné, abyste mohli pokračovat',
  'panel-page.email-alert': 'Neověřili jste vaši e-mailovou adresu',
  'panel-page.button-email-resend': 'Odeslat e-mail znovu',
  'panel-page.email-alert-resend': ' nebo odeslat e-mail ještě jednou.',
  'panel-page.email-alert-resend-title': 'Potvrzení',
  'panel-page.email-alert-resend-msg': 'E-mail byl odeslán!',
  'panel-page.profile-delete-description': 'Opravdu chcete smazat vybraný profil?',
  'panel-page.profile-delete-device-description': 'Opravdu chcete smazat vybrané zařízení? Data budou ztracena.',
  'panel-page.profile-delete-device-second-description': 'Ochrana na zařízení bude vypnuta. Jste si jisti, že chcete pokračovat?',

  'panel-page.email-confirmation-overlay-close': 'Zavřít',
  'panel-page.email-confirmation-overlay-postpone': 'Později',
  'panel-page.email-confirmation-overlay-resend': 'Odeslat e-mail znovu',
  'panel-page.email-confirmation-overlay-header': 'Vaše níže uvedená e-mailová adresa nebyla ověřena',
  'panel-page.email-confirmation-overlay-desc': "Pokud jste udělali chybu v uvedené e-mailové adrese, dejte nám okamžitě vědět. <br>Pokud jste e-mail neobdrželi, klikněte prosím na 'znovu odeslat' a zkontrolujte ostatní složky vaší e-mailové schránky (včetně spamu).",

  'panel-page.email-confirmation-code-success-header': 'Děkujeme',
  'panel-page.email-confirmation-code-success-desc': 'E-mail byl ověřen!',
  'panel-page.email-confirmation-code-header': 'Zadejte prosím 6-ti místný potvrzovací kód, který <br> byl zaslán na vaši e-mailovou adresu.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Zadaný kód je neplatný',
  'panel-page.email-confirmation-code-error': 'Při ověřování kódu došlo k neočekávané chybě',

  // Summary Page
  'summary-page.title': 'Dashboard',
  'summary-page.top-apps.title': 'Nejlepší aplikace',
  'summary-page.top-blocked.title': 'Nejvíce blokované',
  'summary-page.top-domains.title': 'Nejvíce navštěvované stránky',
  'summary-page.top-search.title': 'Nejvíce vyhledávané',
  'summary-page.access-disable': 'Dočasná deaktivace',
  'internet-page.access-time-limit-set-label': 'Denní limit pro přístup k internetu:',
  'internet-page.access-time-limit-description': 'Nastavte denní časové limity pro každé zařízení a zvolte, kolik hodin používání internetu je povoleno.',
  'disable-access.protection-enabled': 'Ochrana je aktivní',
  'disable-access.protection-disabled-for': 'Ochrana je dočasně deaktivována, zbývá {time} minut',
  'disable-access.device-offline': 'Zařízení nedostupné',
  'disable-access.protection-status': 'Stav ochrany',


  // Internet Page
  'internet-page.headline-title': 'Webové stránky',
  'internet-page.access-time-page-title': 'Čas přístupu',
  'internet-page.settings-page-title': 'Nastavení',
  'internet-page.statistics-page-title': 'Statistiky',
  'internet-page.searches-page-title': 'Vyhledávání',

  'internet-page.statistics-page-www-ok': 'Schválená stránka',
  'internet-page.statistics-page-www-ok-white': 'Bezpečná stránka',
  'internet-page.statistics-page-www-timelimit': 'Webová stránka zablokována kvůli časovým limitům',
  'internet-page.statistics-page-www-userdef': 'Webová stránka zablokována, uživatelské definice',
  'internet-page.statistics-page-www-content': 'Webová stránka zablokována, nevhodný obsah',


  'internet-page.white-black-list-page-title': 'Bezpečné / Zablokované webové stránky',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filtry',
  'internet-page.settings-page.white-list-only-label': 'Povolit přístup pouze na stránky na bezpečném seznamu',
  'internet-page.settings-page.block-erotic-label': 'Blokovat přístup k erotickým webům',
  'internet-page.settings-page.block-offensive-label': 'Blokovat přístup k urážlivým, agresivním webům',
  'internet-page.settings-page.block-file-sites-label': 'Blokovat přístup na stránky, které umožňují stahování souborů z internetu',
  'internet-page.settings-page.block-video-label': 'Blokovat přístup na video portály',
  'internet-page.settings-page.block-social-label': 'Blokovat přístup na sociální sítě',
  'internet-page.settings-page.block-doc-label': 'Blokovat stahování dokumentů',
  'internet-page.settings-page.block-exe-label': 'Blokovat stahování programů',
  'internet-page.settings-page.excludeYT-label': 'Nevynucovat bezpečné vyhledávání na YouTube',
  'internet-page.settings-page.block-new-apps': 'Blokovat nové aplikace',
  'internet-page.settings-page.block-settings': 'Blokovat přístup k nastavením',
  'internet-page.settings-page.custom-msg.card-tile': 'Zpráva pro blokované stránky',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Použít personalizovanou zprávu pro blokované stránky',
  'internet-page.settings-page.save-settings-button-label': 'Uložit nastavení',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Přidat do seznamu:',
  'internet-page.lists.add-button-label': 'Přidat',
  'internet-page.lists.remove-button-label': 'Smazat',
  'internet-page.lists.white-list.card-title': 'Bezpečný seznam',
  'internet-page.lists.black-list.card-title': 'Seznam blokovaných',
  'internet-page.lists.remove-prefix': 'Zadaná adresa začíná s "{prefix}", doporučuje se přidat pouze název domény. Chcete odstranit "{prefix}" z adresy?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Navštívené stránky',

  'internet-page.searches.searches-pages-card-title': 'Hledané fráze',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Harmonogram',
  'internet-page.access-time.schedule-legend-label': 'Legenda harmonogramu',
  'internet-page.access-time.schedule-internet-available-label': 'Internet k dispozici',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet zablokován',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Hovory a textové zprávy (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Hovory',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Textové zprávy (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Poslední změna:',
  'calls-text-messages-page.contacts-no-changes-label': 'Prázdné.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Kontakty',

  // Localization Page
  'localization-page.headline-title': 'Poloha',
  'localization-page.headline-title-off': 'Lokalizace, sledování GPS je vypnuto',
  'localization-page.map-hours-filter-label': 'Vyberte časové období pro zobrazení historie polohy',
  'localization-page.gps-tracking-on': 'Sledování GPS povoleno',
  'localization-page.gps-view-speed': 'Ukázat rychlost cestování',
  'localization-page.map-loading-label': 'Načítání map...',
  'localization-page.map-no-data': 'ŽÁDNÁ DATA',

  'localization-page.one-gps-was-off-since': 'Služby určování polohy zařízení vypnuté od {lastOffStr}',
  'localization-page.one-gps-was-off-for': 'Služby určování polohy zařízení vypnuté na {elapsedStr}',
  'localization-page.many-gps-was-off-since': 'Služby určování polohy zařízení vypnuté od {lastOffStr}',
  'localization-page.many-gps-was-off-for': 'Služby určování polohy zařízení vypnuté na {elapsedStr}',


  // Battery Page
  'battery-page.headline-title': 'Baterie',
  'battery-page.chart-description': 'Úroveň baterie',

  // Access Time Page
  'access-time-page.headline-title': 'Čas přístupu',
  'access-time-page.nav-access-time-label-applications': 'Aplikace',
  'access-time-page.nav-access-time-label-internet': 'Webové stránky',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Seznam videí',
  'youtube-page.no-videos': 'Žádná videa ve vybraném datu',
  'youtube-page.block-videoid': 'Blokovat toto video',
  'youtube-page.block-channelid': 'Blokovat tento kanál',
  'youtube-page.video-blocked': 'Blokováno',

  // Screen Pages
  'screenview-page.headline-title': 'Zobrazení obrazovky',
  'screenview-page.reload-button': 'Obnovit',
  'screenview-page.chart-description': 'Aktuální zobrazení obrazovky',
  'screengrab-page.headline-title': 'Snímek obrazovky',
  'screengrab-page.chart-description': 'Snímek obrazovky',
  'screengrab-page.screen-save-time': 'Jak často se pořizuje snímek obrazovky',
  'screengrab-page.screen-save-time-desc': 'Vyberte, jak často se bude pořizovat snímek obrazovky, nebo vyberte "deaktivováno", aby se nepořizovaly žádné snímky obrazovky',
  'screengrab-page.screen-save-time-disabled': 'deaktivováno',
  'screengrab-page.no-screens-for-today': 'V tento den nebyly pořízeny žádné snímky obrazovky',
  'screengrab-page.no-screens-for-filter': 'Žádné obrazovky nesplňující vybraná kritéria',
  'screengrab-page.imgs-delete-description': 'Opravdu chcete smazat vybrané obrázky?',
  'screengrab-page.imgs-delete-description-one': 'Opravdu chcete smazat vybraný obrázek?',
  'screengrab-page.imgs-delete-label': 'Smazat',
  'screengrab-page.imgs-delete-select': 'Vybrat ke smazání',
  'screengrab-page.imgs-delete-selected': 'Vybrané:',
  'screengrab-page.imgs-delete-limit': 'Byl dosažen limit vybraných snímků obrazovky.',
  'screengrab-page.imgs-filter': 'Filtr:',
  'screengrab-page.imgs-filter-communicators': 'Komunikátory',
  'screengrab-page.imgs-filter-social': 'Sociální média',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Ostatní',

  'screes-pages.device-offline': 'Zařízení je offline',
  'screes-pages.device-deactivated': 'Ochrana byla dočasně deaktivována',
  'screes-pages.device-timeout': 'Zařízení neodpovědělo',
  'screes-pages.device-screenoff': 'Obrazovka je vypnutá',
  'screes-pages.device-notallowed': 'Pořizování snímků obrazovky na zařízení není povoleno',
  'screes-pages.device-error': 'Došlo k chybě',

  'lazy-image.img-delete-description': 'Opravdu chcete smazat vybraný obrázek?',

  // Applications Page
  'applications-page.headline-title': 'Aplikace',
  'applications-page.nav-access-time-label': 'Čas přístupu',
  'applications-page.nav-statistics-label': 'Souhrn',
  'applications-page.nav-apps-list-label': 'Seznam aplikací',
  'applications-page.chart-activity-by-day-label': 'Aktivita během dne',
  'applications-page.chart-hour-label': 'Hodina',
  'applications-page.chart-activity-by-hour-minutes-label': 'Aktivita během dne v minutách',
  'applications-page.list-launched-count-label': 'Počet spuštění:',
  'applications-page.list-elapsed-time-label': 'Doba běhu:',
  'applications-page.introduction': 'Nainstalované aplikace',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Rozsah časového plánu',
  'applications-page.settings.schedule-legend-label': 'Legenda časového plánu',
  'applications-page.settings.schedule-blocked-label': 'Blokované aplikace',
  'applications-page.settings.schedule-available-label': 'Dostupné aplikace',
  'applications-page.time-limit.description': 'Nastavte časový limit pro konkrétní aplikace. Vyberte, kolik hodin denně je dovoleno používat aplikaci.',
  'applications-page.apps-headline-title': 'Dostupné aplikace',
  'applications-page.apps-first-seen-label': 'Poprvé viděno:',
  'applications-page.apps-available-switch-label': 'Dostupné',
  'applications-page.apps-not-available-switch-label': 'Nedostupné',
  'applications-page.apps-schedule-switch-label': 'Plánováno',
  'applications-page.apps-no-schedule-switch-label': 'Neplánováno',
  'applications-page.apps-time-limit-switch-label': 'Časový limit',
  'applications-page.apps-no-time-limit-switch-label': 'Bez časového limitu',
  'applications-page.apps-show-uninstalled-label': 'Zobrazit odebrané aplikace',
  'applications-page.apps-show-apps-list': 'Vybrat časově omezené aplikace',

  'applications-page.used-count-zero-label': 'Nezahájeno',
  'applications-page.used-count-one-label': 'Spuštěno {value} krát, celkový čas: {totalTime}',
  'applications-page.used-count-many-label': 'Spuštěno {value} krát, celkový čas: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sec',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sec',
  'applications-page.total-time-label': 'Celkový čas',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Aktivační odkaz byl použit nebo je neplatný',
  'email-confirmation-page.successful-confirm': 'E-mail byl potvrzen. Děkujeme!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Změnit heslo',
  'change-password-page.new-password-input-label': 'Nové heslo',
  'change-password-page.new-password-repeated-input-label': 'Zopakovat nové heslo',
  'change-password-page.current-password-input-label': 'Aktuální heslo',
  'change-password-page.back-button-label': 'Zpět',
  'change-password-page.change-button-label': 'ZMĚNIT',
  'change-password-page.successful-change-label': 'Heslo bylo úspěšně změněno',
  'change-password-page.wrong-current-password-label': 'Nesprávné aktuální heslo',
  'change-password-page.password-too-short-label': 'Nové heslo je příliš krátké',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENU',
  'top-navigation.user-menu.account-label': 'Účet',
  'top-navigation.user-menu.sign-out': 'Odhlásit se',
  'top-navigation.user-menu.subscription-label': 'Plán předplatného',
  'top-navigation.user-menu.change-password-label': 'Změnit heslo',
  'top-navigation.user-menu.add-device-label': 'Přidat zařízení',

  // Sidebar
  'sidebar.menu-item.applications': 'Aplikace',
  'sidebar.menu-item.calls-and-text-messages': 'Volání & Texty (SMS)',
  'sidebar.menu-item.internet': 'Webové stránky',
  'sidebar.menu-item.localizations': 'Lokalizace',
  'sidebar.menu-item.social-networks': 'Sociální sítě',
  'sidebar.menu-item.summary': 'Panel',
  'sidebar.menu-item.battery': 'Baterie',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Zobrazení obrazovky',
  'sidebar.menu-item.screengrab': 'Snímek obrazovky',
  'sidebar.menu-item.access-time': 'Doba přístupu',

  // Validator
  'phone-number.not-valid': 'Zadejte platné telefonní číslo',
  'input.required-label': 'Pole je povinné',
  'input.select-required-label': 'Prosím vyberte',
  'email.not-valid-label': 'Zadejte platnou e-mailovou adresu',
  'domain-exist-label': 'Doména byla přidána',
  'domain-exist-other-label': 'Doména existuje na jiném seznamu',
  'domain-not-valid': 'Doména není platná',
  'password-not-match-label': 'Hesla se neshodují',

  // misc
  'button-buy-label': 'Vyberte plán',

  'transactiion-status-0': 'Očekává se',
  'transactiion-status-1': 'Dokončeno',
  'transactiion-status--1': 'Zrušeno',
  'transactiion-status--2': 'Zrušeno – refundace',
  'transactiion-status--3': 'Čeká se na potvrzení',
  'transactiion-status--100': 'Zahájeno',
  'transactiion-status-other': 'Ostatní',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Úspěch',
  'reward-page.error-already-used': 'Tento kód byl již použit',
  'reward-page.error-invalid-code': 'Nesprávný kód',
  'reward-page.error-connection': 'Chyba připojení',
  'reward-page.additional-bonus': 'Bonus',
  'reward-page.get-additional-bonus': 'Uplatnit propagační kupon',
  'reward-page.get-now': 'Uplatnit',
  'reward-page.lic-was-extended-by-days': 'Váš předplatitelský plán byl prodloužen o {rewardDays} dnů!',
  'reward-page.there-was-an-error': 'Bohužel došlo k chybě.',
  'reward-page.lic-not-extended': 'Váš předplatitelský plán nebyl prodloužen.',
  'reward-page.button-close': 'Zavřít',

  // url item
  'url-item.copied': 'Zkopírováno do schránky',
  'url-item.copy-link': 'Kopírovat odkaz',

  // APK howto

  'apk-howto-google-play': 'Google Play Store omezuje určitá oprávnění aplikací, která jsou potřebná pro využití této funkce.',
  'apk-howto-on-the-phone': 'Na dětském telefonu s nainstalovanou aplikací {applite}',
  'apk-howto-step1': 'Odinstalujte {applite}, pokud jste ji předtím stáhli přes Google Play',
  'apk-howto-step2': 'Stáhněte aplikaci přímo na telefon dítěte přímo z našich webových stránek',
  'apk-howto-step2B': 'nebo naskenujte níže uvedený QR kód na dětském telefonu',
  'apk-howto-step3': 'Nainstalujte staženou aplikaci do zařízení',
  'apk-howto-tutorial': 'Průvodce instalací aplikace na telefonu nebo tabletu',

  // Cookies

  'cookies-info-uses': 'Tato webová stránka používá cookies.',
  'cookies-info-privacy-policy': 'Více v zásadách ochrany soukromí.',

  // Plan names

  'plan-name-demoExpired': 'Zkušební doba vypršela',
  'plan-name-licExpired': 'Plán vypršel',
  'plan-name-reward': 'Bonus',
  'plan-name-demo': 'Zkušební doba',
  'plan-name-minimal': 'Základní',
  'plan-name-regular': 'Pravidelný',
  'plan-name-premium': 'Prémiový',

  // agreements

  'agreement-will-inform-user': 'Prohlašuji, že informuji uživatele, na jehož zařízení bude aplikace nainstalována, že jejich aktivita a poloha budou monitorovány.',

  'agreement-privacy-policy': 'Prohlašuji, že jsem si přečetl(a) a přijal(a) <a href="{regulations}" target="_blank">podmínky</a> a <a href="{privacy}" target="_blank">zásady ochrany osobních údajů</a>. Potvrzuji, že jsem starší 18 let a na svém účtu přidám pouze děti, které jsou mladší 18 let a jsou mými zákonnými závislými.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': 'Souhlasím s tím, že na svou emailovou adresu a telefonní číslo poskytnuté na {domain} obdržím marketingový obsah týkající se propagačních nabídek a že budou údaje předány reklamním partnerům uvedeným v zásadách ochrany osobních údajů za účelem digitální inzerce. <a href="{marketing}" target="_blank">Více</a>',


  'gdpr-page1-title': 'Vážíme si vašeho soukromí',
  'gdpr-page1-content': 'Kliknutím na „PŘIJMOUT VŠE“ souhlasíte s použitím souborů cookie pro automatický sběr a zpracování vašich osobních údajů doménou {domain}. Soubory cookie používáme ke zlepšení kvality prohlížení, zobrazování reklam nebo obsahu přizpůsobeného potřebám jednotlivých uživatelů a analýze provozu na našich webových stránkách. Kliknutím na tlačítko „PŘIJMOUT VŠE“ souhlasíte s použitím našich souborů cookie.',

  'gdpr-page1-advanced': 'Více informací',
  'gdpr-page1-agree': 'PŘIJMOUT VŠE',

  'gdpr-page2-title': 'Více	 informací',
  'gdpr-page2-content': 'Když používáte náš web {domain} nebo {panel}, můžeme automaticky sbírat údaje o vaší aktivitě. Tato data jsou sbírána pomocí Cookies. Cookies jsou vloženy do prohlížeče vašeho počítače nebo jiného zařízení během procházení webu. Cookies také umožňují přihlášení do {panel}, kde můžete měnit nastavení účtu a zobrazovat aktivitu chráněných uživatelů. Cookies se také používají pro personalizaci a profilování reklam na základě vaší aktivity na avosmart.com a všech jeho podstránkách. Některé Cookies jsou nezbytné pro správné fungování webu, zatímco jiné jsou volitelné. Vyberte si, které Cookies odsouhlasíte. <a href="{privacy}" target="_blank">Více v našem zásad o ochraně osobních údajů.</a>',

  'gdpr-page2-necessary': 'Pouze Nezbytné',
  'gdpr-page2-agree': 'PŘIJMOUT VŠE',
}

export default {
  locale: 'cs',
  name: 'Čeština',
  data: appLocalData,
  messages
} as LanguageLocale
