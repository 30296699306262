import React from 'react'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'
import { InjectedI18nProviderProps, withI18nProvider } from '../providers/i18nProvider'
import { subtractDateByDays, addDateByHours, dateToAllDayFromToDate } from '../lib'
import DatePickerWithButtons from './DatePickerWithButtons'

type Props = InjectedI18nProviderProps & {
  className?: string
  dateFrom: Date | null
  dateTo: Date | null
  dateRangeHidden?: boolean
  onDateFromChanged: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void
  onDateToChanged: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void
}

// TODO set from language
// const DATE_FORMAT = 'dd/MM/yyyy'

const DateRange: React.FC<Props> = ({
  className,
  dateFrom,
  dateTo,
  dateRangeHidden,
  onDateFromChanged,
  onDateToChanged,
  i18nProvider
}) => {

  const [ currentType, setCurrentType ] = React.useState('week')
  // const [ currentSelectedDay, setCurrentSelectedDay ] = React.useState( new Date() )
  // const [ currentSelectedDayTo, setCurrentSelectedDayTo ] = React.useState( new Date() )

  function handleOnLastDayClick () {
    const currentDate = new Date()
    const from = addDateByHours(currentDate, -24)

    setCurrentType('day')

    onDateFromChanged(from, undefined)
    onDateToChanged(currentDate, undefined)
  }

  function handleOnCustomClick () {
    const currentDate = new Date()
    const fromTo = dateToAllDayFromToDate(currentDate)
    
    setCurrentType('custom')
    // setCurrentSelectedDay(fromTo.from)
    // setCurrentSelectedDayTo(fromTo.to)

    onDateFromChanged(fromTo.from, undefined)
    onDateToChanged(fromTo.to, undefined)
  }

  function handleOnDateFromChanged (date: Date, event: React.SyntheticEvent<any> | undefined) {
    const fromTo = dateToAllDayFromToDate(date)
    // setCurrentSelectedDay(fromTo.from)
    // setCurrentSelectedDayTo(fromTo.to)
    onDateFromChanged(fromTo.from, event)
    onDateToChanged(fromTo.to, event)
  }

  function handleOnThisWeekClick () {
    const currentDate = new Date()
    const from = subtractDateByDays(currentDate, 7)
    setCurrentType('week')
    // setCurrentSelectedDay(from)
    // setCurrentSelectedDayTo(currentDate)
    onDateFromChanged(from, undefined)
    onDateToChanged(currentDate, undefined)
  }

  function handleOnThisMonthClick () {

    const currentDate = new Date()
    const from = subtractDateByDays(currentDate, 30)

    setCurrentType('month')
    // setCurrentSelectedDay(from)
    // setCurrentSelectedDayTo(currentDate)
    onDateFromChanged(from, undefined)
    onDateToChanged(currentDate, undefined)
  }

  // function handleNextDay () 
  // {
  //   const currentDate = new Date()

  //   if (currentSelectedDayTo < currentDate) 
  //   {
  //     const fromTo = dateToAllDayFromToDate(addDateByDays(currentSelectedDay, 1))
  //     setCurrentSelectedDay(fromTo.from)
  //     setCurrentSelectedDayTo(fromTo.to)
  //     onDateFromChanged(fromTo.from, undefined)
  //     onDateToChanged(fromTo.to, undefined)
  //   }
  // }

  // function handlePrevDay () 
  // {
  //   const fromTo = dateToAllDayFromToDate(subtractDateByDays(currentSelectedDay, 1))
  //   setCurrentSelectedDay(fromTo.from)
  //   setCurrentSelectedDayTo(fromTo.to)
  //   onDateFromChanged(fromTo.from, undefined)
  //   onDateToChanged(fromTo.to, undefined)
  // }

  React.useEffect(() => {
    const datePickers = Array.from(document.querySelectorAll('.react-datepicker__input-container input'))
    datePickers.map(datePicker =>
      datePicker.setAttribute('readonly', 'readonly')
    )
  }, [])

  return (
    <div className="date-range-picker">
            <div className="btn-group ml-2">
              <UncontrolledDropdown>
                <DropdownToggle caret color="primary" className="btn-s">
                  {currentType === 'day' && <I18nMessages id="date-filter.this.day" />}
                  {currentType === 'week' && <I18nMessages id="date-filter.this.week" />}
                  {currentType === 'month' && <I18nMessages id="date-filter.this-month" />}
                  {currentType === 'custom' && <I18nMessages id="date-filter.this.custom" />}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={handleOnLastDayClick}>
                    <I18nMessages id="date-filter.this.day" />
                  </DropdownItem>
                  <DropdownItem onClick={handleOnThisWeekClick}>
                    <I18nMessages id="date-filter.this.week" />
                  </DropdownItem>
                  <DropdownItem onClick={handleOnThisMonthClick}>
                    <I18nMessages id="date-filter.this-month" />
                  </DropdownItem>
                  <DropdownItem onClick={handleOnCustomClick}>
                    <I18nMessages id="date-filter.this.custom" />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

          {currentType === 'custom' && <div className="datepicker-with-buttons">

          <div className="d-flex align-items-flex-center">
            <DatePickerWithButtons
              selectedDate={dateFrom}
              onSelectedDateChanged={ (d) => d && handleOnDateFromChanged(d, undefined) }
              
            />
            </div>

            {/* <div className="d-flex align-items-center">
                <Button color="light" size="s" onClick={handlePrevDay}><i className="simple-icon-arrow-left"/></Button>
                <DatePicker
                  className="ml-1"
                  locale={i18nProvider.locale}
                  dateFormat={DATE_FORMAT}
                  selected={dateFrom}
                  maxDate={new Date()}
                  onChange={handleOnDateFromChanged}
                  readOnly={true}
                />
                <Button color="light" size="s" onClick={handleNextDay} className={currentSelectedDayTo < getCurrentDate000() ? '' : 'disabled'}><i className="simple-icon-arrow-right"/></Button>
            </div> */}
          </div>}
    </div>
  )
}

export default withI18nProvider(DateRange)
