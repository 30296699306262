import React from 'react'
import { Switch, Redirect, Route, NavLink } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'
import I18nMessages from '../../../components/I18nMessages'

import CallsListPage from './CallListPage'
import SMSListPage from './SMSListPage'
import ContactListPage from './ContactListPage'

const CallsTextMessages: React.FC = () => {

  return (
    <div className="dashboard-wrapper">
      <I18nMessages id="calls-text-messages-page.headline-title" tagName="h1" />

      <Nav tabs className="separator-tabs ml-0 mb-5">
        <NavItem>
          <NavLink className="nav-link" to="/panel/calls-and-text-messages/call-list">
            <I18nMessages id="calls-text-messages-page.calls-list-title" />
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink className="nav-link" to="/panel/calls-and-text-messages/sms-list">
            <I18nMessages id="calls-text-messages-page.sms-list-title" />
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink className="nav-link" to="/panel/calls-and-text-messages/contact-list">
            <I18nMessages id="calls-text-messages-page.contacts-title" />
          </NavLink>
        </NavItem>
      </Nav>

      <Switch>
        <Redirect exact from="/panel/calls-and-text-messages" to="/panel/calls-and-text-messages/call-list" />
        <Route path="/panel/calls-and-text-messages/call-list" component={CallsListPage} />
        <Route path="/panel/calls-and-text-messages/sms-list" component={SMSListPage} />
        <Route path="/panel/calls-and-text-messages/contact-list" component={ContactListPage} />
        <Redirect to="/error" />
      </Switch>
    </div>
  )
}

export default CallsTextMessages
