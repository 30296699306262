import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import ContactCard from '../../../containers/ContactCard'
import I18nMessages from '../../../components/I18nMessages'
//import Pagination from '../../../components/Pagination'
import Spinner from '../../../components/Spinner'
import { InjectedBenServiceProps, withBenService, ContactData } from '../../../providers/benServiceProvider'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import { HtmlEntities } from '../../../lib/HtmlEntities'
import { shouldDisplaySmsScreensAnyway } from '../../../lib/utils'
import ApkHowto from '../ApkHowto'

type Props = InjectedBenServiceProps & InjectedBenAccountProps

type MappedContact = {
  id: number
  fullName: string
  lastContacted?: Date
  lastUpdated?: Date
  phoneNumbers: string[]
}



function mapContacts (data: ContactData[]) {

  const entities = new HtmlEntities()

  return data.reduce<MappedContact[]>((mappedContacts, current) => {


    const existingItem = mappedContacts.find(item => item.id === current.id)

    if (existingItem && current.text.length > 0) {
      existingItem.phoneNumbers.push(current.text)

      return mappedContacts
    }

    mappedContacts.push({
      id: current.id,
      fullName:  entities.decode(current.name),
      lastContacted: current.lastContacted > 0 ? new Date(current.lastContacted*1000) : undefined,
      lastUpdated: current.lastUpdated > 0 ? new Date(current.lastUpdated*1000) : undefined,
      phoneNumbers: current.text.length > 0 ? [current.text] : []
    })

    return mappedContacts
  }, [])
}

const ContactListPage: React.FC<Props> = ({
  benAccount,
  benService,
}) => {

  const [contacts, setContacts] = React.useState<MappedContact[]>([])
  const [isLoading, setLoadingState] = React.useState(true)


  React.useEffect(() => {
    const { deviceId, profileId } = benAccount.currentProfile
    const offset = 0
    let isMounted = true

    if( shouldDisplaySmsScreensAnyway(benAccount) )
      return

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {
      setLoadingState(true)

      benService.getContacts(profileId, deviceId, offset, 9999)
      .then(result => {
        if (isMounted) {
          const mappedResult = mapContacts(result.data)

          mappedResult.sort( (a,b) => a.fullName.localeCompare(b.fullName) )

          setContacts(mappedResult)
        }
      })

      .finally(() => isMounted && setLoadingState(false))
    }

    return () => {
      isMounted = false
    }
  }, [benAccount, benService, setContacts, setLoadingState])


  if( shouldDisplaySmsScreensAnyway(benAccount) )
  {
    return (
      <ApkHowto labelMessageId={null}/>
    )
  }  

  if (isLoading) {
    return (
      <Spinner />
    )
  }  

  if (contacts.length === 0) {
    return (
      <Card>
        <CardBody>
          <div className="d-flex p-4 align-items-center justify-content-center text-center">
            <I18nMessages id="empty-list-label" />
          </div>
        </CardBody>
      </Card>
    )
  }

  return (
    <Row>
      <Col sm="12" className="mb-4">
        {contacts.map((item, index) => (
          <ContactCard
            key={index}
            fullName={item.fullName}
            lastUpdated={ item.lastUpdated }
            lastContacted={ item.lastContacted }
            phoneNumbers={item.phoneNumbers}
          />
        ))}

      </Col>
    </Row>
  )
}

export default withBenAccount(withBenService(ContactListPage))
