import React from 'react'
import { InjectedIntlProps, injectIntl, InjectedIntl } from 'react-intl'
import { ChartData, ChartLegendOptions, ChartDataSets, ChartOptions } from 'chart.js'
import Chart from 'react-chartjs-2'
import I18nMessages from '../components/I18nMessages'
import getMessage from '../language/getMessage'
import { BarChartDefaults, getColorForChartBackground } from '../lib'
import { MessageKey } from '../language'
import { toLocaleTime } from '../lib/utils'

export interface AppsUsageChartRow {
  hour: number
  count: number
  elapsed: number
  from: number
  to: number
}

export interface AppsUsageChartData {
  name: string
  pkg: string
  usedCount: number
  rows: AppsUsageChartRow[]
}

type Props = InjectedIntlProps & {
  data: AppsUsageChartData[]
}

const DAY_HOURS = Array.from(Array(24).keys())
const SHOW_SECONDS_LIMIT = 5

function tooltipTitleCallback (items: Chart.ChartTooltipItem[], data: ChartData): string {
  const datasetIndex = items[0].datasetIndex
  const dataset = data.datasets

  if (typeof datasetIndex !== 'undefined' && typeof dataset !== 'undefined') {
    return dataset[datasetIndex].label || ''
  }

  return ''
}

function getFormattedTotalTime (minutes: number, seconds: number, intl: InjectedIntl): string {
  const id: MessageKey = (minutes < SHOW_SECONDS_LIMIT ?
    'applications-page.total-minutes-seconds-label' : 'applications-page.total-minutes-label'
  )

  return intl.formatMessage({ id }, { minutes, seconds })
}

function getUsedCountMessageKey (usedCount: number, intl: InjectedIntl): MessageKey {
  switch (intl.formatPlural(usedCount)) {
    case 'zero': return 'applications-page.used-count-zero-label'
    case 'one': return 'applications-page.used-count-one-label'

    default: return 'applications-page.used-count-many-label'
  }
}

const AppsUsageChart: React.FC<Props> = ({
  intl,
  data
}) => {


  const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth)

  
  React.useEffect( () => {

    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }    
    
    window.addEventListener('resize',  handleResize )

  
    return () => {
      window.removeEventListener('resize', handleResize )
    }

  },[])


  const shouldDisplayLegend = () : boolean => {

    if( chartData == null || chartData.datasets == null )
      return false

    if( screenWidth > 960 ) {
      return true
    }
    else if( screenWidth > 640 ) {
      return chartData.datasets.length < 10
    }
    else {
      return false
    }

  }


  function tooltipLabelCallback (tooltipItem: Chart.ChartTooltipItem, tooltipData: ChartData): string {
    const yLabel = typeof tooltipItem.yLabel === 'number' ? tooltipItem.yLabel : undefined
    const datasetIndex = tooltipItem.datasetIndex

    if (typeof datasetIndex !== 'undefined' && typeof yLabel !== 'undefined') {
      const dataItem = data[datasetIndex]
      const minutes = Math.trunc(yLabel)
      const seconds = Math.trunc((yLabel - Math.trunc(yLabel)) * 60)
      const formattedTotalTime = getFormattedTotalTime(minutes, seconds, intl)
      const usedCountMessageKey = getUsedCountMessageKey(dataItem.usedCount, intl)

      return intl.formatMessage({ id: usedCountMessageKey }, {
        value: dataItem.usedCount,
        totalTime: formattedTotalTime
      })
    }

    return ''
  }

  function trimmedStr(str:string, maxLen:number) : string {
    if( str.length <= maxLen )
      return str;

    return str.substring(0, maxLen-3)+"..."
  }

  const chartData: ChartData = {
    //labels: DAY_HOURS.map<string>(hour => hour < 10 ? `0${hour}:00` : `${hour}:00`),
    labels: DAY_HOURS.map<string>(hour => {  const d = new Date(); d.setHours(hour,0,0,0); return toLocaleTime( d, intl) } ),
    datasets: data.map<ChartDataSets>((item, index) => ({
      label: trimmedStr(item.name, 30),
      backgroundColor: getColorForChartBackground(index),
      data: DAY_HOURS.map<number>(hour => {
        const hourFromRow = item.rows.find(row => row.hour === hour)
        return hourFromRow ? (hourFromRow.elapsed / 60) : 0
      })
    }))
  }

  const legend : ChartLegendOptions =  {
    ...BarChartDefaults.legend,
    display: shouldDisplayLegend()
  } 
  
  const chartOptions: ChartOptions = {
    ...BarChartDefaults.options,
    legend: {
      display: shouldDisplayLegend()
    },
    tooltips: {
      callbacks: {
        title: tooltipTitleCallback,
        label: tooltipLabelCallback
      }
    },
    title: {
      display: true,
      text: getMessage('applications-page.chart-activity-by-day-label', intl)
    },
    scales: {
      xAxes: [{
        gridLines: { offsetGridLines: true },
        stacked: true,
        scaleLabel: {
          display: true,
          labelString: getMessage('applications-page.chart-hour-label', intl)
        }
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 10,
          max: 60
        },
        scaleLabel: {
          display: true,
          labelString: getMessage('applications-page.chart-activity-by-hour-minutes-label', intl)
        }
      }]
    }
  }


  return (

    <React.Fragment>
        {/* Width = {screenWidth} <br/>
        Legend = { legend.display ? "1" : "0" } <br/>
        Size = { chartData != null && chartData.datasets != null ? chartData.datasets.length : "-" }  */}

        {data.length === 0 && (
          <div className="chart-container d-flex align-items-center justify-content-center text-center" style={{ position: 'absolute', width:'100%' }}>
            <I18nMessages id="no-data.label-mid" />
          </div>
        )}

        <div className="chart-container">
          <Chart  type="bar"
                  legend={legend}
                  options={chartOptions}
                  data={chartData}
          />
        </div>

    </React.Fragment>
  )
}

export default injectIntl(AppsUsageChart)
