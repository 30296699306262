import React from 'react'
import classnames from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Notification from '../components/Notification'

export type BenNotificationType = 'info' | 'success' | 'warning' | 'error' | 'primary' | 'secondary'

export interface BenNotification {
  uid: string
  className?: string
  type: BenNotificationType
  title?: string
  message: string
  timeout?: number
  onClick?: () => void
}

export interface BenNotificationContextValue {
  notify: (params: NotifyParameters) => void
  remove: (uid: string) => void
}

export interface InjectedBenNotificationProps {
  benNotification: BenNotificationContextValue
}

export const BenNotificationContext = React.createContext<BenNotificationContextValue>({
  notify: () => undefined,
  remove: () => undefined
})

type NotifyParameters = {
  className?: string
  type?: BenNotificationType,
  title?: string
  message: string
  timeout?: number
  hasHightPriority?: boolean
  onClick?: () => void
}

interface NotificationProviderProps {
  enterTimeout?: number
  leaveTimeout?: number
}

interface NotificationProviderState {
  notifications: BenNotification[]
}

class NotificationProvider extends React.Component<NotificationProviderProps, NotificationProviderState> {

  constructor (props: Readonly<NotificationProviderProps>) {
    super(props)

    this.state = { notifications: [] }
  }

  notify = ({ hasHightPriority, ...params }: NotifyParameters) => {
    const notification: BenNotification = {
      ...params,
      uid: this.generateUID(),
      type: params.type || 'info'
    }

    if (hasHightPriority) {
      this.setState({ notifications: [notification, ...this.state.notifications] })
    } else {
      this.setState({ notifications: [...this.state.notifications, notification] })
    }
  }

  remove = (uid: string) => {
    this.setState({ notifications: [...this.state.notifications.filter(item => item.uid !== uid)] })
  }

  private generateUID (): string {
    const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'

    return pattern.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0
      const v = c === 'x' ? r : ((r & 0x3) | 0x8)

      return v.toString(16)
    })
  }

  render () {
    const timeout = { enter: this.props.enterTimeout, exit: this.props.leaveTimeout }
    const className = classnames('notification-container', {
      'notification-container-empty': this.state.notifications.length === 0
    })

    return (
      <BenNotificationContext.Provider value={{ notify: this.notify, remove: this.remove }}>
        <div className={className} >
          <TransitionGroup>
            {this.state.notifications.map(notification => (
              <CSSTransition key={notification.uid} classNames="notification" timeout={timeout}>
                <Notification {...notification} onRequestHide={() => this.remove(notification.uid)} />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
        {this.props.children}
        </BenNotificationContext.Provider>
    )
  }
}

export function withBenNotification <P extends InjectedBenNotificationProps> (Component: React.ComponentType<P>) {
  return (props: Omit<P, keyof InjectedBenNotificationProps>) => (
    <BenNotificationContext.Consumer>
      {state => <Component {...props as P} benNotification={state} />}
    </BenNotificationContext.Consumer>
  )
}

export default NotificationProvider
