import React from 'react'
import Slider, { Marks } from 'rc-slider'
import { Col, Row, Label, Button, Modal, Card, CardBody, ModalHeader, ModalBody } from 'reactstrap'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import I18nMessages from '../components/I18nMessages'
import TimeButton from './TimeButton'
import getMessage from '../language/getMessage'


const HOURS = 8

type Props = InjectedIntlProps & {
  supportsBonus: boolean
  values: number[]
  timeBonusIn: number
  onChange2: (values: number[], bonusTime:number | null) => void
  max?: number
  step?: number
  sliderMarks?: Marks
  smallLabels?: boolean
  labelFormater?: any
}

const SLIDER_MARKS = {
  0: '0',
  60: '1',
  120: '2',
  180: '3',
  240: '4',
  300: '5',
  360: '6',
  420: '7',
  480: '∞'
};

const TimeLimitPicker2: React.FC<Props> = ({
  intl,
  supportsBonus,
  values,
  timeBonusIn,
  onChange2,
  max = HOURS*60,
  step = 5,
  sliderMarks = SLIDER_MARKS,
  smallLabels = false,
}) => {

  const [activeValue, setActiveValue] = React.useState(values[0])
  const [activeIdx, setActiveIdx] = React.useState( getWeekDay() )

  const [currentValues, setCurrentValues] = React.useState(values)
  const [lastValues, setLastValues] = React.useState(values)
  
  const [isBonusPopupOpen, setBonusPopupOpen] = React.useState(false)

  const [timeBonus, setTimeBonus] = React.useState(timeBonusIn) 
  const [timeBonusDialog, setTimeBonusDialog] = React.useState(0)

  const timer = React.useRef<NodeJS.Timer | null>(null)
  const [buttonDownPressed, setButtonDownPressed] = React.useState(false)
  const [buttonUpPressed, setButtonUpPressed] = React.useState(false)

  const MAX_BONUS  = 120
  const MIN_BONUS  = 0
  const BONUS_STEP = 5

  function onToggleBonusPopup()
  {
    if( !isBonusPopupOpen )
    {
      setTimeBonusDialog(timeBonus)
      setBonusPopupOpen( true )
    }
    else
    {
      setBonusPopupOpen( false )
    }

    if(timer.current)
    {
      clearTimeout(timer.current)
      timer.current = null
    }
  }

  function getTimeBonusStrDialog()
  {
    if( timeBonusDialog < 1 )
      return  getMessage( 'time-picker.no-bonus', intl ) //  "No bonus"

    return formatTime(timeBonusDialog, false)
  }

  function getTimeBonusButtonText()
  {
    if( timeBonus < 1 )
      return " + " + getMessage( 'time-picker.bonus', intl )  // Bonus

    return " + " + formatTime(timeBonus, false)
  }




  function getWeekDay() : number
  {
    const d = new Date()
    return ( d.getDay() + 6 ) % 7
  }

  function equals(arr1 : number[], arr2: number[]) 
  {
    if( arr1.length !== arr2.length )
      return false

    for( let i = 0; i<arr1.length; i++ )
      if(arr1[i] !== arr2[i] )
        return false

    return true
  }

  function onDaySelected(idx : number)
  {
    setActiveIdx(idx)
    setActiveValue(currentValues[idx])

    //currentValues[idx] = activeValue
    //setCurrentValues(currentValues)
  }

  function activeValueAferChange ()
  {
    currentValues[activeIdx] = activeValue

    if( equals(lastValues, currentValues) )
    {
      setLastValues(currentValues)
      return onChange2(currentValues, null)
    }
  }

  function activeValueHandleChange(val:number) {
    currentValues[activeIdx] = val
    setActiveValue(val)
  }

  function formatTime(minutes:number, short:boolean)
  {
    if( minutes === HOURS*60 )
    {
      if( short )
        return '∞'
      else
        return getMessage( 'time-picker.unlimied-label', intl ) 
    }

    const h = Math.floor( minutes/60 )
    const m = minutes % 60 

    if( h > 0 )
    {
      if( m > 0 )
        return intl.formatMessage( {id:'time-picker.time-format'}, { h,m })
      else
        return intl.formatMessage( {id:'time-picker.time-format-hour'}, { h })
    }
    else
      return intl.formatMessage( {id:'time-picker.time-format-min'}, { m })
  }

  function getDayNameShort(day:number)
  {
    switch(day)
    {
      case 0: return getMessage('monday-short-label', intl)
      case 1: return getMessage('tuesday-short-label', intl)
      case 2: return getMessage('wednesday-short-label', intl)
      case 3: return getMessage('thursday-short-label', intl)
      case 4: return getMessage('friday-short-label', intl)
      case 5: return getMessage('saturday-short-label', intl)
      case 6: return getMessage('sunday-short-label', intl)
    }
    
    return ""
  }

  function getDayName(day:number)
  {
    switch(day)
    {
      case 0: return getMessage('monday-label', intl)
      case 1: return getMessage('tuesday-label', intl)
      case 2: return getMessage('wednesday-label', intl)
      case 3: return getMessage('thursday-label', intl)
      case 4: return getMessage('friday-label', intl)
      case 5: return getMessage('saturday-label', intl)
      case 6: return getMessage('sunday-label', intl)
    }
    
    return ""
  }

  function onDownClicked(mouseDown: boolean)
  {
    //console.log("onDownClicked")

    setButtonDownPressed(mouseDown)

    if( mouseDown )
    {
      if(timeBonusDialog > 0)
      {
        var b = timeBonusDialog
        b -= BONUS_STEP
        if( b < MIN_BONUS ) b = MIN_BONUS
        setTimeBonusDialog(b)
      }
    }
    else
    {
      if(timer.current) 
      {
        clearTimeout(timer.current)
        timer.current = null
      }
    }    
  }


  function onUpClicked(mouseDown: boolean)
  {
    //console.log("onDownClicked")

    setButtonUpPressed(mouseDown)

    if( mouseDown )
    {
      if(timeBonusDialog < MAX_BONUS)
      {
        var b = timeBonusDialog
        b += BONUS_STEP
        if( b > MAX_BONUS ) b = MAX_BONUS
        setTimeBonusDialog(b)
      }
    }
    else
    {
      if(timer.current)
      {
        clearTimeout(timer.current)
        timer.current = null
        return
      }
    }
  }

  function onOK()
  {
    setTimeBonus(timeBonusDialog)
    onChange2(values, timeBonusDialog)

    onToggleBonusPopup()
  }

  function onCancel()
  {
    onToggleBonusPopup()
  }

  React.useEffect(() => {

    if( buttonUpPressed )
      timer.current = setTimeout( () => {onUpClicked(true)}, timer.current ? 200 : 500 )

    if( buttonDownPressed )
      timer.current = setTimeout( () => {onDownClicked(true)}, timer.current ? 200 : 500 )

  },[buttonUpPressed, buttonDownPressed, timeBonusDialog])


  React.useEffect(() => {

      setCurrentValues(values)
      setLastValues(values)
      setActiveValue(values[activeIdx])
      setTimeBonus(timeBonusIn)

  }, [values, timeBonusIn, activeIdx])

  const noLimit = activeValue === HOURS*60
  const currentWeekDay = getWeekDay()


  return (
    <React.Fragment>

      <Modal isOpen={isBonusPopupOpen} toggle={onToggleBonusPopup} size="lg" className="modal-centered">
        <ModalHeader>
          <Button color="light" size="xs" onClick={onToggleBonusPopup} className="button-x" >X</Button>
          <div className="modal-large-title">
            <I18nMessages id="time-picker.add-bonus-title" />
          </div>
        </ModalHeader>

        <ModalBody className="">
          <Card>
            <CardBody className="text-center">
              <Button className="rounded-button" color={ timeBonusDialog > MIN_BONUS ? "primary" : "light" } size="xs" 
                      onMouseDown={ (e) => onDownClicked(true) } 
                      onMouseUp={(e) => onDownClicked(false)} 
                      onMouseLeave={(e) => onDownClicked(false) }
                      >-</Button>
              
              <Label className="bonus-time-text">{getTimeBonusStrDialog()}</Label>
              
              <Button className="rounded-button" color={ timeBonusDialog < MAX_BONUS ? "primary" : "light" } size="xs" 
                      onMouseDown={ (e) => onUpClicked(true) } 
                      onMouseUp={(e) => onUpClicked(false) }
                      onMouseLeave={(e) => onUpClicked(false) }
                      >+</Button>
              </CardBody>
            </Card>
            <div className="text-center mt-4">
              <Button color="primary" onClick={onOK}     className="m-2" ><I18nMessages id="time-picker.add-bonus-button-set" /></Button>
              <Button color="light"   onClick={onCancel} className="m-2"><I18nMessages id="time-picker.add-bonus-button-cancel" /></Button>
            </div>
        </ModalBody>
      </Modal>

      <Row>
        <Col>
          <TimeButton buttonId={0} active={activeIdx===0} currentDay={currentWeekDay===0} labelTime={formatTime(values[0], true)} onClicked={onDaySelected} labelButton='monday-sshort-label'   />
        </Col>
        <Col>
          <TimeButton buttonId={1} active={activeIdx===1} currentDay={currentWeekDay===1} labelTime={formatTime(values[1], true)} onClicked={onDaySelected} labelButton='tuesday-sshort-label'  />
        </Col>
        <Col>
          <TimeButton buttonId={2} active={activeIdx===2} currentDay={currentWeekDay===2} labelTime={formatTime(values[2], true)} onClicked={onDaySelected} labelButton='wednesday-sshort-label'/>
        </Col>
        <Col>
          <TimeButton buttonId={3} active={activeIdx===3} currentDay={currentWeekDay===3} labelTime={formatTime(values[3], true)} onClicked={onDaySelected} labelButton='thursday-sshort-label' />
        </Col>
        <Col>
          <TimeButton buttonId={4} active={activeIdx===4} currentDay={currentWeekDay===4} labelTime={formatTime(values[4], true)} onClicked={onDaySelected} labelButton='friday-sshort-label'   />
        </Col>
        <Col>
          <TimeButton buttonId={5} active={activeIdx===5} currentDay={currentWeekDay===5} labelTime={formatTime(values[5], true)} onClicked={onDaySelected} labelButton='saturday-sshort-label' />
        </Col>
        <Col>
          <TimeButton buttonId={6} active={activeIdx===6} currentDay={currentWeekDay===6} labelTime={formatTime(values[6], true)} onClicked={onDaySelected} labelButton='sunday-sshort-label'   />
        </Col>
      </Row>

      <div className="separator mb-4 mt-4"></div>

      <div className="text-left mb-3">
        <p className={noLimit || smallLabels ? 'time-limit-clock-small' : 'time-limit-clock-small'}>
           <span className="time-limit-oneline">{ getDayName(activeIdx) }: </span>
           <span className="time-limit-oneline">{ formatTime(activeValue, false) }</span>

           { supportsBonus && timeBonus >= 0 && getWeekDay() === activeIdx && (            
              <button className="ml-4 button-bonus btn btn-outline-success glyph-icon iconsminds-clock-forward" onClick={onToggleBonusPopup}>{getTimeBonusButtonText()}</button>
            ) }
        </p>

      </div>
      <div className="nb-4">
        <Slider
          value={activeValue}
          dotStyle={{ display: 'none' }}
          step={step}
          max={max}
          marks={{ ...sliderMarks }}
          onAfterChange={activeValueAferChange}
          onChange={activeValueHandleChange}
        />
      </div>
    </React.Fragment>
  )
}

export default injectIntl(TimeLimitPicker2)
