import React from 'react'

import IcoContent from '../../../../assets/media/IcoContent.png'
import IcoOK from '../../../../assets/media/IcoOK.png'
import IcoTime from '../../../../assets/media/IcoTime.png'
import IcoUserDef from '../../../../assets/media/IcoUserDef.png'
import IcoWhite from '../../../../assets/media/IcoWhite.png'
import { Row, Col, UncontrolledTooltip } from 'reactstrap'
import { InjectedIntl, injectIntl } from 'react-intl'
import I18nMessages, { getI18nMessage } from '../../../../components/I18nMessages'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import CopyIcon from '../../../../assets/media/ico-copy-link.svg'
import { toLocaleDateTime } from '../../../../lib/utils'

export type URLItemType = 'white' | 'ok' | 'time' | 'userDef' | 'content'

type URLItemProps = {
  index: number
  itemType?: URLItemType
  date: Date
  domain: string
  url: string
  intl: InjectedIntl
}


function getImg(itemType: URLItemType )
{
  switch(itemType)
  {
    case 'ok':      return IcoOK;
    case 'white':   return IcoWhite;
    case 'time':    return IcoTime;
    case 'userDef': return IcoUserDef;
    case 'content': return IcoContent;
  }
}

function getTxtClass(itemType: URLItemType)
{
  switch(itemType)
  {
    case 'ok':      return "text-body";
    case 'white':   return "text-success";
    case 'time':    return "text-warning";
    case 'userDef': return "text-warning";
    case 'content': return "text-danger";
  }
}

function getMsg(itemType: URLItemType, intl: InjectedIntl) : string
{
  switch(itemType)
  {
    case 'white':   return getI18nMessage("internet-page.statistics-page-www-ok-white", intl)
    case 'time':    return getI18nMessage("internet-page.statistics-page-www-timelimit", intl)
    case 'userDef': return getI18nMessage("internet-page.statistics-page-www-userdef", intl)
    case 'content': return getI18nMessage("internet-page.statistics-page-www-content", intl)
    default:
      return getI18nMessage("internet-page.statistics-page-www-ok", intl)

  }
}

const URLItem: React.FC<URLItemProps> = ({
  index,
  domain,
  itemType = 'ok',
  date,
  url,
  intl
}) => {

  const [isCopied, setIsCopied] = React.useState(false)

  return (
    <div className={`pb-1 border-bottom url-item ${getTxtClass(itemType)}`}>
      <Row>
        <Col  xs="12" sm="1" style={{margin: "auto"}}>
          <div >
            <img id={'img'+String(index)} className="url-ico-container" src={getImg(itemType)} alt={getMsg(itemType, intl)} />
          </div>

          <UncontrolledTooltip placement="right" target={'img'+String(index)} >
            { getMsg(itemType, intl) }
          </UncontrolledTooltip>

        </Col>
        <Col className="d-flex" xs="8" sm="7">
          <div className="truncate-text">
            <p className="font-weight-medium mb-1">{domain}</p>
            <p className="text-muted mb-1 text-small">{ toLocaleDateTime(date, intl) }</p>
            <p className="text-small truncate">
              {url}
            </p>
          </div>
        </Col>
        <Col xs="1" sm="3" className="d-sm-flex flex-lg-row-reverse d-none">
          <div className="copied-link-wrapper">
            {
              isCopied && <span className="badge badge-success copied-link-msg my-2">
                <I18nMessages id="url-item.copied" />
              </span>
            }
          </div>
        </Col>
        <Col xs="4" sm="1" className="d-flex flex-row-reverse">
          <CopyToClipboard
            onCopy={() => {
              setIsCopied(true)
              setTimeout(() => {
                setIsCopied(false)
              }, 1500)
            }}
            text={url}>
            <button className="border-0 copy-link-btn m-0 mr-xs-0 mr-lg-4 p-0" id={'copyToClipboard-' + index}>
              <img src={CopyIcon} width="32px" alt="img" />
            </button>
          </CopyToClipboard>

          <UncontrolledTooltip
                  placement="top"
                  target={'copyToClipboard-' + index}>
              <I18nMessages id="url-item.copy-link" />
          </UncontrolledTooltip>
        </Col>
        <Col xs="12" sm="4" className="d-xs-flex flex-lg-row-reverse d-sm-none">
          <div className="copied-link-wrapper">
            {
              isCopied && <span className="badge badge-success copied-link-msg my-2">
                <I18nMessages id="url-item.copied" />
              </span>
            }
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default injectIntl(URLItem)
