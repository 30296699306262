import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Nav, NavItem } from 'reactstrap'
import { RouteComponentProps, NavLink, withRouter } from 'react-router-dom'
import { APP_TYPE, Privileges } from '../providers/benServiceProvider'
import I18nMessage from '../components/I18nMessages'
import { withBenAccount, InjectedBenAccountProps, BenAccountContextValue } from '../providers/benAccountProvider'

import ytIcon from '../assets/media/yt_icon.png'
import screenViewIcon from '../assets/media/screenview_icon.png'
import screenGrabIcon from '../assets/media/screengrab_icon.png'
import { hasDeviceFeature, shouldDisplaySmsScreensAnyway } from '../lib/utils'

type SidebarProps = {
  privileges: Privileges
  onMenuClicked: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

// TODO: Move Sidebar to React.FC component
class Sidebar extends React.Component<InjectedBenAccountProps & RouteComponentProps & SidebarProps> {


  static summaryPath = '/panel/summary'
  static internetPath = '/panel/internet'
  static applicationsPath = '/panel/applications'
  static localizationsPath = '/panel/localization'
  static callsAndTextMessagePath = '/panel/calls-and-text-messages'
  static batteryPath = '/panel/battery'
  static youtubePath = '/panel/youtube'
  static screenViewPath = '/panel/screenview'
  static screenGrabPath = '/panel/screengrab'
  static socialNetworksPath = '/panel/social-networks'
  static accessTimePath = '/panel/access-time'

  private navItemClassNameForPath (path: string): string {
    return this.props.location.pathname.indexOf(path) > -1 ? 'active' : ''
  }

  private get summaryNavItemClassName (): string {
    return this.props.location.pathname.indexOf('/panel/summary') > -1 ? 'active' : ''
  }

  private handleOnResizeWindow = (event: UIEvent) => {
    // console.log(event.isTrusted)
  }



  componentDidMount () {
    window.addEventListener('resize', this.handleOnResizeWindow)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleOnResizeWindow)
  }

  render () {
    return (
      <div className="sidebar app-functions-main-menu">
        <div className="main-menu">
          <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: false }}>
            <Nav vertical className="list-unstyled">

              <NavItem className={this.summaryNavItemClassName} onClick={this.props.onMenuClicked}>
                <NavLink to={Sidebar.summaryPath}>
                  <i className="iconsminds-line-chart-3 sidebar-icon"/>
                  <I18nMessage id="sidebar.menu-item.summary"/>
                </NavLink>
              </NavItem>

              <NavItem className={this.navItemClassNameForPath(Sidebar.internetPath)}  onClick={ (e) => this.props.onMenuClicked(e) }>
                <NavLink to={Sidebar.internetPath}>
                  <i className="iconsminds-digital-drawing sidebar-icon"/>
                  <I18nMessage id="sidebar.menu-item.internet"/>
                </NavLink>
              </NavItem>

              <NavItem className={this.navItemClassNameForPath(Sidebar.applicationsPath)}  onClick={this.props.onMenuClicked}>
              {/* className = {this.props.privileges && this.props.privileges.getApps === 1 ? '' : 'disabled' } */}
                <NavLink to="/panel/applications" >
                  <i className="simple-icon-screen-smartphone sidebar-icon"/>
                  <I18nMessage id="sidebar.menu-item.applications"/>
                </NavLink>
              </NavItem>

              <NavItem className={this.navItemClassNameForPath(Sidebar.accessTimePath)}  onClick={this.props.onMenuClicked}>
                <NavLink to="/panel/access-time" >
                  <i className="simple-icon-clock sidebar-icon"/>
                  <I18nMessage id="sidebar.menu-item.access-time"/>
                </NavLink>
              </NavItem>

              { hasDeviceFeature( this.props.benAccount, '_GPS_') && (
                <NavItem className={this.navItemClassNameForPath(Sidebar.localizationsPath)}  onClick={this.props.onMenuClicked}>
                {/* className = {this.props.privileges && this.props.privileges.getGPS === 1 ? '' : 'disabled' } */}
                  <NavLink to="/panel/localization" >
                    <i className="simple-icon-location-pin sidebar-icon"/>
                    <I18nMessage id="sidebar.menu-item.localizations"/>
                  </NavLink>
                </NavItem>
              )}

              { hasDeviceFeature( this.props.benAccount, '_YT_') && (
              <NavItem className={this.navItemClassNameForPath(Sidebar.youtubePath)} onClick={this.props.onMenuClicked}>
                <NavLink to="/panel/youtube" >
                  {/* <i className="iconsminds-youtube"/> */}
                  <img src={ytIcon} className="sidebar-yt-icon" alt="YT"/>
                  <I18nMessage id="sidebar.menu-item.youtube"/>
                </NavLink>
              </NavItem>
              )}

              { ( hasDeviceFeature( this.props.benAccount, '_SCR_', true) || shouldDisplaySmsScreensAnyway( this.props.benAccount ) ) && (
                <NavItem className={this.navItemClassNameForPath(Sidebar.screenViewPath)} onClick={this.props.onMenuClicked}>
                  <NavLink to="/panel/screenview" >
                    <img src={screenViewIcon} className="sidebar-screen-icon" alt="ScreenView"/>
                    <I18nMessage id="sidebar.menu-item.screenview"/>
                  </NavLink>
                </NavItem>
              )}

            { ( hasDeviceFeature( this.props.benAccount, '_SCR_', true) || shouldDisplaySmsScreensAnyway( this.props.benAccount ) ) && (
                <NavItem className={this.navItemClassNameForPath(Sidebar.screenGrabPath)} onClick={this.props.onMenuClicked}>
                  <NavLink to="/panel/screengrab" >
                    <img src={screenGrabIcon} className="sidebar-screen-icon" alt="ScreenGrab"/>
                    <I18nMessage id="sidebar.menu-item.screengrab"/>
                  </NavLink>
                </NavItem>
              )}

              { ( hasDeviceFeature( this.props.benAccount, '_SMS_', true) || shouldDisplaySmsScreensAnyway( this.props.benAccount ) ) && (
                <NavItem className={this.navItemClassNameForPath(Sidebar.callsAndTextMessagePath)}  onClick={this.props.onMenuClicked}>
                {/* className = {this.props.privileges && this.props.privileges.getSmses === 1 ? '' : 'disabled' } */}
                  <NavLink to="/panel/calls-and-text-messages" >
                    <i className="simple-icon-call-in sidebar-icon"/>
                    <I18nMessage id="sidebar.menu-item.calls-and-text-messages"/>
                  </NavLink>
                </NavItem>
              )}

              { hasDeviceFeature( this.props.benAccount, '_BATTERY_') && (
              <NavItem className={this.navItemClassNameForPath(Sidebar.batteryPath)} onClick={this.props.onMenuClicked}>
                <NavLink to="/panel/battery" >
                  <i className="iconsminds-battery-100 sidebar-icon"/>
                  <I18nMessage id="sidebar.menu-item.battery"/>
                </NavLink>
              </NavItem>
              )}




              {
                /* TODO: Add support for SocialNetwork
                <NavItem>
                  <NavLink to="/panel/social-networks">
                    <i className="iconsminds-like" />
                    <I18nMessage id="sidebar.menu-item.social-networks" />
                  </NavLink>
                </NavItem>
                */
              }
            </Nav>
          </PerfectScrollbar>
        </div>
        <div className="sub-menu"></div>
      </div>
    )
  }
}

export default withRouter(withBenAccount(Sidebar))
