import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Modal, ModalBody, Button, Card, CardBody } from 'reactstrap'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import I18nMessages from '../../components/I18nMessages'

import appLogo from '../../assets/media/logo.svg'
import { ANALYTICS_NAMES, ANALYTICS_VALUES, InjectedBenServiceProps, withBenService } from '../../providers/benServiceProvider'
import AddDeviceContent from './AddDeviceContent'

type AddDevicePageProps = 
    RouteComponentProps & 
    InjectedBenAccountProps & 
    InjectedBenServiceProps &
    {
        isOpen: boolean
        onToggle: () => void
    }

const AddDevicePage: React.FC<AddDevicePageProps> = ({
    isOpen,
    onToggle,
    benAccount,
    benService,
    history
}) => {

    function handleOnCloseClick () {
        //benAccount.changeSettings({ ...benAccount.settings, skipProfileCreator: true })
        //history.push('/')
        onToggle()
    }

    React.useEffect(() => {
        document.body.classList.add('h-100')
        return () => document.body.classList.remove('h-100')
    }, [])

    React.useEffect(() => {
        if( isOpen ) {
            benService.analyticsEvent(-1, ANALYTICS_NAMES.ADD_DEVICE, ANALYTICS_VALUES.PAGE_DISPLAY ).catch( () => {} )
        }
    }, [benService, isOpen])    

    return (

        <Modal isOpen={isOpen} toggle={handleOnCloseClick} size="lg">

            <ModalBody className="add-device-body">

                <Card>
                    <CardBody className="text-center">
                        <Button color="light" size="xs" onClick={handleOnCloseClick} className="button-x" >
                            X
                        </Button>
                        
                        <div className="benjamin-logo-container">
                            <img src={appLogo} className="login-logo" alt="logo" />
                        </div>
                        
                        <AddDeviceContent></AddDeviceContent>

                        <Button color="light" onClick={handleOnCloseClick}>
                            <I18nMessages id="add-device-page.close-button" />
                        </Button>
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
          
    )
}

export default withBenService(withBenAccount(withRouter(AddDevicePage)))
