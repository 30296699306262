import React from 'react'
import classnames from 'classnames'
import { Nav, NavItem, NavLink } from 'reactstrap'
import Colxx from './Colxx'

interface PaginationProps {
  pageCount: number
  currentPage: number
  numberLimit?: number
  isLastDisabled?: boolean
  isFirstDisabled?: boolean
  visibleEmpty?: boolean
  onChangePage: (page: number) => void
}

function getPoints (numberLimit: number, pageCount: number, currentPage: number): number[] {
  const halfNumberLimit = parseInt(String(numberLimit / 2), 10)
  const points: number[] = []

  let startPoint = 1
  let endPoint = numberLimit

  if (numberLimit > pageCount) {
    startPoint = 1
    endPoint = pageCount
  } else if (currentPage <= halfNumberLimit) {
    startPoint = 1
    endPoint = numberLimit
  } else if (currentPage + halfNumberLimit <= pageCount) {
    startPoint = currentPage - halfNumberLimit
    endPoint = currentPage + halfNumberLimit
  } else {
    startPoint = pageCount - (numberLimit - 1)
    endPoint = pageCount
  }

  if (startPoint === 0) {
    startPoint = 1
  }

  for (let i = startPoint; i <= endPoint; i++) {
    points.push(i)
  }

  return points
}

const Pagination = ({
  pageCount,
  currentPage,
  numberLimit = 3,
  isLastDisabled = false,
  isFirstDisabled = false,
  visibleEmpty = false,
  onChangePage
}: PaginationProps) => {

  const points = getPoints(numberLimit, pageCount, currentPage)

  if (!visibleEmpty && pageCount <= 1) {
    return <Colxx xxs="18" className="mt-2" />
  }

  return (
    <Colxx xxs="18" className="mt-1">
      <Nav className="pagination justify-content-center">
        {!isFirstDisabled && (
          <NavItem className={classnames('page-item', { 'disabled': currentPage <= 1 })}>
            <NavLink className="page-link first" onClick={() => onChangePage(1)}>
              <i className="simple-icon-control-start" />
            </NavLink>
          </NavItem>
        )}

        <NavItem className={classnames('page-item', { 'disabled': currentPage <= 1 })}>
          <NavLink className="page-link prev" onClick={() => onChangePage(currentPage - 1)}>
            <i className="simple-icon-arrow-left" />
          </NavLink>
        </NavItem>

        {points.map(item => (
          <NavItem key={item} className={classnames('page-item', { 'active': currentPage === item })}>
            <NavLink className="page-link" onClick={() => onChangePage(item)}>
              {item}
            </NavLink>
          </NavItem>
        ))}

        <NavItem className={classnames('page-item', { 'disabled': currentPage >= pageCount })}>
          <NavLink className="page-link next" onClick={() => onChangePage(currentPage + 1)}>
            <i className="simple-icon-arrow-right" />
          </NavLink>
        </NavItem>

        {!isLastDisabled && (
          <NavItem className={classnames('page-item', { 'disabled': currentPage >= pageCount })}>
            <NavLink className="page-link last" onClick={() => onChangePage(pageCount)}>
              <i className="simple-icon-control-end" />
            </NavLink>
          </NavItem>
        )}
      </Nav>
    </Colxx>
  )
}

export default Pagination
