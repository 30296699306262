import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import AppsSettings from '../../../containers/AppsSettings'

const AppsListPage: React.FC = () => {

  return (
    <Row className="mb-4">
        <Col sm="12">
          <Card>
            <CardBody>
              <AppsSettings />
            </CardBody>
          </Card>
        </Col>
      </Row>
  )
}

export default AppsListPage
