import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/vi'

const messages: LanguageMessagesKeys = { 


  // Common
  'side-effect.successful-title': 'Tất cả đều ổn!',
  'side-effect.successful-message': 'Dữ liệu đã được lưu chính xác',
  'side-effect.internal-error-title': 'Đã có lỗi xảy ra',
  'side-effect.internal-error-message': 'Vui lòng thử lại sau hoặc kiểm tra kết nối internet của bạn',

  'monday-sshort-label': 'Hai',
  'tuesday-sshort-label': 'Ba',
  'wednesday-sshort-label': 'Tư',
  'thursday-sshort-label': 'Năm',
  'friday-sshort-label': 'Sáu',
  'saturday-sshort-label': 'Bảy',
  'sunday-sshort-label': 'CN',

  'monday-short-label': 'Thứ 2',
  'tuesday-short-label': 'Thứ 3',
  'wednesday-short-label': 'Thứ 4',
  'thursday-short-label': 'Thứ 5',
  'friday-short-label': 'Thứ 6',
  'saturday-short-label': 'Thứ 7',
  'sunday-short-label': 'Chủ nhật',

  'monday-label': 'Thứ Hai',
  'tuesday-label': 'Thứ Ba',
  'wednesday-label': 'Thứ Tư',
  'thursday-label': 'Thứ Năm',
  'friday-label': 'Thứ Sáu',
  'saturday-label': 'Thứ Bảy',
  'sunday-label': 'Chủ Nhật',

  'january-label': 'Tháng Một',
  'february-label': 'Tháng Hai',
  'march-label': 'Tháng Ba',
  'april-label': 'Tháng Tư',
  'may-label': 'Tháng Năm',
  'june-label': 'Tháng Sáu',
  'july-label': 'Tháng Bảy',
  'august-label': 'Tháng Tám',
  'september-label': 'Tháng Chín',
  'october-label': 'Tháng Mười',
  'november-label': 'Tháng Mười Một',
  'december-label': 'Tháng Mười Hai',

  'interval-name-year': 'năm',
  'interval-name-month': 'tháng',
  'interval-name-week': 'tuần',
  'interval-name-day': 'ngày',
  'interval-name-years': 'năm',
  'interval-name-months': 'tháng',
  'interval-name-weeks': 'tuần',
  'interval-name-days': 'ngày',

  'select-input.no-options-label': 'Không có tùy chọn',
  'filter-input.label': 'Lọc:',

  'no-data.label': 'Không có dữ liệu để hiển thị. Thay đổi bộ lọc hoặc ngày đã chọn.',
  'no-data.label-mid': 'Không có dữ liệu để hiển thị. Thay đổi ngày đã chọn.',
  'no-data.label-short': 'Không có dữ liệu để hiển thị.',
  'empty-list-label': 'Danh sách trống.',
  'overlay.empty-device-label': 'Chưa thêm thiết bị nào vào hồ sơ này.',
  'overlay.empty-profiles-label': 'Chưa thêm hồ sơ.',
  'overlay.empty-device.add-device-label': 'Thêm thiết bị',

  'confirmation-modal.cancel-button': 'Hủy bỏ',
  'confirmation-modal.confirm-button': 'XÁC NHẬN',
  'confirmation-modal.button-yes': 'Có',
  'confirmation-modal.button-no': 'Không',

  // Date Picker
  'date-picker.select-date-label': 'Chọn ngày',

  // Date Filter
  'date-filter.select-label': 'Chọn phạm vi',
  'date-filter.this-month': 'Tháng này',
  'date-filter.this.week': 'Tuần này',
  'date-filter.this.day': '24 giờ',
  'date-filter.this.custom': 'Chọn ngày',
  'date-filter.from-label': 'Từ: ',
  'date-filter.to-label': 'Đến: ',

  // Time Picker
  'time-picker.unlimied-label': 'Không giới hạn',
  'time-picker.time-format': '{h}g {m}phút',
  'time-picker.time-format-min': '{m} phút',
  'time-picker.time-format-hour': '{h} giờ',

  'time-picker.add-bonus-title': 'Chỉ thêm thời gian bonus cho hôm nay',
  'time-picker.no-bonus': 'Không có bonus',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Đặt bonus',
  'time-picker.add-bonus-button-cancel': 'Hủy',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Phạm vi ngày',
  'profile-image-picker.girl-label': 'Gái',
  'profile-image-picker.boy-label': 'Trai',

  // Profile List
  'profile-list.remove-device-label': 'Gỡ bỏ thiết bị',
  'profile-list.move-device-label': 'Di chuyển thiết bị đến hồ sơ khác',
  'profile-list.add-device-label': 'Thêm thiết bị',

  'profile-list.add-profile-label': 'Thêm hồ sơ',
  'profile-list.edit-profile-label': 'Chỉnh sửa hồ sơ',
  'profile-list.add-devices-label': 'Thêm thiết bị mới',
  'profile-list.remove-profile-label': 'Gỡ bỏ hồ sơ',
  'profile-list.remove-profile-disabled-tooltip': 'Để xóa hồ sơ của bạn, bạn phải gỡ bỏ tất cả các thiết bị đính kèm với hồ sơ.',
  'profile-list.title': 'Danh sách hồ sơ',

  // Profile Form
  'profile-form.full-name-label': 'Tên',
  'profile-form.month-of-birth-label': 'Tháng sinh',
  'profile-form.year-of-birth-label': 'Năm sinh',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Hủy bỏ',
  'profile-form-modal.create.submit-button': 'THÊM HỒ SƠ',
  'profile-form-modal.create.title': 'Thêm hồ sơ trẻ em',
  'profile-form-modal.edit.skip-button': 'Hủy bỏ',
  'profile-form-modal.edit.submit-button': 'LƯU',
  'profile-form-modal.edit.title': 'Chỉnh sửa hồ sơ trẻ em',
  'profile-form-modal.max-profiles-error': 'Đã đạt giới hạn hồ sơ',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Chuyển thiết bị sang hồ sơ khác',
  'profile-move-device-modal.cancel-button': 'Hủy',
  'profile-move-device-modal.submit-button': 'Lưu',
  'profile-move-device-modal.select-profile-label': 'Chọn hồ sơ',

  // CallLog
  'call-log.duration-label': 'Thời gian cuộc gọi:',
  'call-log.seconds-label': 'giây.',

  // Create Profile Page
  'create-profile-page.submit-button': 'THÊM HỒ SƠ',
  'create-profile-page.skip-button': 'Hủy',
  'create-profile-page.title': 'Thêm hồ sơ con',
  'create-profile-page.description': 'Người sẽ được giám sát',

  // Add device page
  'add-device-page.close-button': 'ĐÓNG',
  'add-device-page.description': 'Chọn loại thiết bị để cài đặt ứng dụng',
  'add-device-page.title': 'Hướng dẫn cài đặt',
  'add-device-page.check': 'Kiểm tra hướng dẫn sử dụng',
  'add-device-page.mobile-title': 'Smartphone hoặc máy tính bảng Android',
  'add-device-page.mobile-description': 'Phiên bản Android 6 hoặc cao hơn',
  'add-device-page.desktop-title': 'Máy tính để bàn hoặc laptop Windows',
  'add-device-page.desktop-description': 'Phiên bản Windows 7 hoặc cao hơn',


  // Purchase Page
  'purchase-page.skip-label': 'Đi đến trang tổng quan',
  'purchase-page.title': 'Chọn gói dịch vụ tốt nhất',
  'purchase-page.title.features': 'Tất cả các gói đều cung cấp các chức năng sau',
  'purchase-page.title.reviews': 'Tại sao người dùng của chúng tôi yêu thích chúng tôi',
  'purchase-page.subtitle': 'Nếu chương trình không đáp ứng được kỳ vọng của bạn, chúng tôi sẽ hoàn trả tiền',
  'purchase-page.plan-highlight': 'Giá trị tốt nhất',
  'purchase-page.monthly': 'hàng tháng',
  'purchase-page.select': 'Chọn',
  'purchase-page.close': 'Đóng',
  'purchase-page.money-back.title': 'Không rủi ro',
  'purchase-page.money-back.desc': 'Nếu bạn không hài lòng, chúng tôi sẽ hoàn trả.',
  'purchase-page.header.check-1': 'Ứng dụng kiểm soát cha mẹ tốt nhất',
  'purchase-page.header.check-2': 'Đặt giới hạn thời gian cho trò chơi, YouTube, và các trang web',
  'purchase-page.header.check-3': 'Hàng trăm nghìn khách hàng hài lòng',
  'purchase-page.plan.year': 'năm',
  'purchase-page.plan.optimal': 'Giá trị tốt nhất',


  'purchase-page.modal.2y-header': 'Ưu đãi đặc biệt!',
  'purchase-page.modal.2y-sub-header': 'Chọn <b>gói 2 năm và tiết kiệm</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Chọn <b>gói 2 năm và tiết kiệm</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Bảo vệ tới {devices} thiết bị',
  'purchase-page.modal.upgrade': 'Chọn giảm giá',
  'purchase-page.modal.no-thanks': 'Tiếp tục với gói 1 năm',


  'purchase-plan.features.title.feature_1': 'Giới hạn thời gian ứng dụng và trò chơi',
  'purchase-plan.features.title.feature_2': 'Lọc trang web',
  'purchase-plan.features.title.feature_3': 'Chặn ứng dụng, trò chơi và trang web',
  'purchase-plan.features.title.feature_4': 'Giám sát Youtube',
  'purchase-plan.features.title.feature_5': 'Xem màn hình từ xa trực tiếp',
  'purchase-plan.features.title.feature_6': 'Truy cập từ xa',
  'purchase-plan.features.title.feature_7': 'Báo cáo chi tiết',
  'purchase-plan.features.title.feature_8': 'Vị trí (chỉ áp dụng cho điện thoại)',
  'purchase-plan.features.title.feature_9': 'Kiểm tra tin nhắn và cuộc gọi',
  'purchase-plan.features.title.feature_10': 'Hỗ trợ kỹ thuật ưu tiên',

  'purchase-plan.features.desc.feature_1': 'Đặt giới hạn thời gian cho ứng dụng, trò chơi và truy cập internet. Ngoài ra, lên lịch cho phép con bạn truy cập vào những giờ nào',
  'purchase-plan.features.desc.feature_2': 'Tất cả các trang web không phù hợp đều bị chặn. Chương trình sử dụng thuật toán phân tích nội dung trang web để tìm bất kỳ tài liệu không phù hợp nào. Nó cũng thu thập thông tin từ một bộ sưu tập cơ sở dữ liệu lớn về các trang web và từ không phù hợp, cả hai đều được cập nhật định kỳ.',
  'purchase-plan.features.desc.feature_3': 'Chặn ứng dụng và trang web có thể tác động tiêu cực đến con bạn. Ngoài ra, bất kỳ trò chơi mới nào được cài đặt cũng sẽ cần sự ủy quyền của bạn.',
  'purchase-plan.features.desc.feature_4': 'Trên bảng điều khiển, giám sát tất cả các video mà con bạn xem trên YouTube. Chặn bất kỳ video nào hoặc hoàn toàn chặn quyền truy cập vào dịch vụ video.',
  'purchase-plan.features.desc.feature_5': 'Truy cập từ xa vào luồng trực tiếp màn hình của con bạn và kiểm tra hoạt động của họ trong suốt cả ngày. Điều này cho phép theo dõi chặt chẽ nội dung mà con bạn đang truy cập.',
  'purchase-plan.features.desc.feature_6': 'Sau khi bạn đã đăng nhập, có thể truy cập vào bảng điều khiển của phụ huynh từ bất kỳ đâu và từ bất kỳ thiết bị nào. Trong bảng điều khiển, điều chỉnh cài đặt, giám sát các thiết bị của trẻ em và đặt giới hạn thời gian.',
  'purchase-plan.features.desc.feature_7': 'Trong bảng điều khiển dành cho phụ huynh, dễ dàng giám sát hoạt động của một đứa trẻ trên máy tính hoặc điện thoại của họ, xem họ đã dành bao nhiêu thời gian trên màn hình, họ đã truy cập các trang web nào và nhiều hơn thế nữa.',
  'purchase-plan.features.desc.feature_8': 'Kiểm tra vị trí hiện tại và các vị trí trước đó của con bạn trên bản đồ. Xem chính xác nơi con bạn đã ở vào ngày nào đó hoặc vào một thời điểm nào đó.',
  'purchase-plan.features.desc.feature_9': 'Bạn có thể dễ dàng kiểm tra tin nhắn đã gửi và nhận của con bạn, cuộc gọi điện thoại và danh bạ đã lưu bằng cách sử dụng bảng điều khiển của phụ huynh.',
  'purchase-plan.features.desc.feature_10': 'Nếu bạn cần bất kỳ sự giúp đỡ nào, chúng tôi luôn sẵn sàng. Còn có nhiều hướng dẫn video để hỗ trợ bạn sử dụng {app}.',



  'purchase-plan.raitings.desc.name_1': 'Sophia',
  'purchase-plan.raitings.desc.name_2': 'Olivia',
  'purchase-plan.raitings.desc.name_3': 'Mateo',
  'purchase-plan.raitings.desc.name_4': 'Lucas',
  'purchase-plan.raitings.desc.name_5': 'Emma',
  'purchase-plan.raitings.desc.name_6': 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Rất hiệu quả! Hoàn toàn bảo vệ con tôi khỏi nội dung internet có hại.',
  'purchase-plan.raitings.desc.raiting_2': 'Đúng những gì tôi đang tìm kiếm! Tôi đã cài đặt giới hạn hàng ngày một giờ cho các cô gái của tôi truy cập vào internet và Avosmart chăm sóc nó cho tôi.',
  'purchase-plan.raitings.desc.raiting_3': 'Tôi khuyên dùng Avosmart vì nó có nhiều chức năng khác nhau. Con tôi được bảo vệ khi sử dụng internet và tôi có thể kiểm soát được thời gian mà họ chơi game, v.v.',
  'purchase-plan.raitings.desc.raiting_4': 'Đối với vợ chồng tôi, việc có khả năng theo dõi thói quen sử dụng mạng xã hội của con gái chúng tôi rất quan trọng. Avosmart giúp chúng tôi giữa cân đối giữa cuộc sống kỹ thuật số và thực tế của con gái chúng tôi.',
  'purchase-plan.raitings.desc.raiting_5': 'Con trai tôi trước đây đã dành quá nhiều thời gian trên internet, sử dụng laptop, tablet, v.v.. Với các báo cáo được cung cấp bởi Avosmart, tôi biết chính xác con trai tôi truy cập vào những trang web nào và trong bao lâu.',
  'purchase-plan.raitings.desc.raiting_6': 'Bây giờ tôi có thể kiểm tra vị trí hiện tại của con trai tôi bất cứ lúc nào. Một dịch vụ xuất sắc!',


  'account-settings-page.title': 'Cài đặt tài khoản',
  'account-settings-page.automatic-update': 'Cập nhật tự động cho ứng dụng Windows',
  'account-settings-page.timezone': 'Múi giờ:',
  'account-settings-page.send-reports': 'Gửi báo cáo hoạt động hàng ngày',


  'plans-info-page.title': 'Thông tin đăng ký',
  'plans-info-page.plan': 'Kế hoạch:',
  'plans-info-page.max-devices': 'Số lượng thiết bị tối đa:',
  'plans-info-page.valid-till': 'Ngày hết hạn:',

  'plans-info-page.your-plan': 'Kế hoạch của bạn:',
  'plans-info-page.subscription-information': 'Thông tin đăng ký',
  'plans-info-page.subscription-created': 'Đăng ký được tạo: ',
  'plans-info-page.subscription-current-period': 'Kỳ đăng ký hiện tại: ',
  'plans-info-page.subscription-renews-on': 'Gói của bạn sẽ được gia hạn vào ',
  'plans-info-page.subscription-cancel-renewal': 'Hủy gia hạn',
  'plans-info-page.subscription-not-renewed': 'Gói của bạn sẽ KHÔNG được gia hạn vào cuối kỳ hiện tại',
  'plans-info-page.subscription-enable-renewal': 'Kích hoạt lại gia hạn',
  'plans-info-page.subscription-price': 'Giá đăng ký: ',
  'plans-info-page.subscription-price-amount': '{amount} mỗi {period}',


  'plans-info-page.upgrade-plan': 'Nâng cấp gói của bạn',
  'plans-info-page.extend-lic': 'Mở rộng gói của bạn',
  'plans-info-page.subscriptions-portal': 'Quản lý phương thức thanh toán của bạn',
  'plans-info-page.recent-payments': 'Giao dịch gần đây nhất',
  'plans-info-page.date-of-payment': 'Ngày giao dịch:',
  'plans-info-page.transaction-status': 'Trạng thái giao dịch:',
  'plans-info-page.no-recent-payments': 'Không có giao dịch gần đây',
  'plans-info-page.btn-close': 'Đóng',
  'plans-info-page.plan-devices': 'lên đến {devices} thiết bị',
  'plans-info-page.plan-devices-types': 'máy tính/máy tính bảng/điện thoại',


  'upgrade-page.title': 'Nâng cấp gói',
  'upgrade-page.devices': 'Thiết bị: ',
  'upgrade-page.valid-till': 'Gói của bạn có hiệu lực cho đến: ',
  'upgrade-page.confirm-upgrade': 'Gói của bạn sẽ được nâng cấp, bạn sẽ được thanh toán {amount}',
  'upgrade-page.pay-now': 'Thanh toán ngay {amount}',
  'upgrade-page.pay-then': 'Sau đó {amount} mỗi {period}',


  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Mua đăng ký',
  'purchase-checkout.up-to-devices': 'Lên đến {devices} thiết bị',
  'purchase-checkout.subscribtion-price': '{amount} mỗi {period}',
  'purchase-checkout.subscribe': 'Đăng ký',
  'purchase-checkout.cancel': 'Hủy',


  // Purchase Status Page
  'purchase-status-page.title': 'Đang kiểm tra thanh toán',
  'purchase-status-page.status-pending': 'Đang chờ',
  'purchase-status-page.status-completed': 'Đã hoàn tất',
  'purchase-status-page.status-canceled': 'Đã hủy',
  'purchase-status-page.status-refund-finalized': 'Hoàn trả đã hoàn tất',
  'purchase-status-page.status-wating-for-conf': 'Đang chờ xác nhận',
  'purchase-status-page.status-created': 'Đã tạo',
  'purchase-status-page.status-unkown': 'Không xác định',
  'purchase-status-page.status-error': 'Lỗi',
  'purchase-status-pag.back-button': 'Quay lại trang chủ',
  'purchase-status-pag.invoice-link': 'Tôi muốn nhận hóa đơn VAT',

  // Login Page
  'login-page.brandline': 'Đăng nhập',
  'login-page.brandline2': 'Lọc trang web',
  'login-page.email-label': 'địa chỉ e-mail',
  'login-page.login-button': 'ĐĂNG NHẬP',
  'login-page.password-label': 'Mật khẩu',
  'login-page.recovery-link': 'Quên mật khẩu?',
  'login-page.invalid-login-error': 'Địa chỉ email hoặc mật khẩu bạn nhập không hợp lệ',

  // Register Page
  'register-page.create-account-label': 'Cần một tài khoản? Tạo một tài khoản mới',
  'register-page.description': 'Tạo tài khoản miễn phí của bạn',
  'register-page.email-label': 'Địa chỉ e-mail',
  'register-page.login-link-label': 'Bạn đã có tài khoản? Đăng nhập',
  'register-page.marketing-checkbox-label': 'Tôi đồng ý nhận các tài liệu tiếp thị và quảng cáo',
  'register-page.password-label': 'Mật khẩu',
  'register-page.phoneNumber-label': 'Số điện thoại',
  'register-page.terms-privacy-checkbox-label': 'Tôi đồng ý với chính sách bảo mật và điều khoản sử dụng',
  'register-page.register-button': 'ĐĂNG KÝ',
  'register-page.register-success-title': 'Thành công',
  'register-page.register-success-message': 'Đăng ký thành công. Kiểm tra hộp thư của bạn để xác nhận email',
  'register-page.register-error-captcha': 'Lỗi Captcha. Thử lại.',
  'register-page.register-error-already-registered-label': 'Đã đăng ký. Hãy thử đăng nhập hoặc nhắc lại mật khẩu.',
  'register-page.register-error-password-to-short-label': 'Mật khẩu quá ngắn.',

  // Forgot Password Page
  'register-page.strong-pass': 'Mật khẩu của bạn quá đơn giản và có thể dễ dàng bị người khác giải mã. Mật khẩu của bạn nên chứa cả chữ in hoa và chữ thường cũng như số, và phải dài ít nhất 8 ký tự. Bạn có chắc bạn muốn sử dụng một mật khẩu đơn giản không?',
  'forgot-password-page.headline-title': 'Nhắc lại mật khẩu',
  'forgot-password-page.email-label': 'Địa chỉ e-mail',
  'forgot-password-page.reset-button': 'NHẮC LẠI MẬT KHẨU',
  'forgot-password-page.login-link-label': 'Quay lại trang đăng nhập',
  'forgot-password-page.success-title': 'Đặt lại mật khẩu',
  'forgot-password-page.success-message': 'Kiểm tra hộp thư của bạn để biết hướng dẫn về cách đặt lại mật khẩu của bạn',
  'forgot-password-page.email-not-confirmed': 'Địa chỉ email của bạn chưa được xác minh. Không thể đặt lại mật khẩu.',

  // Update Password Page
  'update-password-page.headline-title': 'Đặt mật khẩu mới',
  'update-password-page.warning-title': 'LƯU Ý:',
  'update-password-page.warning-description': 'Việc đặt mật khẩu mới sẽ dẫn đến mất dữ liệu vị trí đã lưu trước đó. Dữ liệu còn lại sẽ không bị mất.',
  'update-password-page.warning-agreement': 'Tôi hiểu và chấp nhận mất dữ liệu vị trí.',
  'update-password-page.new-password': 'Mật khẩu mới:',
  'update-password-page.new-password-retype': 'Nhập lại mật khẩu mới:',
  'update-password-page.submit': 'Đặt mật khẩu mới',
  'update-password-page.alert-weak-password': 'Mật khẩu của bạn quá đơn giản và có thể dễ dàng bị đoán bởi những người không được phép. Mật khẩu nên chứa các chữ cái viết hoa, chữ cái viết thường, số và ít nhất 8 ký tự. Bạn có muốn sử dụng mật khẩu đơn giản dù sao không? ',
  'update-password-page.alert-agreement': 'Yêu cầu chấp nhận mất dữ liệu.',
  'update-password-page.error-message': 'Lỗi! Đặt mật khẩu mới thất bại. ',
  'update-password-page.success-message': 'Đã đặt mật khẩu mới',
  'update-password-page.unknow-error-message': 'Có lỗi xảy ra',
  'update-password-page.error-link': 'Liên kết sai',
  'update-password-page.error-posswords-not-equal': 'Mật khẩu không khớp',
  'update-password-page.error-posswords-length': 'Mật khẩu quá ngắn',
  'update-password-page.error-wrong-code': 'Mã sai',
  'update-password-page.register-success-title': 'Thành công!',
  'update-password-page.register-success-message': 'Đã đặt mật khẩu mới.',

  // Error Page
  'not-found-page.title': 'Rất tiếc ... có vẻ như đã xảy ra lỗi!',
  'not-found-page.error-code-text': 'Mã lỗi',
  'not-found-page.go-back-home-button': 'TRỞ LẠI TRANG CHỦ',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Gói dịch vụ của bạn kết thúc vào {date}. Hãy xem xét việc mua gói dịch vụ hôm nay',
  'panel-page.purchase-demo-alert': 'Bạn đang sử dụng phiên bản dùng thử.',
  'panel-page.purchase-expired-alert': 'Bạn đang sử dụng phiên bản dùng thử đã hết hạn. Mua gói dịch vụ để tiếp tục',
  'panel-page.purchase-lic-expired-alert': 'Gói dịch vụ của bạn đã hết hạn. Mua gói dịch vụ để tiếp tục',
  'panel-page.email-alert': 'Bạn chưa xác nhận địa chỉ email của mình',
  'panel-page.button-email-resend': 'Gửi lại email',
  'panel-page.email-alert-resend': ' hoặc gửi lại email.',
  'panel-page.email-alert-resend-title': 'Xác nhận',
  'panel-page.email-alert-resend-msg': 'Email đã được gửi!',
  'panel-page.profile-delete-description': 'Bạn có chắc chắn muốn xóa hồ sơ đã chọn không?',
  'panel-page.profile-delete-device-description': 'Bạn có chắc chắn muốn xóa thiết bị đã chọn không? Dữ liệu sẽ bị mất.',
  'panel-page.profile-delete-device-second-description': 'Bảo vệ trên thiết bị sẽ bị vô hiệu hóa. Bạn có chắc chắn muốn tiếp tục không?',

  'panel-page.email-confirmation-overlay-close': 'Đóng',
  'panel-page.email-confirmation-overlay-postpone': 'Sau',
  'panel-page.email-confirmation-overlay-resend': 'Gửi lại email',
  'panel-page.email-confirmation-overlay-header': 'Địa chỉ email dưới đây của bạn chưa được xác nhận',
  'panel-page.email-confirmation-overlay-desc': "Nếu bạn đã mắc lỗi với địa chỉ email trên, vui lòng cho chúng tôi biết ngay lập tức.  <br>Nếu bạn không nhận được email, vui lòng nhấp vào 'gửi lại' và kiểm tra các thư mục email khác của bạn (bao gồm rác/spam).",

  'panel-page.email-confirmation-code-success-header': 'Cảm ơn bạn',
  'panel-page.email-confirmation-code-success-desc': 'Email đã được xác nhận!',
  'panel-page.email-confirmation-code-header': 'Vui lòng nhập mã xác nhận 6 số đã được gửi tới địa chỉ email của bạn.',
  'panel-page.email-confirmation-code-error-invalid-code': 'Mã đã nhập không hợp lệ',
  'panel-page.email-confirmation-code-error': 'Đã xảy ra lỗi không mong muốn khi xác thực mã',

  // Summary Page
  'summary-page.title': 'Bảng điều khiển',
  'summary-page.top-apps.title': 'Ứng dụng hàng đầu',
  'summary-page.top-blocked.title': 'Chặn hàng đầu',
  'summary-page.top-domains.title': 'Trang web được truy cập nhiều nhất',
  'summary-page.top-search.title': 'Tìm kiếm hàng đầu',
  'summary-page.access-disable': 'Tạm thời vô hiệu hóa',
  'internet-page.access-time-limit-set-label': 'Giới hạn truy cập internet hàng ngày:',
  'internet-page.access-time-limit-description': 'Đặt giới hạn thời gian hàng ngày cho mỗi thiết bị và chọn số giờ sử dụng internet được cho phép.',
  'disable-access.protection-enabled': 'Bảo vệ đang hoạt động',
  'disable-access.protection-disabled-for': 'Bảo vệ tạm thời bị vô hiệu hóa, còn {time} phút',
  'disable-access.device-offline': 'Không thể truy cập thiết bị',
  'disable-access.protection-status': 'Trạng thái bảo vệ',


  // Internet Page
  'internet-page.headline-title': 'Trang web',
  'internet-page.access-time-page-title': 'Thời gian truy cập',
  'internet-page.settings-page-title': 'Cài đặt',
  'internet-page.statistics-page-title': 'Thống kê',
  'internet-page.searches-page-title': 'Tìm kiếm',

  'internet-page.statistics-page-www-ok': 'Website được phê duyệt',
  'internet-page.statistics-page-www-ok-white': 'Website an toàn',
  'internet-page.statistics-page-www-timelimit': 'Website bị chặn do giới hạn thời gian',
  'internet-page.statistics-page-www-userdef': 'Website bị chặn, quy định của người dùng',
  'internet-page.statistics-page-www-content': 'Website bị chặn, nội dung không phù hợp',


  'internet-page.white-black-list-page-title': 'Website an toàn / bị chặn',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Bộ lọc',
  'internet-page.settings-page.white-list-only-label': 'Chỉ cho phép truy cập vào các trang web trong danh sách an toàn',
  'internet-page.settings-page.block-erotic-label': 'Chặn việc truy cập vào các trang web hấp dẫn tình dục',
  'internet-page.settings-page.block-offensive-label': 'Chặn việc truy cập vào các trang web gây khó chịu, hấp dẫn cãi vã',
  'internet-page.settings-page.block-file-sites-label': 'Chặn việc truy cập vào các trang cho phép tải tập tin từ internet',
  'internet-page.settings-page.block-video-label': 'Chặn việc truy cập vào cổng thông tin video',
  'internet-page.settings-page.block-social-label': 'Chặn việc truy cập vào các mạng xã hội',
  'internet-page.settings-page.block-doc-label': 'Chặn tài liệu từ việc tải về',
  'internet-page.settings-page.block-exe-label': 'Chặn chương trình từ việc được tải về',
  'internet-page.settings-page.excludeYT-label': 'Không buộc tìm kiếm an toàn trên YouTube',
  'internet-page.settings-page.block-new-apps': 'Chặn ứng dụng mới',
  'internet-page.settings-page.block-settings': 'Chặn quyền truy cập vào thiết lập',
  'internet-page.settings-page.custom-msg.card-tile': 'Thông báo cho trang bị chặn',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Sử dụng thông báo tùy chỉnh cho các trang web bị chặn',
  'internet-page.settings-page.save-settings-button-label': 'Lưu thiết lập',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Thêm vào danh sách:',
  'internet-page.lists.add-button-label': 'Thêm',
  'internet-page.lists.remove-button-label': 'Xóa',
  'internet-page.lists.white-list.card-title': 'Danh sách an toàn',
  'internet-page.lists.black-list.card-title': 'Danh sách bị chặn',
  'internet-page.lists.remove-prefix': 'Địa chỉ đã nhập bắt đầu bằng "{prefix}", nên chỉ thêm tên miền. Bạn có muốn xóa "{prefix}" khỏi địa chỉ không?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Các trang đã truy cập',

  'internet-page.searches.searches-pages-card-title': 'Cụm từ đã tìm kiếm',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Lịch trình',
  'internet-page.access-time.schedule-legend-label': 'Chú thích lịch trình',
  'internet-page.access-time.schedule-internet-available-label': 'Internet có sẵn',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet bị chặn',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Cuộc gọi & tin nhắn (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Cuộc gọi',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Tin nhắn (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Thay đổi cuối:',
  'calls-text-messages-page.contacts-no-changes-label': 'Trống.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Liên hệ',

  // Localization Page
  'localization-page.headline-title': 'Vị trí',
  'localization-page.headline-title-off': 'Bản địa hóa, theo dõi GPS đã được tắt',
  'localization-page.map-hours-filter-label': 'Chọn khoảng thời gian để xem lịch sử vị trí',
  'localization-page.gps-tracking-on': 'Bật theo dõi GPS',
  'localization-page.gps-view-speed': 'Hiển thị tốc độ di chuyển',
  'localization-page.map-loading-label': 'Đang tải bản đồ...',
  'localization-page.map-no-data': 'KHÔNG CÓ DỮ LIỆU',

  'localization-page.one-gps-was-off-since': 'Dịch vụ vị trí thiết bị đã được tắt từ {lastOffStr}',
  'localization-page.one-gps-was-off-for': 'Dịch vụ vị trí thiết bị đã được tắt trong {elapsedStr}',
  'localization-page.many-gps-was-off-since': 'Dịch vụ vị trí thiết bị đã được tắt từ {lastOffStr}',
  'localization-page.many-gps-was-off-for': 'Dịch vụ vị trí thiết bị đã được tắt trong {elapsedStr}',


  // Battery Page
  'battery-page.headline-title': 'Pin',
  'battery-page.chart-description': 'Mức Pin',

  // Access Time Page
  'access-time-page.headline-title': 'Thời gian truy cập',
  'access-time-page.nav-access-time-label-applications': 'ứng dụng',
  'access-time-page.nav-access-time-label-internet': 'Trang web',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'Danh sách video',
  'youtube-page.no-videos': 'Không có video nào vào ngày được chọn',
  'youtube-page.block-videoid': 'Chặn video này',
  'youtube-page.block-channelid': 'Chặn kênh này',
  'youtube-page.video-blocked': 'Đã chặn',

  // Screen Pages
  'screenview-page.headline-title': 'Xem Màn hình',
  'screenview-page.reload-button': 'Tải lại',
  'screenview-page.chart-description': 'Xem Màn hình hiện tại',
  'screengrab-page.headline-title': 'Chụp màn hình',
  'screengrab-page.chart-description': 'Chụp màn hình',
  'screengrab-page.screen-save-time': 'Tần suất chụp màn hình được thực hiện là bao nhiêu',
  'screengrab-page.screen-save-time-desc': 'Chọn tần suất chụp màn hình hoặc chọn "đã vô hiệu hóa" để không chụp bất kỳ hình ảnh màn hình nào',
  'screengrab-page.screen-save-time-disabled': 'đã vô hiệu hóa',
  'screengrab-page.no-screens-for-today': 'Không có hình chụp màn hình nào trong ngày này',
  'screengrab-page.no-screens-for-filter': 'Không có màn hình nào phù hợp với tiêu chí đã chọn',
  'screengrab-page.imgs-delete-description': 'Bạn có chắc chắn muốn xóa các hình ảnh đã chọn không?',
  'screengrab-page.imgs-delete-description-one': 'Bạn có chắc chắn muốn xóa hình ảnh đã chọn không?',
  'screengrab-page.imgs-delete-label': 'Xóa',
  'screengrab-page.imgs-delete-select': 'Chọn để xóa',
  'screengrab-page.imgs-delete-selected': 'Đã chọn:',
  'screengrab-page.imgs-delete-limit': 'Đã đạt giới hạn chụp màn hình đã chọn.',
  'screengrab-page.imgs-filter': 'Bộ lọc:',
  'screengrab-page.imgs-filter-communicators': 'Người liên lạc',
  'screengrab-page.imgs-filter-social': 'Mạng xã hội',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Khác',

  'screes-pages.device-offline': 'Thiết bị đang ngoại tuyến',
  'screes-pages.device-deactivated': 'Sự bảo vệ đã tạm thời bị tắt',
  'screes-pages.device-timeout': 'Thiết bị không phản hồi',
  'screes-pages.device-screenoff': 'Màn hình đã bị tắt',
  'screes-pages.device-notallowed': 'Không cho phép chụp màn hình trên thiết bị',
  'screes-pages.device-error': 'Đã xảy ra lỗi',

  'lazy-image.img-delete-description': 'Bạn có chắc chắn muốn xóa hình ảnh đã chọn?',

  // Applications Page
  'applications-page.headline-title': 'Ứng dụng',
  'applications-page.nav-access-time-label': 'Thời gian truy cập',
  'applications-page.nav-statistics-label': 'Tóm tắt',
  'applications-page.nav-apps-list-label': 'Danh sách ứng dụng',
  'applications-page.chart-activity-by-day-label': 'Hoạt động trong ngày',
  'applications-page.chart-hour-label': 'Giờ',
  'applications-page.chart-activity-by-hour-minutes-label': 'Hoạt động trong ngày theo phút',
  'applications-page.list-launched-count-label': 'Số lần khởi chạy:',
  'applications-page.list-elapsed-time-label': 'Thời gian chạy:',
  'applications-page.introduction': 'Ứng dụng đã cài đặt',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Khoảng thời gian lên lịch',
  'applications-page.settings.schedule-legend-label': 'Chú giải lịch',
  'applications-page.settings.schedule-blocked-label': 'Đã chặn ứng dụng',
  'applications-page.settings.schedule-available-label': 'Ứng dụng sẵn có',
  'applications-page.time-limit.description': 'Đặt giới hạn thời gian cho các ứng dụng cụ thể. Chọn số giờ ứng dụng được sử dụng mỗi ngày.',
  'applications-page.apps-headline-title': 'Ứng dụng có sẵn',
  'applications-page.apps-first-seen-label': 'Lần đầu tiên nhìn thấy:',
  'applications-page.apps-available-switch-label': 'Có sẵn',
  'applications-page.apps-not-available-switch-label': 'Không có sẵn',
  'applications-page.apps-schedule-switch-label': 'Đã lập lịch',
  'applications-page.apps-no-schedule-switch-label': 'Chưa lập lịch',
  'applications-page.apps-time-limit-switch-label': 'Giới hạn thời gian',
  'applications-page.apps-no-time-limit-switch-label': 'Không giới hạn thời gian',
  'applications-page.apps-show-uninstalled-label': 'Hiển thị các ứng dụng đã gỡ cài đặt',
  'applications-page.apps-show-apps-list': 'Chọn ứng dụng giới hạn thời gian',

  'applications-page.used-count-zero-label': 'Chưa được khởi chạy',
  'applications-page.used-count-one-label': 'Đã khởi chạy {value} lần, tổng thời gian: {totalTime}',
  'applications-page.used-count-many-label': 'Đã khởi chạy {value} lần, tổng thời gian: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} giờ {minutes} phút',
  'applications-page.total-minutes-seconds-label': '{minutes} phút {seconds} giây',
  'applications-page.total-minutes-label': '{minutes} phút',
  'applications-page.total-seconds-label': '{seconds} giây',
  'applications-page.total-time-label': 'Tổng thời gian',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Liên kết kích hoạt đã được sử dụng hoặc không hợp lệ',
  'email-confirmation-page.successful-confirm': 'Email đã được xác nhận. Cảm ơn bạn!',
  'email-confirmation-page.button-home-page-label': 'Đồng ý',

  // Change password
  'change-password-page.change-password-label': 'Đổi mật khẩu',
  'change-password-page.new-password-input-label': 'Mật khẩu mới',
  'change-password-page.new-password-repeated-input-label': 'Nhập lại mật khẩu mới',
  'change-password-page.current-password-input-label': 'Mật khẩu hiện tại',
  'change-password-page.back-button-label': 'Trở lại',
  'change-password-page.change-button-label': 'THAY ĐỔI',
  'change-password-page.successful-change-label': 'Mật khẩu đã được thay đổi đúng',
  'change-password-page.wrong-current-password-label': 'Mật khẩu hiện tại không đúng',
  'change-password-page.password-too-short-label': 'Mật khẩu mới quá ngắn',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENU',
  'top-navigation.user-menu.account-label': 'Tài khoản',
  'top-navigation.user-menu.sign-out': 'Đăng xuất',
  'top-navigation.user-menu.subscription-label': 'Gói đăng ký',
  'top-navigation.user-menu.change-password-label': 'Đổi mật khẩu',
  'top-navigation.user-menu.add-device-label': 'Thêm thiết bị',

  // Sidebar
  'sidebar.menu-item.applications': 'Ứng dụng',
  'sidebar.menu-item.calls-and-text-messages': 'Cuộc gọi & Tin nhắn(SMS)',
  'sidebar.menu-item.internet': 'Trang web',
  'sidebar.menu-item.localizations': 'Định vị',
  'sidebar.menu-item.social-networks': 'Mạng xã hội',
  'sidebar.menu-item.summary': 'Bảng điều khiển',
  'sidebar.menu-item.battery': 'Pin',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Xem màn hình',
  'sidebar.menu-item.screengrab': 'Chụp màn hình',
  'sidebar.menu-item.access-time': 'Thời gian truy cập',

  // Validator
  'phone-number.not-valid': 'Nhập số điện thoại hợp lệ',
  'input.required-label': 'Trường này là bắt buộc',
  'input.select-required-label': 'Vui lòng chọn',
  'email.not-valid-label': 'Nhập địa chỉ e-mail hợp lệ',
  'domain-exist-label': 'Tên miền đã được thêm',
  'domain-exist-other-label': 'Tên miền tồn tại trên danh sách khác',
  'domain-not-valid': 'Tên miền không hợp lệ',
  'password-not-match-label': 'Mật khẩu không khớp',

  // misc
  'button-buy-label': 'Chọn một gói',

  'transactiion-status-0': 'Đang chờ',
  'transactiion-status-1': 'Đã hoàn thành',
  'transactiion-status--1': 'Đã hủy',
  'transactiion-status--2': 'Đã hủy - hoàn tiền',
  'transactiion-status--3': 'Đang chờ xác nhận',
  'transactiion-status--100': 'Đã bắt đầu',
  'transactiion-status-other': 'Khác',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Thành công',
  'reward-page.error-already-used': 'Mã này đã được sử dụng',
  'reward-page.error-invalid-code': 'Mã không chính xác',
  'reward-page.error-connection': 'Lỗi kết nối',
  'reward-page.additional-bonus': 'Bonus',
  'reward-page.get-additional-bonus': 'Yêu cầu phiếu giảm giá khuyến mãi',
  'reward-page.get-now': 'Yêu cầu',
  'reward-page.lic-was-extended-by-days': 'Gói đăng ký của bạn đã được gia hạn {rewardDays} ngày!',
  'reward-page.there-was-an-error': 'Rất tiếc, đã xảy ra lỗi.',
  'reward-page.lic-not-extended': 'Gói đăng ký của bạn chưa được gia hạn.',
  'reward-page.button-close': 'Đóng',

  // url item
  'url-item.copied': 'Đã sao chép vào khay nhớ tạm',
  'url-item.copy-link': 'Sao chép liên kết',

  // APK howto

  'apk-howto-google-play': 'Google Play Store hạn chế một số quyền cho phép của ứng dụng, điều này là cần thiết để sử dụng chức năng này.',
  'apk-howto-on-the-phone': 'Trên điện thoại của con có {applite} được cài đặt',
  'apk-howto-step1': 'Gỡ cài đặt {applite} nếu trước đó đã tải về qua Google Play',
  'apk-howto-step2': 'Tải ứng dụng trực tiếp lên điện thoại của trẻ thông qua website của chúng tôi',
  'apk-howto-step2B': 'hoặc quét mã QR dưới đây trên điện thoại của trẻ',
  'apk-howto-step3': 'Cài đặt ứng dụng đã tải xong lên thiết bị',
  'apk-howto-tutorial': 'Hướng dẫn cài đặt ứng dụng trên điện thoại hoặc máy tính bảng',

  // Cookies

  'cookies-info-uses': 'Website này sử dụng cookie.',
  'cookies-info-privacy-policy': 'Thông tin thêm trong chính sách riêng tư.',

  // Plan names

  'plan-name-demoExpired': 'Thời hạn dùng thử đã hết',
  'plan-name-licExpired': 'Kế hoạch đã hết hạn',
  'plan-name-reward': 'Bonus',
  'plan-name-demo': 'Dùng thử',
  'plan-name-minimal': 'Cơ bản',
  'plan-name-regular': 'Thường xuyên',
  'plan-name-premium': 'Cao cấp',

  // agreements

  'agreement-will-inform-user': 'Tôi khẳng định rằng tôi sẽ thông báo cho người sử dụng mà ứng dụng sẽ được cài đặt trên thiết bị của họ rằng hoạt động và vị trí của họ sẽ được giám sát.',

  'agreement-privacy-policy': 'Tôi khẳng định rằng tôi đã đọc và chấp nhận <a href="{regulations}" target="_blank">điều khoản</a> và <a href="{privacy}" target="_blank">chính sách riêng tư</a>. Tôi chứng thực rằng tôi đã trên 18 tuổi và sẽ chỉ thêm vào tài khoản của tôi những đứa trẻ dưới 18 tuổi và là con cưng hợp pháp của tôi.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing': 'Tôi đồng ý nhận nội dung tiếp thị từ {domain} về các ưu đãi khuyến mại đến địa chỉ email và số điện thoại mà tôi cung cấp, cũng như chuyển tiếp chi tiết đến các đối tác quảng cáo của trang web được đề cập trong chính sách bảo mật với mục đích quảng cáo kỹ thuật số. <a href="{marketing}" target="_blank">Thêm</a>',


  'gdpr-page1-title': 'Chúng tôi coi trọng quyền riêng tư của bạn',
  'gdpr-page1-content': 'Nhấp vào "CHẤP NHẬN TẤT CẢ" để đồng ý với việc sử dụng Cookies để tự động thu thập và xử lý dữ liệu cá nhân của bạn bởi {domain}. Chúng tôi sử dụng Cookies để nâng cao chất lượng duyệt web, hiển thị quảng cáo hoặc nội dung phù hợp với nhu cầu của từng người dùng và phân tích lưu lượng truy cập trên trang web của chúng tôi. Nhấp vào nút "CHẤP NHẬN TẤT CẢ" có nghĩa là bạn đồng ý với việc chúng tôi sử dụng Cookies."',

  'gdpr-page1-advanced': 'Thông tin thêm',
  'gdpr-page1-agree': 'CHẤP NHẬN TẤT CẢ',

  'gdpr-page2-title': 'Thông tin thêm',
  'gdpr-page2-content': 'Khi bạn duyệt trang web của chúng tôi {domain} hoặc {panel}, chúng tôi có thể tự động thu thập dữ liệu về hoạt động của bạn. Dữ liệu này được thu thập bằng cách sử dụng Cookies. Cookies được nhúng trong trình duyệt của máy tính hoặc thiết bị khác khi duyệt trang. Cookies cũng cho phép bạn đăng nhập vào {panel} để thay đổi cài đặt tài khoản và xem hoạt động của Người dùng đã được bảo vệ. Cookies cũng được sử dụng để cá nhân hóa và tạo hồ sơ quảng cáo dựa trên hoạt động của bạn trên avosmart.com và tất cả các trang con của nó. Một số Cookies là cần thiết cho sự hoạt động đúng của trang, trong khi các Cookies khác là tùy chọn. Chọn những Cookies mà bạn đồng ý. <a href="{privacy}" target="_blank">Thêm trong chính sách bảo mật của chúng tôi.</a>',

  'gdpr-page2-necessary': 'Chỉ những điều cần thiết',
  'gdpr-page2-agree': 'CHẤP NHẬN TẤT CẢ',
}

export default {
  locale: 'vi',
  name: 'Tiếng Việt',
  data: appLocalData,
  messages
} as LanguageLocale
