import { ChartLegendOptions, ChartOptions } from 'chart.js'

type ChartDefaults = {
  legend: ChartLegendOptions,
  options: ChartOptions,
}

export const PieChartDefaults: ChartDefaults = {
  legend: {
    display: true,
    position: 'right',
    labels: {
      usePointStyle: true,
      fontSize: 12
    }
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
  }
}

export const BarChartDefaults: ChartDefaults = {
  legend: {
    display: false
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        ticks: {
          autoSkip: false,
          minRotation: 90,
          maxRotation: 90,
          fontSize: 12,
          beginAtZero: true
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  }
}
