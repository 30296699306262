import React from 'react'
import { Nav, NavItem } from 'reactstrap'
import I18nMessages from '../../../components/I18nMessages'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import InternetAccessTimePage from './InternetPage'
import ApplicationsAccessTimePage from './ApplicationsPage'

const AccessTime: React.FC = () => {
  return (
    <div className="dashboard-wrapper">
        <I18nMessages id="access-time-page.headline-title" tagName="h1" />

        <Nav tabs className="separator-tabs ml-0 mb-5">
            <NavItem>
                <NavLink className="nav-link" to="/panel/access-time/applications">
                    <I18nMessages id="access-time-page.nav-access-time-label-applications" />
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className="nav-link" to="/panel/access-time/internet">
                    <I18nMessages id="access-time-page.nav-access-time-label-internet" />
                </NavLink>
            </NavItem>
        </Nav>

        <Switch>
            <Redirect exact from="/panel/access-time" to="/panel/access-time/applications" />
            <Route path="/panel/access-time/applications" component={ApplicationsAccessTimePage} />
            <Route path="/panel/access-time/internet" component={InternetAccessTimePage} />
            <Redirect to="/error" />
        </Switch>
    </div>
  )
}

export default AccessTime
