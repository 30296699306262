import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import ReactPlayer from 'react-player'
import { Row, Col, Card, CardBody } from 'reactstrap'
import I18nMessage from '../../components/I18nMessages'
import { MessageKey } from '../../language'
import { getBuildConfigStr } from '../../lib/utils'
import QR from '../../assets/media/QR.jpg'

type Props = InjectedIntlProps & {
    
    labelMessageId: MessageKey | null

}

const ApkHowto: React.FC<Props> = ({

    labelMessageId,
    intl

}) => {

  const [collapsed, setCollapsed] = React.useState<boolean> (true)
  const divRef = React.useRef<HTMLDivElement>(null)

  function onColapse () 
  {
    setCollapsed(!collapsed)
    if( collapsed )
    {
      setTimeout( () => {
        divRef.current?.scrollIntoView({ behavior: "smooth" }) 
      }, 300 )
      
    }
  }

  function withAppNameStr(id:string) : string
  {
    return intl.formatMessage( {id}, {applite:getBuildConfigStr('NAME_LITE'), app:getBuildConfigStr('NAME') } )
  }


  return (
      
    <div className="dashboard-wrapper">

    { labelMessageId != null && (
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
            <h1 className="mb-0 p-0"><I18nMessage id={labelMessageId} /></h1>
        </div>
    )}


    <Row className="mb-12">
        <Col lg="12">
          <Card>
            <CardBody>
              
                <div>
                  <p>
                    <I18nMessage id="apk-howto-google-play"/>
                  </p>

                  <p>
                    <b>
                      { withAppNameStr("apk-howto-on-the-phone") }
                    </b>

                    <ul>
                      <li>{ withAppNameStr('apk-howto-step1') }</li>
                      <li>{ withAppNameStr('apk-howto-step2') } <a className="device-mobile" href={getBuildConfigStr('APK_DOWNLOAD')}>{getBuildConfigStr('APK_DOWNLOAD')}</a> { withAppNameStr('apk-howto-step2B') } </li>
                      <li>{ withAppNameStr('apk-howto-step3') }</li>
                    </ul>
                  </p>

                  <p onClick={ onColapse }>
                    <I18nMessage id="apk-howto-tutorial"/>

                    <button className="rounded-border" >
                      <i className={ collapsed ? "simple-icon-arrow-down" : "simple-icon-arrow-up" } />
                    </button>
                  </p>

                  <div className= { 'embeded-player' + ( collapsed ? ' embeded-player-collapsed' : '' ) } ref={divRef}  >
                    <ReactPlayer 
                      width="100%"
                      height="100%"
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1, controls:2 }
                        }
                      }}
                      url={getBuildConfigStr('APK_TUTORIAL')}
                      playing={!collapsed}
                    ></ReactPlayer>
                    
                  </div>

                  <img src={QR} alt="QR code" className="QRCode" />

                  {/* <div className= { 'embeded-player' + ( collapsed ? ' embeded-player-collapsed' : '' ) }  >
                    <iframe 
                        width="100%"
                        height="100%"
                        src="//www.y o u t u be .com/embed/0oVpsgz28rA"
                        frameBorder="0" 
                        allow="autoplay; encrypted-media" 
                        allowFullScreen/>
                  </div> */}

                </div>
              
            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  )
}

export default injectIntl(ApkHowto)
