import React from 'react'

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { getAllowedLanguages } from '../lib/utils'
import { InjectedBenServiceProps, withBenService } from '../providers/benServiceProvider'
import { InjectedI18nProviderProps, withI18nProvider, Locale } from '../providers/i18nProvider'


type Props =  InjectedBenServiceProps & InjectedI18nProviderProps & {
}


const LanguageDropdown: React.FC<Props> = ({
        benService,
        i18nProvider
    }) => {

    const allowedLanguages = getAllowedLanguages()

    function languageName (): string 
    {
        return i18nProvider.locale.toUpperCase()
    }

    function handleLocaleChange(locale: Locale, localeCode:string )
    {
        i18nProvider.changeLocale(locale)
        benService.setLang(localeCode)
    }

    function renderLanguageDropdownMenuItems () 
    {
        // return Object.keys(i18nProvider.locales).map((code) => {

        return allowedLanguages.map( (code) => {
            const locale = code as Locale
            const name = i18nProvider.locales[locale].name
            const localeCode = i18nProvider.locales[locale].locale
            const onClickHandler = () => handleLocaleChange(locale, localeCode)
    
            return (
                <DropdownItem key={code} onClick={onClickHandler}>
                    {name}
                </DropdownItem>
            )
        })
    }


    if( Object.keys(i18nProvider.locales).length < 2 ) {
        return (
            <div/>
        )
    }


    return (

        <div className="d-inline-block" >

            {allowedLanguages.length > 1 && (
                <UncontrolledDropdown className="ml-2 " >
                    <DropdownToggle caret color="light" className="btn-xs">
                        <span className="name">{ languageName() }</span>
                    </DropdownToggle>
                    <DropdownMenu className="ml-2">
                        { renderLanguageDropdownMenuItems() }
                    </DropdownMenu>
                </UncontrolledDropdown>
            )}
        </div>

    )
}
  

export default withBenService( withI18nProvider(LanguageDropdown) )
