import React from 'react'
import { injectIntl, InjectedIntlProps } from 'react-intl'
import * as Reactstrap from 'reactstrap'
import Colxx from '../../../components/Colxx'
import Spinner from '../../../components/Spinner'
import I18nMessages, { getI18nMessage } from '../../../components/I18nMessages'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService } from '../../../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../../../providers/notificationProvider'

type SettingsPageProps = InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps

interface SettingsPageState {
  blockErotic: boolean
  blockFileSites: boolean
  blockOffensive: boolean
  blockSocial: boolean
  blockVideo: boolean
  blockDoc: boolean
  blockExe: boolean
  excludeYT: boolean
  customMsg: string
  customMsgOn: boolean
  isLoading: boolean
  isSubmitting: boolean
  whiteListOnly: boolean
}

class SettingsPage extends React.Component<SettingsPageProps, SettingsPageState> {

  state: SettingsPageState = {
    blockErotic: false,
    blockFileSites: false,
    blockOffensive: false,
    blockSocial: false,
    blockVideo: false,
    blockDoc: false,
    blockExe: false,
    excludeYT: false,
    customMsg: '',
    customMsgOn: false,
    isLoading: false,
    isSubmitting: false,
    whiteListOnly: false,
  }

  private timerId: NodeJS.Timeout | null = null
  private saveSettingsDelay = 1500

  componentDidMount () {
    this.refresh()
  }

  componentDidUpdate (prevProps: SettingsPageProps) {
    if (this.props.benAccount.currentProfile.deviceId !== prevProps.benAccount.currentProfile.deviceId) {
      this.refresh()
    }
  }

  componentWillUnmount () {
    if (this.timerId) clearTimeout(this.timerId)
  }

  private refresh = () => {

    if (!this.props.benAccount.currentProfile.deviceId || !this.props.benAccount.currentProfile.profileId) {
      return
    }

    const profile = this.props.benAccount.currentProfile.profileId
    const device = this.props.benAccount.currentProfile.deviceId

    this.setState({ isLoading: true })

    this.props.benService.loadSettings(profile, device)
      .then(result => this.setState({
        blockErotic: result.data.blockErotic,
        blockFileSites: result.data.blockFileSites,
        blockOffensive: result.data.blockOffensive,
        blockSocial: result.data.blockSocial,
        blockVideo: result.data.blockVideo,
        blockDoc: result.data.blockDoc,
        blockExe: result.data.blockExe,
        excludeYT: result.data.excludeYT,
        customMsg: result.data.customMsg,
        customMsgOn: result.data.customMsgOn,
        whiteListOnly: result.data.whiteListOnly
      })).finally(() => this.setState({ isLoading: false }))
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    let value: boolean | string

    switch (event.target.type) {
      case 'checkbox':
        value = event.target.checked
        break

      default:
        value = event.target.value
    }

    this.setState(state => ({ ...state, [name]: value }), () => {
      if (this.timerId) {
        clearTimeout(this.timerId)
      }

      this.timerId = setTimeout(() => this.handleFormSubmit(), this.saveSettingsDelay)
    })
  }

  private handleFormSubmit = () => {

    if (!this.props.benAccount.currentProfile.deviceId || !this.props.benAccount.currentProfile.profileId) {
      return
    }

    this.setState({ isSubmitting: true }, () => {
      const profile = this.props.benAccount.currentProfile.profileId as string
      const device = this.props.benAccount.currentProfile.deviceId as string

      this.props.benService.saveSettings(profile, device, {
        blockErotic: this.state.blockErotic,
        blockFileSites: this.state.blockFileSites,
        blockOffensive: this.state.blockOffensive,
        blockSocial: this.state.blockSocial,
        blockVideo: this.state.blockVideo,
        blockDoc: this.state.blockDoc,
        blockExe: this.state.blockExe,
        excludeYT: this.state.excludeYT,
        customMsg: this.state.customMsg,
        customMsgOn: this.state.customMsgOn,
        whiteListOnly: this.state.whiteListOnly
      })

      .then(() => this.props.benNotification.notify({
        className: 'filled',
        title: getI18nMessage('side-effect.successful-title', this.props.intl),
        message: getI18nMessage('side-effect.successful-message', this.props.intl),
        type: 'success'
      }))

      .catch(() => this.props.benNotification.notify({
        className: 'filled',
        title: getI18nMessage('side-effect.internal-error-title', this.props.intl),
        message: getI18nMessage('side-effect.internal-error-message', this.props.intl),
        type: 'error'
      }))

      .finally(() => this.setState({ isSubmitting: false }))
    })
  }

  render () {

    // if (this.state.isLoading) {
    //   return <Spinner />
    // }

    return (

      <React.Fragment>



        <Reactstrap.Row>
          <Colxx sm="12" className="mb-4">
            <Reactstrap.Card>
              <Reactstrap.CardBody>

                <Reactstrap.CardTitle tag="h2">
                  <I18nMessages id="internet-page.settings-page.filters.card-title" />
                </Reactstrap.CardTitle>

                <Reactstrap.Form onSubmit={this.handleFormSubmit}>
                  <Reactstrap.FormGroup className="settings-list">

                    {this.state.isLoading && (
                      <div style={{position:'absolute', left:'50%'}}>
                        <Spinner />
                      </div>
                    ) }

                    <Reactstrap.CustomInput
                      id="whiteListOnly"
                      name="whiteListOnly"
                      type="checkbox"
                      disabled={this.state.isLoading}
                      label={<I18nMessages id="internet-page.settings-page.white-list-only-label" />}
                      checked={this.state.whiteListOnly}
                      onChange={this.handleInputChange}
                    />

                    <Reactstrap.CustomInput
                      id="blockErotic"
                      name="blockErotic"
                      type="checkbox"
                      disabled={this.state.isLoading}
                      label={<I18nMessages id="internet-page.settings-page.block-erotic-label" />}
                      checked={this.state.blockErotic}
                      onChange={this.handleInputChange}
                    />

                    <Reactstrap.CustomInput
                      id="blockOffensive"
                      name="blockOffensive"
                      type="checkbox"
                      disabled={this.state.isLoading}
                      label={<I18nMessages id="internet-page.settings-page.block-offensive-label" />}
                      checked={this.state.blockOffensive}
                      onChange={this.handleInputChange}
                    />

                    <Reactstrap.CustomInput
                      id="blockFileSites"
                      name="blockFileSites"
                      type="checkbox"
                      disabled={this.state.isLoading}
                      label={<I18nMessages id="internet-page.settings-page.block-file-sites-label" />}
                      checked={this.state.blockFileSites}
                      onChange={this.handleInputChange}
                    />

                    <Reactstrap.CustomInput
                      id="blockVideo"
                      name="blockVideo"
                      className="blockVideo"
                      type="checkbox"
                      disabled={this.state.isLoading}
                      label={<I18nMessages id="internet-page.settings-page.block-video-label" />}
                      checked={this.state.blockVideo}
                      onChange={this.handleInputChange}
                    />

                    <Reactstrap.CustomInput
                      id="blockSocial"
                      name="blockSocial"
                      type="checkbox"
                      disabled={this.state.isLoading}
                      label={<I18nMessages id="internet-page.settings-page.block-social-label" />}
                      checked={this.state.blockSocial}
                      onChange={this.handleInputChange}
                    />

                    { this.props.benAccount.currentProfile.device != null && this.props.benAccount.currentProfile.device.osName==='Windows' && (
                      
                      <React.Fragment>
                      
                        <Reactstrap.CustomInput
                          id="blockDoc"
                          name="blockDoc"
                          type="checkbox"
                          disabled={this.state.isLoading}
                          label={<I18nMessages id="internet-page.settings-page.block-doc-label" />}
                          checked={this.state.blockDoc}
                          onChange={this.handleInputChange} />

                        <Reactstrap.CustomInput
                          id="blockExe"
                          name="blockExe"
                          type="checkbox"
                          disabled={this.state.isLoading}
                          label={<I18nMessages id="internet-page.settings-page.block-exe-label" />}
                          checked={this.state.blockExe}
                          onChange={this.handleInputChange} />

                        <Reactstrap.CustomInput
                          id="excludeYT"
                          name="excludeYT"
                          type="checkbox"
                          disabled={this.state.isLoading}
                          label={<I18nMessages id="internet-page.settings-page.excludeYT-label" />}
                          checked={this.state.excludeYT}
                          onChange={this.handleInputChange} />

                      </React.Fragment>

                    ) }

                    

                  </Reactstrap.FormGroup>

                  <Reactstrap.CardTitle tag="h5">
                    <I18nMessages id="internet-page.settings-page.custom-msg.card-tile" />
                  </Reactstrap.CardTitle>

                  <Reactstrap.CustomInput
                    id="customMsgOn"
                    name="customMsgOn"
                    type="checkbox"
                    disabled={this.state.isLoading}
                    label={<I18nMessages id="internet-page.settings-page.custom-msg.custom-msg-on-label" />}
                    checked={this.state.customMsgOn}
                    onChange={this.handleInputChange}
                  />

                  <Reactstrap.Input
                    disabled={!this.state.customMsgOn || this.state.isLoading}
                    type="textarea"
                    id="customMsg"
                    name="customMsg"
                    rows={4}
                    value={this.state.customMsg}
                    onChange={this.handleInputChange}
                  />
                </Reactstrap.Form>

              </Reactstrap.CardBody>
            </Reactstrap.Card>
          </Colxx>
        </Reactstrap.Row>
      </React.Fragment>
    )
  }
}

export default withBenService(withBenAccount(withBenNotification(injectIntl(SettingsPage))))
