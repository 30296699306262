import React from 'react'
import { Switch, Redirect, Route, NavLink } from 'react-router-dom'
import StatisticsPage from './StatisticsPage'
import WhiteBlackListPage from './WhiteBlackListPage'
import SettingsPage from './SettingsPage'

import I18nMessages from '../../../components/I18nMessages'
import { Nav, NavItem } from 'reactstrap'
import SearchesPage from './SearchesPage'

const InternetPage = () => (
  <div className="dashboard-wrapper">
    <h1>
      <I18nMessages id="internet-page.headline-title" />
    </h1>

    <Nav tabs className="separator-tabs ml-0 mb-5">
    <NavItem>
        <NavLink className="nav-link" to="/panel/internet/statistics">
          <I18nMessages id="internet-page.statistics-page-title" />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link" to="/panel/internet/searches">
          <I18nMessages id="internet-page.searches-page-title" />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link" to="/panel/internet/white-black-list">
          <I18nMessages id="internet-page.white-black-list-page-title" />
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link" to="/panel/internet/settings">
          <I18nMessages id="internet-page.settings-page-title" />
        </NavLink>
      </NavItem>
    </Nav>

    <Switch>
      <Redirect exact from="/panel/internet" to="/panel/internet/statistics" />
      <Route path="/panel/internet/statistics" component={StatisticsPage} />
      <Route path="/panel/internet/searches" component={SearchesPage} />
      <Route path="/panel/internet/white-black-list" component={WhiteBlackListPage} />
      <Route path="/panel/internet/settings" component={SettingsPage} />
      <Redirect to="/error" />
    </Switch>
  </div>
)

export default InternetPage
