import React from 'react'

export function useMountedState (): () => boolean {
  const refMounted = React.useRef(false)
  const get = React.useCallback(() => refMounted.current, [])

  React.useEffect(() => {
    refMounted.current = true

    return () => {
      refMounted.current = false
    }
  })

  return get
}
