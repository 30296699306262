import React from 'react'
import { Modal, ModalHeader, ModalBody, NavLink, Button } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'

type Props = {
  title?: string
  isOpen: boolean
  reverse?: boolean
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmationModal: React.FC<Props> = ({
  children,
  title,
  isOpen,
  reverse,
  onCancel,
  onConfirm
}) => {

  return (
    <Modal isOpen={isOpen} toggle={onCancel}>
      {title && (
        <ModalHeader>{title}</ModalHeader>
      )}

      <ModalBody>
        <div className="mb-4">
          {children}
        </div>

        <div className="d-flex align-items-center justify-content-between">

          {reverse ? (
            <React.Fragment>
                <NavLink onClick={onConfirm} className="cursor-pointer">
                    <I18nMessages id="confirmation-modal.confirm-button"/>
                </NavLink>
                <Button color="danger" onClick={onCancel}>
                    <I18nMessages id="confirmation-modal.cancel-button" />
                </Button>
            </React.Fragment>
          
            ) : (

            <React.Fragment>
                <NavLink onClick={onCancel} className="cursor-pointer">
                    <I18nMessages id="confirmation-modal.cancel-button"/>
                </NavLink>
                <Button color="danger" onClick={onConfirm}>
                    <I18nMessages id="confirmation-modal.confirm-button" />
                </Button>
            </React.Fragment>
          )}

        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmationModal
