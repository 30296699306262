import React from 'react'
import { Label, Col, Row } from 'reactstrap' // , Button, Modal, ModalHeader, ModalBody 
import { InjectedIntlProps, injectIntl } from 'react-intl'
import I18nMessages from '../components/I18nMessages'
import TimeLimitPicker from '../containers/TimeLimitPicker'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { InjectedBenServiceProps, Settings, withBenService } from '../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import getMessage from '../language/getMessage'
import { NavLink } from 'react-router-dom'
import TimeLimitPicker2 from './TimeLimitPicker2'
import { array2string, hasDeviceFeature, string2array } from '../lib/utils'


type Props = InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps
  & InjectedIntlProps

const AppsTimeLimit: React.FC<Props> = ({
  benAccount,
  benNotification,
  benService,
  intl
}) => {

  const [timeLimit, setTimeLimit] = React.useState(0)
  const [timeLimitWeek, setTimeLimitWeek] = React.useState([1*60,2*60,3*60,4*60,5*60,6*60,7*60])
  const [timeBonus, setTimeBonus] = React.useState(0)
  // const [timeBonusDay, setTimeBonusDay] = React.useState(0)


  const isMounted = React.useRef(false)
  const timer = React.useRef<NodeJS.Timeout | undefined>(undefined)

  function getDayNumberYYYYMMDD()
  {
    const d = new Date()
    return d.getFullYear() * 10000 + (d.getMonth()+1) * 100 + d.getDate()
  }

  function updateSettings( value: number|null, valuesWeek: number[]|null, timeBonus:number | null )
  {
    const { deviceId, profileId } = benAccount.currentProfile

    if( timer.current )
    {
        clearTimeout(timer.current)
        timer.current = undefined
    }

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {

      let val : Partial<Settings> = {}

      if( value != null )
      {
        const arrNum = Array(7).fill(value)
        const arr = array2string(arrNum,7)

        val = { timeLimit: value, timeLimitWeek: arr }
      }
      else if( valuesWeek != null )
      {
        const arr = array2string(valuesWeek,7)
        // obejscie dla niezaktualizowanych aplikacji
        val = { timeLimit: valuesWeek[0], timeLimitWeek: arr }
      }
      else
      {
        return
      }

      if( timeBonus != null )
      {
        val.timeBonus = timeBonus
        val.timeBonusDay = getDayNumberYYYYMMDD()
        setTimeBonus(timeBonus)
      }

      benService.saveSettings(profileId, deviceId, val)

      .then(() => benNotification.notify({
        type: 'success',
        title: getMessage('side-effect.successful-title', intl),
        message: getMessage('side-effect.successful-message', intl)
      }))

      .catch(() => benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
      }))
    }
  }

  function handleOnInputChange (value: number) 
  {
    if( value <= 24*60 ) // !isNaN(value) &&
    { 
      if( timer.current )
        clearTimeout(timer.current)

      timer.current = setTimeout(() => updateSettings(value, null, null), 500)
    }
  }

  function handleOnInputChange2 (values: number[], timeBonus:number | null)
  {
    if( timer.current )
      clearTimeout(timer.current)

    timer.current = setTimeout(() => updateSettings(null, values, timeBonus), 500)

    // console.log( values )
  }



  function supportsWeeklyTimeLimit()
  {
    return hasDeviceFeature(benAccount, '_TLW_')
  }

  function supportsBonus()
  {
    return hasDeviceFeature(benAccount, '_BT_')
  }


  React.useEffect(() => {
    const { deviceId, profileId } = benAccount.currentProfile

    isMounted.current = true

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {

      benService.loadSettings(profileId, deviceId)
        .then(result => {
          if( isMounted.current )
          {
            setTimeLimit(result.data.timeLimit) 
            setTimeLimitWeek( string2array(result.data.timeLimitWeek, 7) ) 

            var today = getDayNumberYYYYMMDD()

            if( today === result.data.timeBonusDay )
            {
              setTimeBonus( result.data.timeBonus )
            }
            else
            {
              setTimeBonus(0)
            }

            // console.log( result.data )

          }
        }) 
        .finally(() => isMounted.current)
    }

    return () => {
      isMounted.current = false
    }
  }, [benService, benAccount])

  const weeklyTimeLimit = supportsWeeklyTimeLimit()

  // console.log( "supportsBonus = " + supportsBonus() )

  return (

    <React.Fragment>

      { !weeklyTimeLimit && (
        <Row>
          <Col sm="6">
          <Label >
            <I18nMessages id="applications-page.time-limit.description" />
            <NavLink to="/panel/applications/list">
              <I18nMessages id="applications-page.apps-show-apps-list" />.
            </NavLink>
          </Label>
          </Col>
          <Col sm="6" className="mb-5">
            <TimeLimitPicker
              value={timeLimit}
              onChange={handleOnInputChange}
            />
          </Col>
        </Row>
      )}
      

      { weeklyTimeLimit && (
        <Row>
          <Col sm="4">
            <Label>
              <I18nMessages id="applications-page.time-limit.description" />
              <NavLink to="/panel/applications/list" className="mb-1 badge badge-success">
                <I18nMessages id="applications-page.apps-show-apps-list" />
              </NavLink>
            </Label>
          </Col>

          <Col sm="8" className="mb-5">
            <TimeLimitPicker2
              supportsBonus={supportsBonus()}
              values={timeLimitWeek}
              timeBonusIn={timeBonus}
              onChange2={handleOnInputChange2}
            />
          </Col>

        </Row>
      )}

      

    </React.Fragment>
  )
}

export default withBenNotification(withBenService(withBenAccount(injectIntl(AppsTimeLimit))))
