import { addLocaleData } from 'react-intl'
import { registerLocale as registerDatePickerLocale } from 'react-datepicker'
import pl from 'date-fns/locale/pl'
import en from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import pt from 'date-fns/locale/pt'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import cs from 'date-fns/locale/cs'
import ro from 'date-fns/locale/ro'
import vi from 'date-fns/locale/vi'
import hu from 'date-fns/locale/hu'
import ja from 'date-fns/locale/ja'


// import vi from 'react-intl/locale-data/vi';

import { LanguageLocale } from './types/LanguageLocale'

import polishLocale from './locales/pl_PL'
import englishLocale from './locales/en_US'
import germanLocale from './locales/de_DE'
import spanishLocale from './locales/es_ES'
import portugeseLocale from './locales/pt_PT'
import frenchLocale from './locales/fr_FR'
import italianLocale from './locales/it_IT'
import czechLocale from './locales/cz_CZ'
import romanianLocale from './locales/ro_RO'
import vietnamizeLocale from './locales/vn_VN'
import hungarianLocale from './locales/hu_HU'
import japaneseLocale from './locales/jp_JA'


export * from './types/LanguageLocale'

export interface Locales {
  pl: LanguageLocale,
  en: LanguageLocale
  de: LanguageLocale
  es: LanguageLocale
  pt: LanguageLocale
  fr: LanguageLocale
  it: LanguageLocale
  cz: LanguageLocale
  ro: LanguageLocale
  vn: LanguageLocale
  hu: LanguageLocale
  ja: LanguageLocale
}

registerDatePickerLocale('pl', pl)
registerDatePickerLocale('pl-PL', pl)
registerDatePickerLocale('en', en)
registerDatePickerLocale('en-US', en)
registerDatePickerLocale('de', de)
registerDatePickerLocale('de-DE', de)

registerDatePickerLocale('es', es)
registerDatePickerLocale('es-ES', es)

registerDatePickerLocale('pt', pt)
registerDatePickerLocale('pt-PT', pt)

registerDatePickerLocale('fr', fr)
registerDatePickerLocale('fr-FR', fr)

registerDatePickerLocale('it', it)
registerDatePickerLocale('it-IT', it)
registerDatePickerLocale('cz', cs)
registerDatePickerLocale('cz-CZ', cs)
registerDatePickerLocale('ro', ro)
registerDatePickerLocale('ro-RO', ro)
registerDatePickerLocale('vn', vi)
registerDatePickerLocale('vn-VN', vi)

registerDatePickerLocale('hu', hu)
registerDatePickerLocale('hu-HU', hu)
registerDatePickerLocale('ja', ja)
registerDatePickerLocale('jp-JA', ja)

addLocaleData([
  ...polishLocale.data,
  ...englishLocale.data,
  ...germanLocale.data,
  ...spanishLocale.data,
  ...portugeseLocale.data,
  ...frenchLocale.data,
  ...italianLocale.data,
  ...czechLocale.data,
  ...romanianLocale.data,
  ...vietnamizeLocale.data,
  ...hungarianLocale.data,
  ...japaneseLocale.data
])

const languages: Locales = {
  pl: polishLocale,
  en: englishLocale,
  de: germanLocale,
  es: spanishLocale,
  pt: portugeseLocale,
  fr: frenchLocale,
  it: italianLocale,
  cz: czechLocale,
  ro: romanianLocale,
  vn: vietnamizeLocale,
  hu: hungarianLocale,
  ja: japaneseLocale
}

export default languages
