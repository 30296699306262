import React from 'react'
import {MouseEvent } from 'react'
import { Button } from 'reactstrap'
import { getBuildConfigStr } from '../../lib/utils'
import I18nMessages from '../../components/I18nMessages'
import ReactPlayer from 'react-player'
import { ANALYTICS_NAMES, ANALYTICS_VALUES, InjectedBenServiceProps, withBenService } from '../../providers/benServiceProvider'


type AddDeviceContentProps = InjectedBenServiceProps &
{
}

const AddDeviceContent: React.FC<AddDeviceContentProps> = ({
    benService
}) => {

    const [utlToPlay, setutlToPlay] = React.useState<string|null> (null)
    const divRef = React.useRef<HTMLDivElement>(null)

    enum DISPLAY {
        ANDROID = 1,
        WINDOWS = 2,
        IOS     = 3,
        MACOS   = 4
      }

    function setPlayUrl(e: MouseEvent, what: DISPLAY) : boolean
    {
        e.preventDefault()
        e.stopPropagation()

        //console.log(url)

        var url:string   = ''
        var eventName:ANALYTICS_NAMES = ANALYTICS_NAMES.NONE
        var eventVal:ANALYTICS_VALUES = ANALYTICS_VALUES.PAGE_DISPLAY

        switch(what)
        {
            case DISPLAY.ANDROID:
                url = getBuildConfigStr('INSTALL_MOBILE_EMBEDED') 
                eventName = ANALYTICS_NAMES.VIDEO_INSTALL_ANDROID
                break

            case DISPLAY.WINDOWS:
                url = getBuildConfigStr('INSTALL_DESKTOP_EMBEDED') 
                eventName = ANALYTICS_NAMES.VIDEO_INSTALL_WINDOWS
                break
            
            default:
                break
        }
        
        if( url )
        {
            setutlToPlay(url)
            setTimeout( () => {
                divRef.current?.scrollIntoView({ behavior: "smooth", block: 'center', inline: 'center' }) 
            }, 300 )
        }

        if( eventName !== ANALYTICS_NAMES.NONE )
            benService.analyticsEvent(0, eventName, eventVal ).catch( () => {} )

        return false
    }

    return (

        <>
            <h1 className="mb-1 p-0">
                <I18nMessages id="add-device-page.title" />
            </h1>
            <h3>
                <I18nMessages id="add-device-page.description" />
            </h3>

            <div className="add-device-type-select">
                <a className="device-mobile" href={getBuildConfigStr('INSTALL_MOBILE')} onClick={ (e) => { setPlayUrl(e, DISPLAY.ANDROID) } } target="_blank" rel="noopener noreferrer">
                    <h2><I18nMessages id="add-device-page.mobile-title" /></h2>
                    <p><I18nMessages id="add-device-page.mobile-description" /></p>
                    <div className="img"></div>
                    <Button color="primary">
                        <I18nMessages id="add-device-page.check" />
                    </Button>
                </a>
                <a className="device-desktop" href={getBuildConfigStr('INSTALL_DESKTOP')} onClick={ (e) => { setPlayUrl(e, DISPLAY.WINDOWS) } }target="_blank" rel="noopener noreferrer">
                    <h2><I18nMessages id="add-device-page.desktop-title" /></h2>
                        <p><I18nMessages id="add-device-page.desktop-description" /></p>
                    <div className="img"></div>
                    <Button color="primary">
                        <I18nMessages id="add-device-page.check" />
                    </Button>
                </a>
            </div>

            { utlToPlay && (
                <div ref={divRef} className="embeded-player-add-device">
                    <div className='embeded-player' >
                        <ReactPlayer 
                            width="100%"
                            height="100%"
                            config={{
                            youtube: {
                                playerVars: { showinfo: 1, controls:2 }
                            }
                            }}
                            url={utlToPlay}
                            playing={true}></ReactPlayer>
                    </div>
                </div>
            )}
        </>
    )
}

export default withBenService(AddDeviceContent)
