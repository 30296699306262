import React from 'react'
import Slider, { Marks } from 'rc-slider'
import { minutesToClockFormat } from '../lib'
import I18nMessages from '../components/I18nMessages'


//const SLIDER_MARKS: string[] = Array.from(Array(9).keys()).map(v => v === 8 ? '∞' : String(v))
const HOURS = 8

type Props = {
  value?: number
  onChange: (value: number) => void
  max?: number
  step?: number
  sliderMarks?: Marks
  smallLabels?: boolean
  labelFormater?: any
}

const SLIDER_MARKS = {
  0: '0',
  60: '1',
  120: '2',
  180: '3',
  240: '4',
  300: '5',
  360: '6',
  420: '7',
  480: '∞'
};

const TimeLimitPicker: React.FC<Props> = ({
  value,
  onChange,
  max = HOURS*60,
  step = 5,
  sliderMarks = SLIDER_MARKS,
  smallLabels = false,
  labelFormater = minutesToClockFormat
}) => {

  const [currentValue, setCurrentValue] = React.useState(value || 0)
  const [lastValue, setLastValue] = React.useState(value || 0)
  const hour = labelFormater(currentValue)

  function aferChange ()
  {
    if (lastValue !== currentValue) {
      setLastValue(currentValue)
      return onChange(currentValue)
    }
  }

  function handleChange(val:number) {
    setCurrentValue(val)
  }

  React.useEffect(() => {
    if (value) {
      setCurrentValue(value)
      setLastValue(value)
    }
  }, [value])

  const noLimit = currentValue === HOURS*60

  return (
    <React.Fragment>
      <div className="text-center ">
        <p className={noLimit || smallLabels ? 'time-limit-clock-small' : 'time-limit-clock-small'}>
          { noLimit
                ? <I18nMessages id="time-picker.unlimied-label" />
                : hour
          }
          </p>
      </div>
      <div className="nb-4">
        <Slider
          value={currentValue}
          dotStyle={{ display: 'none' }}
          step={step}
          max={max}
          marks={{ ...sliderMarks }}
          onAfterChange={aferChange}
          onChange={handleChange}
        />
      </div>
    </React.Fragment>
  )
}

export default TimeLimitPicker
