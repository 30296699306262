import React from 'react'
import classnames from 'classnames'
import { Button, ButtonProps } from 'reactstrap'

interface AsyncButtonProps extends ButtonProps {
  showSpinner?: boolean
}

const AsyncButton = ({
  showSpinner = false,
  className,
  children,
  ...props
}: AsyncButtonProps) => {

  const classes = classnames('btn-multiple-state', className, {
    'show-spinner': showSpinner,
    'show-success': false,
    'show-fail': false
  })

  return (
    <Button {...props} disabled={showSpinner || props.disabled} className={classes}>
      {/* <Popover placement="top" isOpen={false} target={props.id || ''} >
        <PopoverBody>{' '}</PopoverBody>
      </Popover> */}
      { showSpinner && (
        <span className="spinner d-inline-block">
            <span className="bounce1"></span>
            <span className="bounce2"></span>
            <span className="bounce3"></span>
        </span>
      )}
      <span className="icon success"><i className="simple-icon-check"></i></span>
      <span className="icon fail"><i className="simple-icon-exclamation"></i></span>
      <span className="label">{children}</span>
    </Button>
  )
}

export default AsyncButton
