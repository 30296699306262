import React from 'react'
import I18nMessages from '../components/I18nMessages'

import addProfileCircleSrc from '../assets/media/add-profile-circle.png'

interface AddProfileCardProps {
  onClick?: () => void
}

const AddProfileCard = ({
  onClick
}: AddProfileCardProps) => (
  <div className="add-new" onClick={onClick}>
      <img src={addProfileCircleSrc} alt="Add profile" />
      <I18nMessages id="profile-list.add-profile-label" tagName="h5" />
  </div>
)

export default AddProfileCard
