import React from 'react'
import { Switch, Redirect, Route, withRouter, RouteComponentProps} from 'react-router-dom'
import { Row } from 'reactstrap'
import CreateProfilePage from './CreateProfilePage'
//import AddDevicePage from './AddDevicePage'
import PurchasePage from './PurchasePage'
import PurchaseStatusPage from './PurchaseStatusPage'
import ChangePasswordPage from './ChangePasswordPage'
// import PurchaseCheckoutPage from './PurchaseCheckout'
// import TestPage from './Test'

const AccountPage: React.FC<RouteComponentProps> = ({
  location
}) => (

    <div className={location.pathname !== '/account/purchase' ? 'container h-100' : 'h-100'}>
      <Row className={location.pathname !== '/account/purchase' ? 'h-100' : 'h-100 mx-0'}>
        <Switch>
          <Redirect exact from="/account" to="/error" />
          {/* <Route path="/account/add-device" component={AddDevicePage} /> */}
          <Route path="/account/change-password" component={ChangePasswordPage} />
          <Route path="/account/create-profile" component={CreateProfilePage} />
          <Route path="/account/purchase" component={PurchasePage} />
          <Route path="/account/purchase-status" component={PurchaseStatusPage} />
          {/* <Route path="/account/purchase-checkout" component={PurchaseCheckoutPage} /> 
          <Route path="/account/test" component={TestPage} />*/}          
          <Redirect to="/error" />
        </Switch>
      </Row>
    </div>
)

export default withRouter(AccountPage)
