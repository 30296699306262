import React from 'react'
import I18nMessages from '../components/I18nMessages'
import { ContactData } from '../providers/benServiceProvider'
import { HtmlEntities } from '../lib/HtmlEntities'
import { toLocaleDateTime } from '../lib/utils'
import { InjectedIntlProps, injectIntl } from 'react-intl'

type CallType = 'incoming' | 'outgoing'

export type Call = {
  date: Date
  duration: number
  id: string
  location: string
  formatedNumber: string
  normalizedNumber: string
  type: CallType
}

type Props = InjectedIntlProps & {
  data: Call[]
  contacts: ContactData[]
}

const CallLog: React.FC<Props> = ({
  data,
  contacts,
  intl
}) => {

  const entities = new HtmlEntities()

  function buildMap(contacts: ContactData[])
  {
    let m = new Map<string, string>()

    contacts.forEach(item => {

      if( item.text.length > 0 && item.name.length > 0 )
        m.set( item.text, item.name );

      if( item.text2.length > 0 && item.name.length > 0 )
        m.set( item.text2, item.name );
      
    });

    return m
  }

  function getName(map : Map<string, string>,  phone: string)
  {
    if( phone.length < 1 )
      return phone

    if( map.has(phone) )
      return entities.decode( map.get(phone) )

    return ""
  }
    

  function mapTypeToIconClassName (type: CallType): string {
    return type === 'incoming' ? 'simple-icon-call-in' : 'simple-icon-call-out'
  }

  function mapTypeToTextColorClass (type: CallType): string {
    return type === 'incoming' ? 'direction-in' : 'direction-out'
  }

  // function sortByDate (a: any, b: any) {
  //   return b - a
  // }

  const namesMap = buildMap(contacts)

  if (data.length === 0) {
    return (
      <div className="d-flex p-4 align-items-center justify-content-center text-center">
        <I18nMessages id="empty-list-label" />
      </div>
    )
  }

  return (
    <div>
      {data.map(item => (
        <div key={item.id} className={`d-flex mb-4 pb-4 border-bottom ${mapTypeToTextColorClass(item.type)}`}>
          <div className="px-2 align-self-center">
            <i className={mapTypeToIconClassName(item.type)} style={{ fontSize: '24px' }}/>
          </div>
          <div className="ml-3">
            <p className="mb-0 list-item-heading">{ getName(namesMap, item.normalizedNumber) }</p>
            <p className="mb-0 list-item-heading">{item.formatedNumber}</p>
            <p className="mb-2">{item.location}</p>
            <p className="mb-0 text-muted">{ toLocaleDateTime(item.date,intl) }</p>
            <p className="mb-0 text-muted">
              <I18nMessages id="call-log.duration-label" />
              <span className="ml-1">{item.duration}</span>
              <I18nMessages id="call-log.seconds-label"/>
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default injectIntl(CallLog)
