import React from 'react'
import Switch from 'rc-switch'
import { Row, Col, Card, CardBody, CardTitle, Label } from 'reactstrap'
import DatePickerWithButtons from '../../containers/DatePickerWithButtons'
import Pagination from '../../components/Pagination'
import Spinner from '../../components/Spinner'
import { YouTubeItem, withBenService, InjectedBenServiceProps, WebsitesLists } from '../../providers/benServiceProvider'
import { withI18nProvider, InjectedI18nProviderProps } from '../../providers/i18nProvider'
import { withBenAccount, InjectedBenAccountProps } from '../../providers/benAccountProvider'
import { injectIntl, InjectedIntlProps } from 'react-intl'
import { withBenNotification, InjectedBenNotificationProps } from '../../providers/notificationProvider'
import { dateToAllDayFromToDate, getCurrentDate000WithOffset } from '../../lib'
import { openYoutubeVideoLink, toLocaleDateTime } from '../../lib/utils'
import { HtmlEntities } from '../../lib/HtmlEntities'
import I18nMessages from '../../components/I18nMessages'
import getMessage from '../../language/getMessage'


type Props = InjectedI18nProviderProps & InjectedBenAccountProps & InjectedBenServiceProps & InjectedBenNotificationProps & InjectedIntlProps & InjectedBenNotificationProps & InjectedIntlProps

const YoutubePage: React.FC<Props> = ({
    benAccount,
    benNotification,
    benService,
    intl
  }) => {

    const [isLoading, setLoading] = React.useState(true)
    const [selectedDate, setSelectedDate] = React.useState( new Date() )
    const [currentPage, setCurrentPage] = React.useState( 0 )
    const [pageCount, setPageCount] = React.useState( 0 )
    const [youTubeItems, setYouTubeItems] = React.useState<YouTubeItem[]>( [] )
    const [blockedVideos, setBlockedVideos] = React.useState< Set<string> > ( new Set<string>() )
    const [blockedChannel, setBlockedChannel] = React.useState< Set<string> > ( new Set<string>() )

    const itemsPerPage = 10

    const isWindows = benAccount.currentProfile.device != null && benAccount.currentProfile.device.osName === 'Windows'

    const entities = new HtmlEntities()

    const [timerSaveVideos, setTimerSaveVideos] = React.useState< NodeJS.Timeout | null > ( null )
    const [timerSaveChannel, setTimerSaveChannel] = React.useState< NodeJS.Timeout | null > ( null )
    let saveSettingsDelay = 1000

    function handleDatePickerDateChange (date: Date | null) {
        if (date !== null) {
          setSelectedDate(date)
        }
    }

    function handleOnPaginationPageChange(currentPage: number)
    {
        setCurrentPage(currentPage)
    }

    function getItemsInRange() : YouTubeItem[]
    {
        let idx = (currentPage-1)*itemsPerPage;
        return youTubeItems.slice( idx, idx+itemsPerPage )
    }


    function getDateFormated(ts:number) : string
    {
        return toLocaleDateTime( new Date(ts * 1000), intl )
    }

    function saveListVideos(resetTimer : boolean)
    {
        if( resetTimer )
            setTimerSaveVideos(null)

        const websitesList: WebsitesLists = {
            blackList: [],
            whiteList: [],
            videoIds: Array.from(blockedVideos),
            channelIds: []
          }

        const { deviceId, profileId } = benAccount.currentProfile
        if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {
            benService.saveLists(profileId, deviceId, websitesList, [2])
                .then(() => benNotification.notify({
                    type: 'success',
                    title: getMessage('side-effect.successful-title', intl),
                    message: getMessage('side-effect.successful-message', intl)
                }))

                .catch(() => {
                    
                    benNotification.notify({
                    type: 'error',
                    title: getMessage('side-effect.internal-error-title', intl),
                    message: getMessage('side-effect.internal-error-message', intl)})
                    
                })
        }
    }

    function saveListChannel(resetTimer : boolean)
    {
        if( resetTimer )
            setTimerSaveChannel(null)

        const websitesList: WebsitesLists = {
            blackList: [],
            whiteList: [],
            videoIds: [],
            channelIds: Array.from(blockedChannel)
          }

        const { deviceId, profileId } = benAccount.currentProfile
        if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {
            benService.saveLists(profileId, deviceId, websitesList, [3])
                .then(() => benNotification.notify({
                    type: 'success',
                    title: getMessage('side-effect.successful-title', intl),
                    message: getMessage('side-effect.successful-message', intl)
                }))

                .catch(() => {

                benNotification.notify({
                    type: 'error',
                    title: getMessage('side-effect.internal-error-title', intl),
                    message: getMessage('side-effect.internal-error-message', intl)})
                
                })
        }
    }

    function onBlockedVideoChanged(checked: boolean, videoId: string) : void
    {
        if( checked )
            blockedVideos.add(videoId)
        else
            blockedVideos.delete(videoId)

        setBlockedVideos(new Set(blockedVideos) )

        if (timerSaveVideos) {
            clearTimeout(timerSaveVideos)
        }

        setTimerSaveVideos( setTimeout( () => saveListVideos(true), saveSettingsDelay ) )
    }

    function onBlockedChannelChanged(checked: boolean, channelId: string) : void
    {
        if( checked )
            blockedChannel.add(channelId)
        else
            blockedChannel.delete(channelId)

        setBlockedChannel(new Set(blockedChannel) )

        if (timerSaveChannel) {
            clearTimeout(timerSaveChannel)
        }

        setTimerSaveChannel( setTimeout( () => saveListChannel(true), saveSettingsDelay ) )
    }


    React.useEffect(() =>
    {
        const { deviceId, profileId } = benAccount.currentProfile
        const fromToDate = dateToAllDayFromToDate(selectedDate)
        let isMounted = true

        setLoading(true)

        let listLoaded = false
        let blockedLoaded = false

        if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' )
        {
            benService.getYoutube(profileId, deviceId, fromToDate.from, fromToDate.to, 0, 1000)
                .then(result => {

                    const pages = Math.ceil( result.data.length / itemsPerPage )

                    if (isMounted) {
                        setYouTubeItems(result.data)
                        setCurrentPage(1)
                        setPageCount( pages )
                    }
                })
                .catch( (ex) => {

                    if (isMounted) 
                    {
                        if( !ex || !ex.code || ex.code !== 5  )
                        {
                            benNotification.notify({
                                type: 'error',
                                title: getMessage('side-effect.internal-error-title', intl),
                                message: getMessage('side-effect.internal-error-message', intl)
                                })
                        }
                    }

                } )
                .finally( () => {
                    if (isMounted) {
                        listLoaded = true
                        setLoading( !listLoaded || !blockedLoaded )
                    }
                })
                

            benService.loadLists(profileId, deviceId, [2,3])
                .then(result => {

                    if (isMounted) {
                        setBlockedVideos( new Set(result.data.videoIds) )
                        setBlockedChannel( new Set(result.data.channelIds) )
                    }
                })
                .catch( (ex) => {

                    if (isMounted) 
                    {
                        if( !ex || !ex.code || ex.code !== 5  )
                        {
                            benNotification.notify({
                                type: 'error',
                                title: getMessage('side-effect.internal-error-title', intl),
                                message: getMessage('side-effect.internal-error-message', intl)
                                })
                        }
                    }
                } )
                .finally( () => {
                    if (isMounted) {
                        blockedLoaded = true
                        setLoading( !listLoaded || !blockedLoaded )
                    }
                })

        } else {

        }

        return () => {

            isMounted = false

        }
      }, [benAccount, benService, selectedDate])


      React.useEffect(() => {

        return () => {

            if( timerSaveVideos ) {
                clearTimeout(timerSaveVideos)
            }

            if( timerSaveChannel ) {
                clearTimeout(timerSaveChannel)
            }
        }

      }, [timerSaveVideos, timerSaveChannel] )


    return (
        <div className="dashboard-wrapper">

            <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
                <h1 className="mb-0 p-0"><I18nMessages id="youtube-page.headline-title" /></h1>

                <div className="d-flex align-items-center">
                    <DatePickerWithButtons minDate={ getCurrentDate000WithOffset(-29) }  onSelectedDateChanged = {handleDatePickerDateChange} selectedDate={selectedDate}/>
                </div>
            </div>


            <Row className="mb-4">
                <Col lg="12">
                <Card>
                    <CardBody>
                        <CardTitle tag="h3">
                            <I18nMessages id="youtube-page.videos-list" />
                        </CardTitle>

                        <Pagination
                            currentPage={currentPage}
                            pageCount={pageCount}
                            onChangePage={handleOnPaginationPageChange}
                        />

                        { isLoading && (
                             <Spinner />
                        )}

                        { !isLoading && youTubeItems.length < 1 && (
                            <div><I18nMessages id="youtube-page.no-videos" /></div>
                        )}

                        {  getItemsInRange().map( (item,i) => (

                            <Row key={ i } className="mb-4 pb-4 border-bottom">

                                <Col md="auto" className="d-flex align-items-center">
                                    <div className="div-as-a" onClick={ (e) => openYoutubeVideoLink(e, item.videoid) } >
                                        <img alt="icon" src={item.thumbnaildefault}></img>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className={"yt-text-title"  + ( item.blocked !== 0 ? " yt-blocked" : "" ) }>{ entities.decode(item.title)  }</div>    
                                    <div className={"yt-text-author" + ( item.blocked !== 0 ? " yt-blocked" : "" ) }>{ entities.decode(item.author) }</div>
                                    <div className={"yt-text-date"   + ( item.blocked !== 0 ? " yt-blocked" : "" ) }>{ getDateFormated(item.created) }
                                        { item.blocked !== 0 && (
                                            <span className="ml-2 badge badge-danger"><I18nMessages id="youtube-page.video-blocked" /></span>
                                        ) }
                                    </div>
                                </Col>

                                { item.videoid.length > 0 && item.channelid.length > 0 && (

                                    <React.Fragment>

                                        <Col md="auto">
                                            <Label>
                                                <I18nMessages id="youtube-page.block-videoid" />
                                            </Label>
                                            <Switch
                                                disabled={isLoading }
                                                className="custom-switch custom-switch-primary"
                                                checked={ blockedVideos.has(item.videoid) }
                                                onChange={ checked => { onBlockedVideoChanged(checked, item.videoid ) } }
                                                checkedChildren={false}
                                                unCheckedChildren={false} />
                                        </Col>

                                        { ( !isWindows || true ) && (
                                            <Col md="auto">
                                            <Label>
                                                <I18nMessages id="youtube-page.block-channelid" />
                                            </Label>
                                            <Switch
                                                disabled={isLoading}
                                                className="custom-switch custom-switch-primary"
                                                checked={ blockedChannel.has(item.channelid) }
                                                onChange={ checked => { onBlockedChannelChanged(checked, item.channelid ) } }
                                                checkedChildren={false}
                                                unCheckedChildren={false} />
                                        </Col>
                                        )}                                        

                                    </React.Fragment>
                                )}
                            </Row>
                        ))}

                        <Pagination
                            currentPage={currentPage}
                            pageCount={pageCount}
                            onChangePage={handleOnPaginationPageChange}
                        />

                    </CardBody>
                </Card>
                </Col>
            </Row>

        </div>
    )
}

export default withBenNotification(injectIntl(withBenService(withBenAccount(withI18nProvider(injectIntl(YoutubePage))))))
