import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap'
import I18nMessages from '../../../components/I18nMessages'
import AppsSchedule from '../../../containers/AppsSchedule'
import AppsTimeLimit from '../../../containers/AppsTimeLimit'

const ApplicationsAccessTimePage: React.FC = () => {

    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <AppsTimeLimit />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <CardTitle tag="h3">
                                <I18nMessages id="applications-page.settings.headline-title" />
                            </CardTitle>
                            <AppsSchedule />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ApplicationsAccessTimePage
