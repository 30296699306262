import React from 'react'
import ReactDOM from 'react-dom'
import 'rc-slider/assets/index.css'
import 'rc-switch/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './index.css'
import './assets/css/vendor/bootstrap.min.css'
import './assets/css/sass/dore.light.orange.scss'
import './assets/fonts/iconsmind-s/iconsminds.css'
import './assets/fonts/simple-line-icons/simple-line-icons.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'whatwg-fetch'

// GTM causes a full page load for SPAs
// https://support.google.com/tagmanager/thread/220921856/gtm-causes-a-full-page-load-for-spas?hl=en

// https://www.plumfind.com/academy/how-to-implement-google-tag-manager-for-single-page-applications-such-as-react

//import TagManager from 'react-gtm-module'
//import ReactGA  from 'react-ga4'

// if (process.env.REACT_APP_GA_ID) 
// {
//     ReactGA.initialize(process.env.REACT_APP_GA_ID)
//   ReactGA.initialize(process.env.REACT_APP_GA_ID, 
//   {
//     debug: true,
//     titleCase: false,
//   } )

//   // ReactGA.set( {
//   //   userId: '123' 
//   // } )
// }

// if ((window as any).location.port !== '3000' && !(window as any).location.host.endsWith('.local')) {
//   if (process.env.REACT_APP_GTM_ID) 
//   {
//     const tagManagerArgs = {
//       gtmId: process.env.REACT_APP_GTM_ID
//     }
//     TagManager.initialize(tagManagerArgs)
//   }
// }
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
