import React from 'react'
import { Row, Col } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { getPkgStoreOpen, describeTimeElapsedHMS, expandIcoFileName } from '../lib/utils'
import { withBenAccount, InjectedBenAccountProps } from '../providers/benAccountProvider'


export type AppsUsageListData = {
  elapsed: number
  ico: string
  name: string
  pkg: string
  runCount: number
}

type Props = InjectedBenAccountProps & InjectedIntlProps &
{
  data: AppsUsageListData[]
}



const AppsUsageList: React.FC<Props> = ({
  benAccount,
  data,
  intl
}) => {


  const isWindows = benAccount.currentProfile.device != null && benAccount.currentProfile.device.osName === 'Windows'

  if (data.length === 0) {
    return (
      <div className="d-flex p-4 align-items-center justify-content-center text-center">
        <I18nMessages id="empty-list-label" />
      </div>
    )
  }

  return (
    <React.Fragment>
      {data.map((item, index) => (
        <Row key={index} className="mb-4 pb-4 border-bottom">
          <Col sm="6" className="d-flex align-items-center">
            <div className="app-icon">
              <img src={expandIcoFileName(item.ico)} alt={item.name} />
            </div>

            <div className={!isWindows ? "div-as-a" : ""} onClick={ (e) => { if(!isWindows) getPkgStoreOpen(e, item.pkg) } }>
                <div className="ml-2">
                <p className="mb-0 list-item-heading line-break-any">{item.name}</p>
                <p className="mb-2 text-muted line-break-any">{item.pkg}</p>
                </div>
            </div>
          </Col>

          <Col sm="6">
            <p className="mb-0 text-muted">
              <I18nMessages id="applications-page.list-launched-count-label" />
              <span className="ml-1">{item.runCount}</span>
            </p>
            <p className="mb-0 text-muted">
              <I18nMessages id="applications-page.list-elapsed-time-label" />
              <span className="ml-1">{describeTimeElapsedHMS(item.elapsed, intl)}</span>
            </p>
          </Col>
        </Row>

      ))}
    </React.Fragment>
  )
}

export default injectIntl(withBenAccount(AppsUsageList))
