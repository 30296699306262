import { LanguageLocale, LanguageMessagesKeys } from '../types/LanguageLocale'
import appLocalData from 'react-intl/locale-data/en'

const messages: LanguageMessagesKeys = { 

  // Common
  'side-effect.successful-title': 'All OK!',
  'side-effect.successful-message': 'The data has been saved correctly',
  'side-effect.internal-error-title': 'Something went wrong',
  'side-effect.internal-error-message': 'Please try again later or check your internet connection',

  'monday-sshort-label': 'Mo',
  'tuesday-sshort-label': 'Tu',
  'wednesday-sshort-label': 'We',
  'thursday-sshort-label': 'Th',
  'friday-sshort-label': 'Fr',
  'saturday-sshort-label': 'Sa',
  'sunday-sshort-label': 'Su',

  'monday-short-label': 'Mon',
  'tuesday-short-label': 'Tue',
  'wednesday-short-label': 'Wed',
  'thursday-short-label': 'Thu',
  'friday-short-label': 'Fri',
  'saturday-short-label': 'Sat',
  'sunday-short-label': 'Sun',

  'monday-label': 'Monday',
  'tuesday-label': 'Tuesday',
  'wednesday-label': 'Wednesday',
  'thursday-label': 'Thursday',
  'friday-label': 'Friday',
  'saturday-label': 'Saturday',
  'sunday-label': 'Sunday',

  'january-label': 'January',
  'february-label': 'February',
  'march-label': 'March',
  'april-label': 'April',
  'may-label': 'May',
  'june-label': 'June',
  'july-label': 'July',
  'august-label': 'August',
  'september-label': 'September',
  'october-label': 'October',
  'november-label': 'November',
  'december-label': 'December',

  'interval-name-year'  : 'year',
  'interval-name-month' : 'month',
  'interval-name-week'  : 'week', 
  'interval-name-day'   : 'day',
  'interval-name-years' : 'years',
  'interval-name-months': 'months',
  'interval-name-weeks' : 'weeks',
  'interval-name-days'  : 'days',

  'select-input.no-options-label': 'No options',
  'filter-input.label': 'Filter:',

  'no-data.label': 'No data to display. Change filters or the selected date.',
  'no-data.label-mid': 'No data to display. Change the selected date.',
  'no-data.label-short': 'No data to display.',
  'empty-list-label': 'Empty list.',
  'overlay.empty-device-label': 'No devices have been added to this profile yet.',
  'overlay.empty-profiles-label': 'No profile added yet.',
  'overlay.empty-device.add-device-label': 'Add device',

  'confirmation-modal.cancel-button': 'Cancel',
  'confirmation-modal.confirm-button': 'CONFIRM',
  'confirmation-modal.button-yes': 'Yes',
  'confirmation-modal.button-no': 'No',

  // Date Picker
  'date-picker.select-date-label': 'Select date',

  // Date Filter
  'date-filter.select-label': 'Select range',
  'date-filter.this-month': 'This month',
  'date-filter.this.week': 'This week',
  'date-filter.this.day': '24h',
  'date-filter.this.custom': 'Select day',
  'date-filter.from-label': 'From: ',
  'date-filter.to-label': 'To: ',

  // Time Picker
  'time-picker.unlimied-label': 'Unlimited',
  'time-picker.time-format': '{h}h {m}min',
  'time-picker.time-format-min': '{m} min',
  'time-picker.time-format-hour': '{h} h',
  
  'time-picker.add-bonus-title': 'Add time bonus only for today',
  'time-picker.no-bonus' : 'No bonus',
  'time-picker.bonus': 'Bonus',
  'time-picker.add-bonus-button-set': 'Set bonus',
  'time-picker.add-bonus-button-cancel' : 'Cancel',

  // Profile image picker
  'profile-image-picker.date-range-label': 'Date range',
  'profile-image-picker.girl-label': 'Girl',
  'profile-image-picker.boy-label': 'Boy',

  // Profile List
  'profile-list.remove-device-label': 'Remove device',
  'profile-list.move-device-label': 'Move device to another profile',
  'profile-list.add-device-label': 'Add device',

  'profile-list.add-profile-label': 'Add profile',
  'profile-list.edit-profile-label': 'Edit profile',
  'profile-list.add-devices-label': 'Add new device',
  'profile-list.remove-profile-label': 'Remove profile',
  'profile-list.remove-profile-disabled-tooltip': 'In order to delete your profile, you must first remove all devices attached to the profile.',
  'profile-list.title': 'Profiles list',

  // Profile Form
  'profile-form.full-name-label': 'Name',
  'profile-form.month-of-birth-label': 'Month of birth',
  'profile-form.year-of-birth-label': 'Year of birth',

  // Profile Form
  'profile-form-modal.create.skip-button': 'Cancel',
  'profile-form-modal.create.submit-button': 'ADD PROFILE',
  'profile-form-modal.create.title': 'Add child profile',
  'profile-form-modal.edit.skip-button': 'Cancel',
  'profile-form-modal.edit.submit-button': 'SAVE',
  'profile-form-modal.edit.title': 'Edit child profile',
  'profile-form-modal.max-profiles-error': 'Profile limit reached',

  // Profile Move Device Form
  'profile-move-device-modal.title': 'Move device to another profile',
  'profile-move-device-modal.cancel-button': 'Cancel',
  'profile-move-device-modal.submit-button': 'Save',
  'profile-move-device-modal.select-profile-label': 'Choose profile',

  // CallLog
  'call-log.duration-label': 'Call duration:',
  'call-log.seconds-label': 's.',

  // Create Profile Page
  'create-profile-page.submit-button': 'ADD PROFILE',
  'create-profile-page.skip-button': 'Cancel',
  'create-profile-page.title': 'Add child profile',
  'create-profile-page.description': 'Who will be monitored',

  // Add device page
  'add-device-page.close-button': 'CLOSE',
  'add-device-page.description': 'Choose type of device to install app',
  'add-device-page.title': 'Installation Guide',
  'add-device-page.check': 'Check manual',
  'add-device-page.mobile-title': 'Android smartphone or tablet',
  'add-device-page.mobile-description': 'Android version 6 or higher',
  'add-device-page.desktop-title': 'Windows desktop or laptop',
  'add-device-page.desktop-description': 'Windows version 7 or higher',


  // Purchase Page
  'purchase-page.skip-label': 'Go to the dashboard',
  'purchase-page.title': 'Choose the best plan',
  'purchase-page.title.features': 'All plans offer the following functions',
  'purchase-page.title.reviews': 'Why our users love us',
  'purchase-page.subtitle': 'If the program does not meet your expectations, we will refund the payment',
  'purchase-page.plan-highlight': 'Best value',
  'purchase-page.monthly': 'monthly',
  'purchase-page.select': 'Choose',
  'purchase-page.close': 'Close',
  'purchase-page.money-back.title': 'Risk free',
  'purchase-page.money-back.desc': 'If you are not satisfied, you will be refunded.',
  'purchase-page.header.check-1': 'Best parental control app',
  'purchase-page.header.check-2': 'Set time limit for games games, YouTube, and websites',
  'purchase-page.header.check-3': 'Hundreds of thousands of satisfied customers',
  'purchase-page.plan.year': 'year',
  'purchase-page.plan.optimal': 'Best value',


  'purchase-page.modal.2y-header': 'Special offer!',
  'purchase-page.modal.2y-sub-header': 'Choose the <b>2-year plan and save</b> <br> <s>{baseAmount} {currency}</s> <span class="discounted-price">{amount} {currency}</span>',
  'purchase-page.modal.2y-sub-header2': 'Choose the <b>2-year plan and save</b> <br> <s>{baseAmountCurrency}</s> <span class="discounted-price">{amountCurrency}</span>',
  'purchase-page.modal.protect-plan-devices': 'Protect up to {devices} devices',
  'purchase-page.modal.upgrade': 'Choose the discount',
  'purchase-page.modal.no-thanks': 'Continue with the 1-year plan',


  'purchase-plan.features.title.feature_1': 'Apps and games time limit',
  'purchase-plan.features.title.feature_2': 'Website filtering',
  'purchase-plan.features.title.feature_3': 'Block apps, games, and websites',
  'purchase-plan.features.title.feature_4': 'Youtube Monitoring',
  'purchase-plan.features.title.feature_5': 'Live remote screen viewing',
  'purchase-plan.features.title.feature_6': 'Remote access',
  'purchase-plan.features.title.feature_7': 'Detailed reports',
  'purchase-plan.features.title.feature_8': 'Location (phone only)',
  'purchase-plan.features.title.feature_9': 'Check messages and calls',
  'purchase-plan.features.title.feature_10': 'Priority technical support',

  'purchase-plan.features.desc.feature_1': "Set time limits for apps, games, and internet access. Additionally, schedule which hours your child is allowed to access them",
  'purchase-plan.features.desc.feature_2': "All inappropriate websites are blocked. The program uses an algorithm which analyzes the website's contents for any unsuitable material. It also gathers intel from an immense database collection of inappropriate websites and words, both of which are regularly updated.",
  'purchase-plan.features.desc.feature_3': "Block apps and websites that could have a negative impact on your children. Also, any new game installed will require your authorization.",
  'purchase-plan.features.desc.feature_4': "In the dashboard, monitor all videos your child watches on YouTube. Block any video or completely block access to video services.",
  'purchase-plan.features.desc.feature_5': "Remotely access a live feed of your child's screen and check their activity throughout the day. This allows for keeping a close eye on the content your child is accessing.",
  'purchase-plan.features.desc.feature_6': "Once you've logged in, it is possible to access the parent dashboard from anywhere and from any device. In the dashboard, adjust settings, monitor children's devices, and set time limits.",
  'purchase-plan.features.desc.feature_7': "In the parental dashboard, easily monitor a child's activity on their computer or phone, see how much screen time they've done, which websites they've visited, and much more.",
  'purchase-plan.features.desc.feature_8': "Check your child's current location and past locations on the map. See exactly where your child was on any given day or at a certain time.",
  'purchase-plan.features.desc.feature_9': "You can easily check your child's sent and received messages, phone calls, and saved contacts by using the parent panel.",
  'purchase-plan.features.desc.feature_10': "Should you need any help, we are always available. There are also many video guides to aid you using {app}.",

  // 

  'purchase-plan.raitings.desc.name_1' : 'Sophia',
  'purchase-plan.raitings.desc.name_2' : 'Olivia',
  'purchase-plan.raitings.desc.name_3' : 'Mateo',
  'purchase-plan.raitings.desc.name_4' : 'Lucas',
  'purchase-plan.raitings.desc.name_5' : 'Emma',
  'purchase-plan.raitings.desc.name_6' : 'Emma',

  'purchase-plan.raitings.desc.raiting_1': 'Very efficient! Completely protects my child from harmful internet content.',
  'purchase-plan.raitings.desc.raiting_2': 'Just what I was looking for! I set a one-hour daily limit for my daughters accessing the internet and Avosmart takes care of it for me.',
  'purchase-plan.raitings.desc.raiting_3': 'I recommend Avosmart because it has a variety of functions. My children are protected when using the internet and I am able to control how much time they spend playing games, etc.',
  'purchase-plan.raitings.desc.raiting_4': 'It was important for my wife and I to be able to keep an eye on our daughter\'s social media habits. Avosmart helps us keep our daughter\'s digital and real life balanced.',
  'purchase-plan.raitings.desc.raiting_5': 'My son used to spend too much time on the internet, using a laptop, tablet, etc.. With the reports provided by Avosmart, I know exactly which websites my son visits and for how long.',
  'purchase-plan.raitings.desc.raiting_6': 'Now I am able to check my son\'s current location at any time. An excellent service! ',


  'account-settings-page.title': 'Account settings',
  'account-settings-page.automatic-update': 'Automatic updates of Windows application',
  'account-settings-page.timezone': 'Timezone : ',
  'account-settings-page.send-reports': 'Send daily activity reports',


  'plans-info-page.title': 'Subscription information',
  'plans-info-page.plan': ' Plan:',
  'plans-info-page.max-devices': 'Max number of devices:',
  'plans-info-page.valid-till': 'Expiry date:',

  'plans-info-page.your-plan': 'Your plan:',
  'plans-info-page.subscription-information': 'Subscription information',
  'plans-info-page.subscription-created': 'Subscription created: ',
  'plans-info-page.subscription-current-period': 'Current subscription period: ',
  'plans-info-page.subscription-renews-on': 'Your plan renews on ',
  'plans-info-page.subscription-cancel-renewal': 'Cancel renewal',
  'plans-info-page.subscription-not-renewed': 'Your plan will NOT be renewed at the end of current period',
  'plans-info-page.subscription-enable-renewal': 'Enable renewal',
  'plans-info-page.subscription-price': 'Subscription price: ',
  'plans-info-page.subscription-price-amount': '{amount} every {period}',  
  

  'plans-info-page.upgrade-plan': 'Upgrade your plan',
  'plans-info-page.extend-lic': 'Extend your plan',
  'plans-info-page.subscriptions-portal': 'Manage your payment methods',
  'plans-info-page.recent-payments': 'Latest transactions',
  'plans-info-page.date-of-payment': 'Transaction date:',
  'plans-info-page.transaction-status': 'Transaction status:',
  'plans-info-page.no-recent-payments': 'No latest transactions',
  'plans-info-page.btn-close': 'Close',
  'plans-info-page.plan-devices': 'up to {devices} devices',
  'plans-info-page.plan-devices-types': 'computers/tablets/phones',


  'upgrade-page.title': 'Upgrade the plan',
  'upgrade-page.devices': 'Devices: ',
  'upgrade-page.valid-till': 'Your plan is valid until: ',
  'upgrade-page.confirm-upgrade': 'Your plan will be upgraded, you will be charged {amount}',
  'upgrade-page.pay-now': 'Pay now {amount}',
  'upgrade-page.pay-then': 'Then {amount} every {period}',
  

  // Purchase Checkout (Stripe ver)

  'purchase-checkout.buy-subscription': 'Buy subscription',
  'purchase-checkout.up-to-devices': 'Up to {devices} devices',
  'purchase-checkout.subscribtion-price': '{amount} every {period}',
  'purchase-checkout.subscribe': 'Subscribe',
  'purchase-checkout.cancel': 'Cancel',


  // Purchase Status Page
  'purchase-status-page.title': 'Checking payment',
  'purchase-status-page.status-pending': 'Pending',
  'purchase-status-page.status-completed': 'Completed',
  'purchase-status-page.status-canceled': 'Canceled',
  'purchase-status-page.status-refund-finalized': 'Refund finalized',
  'purchase-status-page.status-wating-for-conf': 'Wating for confirmation',
  'purchase-status-page.status-created': 'Created',
  'purchase-status-page.status-unkown': 'Unkown',
  'purchase-status-page.status-error': 'Error',
  'purchase-status-pag.back-button': 'Back to home page',
  'purchase-status-pag.invoice-link': 'I want to receive a VAT invoice',

  // Login Page
  'login-page.brandline': 'Log in',
  'login-page.brandline2': 'Website filtering',
  'login-page.email-label': 'e-mail address',
  'login-page.login-button': 'SIGN IN',
  'login-page.password-label': 'Password',
  'login-page.recovery-link': 'Forget password?',
  'login-page.invalid-login-error': 'The email address or password you entered is not valid',

  // Register Page
  'register-page.create-account-label': 'Need an account? Create a new account',
  'register-page.description': 'Create your Free account',
  'register-page.email-label': 'E-mail address',
  'register-page.login-link-label': 'Have an account? Sign in',
  'register-page.marketing-checkbox-label': 'I agree to receiving marketing and promotional materials',
  'register-page.password-label': 'Password',
  'register-page.phoneNumber-label': 'Phone number',
  'register-page.terms-privacy-checkbox-label': 'I agree to the privacy policy and terms of use',
  'register-page.register-button': 'REGISTER',
  'register-page.register-success-title': 'Success',
  'register-page.register-success-message': 'Registration succeeded. Check your mailbox to confirm email',
  'register-page.register-error-captcha': 'Captcha error. Try again.',
  'register-page.register-error-already-registered-label': 'Already registered. Try to login or remind password.',
  'register-page.register-error-password-to-short-label': 'Password too short.',

  // Forgot Password Page
  'register-page.strong-pass': 'Your password is too simple and may be easily deciphered by other people. Your password should contain uppercase and lowercase letters as well as numbers, and should be at least 8 characters long. Are you sure you want to use a simple password?',
  'forgot-password-page.headline-title': 'Remind password',
  'forgot-password-page.email-label': 'e-mail address',
  'forgot-password-page.reset-button': 'REMIND PASSWORD',
  'forgot-password-page.login-link-label': 'Back to login page',
  'forgot-password-page.success-title': 'Password reset',
  'forgot-password-page.success-message': 'Check your mailbox for instructions on how to reset your password',
  'forgot-password-page.email-not-confirmed': 'Your email address is not verified. Password reset is unavailable.',

  // Update Password Page
  'update-password-page.headline-title': 'Setting a new password',
  'update-password-page.warning-title': 'NOTE:',
  'update-password-page.warning-description': 'Setting a new password will result in the loss of previously saved location data. The remaining data will not be lost. ',
  'update-password-page.warning-agreement': 'I understand and accept the loss of location data.',
  'update-password-page.new-password': 'New password:',
  'update-password-page.new-password-retype': 'Repeat new password:',
  'update-password-page.submit': 'Set new password',
  'update-password-page.alert-weak-password': 'Your password is too simple and could be easily guessed by unauthorized persons. The password should contain uppercase letters, lowercase letters, numbers, and be at least 8 characters long. Do you want to use a simple password anyway? ',
  'update-password-page.alert-agreement': 'Acceptance of data loss is required.',
  'update-password-page.error-message': 'Error! Setting a new password has failed. ',
  'update-password-page.success-message': 'New password set',
  'update-password-page.unknow-error-message': 'Something went wrong',
  'update-password-page.error-link': 'Wrong link',
  'update-password-page.error-posswords-not-equal': 'Passwords not equal',
  'update-password-page.error-posswords-length': 'Passwords to short',
  'update-password-page.error-wrong-code': 'Wrong code',
  'update-password-page.register-success-title': 'Success!',
  'update-password-page.register-success-message': 'New password set.',

  // Error Page
  'not-found-page.title': 'Oops... looks like an error occurred!',
  'not-found-page.error-code-text': 'Error code',
  'not-found-page.go-back-home-button': 'GO BACK HOME',

  // Panel Page
  'panel-page.purchase-ending-soon-alert': 'Your plan ends at {date}. Please consider purchasing a subscription today',
  'panel-page.purchase-demo-alert': 'You are currently using the trial version.',
  'panel-page.purchase-expired-alert': 'You are currently using an expired trial version. Purchase subscriptions to continue',
  'panel-page.purchase-lic-expired-alert': 'Your subscription plan has expired. Purchase subscriptions to continue',
  'panel-page.email-alert': 'You have not confirmed your email address',
  'panel-page.button-email-resend': 'Resend the email',
  'panel-page.email-alert-resend': ' or send the email once again.',
  'panel-page.email-alert-resend-title': 'Confirmation',
  'panel-page.email-alert-resend-msg': 'Email has been sent!',
  'panel-page.profile-delete-description': 'Are you sure you want to delete the selected profile?',
  'panel-page.profile-delete-device-description': 'Are you sure you want to delete the selected device? Data will be lost.',
  'panel-page.profile-delete-device-second-description': 'The protection on the device will be disabled. Are you sure you want to continue?',

  'panel-page.email-confirmation-overlay-close': 'Close',
  'panel-page.email-confirmation-overlay-postpone': 'Later',
  'panel-page.email-confirmation-overlay-resend': 'Resend the email',
  'panel-page.email-confirmation-overlay-header': 'Your e-mail address below has not been confirmed',
  'panel-page.email-confirmation-overlay-desc': 'If you have made a mistake with the above e-mail address, please let us know immediately.  <br>If you did not get the email, please click \'resend\' and check your other email folders (including junk/spam).',

  'panel-page.email-confirmation-code-success-header': 'Thank you',
  'panel-page.email-confirmation-code-success-desc': 'Email has been confirmed!',
  'panel-page.email-confirmation-code-header': 'Please enter the 6-digit confirmation code that <br> was sent to your email address.',
  'panel-page.email-confirmation-code-error-invalid-code': 'The given code is invalid',
  'panel-page.email-confirmation-code-error': 'An unexpected error occurred while validating the code',

  // Summary Page
  'summary-page.title': 'Dashboard',
  'summary-page.top-apps.title': 'Top apps',
  'summary-page.top-blocked.title': 'Top blocked',
  'summary-page.top-domains.title': 'Top visited webpages',
  'summary-page.top-search.title': 'Top search',
  'summary-page.access-disable': 'Temporary deactivation',
  'internet-page.access-time-limit-set-label': 'Daily limit for internet access:',
  'internet-page.access-time-limit-description': 'Set daily time limits for each device and choose how many hours of internet usages is allowed.',  
  'disable-access.protection-enabled': 'Protection is active',
  'disable-access.protection-disabled-for': 'Protection is temporarily disabled, {time} minutes left',
  'disable-access.device-offline': 'Device unavailable',
  'disable-access.protection-status': 'Protection status',


  // Internet Page
  'internet-page.headline-title': 'Web pages',
  'internet-page.access-time-page-title': 'Access time',
  'internet-page.settings-page-title': 'Settings',
  'internet-page.statistics-page-title': 'Statistics',
  'internet-page.searches-page-title': 'Searches',

  'internet-page.statistics-page-www-ok': 'Approved website',
  'internet-page.statistics-page-www-ok-white': 'Safe website',
  'internet-page.statistics-page-www-timelimit': 'Website blocked due to time limits',
  'internet-page.statistics-page-www-userdef': 'Website blocked, user definitions',
  'internet-page.statistics-page-www-content': 'Website blocked, inappropriate content',


  'internet-page.white-black-list-page-title': 'Safe / Blocked Websites',
  // Internet Page / Settings page
  'internet-page.settings-page.filters.card-title': 'Filters',
  'internet-page.settings-page.white-list-only-label': 'Only allow access to sites on safe list',
  'internet-page.settings-page.block-erotic-label': 'Block access to erotic websites',
  'internet-page.settings-page.block-offensive-label': 'Block access to offensive, aggressive websites',
  'internet-page.settings-page.block-file-sites-label': 'Block access to pages that allow downloading files from the internet',
  'internet-page.settings-page.block-video-label': 'Block access to video portals',
  'internet-page.settings-page.block-social-label': 'Block access to social networks',
  'internet-page.settings-page.block-doc-label': 'Block documents from being downloaded',
  'internet-page.settings-page.block-exe-label': 'Block programs from being downloaded',
  'internet-page.settings-page.excludeYT-label': 'Do not force safe search on YouTube',
  'internet-page.settings-page.block-new-apps': 'Block new applications',
  'internet-page.settings-page.block-settings': 'Block access to settings',
  'internet-page.settings-page.custom-msg.card-tile': 'Message for blocked pages',
  'internet-page.settings-page.custom-msg.custom-msg-on-label': 'Use personalized message for blocked websites',
  'internet-page.settings-page.save-settings-button-label': 'Save settings',
  // Internet Page / Lists / White list
  'internet-page.lists.add-to-list-label': 'Add to list:',
  'internet-page.lists.add-button-label': 'Add',
  'internet-page.lists.remove-button-label': 'Delete',
  'internet-page.lists.white-list.card-title': 'Safe List',
  'internet-page.lists.black-list.card-title': 'Blocked List',
  'internet-page.lists.remove-prefix': 'The entered address starts with "{prefix}", it is recommended to add only the domain name. Do you want to remove "{prefix}" from the address?',

  // Internet Page /  Statistics
  'internet-page.statistics.visited-pages-card-title': 'Visited pages',

  'internet-page.searches.searches-pages-card-title': 'Searched phrases',

  // Internet Page / Access Time
  'internet-page.access-time.schedule-card-title': 'Schedule',
  'internet-page.access-time.schedule-legend-label': 'Schedule legend',
  'internet-page.access-time.schedule-internet-available-label': 'Internet available',
  'internet-page.access-time.schedule-internet-blocked-label': 'Internet blocked',

  // Calls And SMS Page
  'calls-text-messages-page.headline-title': 'Calls & Text Messages (SMS)',

  // Calls and SMS Page / Calls List
  'calls-text-messages-page.calls-list-title': 'Calls',
  // Calls And SMS Page / List
  'calls-text-messages-page.sms-list-title': 'Text Messages (SMS)',
  // Calls And SMS Page / Contacts
  'calls-text-messages-page.contacts-last-changed-label': 'Last change:',
  'calls-text-messages-page.contacts-no-changes-label': 'Empty.',
  'calls-text-messages-page.contacts-phone-label': 'tel.',
  'calls-text-messages-page.contacts-title': 'Contacts',

  // Localization Page
  'localization-page.headline-title': 'Location',
  'localization-page.headline-title-off': 'Localizations, GPS tracking is turned off',
  'localization-page.map-hours-filter-label': 'Select time range to view location history',
  'localization-page.gps-tracking-on': 'GPS tracking enabled',
  'localization-page.gps-view-speed': 'Show travel speed',
  'localization-page.map-loading-label': 'Loading maps...',
  'localization-page.map-no-data': 'NO DATA',

  'localization-page.one-gps-was-off-since': 'Device location services turned off since {lastOffStr}',
  'localization-page.one-gps-was-off-for': 'Device location services turned off for {elapsedStr}',
  'localization-page.many-gps-was-off-since': 'Device location services turned off since {lastOffStr}',
  'localization-page.many-gps-was-off-for': 'Device location services turned off for {elapsedStr}',


  // Battery Page
  'battery-page.headline-title': 'Battery',
  'battery-page.chart-description': 'Battery level',

  // Access Time Page
  'access-time-page.headline-title': 'Access Time',
  'access-time-page.nav-access-time-label-applications': 'Applications',
  'access-time-page.nav-access-time-label-internet': 'Web pages',

  // YouTube Page
  'youtube-page.headline-title': 'YouTube',
  'youtube-page.chart-description': 'YouTube',
  'youtube-page.videos-list': 'List of videos',
  'youtube-page.no-videos': 'No videos on selected date',
  'youtube-page.block-videoid': 'Block this video',
  'youtube-page.block-channelid': 'Block this channel',
  'youtube-page.video-blocked': 'Blocked',

  // Screen Pages
  'screenview-page.headline-title': 'Screen View',
  'screenview-page.reload-button': 'Reload',
  'screenview-page.chart-description': 'Current Screen View',
  'screengrab-page.headline-title': 'Screen Grab',
  'screengrab-page.chart-description': 'Screen Grab',
  'screengrab-page.screen-save-time': 'How often the screen grab is taken',
  'screengrab-page.screen-save-time-desc': 'Choose how often the screen grab is taken or choose "disabled" to not have any screen grabs taken',
  'screengrab-page.screen-save-time-disabled': 'disabled',
  'screengrab-page.no-screens-for-today': 'No screen grabs taken on this day',
  'screengrab-page.no-screens-for-filter': 'No screens matching selected criteria',
  'screengrab-page.imgs-delete-description': 'Are you sure you want to delete the selected images?',
  'screengrab-page.imgs-delete-description-one': 'Are you sure you want to delete the selected image?',
  'screengrab-page.imgs-delete-label': 'Delete',
  'screengrab-page.imgs-delete-select': 'Select to delete',
  'screengrab-page.imgs-delete-selected': 'Selected:',
  'screengrab-page.imgs-delete-limit': 'The selected screenshot limit has been reached.',
  'screengrab-page.imgs-filter': 'Filter:',
  'screengrab-page.imgs-filter-communicators': 'Communicators',
  'screengrab-page.imgs-filter-social': 'Social Media',
  'screengrab-page.imgs-filter-video': 'Video',
  'screengrab-page.imgs-filter-others': 'Others',

  'screes-pages.device-offline': ' Device is offline',
  'screes-pages.device-deactivated': 'The protection has been temporarily deactivated',
  'screes-pages.device-timeout': 'Device did not respond',
  'screes-pages.device-screenoff': 'The screen is turned off',
  'screes-pages.device-notallowed': 'Screen Grab not allowed on the device',
  'screes-pages.device-error': 'An error has occured',

  'lazy-image.img-delete-description': 'Are you sure you want to delete the selected image?',

  // Applications Page
  'applications-page.headline-title': 'Applications',
  'applications-page.nav-access-time-label': 'Access time',
  'applications-page.nav-statistics-label': 'Summary',
  'applications-page.nav-apps-list-label': 'Apps list',
  'applications-page.chart-activity-by-day-label': 'Activity during the day',
  'applications-page.chart-hour-label': 'Hour',
  'applications-page.chart-activity-by-hour-minutes-label': 'Activity during the day in minutes',
  'applications-page.list-launched-count-label': 'Launch count:',
  'applications-page.list-elapsed-time-label': 'Run time:',
  'applications-page.introduction': 'Installed apps',

  // Applications Page / Settings
  'applications-page.settings.headline-title': 'Schedule time range',
  'applications-page.settings.schedule-legend-label': 'Schedule legend',
  'applications-page.settings.schedule-blocked-label': 'Apps blocked',
  'applications-page.settings.schedule-available-label': 'Apps available',
  'applications-page.time-limit.description': 'Set time limit for specific apps. Choose how many hours a day application usage is allowed.  ',
  'applications-page.apps-headline-title': 'Available apps',
  'applications-page.apps-first-seen-label': 'First seen:',
  'applications-page.apps-available-switch-label': 'Available',
  'applications-page.apps-not-available-switch-label': 'Unavailable',
  'applications-page.apps-schedule-switch-label': 'Scheduled',
  'applications-page.apps-no-schedule-switch-label': 'Not scheduled',
  'applications-page.apps-time-limit-switch-label': 'Time limit',
  'applications-page.apps-no-time-limit-switch-label': 'No time limit',
  'applications-page.apps-show-uninstalled-label': 'Show uninstalled applications',
  'applications-page.apps-show-apps-list': 'Choose time limited apps',

  'applications-page.used-count-zero-label': 'Not launched',
  'applications-page.used-count-one-label': 'Launched {value} time, total time: {totalTime}',
  'applications-page.used-count-many-label': 'Launched {value} times, total time: {totalTime}',
  'applications-page.total-hours-minutes-label': '{hours} h {minutes} min',
  'applications-page.total-minutes-seconds-label': '{minutes} min {seconds} sec',
  'applications-page.total-minutes-label': '{minutes} min',
  'applications-page.total-seconds-label': '{seconds} sec',
  'applications-page.total-time-label': 'Total time ',

  // EmailConfirmationPage
  'email-confirmation-page.bad-code-label': 'Activation link has been used or is invalid',
  'email-confirmation-page.successful-confirm': 'Email has been confirmed. Thank you!',
  'email-confirmation-page.button-home-page-label': 'OK',

  // Change password
  'change-password-page.change-password-label': 'Change password',
  'change-password-page.new-password-input-label': 'New password',
  'change-password-page.new-password-repeated-input-label': 'Repeat new password',
  'change-password-page.current-password-input-label': 'Current password',
  'change-password-page.back-button-label': 'Back',
  'change-password-page.change-button-label': 'CHANGE',
  'change-password-page.successful-change-label': 'Password has been changed correctly',
  'change-password-page.wrong-current-password-label': 'Wrong current password',
  'change-password-page.password-too-short-label': 'New password is too short',

  // TopNavigation
  'top-navigation.hamburger-menu': 'MENU',
  'top-navigation.user-menu.account-label': 'Account',
  'top-navigation.user-menu.sign-out': 'Sign out',
  'top-navigation.user-menu.subscription-label': 'Subscription plan',
  'top-navigation.user-menu.change-password-label': 'Change password',
  'top-navigation.user-menu.add-device-label': 'Add device',

  // Sidebar
  'sidebar.menu-item.applications': 'Applications',
  'sidebar.menu-item.calls-and-text-messages': 'Calls & Texts(SMS)',
  'sidebar.menu-item.internet': 'Web Pages',
  'sidebar.menu-item.localizations': 'Localizations',
  'sidebar.menu-item.social-networks': 'Social networks',
  'sidebar.menu-item.summary': 'Dashboard',
  'sidebar.menu-item.battery': 'Battery',
  'sidebar.menu-item.youtube': 'YouTube',
  'sidebar.menu-item.screenview': 'Screen View',
  'sidebar.menu-item.screengrab': 'Screen Grab',
  'sidebar.menu-item.access-time': 'Access Time',

  // Validator
  'phone-number.not-valid': 'Enter valid phone number',
  'input.required-label': 'Field is required',
  'input.select-required-label': 'Please select',
  'email.not-valid-label': 'Enter valid e-mail address',
  'domain-exist-label': 'Domain was added',
  'domain-exist-other-label': 'Domain exist on other list',
  'domain-not-valid': 'Domain is not valid',
  'password-not-match-label': 'Password not match',

  // misc
  'button-buy-label': 'Choose a plan',

  'transactiion-status-0': 'Awaiting',
  'transactiion-status-1': 'Completed',
  'transactiion-status--1': 'Canceled',
  'transactiion-status--2': 'Canceled – refund',
  'transactiion-status--3': 'Awaiting confirmation',
  'transactiion-status--100': 'Started',
  'transactiion-status-other': 'Other',

  // reward

  'reward-page.error-ok': 'OK',
  'reward-page.error-success': 'Success',
  'reward-page.error-already-used': 'This code has already been used',
  'reward-page.error-invalid-code': 'Incorrect code',
  'reward-page.error-connection': 'Connection error',
  'reward-page.additional-bonus': 'Bonus',
  'reward-page.get-additional-bonus': 'Claim promotional coupon',
  'reward-page.get-now': 'Claim',
  'reward-page.lic-was-extended-by-days': 'Your subscription plan has been extended by {rewardDays} days!',
  'reward-page.there-was-an-error': 'Unfortunately, an error has occurred.',
  'reward-page.lic-not-extended': 'Your subscription plan has not been extended.',
  'reward-page.button-close': 'Close',

  // url item
  'url-item.copied': 'Copied to clipboard',
  'url-item.copy-link': 'Copy link',

  // APK howto

  'apk-howto-google-play': 'Google Play Store restricts certain application permission which is needed to utilize this function.',
  'apk-howto-on-the-phone': "On child's phone with {applite} installed",
  'apk-howto-step1': 'Uninstall {applite} if previously downloaded through Google Play',
  'apk-howto-step2': 'Download the app onto the child\'s phone directly through our website',
  'apk-howto-step2B': 'or scan the QR code below on the child\'s phone',  
  'apk-howto-step3': 'Install downloaded app onto device',
  'apk-howto-tutorial': 'A guide on how to install the app on a phone or tablet',

  // Cookies

  'cookies-info-uses': 'This website uses cookies.',
  'cookies-info-privacy-policy': 'More in the privacy policy.',

  // Plan names

  'plan-name-demoExpired':  "Trial expired",
  'plan-name-licExpired':   "Plan expired",
  'plan-name-reward':       "Bonus",
  'plan-name-demo':         "Trial",
  'plan-name-minimal':      "Basic",
  'plan-name-regular':      "Regular",
  'plan-name-premium':      "Premium",

  // agreements

  'agreement-will-inform-user' : "I declare that I will inform the user on whose device the app will be installed that their activity and location will be monitored.",

  'agreement-privacy-policy' : 'I declare that I have read and accepted the <a href="{regulations}" target="_blank">terms</a> and <a href="{privacy}" target="_blank">privacy policy</a>. I certify that I am over 18 years of age and will add to my account only children who are under 18 years old and are my legal dependents.',

  // 'agreement-marketing' : 'I consent to being delivered marketing information by {domain} regarding promotional offers to the e-mail address and phone number provided by me during the registration process. <a href="{marketing}" target="_blank">More</a>'
  'agreement-marketing'    : 'I consent to receive marketing content from {domain} regarding promotional offers to the email address and phone number I provided, as well as forwarding the details to the site\'s advertising partners mentioned in the privacy policy for the purpose of digital advertising. <a href="{marketing}" target="_blank">More</a>',


  'gdpr-page1-title' : "We Value Your Privacy",
  'gdpr-page1-content' : 'Click "ACCEPT ALL" to consent to the use of Cookies for the automatic collection and processing of your personal data by {domain}. We use Cookies to improve browsing quality, display ads or content tailored to individual user needs, and analyze traffic on our website. Clicking the "ACCEPT ALL" button means you consent to our use of Cookies."',
  
  'gdpr-page1-advanced' : 'More Information',
  'gdpr-page1-agree' : 'ACCEPT ALL',

  'gdpr-page2-title' :"More	 Information",
  'gdpr-page2-content' : 'When you browse our website {domain} or {panel}, we may automatically collect data about your activity. This data is collected using Cookies. Cookies are embedded in the browser of your computer or other device while browsing the site. Cookies also enable you to log in to {panel} to change account settings and view the activity of protected Users. Cookies are also used for the personalization and profiling of ads based on your activity on avosmart.com and all its subpages. Some Cookies are essential for the proper functioning of the site, while others are optional. Choose which Cookies you consent to. <a href="{privacy}" target="_blank">More in our privacy policy.</a>',
  
  'gdpr-page2-necessary' : 'Essential Only',
  'gdpr-page2-agree' : 'ACCEPT ALL',  
}

export default {
  locale: 'en-US',
  name: 'English',
  data: appLocalData,
  messages
} as LanguageLocale
