import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import I18nMessages from '../components/I18nMessages'
import Schedule, { DaysOfWeek, DaysOfWeekLabels } from '../components/Schedule'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, Settings } from '../providers/benServiceProvider'
import { InjectedBenNotificationProps, withBenNotification } from '../providers/notificationProvider'
import getMessage from '../language/getMessage'

type Props = InjectedIntlProps
  & InjectedBenAccountProps
  & InjectedBenServiceProps
  & InjectedBenNotificationProps

const SAVE_SETTINGS_DELAY = 1500

const INIT_DAYS_OF_WEEK: DaysOfWeek = {
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
  sun: []
}

function mapSKDAToDayOfWeek (data: Settings): DaysOfWeek {
  return {
    mon: Array.from(data.skda1, v => v === '1'),
    tue: Array.from(data.skda2, v => v === '1'),
    wed: Array.from(data.skda3, v => v === '1'),
    thu: Array.from(data.skda4, v => v === '1'),
    fri: Array.from(data.skda5, v => v === '1'),
    sat: Array.from(data.skda6, v => v === '1'),
    sun: Array.from(data.skda7, v => v === '1'),
  }
}

function mapDayOfWeekToSKDA (data: boolean[]): string {
  return data.map(v => v ? '1' : '0').join('')
}

const AppsSchedule: React.FC<Props> = ({
  benAccount,
  benNotification,
  benService,
  intl
}) => {

  const isMounted = React.useRef(false)
  const timer = React.useRef<NodeJS.Timeout | undefined>(undefined)
  const reRender = React.useState()[1]
  const [daysOfWeek, setDaysOfWeek] = React.useState(INIT_DAYS_OF_WEEK)

  const daysOfWeekLabels: DaysOfWeekLabels = {
    mon: getMessage('monday-short-label', intl),
    tue: getMessage('tuesday-short-label', intl),
    wed: getMessage('wednesday-short-label', intl),
    thu: getMessage('thursday-short-label', intl),
    fri: getMessage('friday-short-label', intl),
    sat: getMessage('saturday-short-label', intl),
    sun: getMessage('sunday-short-label', intl)
  }

  function updateSettings () {
    const { deviceId, profileId } = benAccount.currentProfile

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' )
    {

      benService.saveSettings(profileId, deviceId, {
        skda1: mapDayOfWeekToSKDA(daysOfWeek.mon),
        skda2: mapDayOfWeekToSKDA(daysOfWeek.tue),
        skda3: mapDayOfWeekToSKDA(daysOfWeek.wed),
        skda4: mapDayOfWeekToSKDA(daysOfWeek.thu),
        skda5: mapDayOfWeekToSKDA(daysOfWeek.fri),
        skda6: mapDayOfWeekToSKDA(daysOfWeek.sat),
        skda7: mapDayOfWeekToSKDA(daysOfWeek.sun)
      })

      .then(() => benNotification.notify({
        type: 'success',
        title: getMessage('side-effect.successful-title', intl),
        message: getMessage('side-effect.successful-message', intl)
      }))

      .catch(() => benNotification.notify({
        type: 'error',
        title: getMessage('side-effect.internal-error-title', intl),
        message: getMessage('side-effect.internal-error-message', intl)
      }))

      .finally(() => isMounted.current && reRender(undefined))
    }
  }

  function handleOnScheduleUpdate (dayOfWeek: keyof DaysOfWeek, hour: number, value: boolean) {
    const updatedSchedule = daysOfWeek[dayOfWeek]

    updatedSchedule[hour] = value

    setDaysOfWeek({
      ...daysOfWeek,
      ...updatedSchedule
    })

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => updateSettings(), SAVE_SETTINGS_DELAY)
  }

  React.useEffect(() => {
    const { deviceId, profileId } = benAccount.currentProfile

    isMounted.current = true

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' )
    {
        benService.loadSettings(profileId, deviceId)
          .then(result => isMounted.current && setDaysOfWeek(mapSKDAToDayOfWeek(result.data)))
    }

    return () => {
      isMounted.current = false
    }

  }, [benAccount, benService])

  return (
    <React.Fragment>
      <Schedule
        daysOfWeek={daysOfWeek}
        daysOfWeekLabels={daysOfWeekLabels}
        onUpdate={handleOnScheduleUpdate}
      />
      <div>
        <h6 className="mt-4">
          <I18nMessages id="applications-page.settings.schedule-legend-label" />
        </h6>
        <div className="mb-1">
          <span className="schedule-legend-item align-middle" />
          <span className="align-middle ml-1">
            <I18nMessages id="applications-page.settings.schedule-blocked-label" />
          </span>
        </div>
        <div>
          <span className="schedule-legend-item selected align-middle" />
          <span className="align-middle ml-1">
            <I18nMessages id="applications-page.settings.schedule-available-label" />
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withBenNotification(withBenService(withBenAccount(injectIntl(AppsSchedule))))
