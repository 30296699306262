import React from 'react'
import { Row, Card, CardTitle, Button } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import Colxx from '../components/Colxx'
import I18nMessages from '../components/I18nMessages'

class NotFoundPage extends React.Component {

  componentDidMount () {
    document.body.classList.add('background')
  }

  componentWillUnmount () {
    document.body.classList.remove('background')
  }

  render () {
    return (
      <React.Fragment>
        <div className="fixed-background" />
        <main>
          <div className="container">
            <Row className="h-100">
              <Colxx xxs="12" md="10" className="mx-auto my-auto">
                <Card className="auth-card">

                  <div className="position-relative image-side ">
                    <p className="text-white h2">MAGIC IS IN THE DETAILS</p>
                    <p className="white">Yes, it is indeed!</p>
                  </div>

                  <div className="form-side">
                    <NavLink to={`/`} className="white">
                      <span className="logo-single" />
                    </NavLink>
                    <CardTitle className="mb-4">
                      <I18nMessages id="not-found-page.title" />
                    </CardTitle>
                    <p className="mb-0 text-muted text-small mb-0">
                      <I18nMessages id="not-found-page.error-code-text" />
                    </p>
                    <p className="display-1 font-weight-bold mb-5">404</p>
                    <Button href="/" color="primary" size="lg">
                      <I18nMessages id="not-found-page.go-back-home-button" />
                    </Button>
                  </div>

                </Card>
              </Colxx>
            </Row>
          </div>
        </main>
      </React.Fragment>
    )
  }
}

export default NotFoundPage
