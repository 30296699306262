import React from 'react'
import { Card, CardBody } from 'reactstrap'
import Spinner from '../../../components/Spinner'
import Pagination from '../../../components/Pagination'
import DateRange from '../../../containers/DateRange'
import SMSLog, { SMS } from '../../../containers/SMSLog'
import { InjectedBenAccountProps, withBenAccount } from '../../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, SMSData, ContactData } from '../../../providers/benServiceProvider'
import { subtractDateByDays } from '../../../lib'
import { shouldDisplaySmsScreensAnyway } from '../../../lib/utils'
import ApkHowto from '../ApkHowto';

type Props = InjectedBenAccountProps & InjectedBenServiceProps

const ITEMS_PER_PAGE = 10

const SMSListPage: React.FC<Props> = ({
  benAccount,
  benService
}) => {

  const [dateFrom, setDateFrom] = React.useState(subtractDateByDays( new Date() , 7))
  const [dateTo, setDateTo] = React.useState( new Date() )
  const [smsList, setSMSList] = React.useState<SMSData[]>([])
  const [contacts, setContacts] = React.useState<ContactData[]>([])
  const [isLoading, setLoading] = React.useState(true)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)

  function handleDateRangeDateFromChange (date: Date | null) {
    if (date !== null) setDateFrom(date)
  }

  function handleDateRangeDateToChange (date: Date | null) {
    if (date !== null) setDateTo(date)
  }

  function mapSMSDataToSMS (data: SMSData[]): SMS[] {
    return data.map<SMS>(item => ({
      date: new Date(item.ts * 1000),
      id: String(item.msgId),
      message: item.body,
      phoneNo: item.phoneNo,
      type: item.msgType === 2 ? 'outgoing' : 'incoming'
    }))
  }


  React.useEffect( () => {

    const { deviceId, profileId } = benAccount.currentProfile
    let isMounted = true

    if( shouldDisplaySmsScreensAnyway(benAccount) )
      return

    if (profileId !== null && deviceId !== null) {

      benService.getContacts(profileId, deviceId, 0, 9999)
      .then( result => {
        if (isMounted) {
          setContacts(result.data)
        }
      })
    }

    return () => {
      isMounted = false
    }

  }, [benAccount, benService] )



  React.useEffect(() => {
    const { deviceId, profileId } = benAccount.currentProfile
    const offset = (currentPage - 1) * ITEMS_PER_PAGE
    let isMounted = true

    if( shouldDisplaySmsScreensAnyway(benAccount) )
      return

    if ( deviceId !== null && profileId !== null && deviceId !== '' && profileId !== '' ) {
      setLoading(true)

      benService.getSMSList(profileId, deviceId, dateFrom, dateTo, offset, ITEMS_PER_PAGE)
      .then(result => {
        if (isMounted) {
          setSMSList(result.data)
          setTotalPages(Math.ceil(result.total / ITEMS_PER_PAGE))
        }
      })
      .then(() => isMounted && setLoading(false))
    }

    return () => {
      isMounted = false
    }
  }, [benAccount, benService, currentPage, dateFrom, dateTo])



  if( shouldDisplaySmsScreensAnyway(benAccount) )
  {
    return (
      <ApkHowto labelMessageId={null}/>
    )
  }


  return (
    <React.Fragment>
      <DateRange
        className="mb-4"
        dateFrom={dateFrom}
        dateTo={dateTo}
        onDateFromChanged={handleDateRangeDateFromChange}
        onDateToChanged={handleDateRangeDateToChange}
      />

      {isLoading && (
        <Spinner />
      )}

      {!isLoading && (
        <Card>
          <CardBody>
            <SMSLog data={mapSMSDataToSMS(smsList)} contacts={contacts} />
            <Pagination
              visibleEmpty
              currentPage={currentPage}
              pageCount={totalPages}
              onChangePage={setCurrentPage}
            />
          </CardBody>
        </Card>
      )}

    </React.Fragment>
  )
}

export default withBenAccount(withBenService(SMSListPage))
