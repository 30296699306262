/*global google*/
import React from 'react'
import {
  Marker,
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
  Polyline,
  WithGoogleMapProps,
  WithScriptjsProps

} from 'react-google-maps'
import Spinner from '../components/Spinner'
import LocalizationHint from '../components/LocalizationHint'
import I18nMessage from '../components/I18nMessages'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { gMapCircleDefault, gMapPolylineDefault, gMapMarkerLongStay, gMapCircleLongStay } from '../lib'


import { GPSData, GPSDataEx } from '../providers/benServiceProvider'

import GrayMarkerSrc from '../assets/media/maps-marker-gray.png'
import { describeTimeElapsedHM, formatHourHHMM } from '../lib/utils'
//import DrawingManager from 'react-google-maps/lib/components/drawing/DrawingManager'
import SearchBox from 'react-google-maps/lib/components/places/SearchBox'

// https://stackoverflow.com/questions/55468502/difference-between-google-map-react-and-react-google-maps

// https://github.com/tomchentw/react-google-maps/issues/986

// https://github.com/JustFly1984/react-google-maps-api/tree/master/packages/react-google-maps-api
// https://github.com/google-map-react/google-map-react

// https://github.com/google-map-react/google-map-react/issues/687


export class GPSDataWithDate implements GPSData
{
  constructor(public lat:number, public lng: number, public ts: number, public v: number, public a: number )
  {
    this.date = new Date(ts * 1000);
  }

  date: Date
}

export class GPSDataExWithDate implements GPSDataEx
{
  constructor(public ts1: number, public ts2: number, public idx1: number, public idx2: number,
              public elapsed: number, public lat: number, public lng: number, public latLen: number, public lngLen: number)

  {
    this.date1 = new Date(ts1);
    this.date2 = new Date(ts2);
  }

  date1: Date
  date2: Date
}


type CalculatedMinMaxCoords = {
  maxLat: number
  maxLng: number
  minLat: number
  minLng: number
}

type Hint = {
  visible:boolean
  x: number
  y: number
  label: string
}

type Props = WithGoogleMapProps & WithScriptjsProps & InjectedIntlProps & {
  data: GPSDataWithDate[]
  dataEx: GPSDataExWithDate[]
  hoursFilter: [number, number]
  showLoading: boolean
  isCurrentLocalization: boolean
}

type Point = {
  x: number
  y: number
}

function calculateMinMaxCoordinates (data: GPSData[]): CalculatedMinMaxCoords {
  if (data.length === 0) {
    return { maxLat: 0, maxLng: 0, minLat: 0, minLng: 0 }
  }

  var mm : CalculatedMinMaxCoords = {
    maxLat: data[0].lat,
    maxLng: data[0].lng,
    minLat: data[0].lat,
    minLng: data[0].lng
  }

  data.forEach(e => {
    if( mm.maxLat<e.lat ) mm.maxLat=e.lat
    if( mm.maxLng<e.lng ) mm.maxLng=e.lng
    if( mm.minLat>e.lat ) mm.minLat=e.lat
    if( mm.minLng>e.lng ) mm.minLng=e.lng
  });

  return mm
}

const LocalizationMap: React.FC<Props> = ({
  data,
  dataEx,
  showLoading,
  hoursFilter,
  isCurrentLocalization,
  intl
}) => {

  const gMapRef = React.useRef<GoogleMap>(null)
  const gSearchBoxRef = React.useRef<SearchBox>(null)

  const [hint, setHint] = React.useState<Hint>( { visible:false, x:0, y:0, label:'' } )

  const filteredData = data.filter(item => {

    const hours = item.date.getHours()

    if (hours >= hoursFilter[0] && hours <= hoursFilter[1]) {
       return true
    }

    return false
  })


  function getRadiusStay (item: GPSDataEx): number {

    let r = 50 + (item.lat + item.lngLen) * 0.2;

    if( item.elapsed > 60  ) r += 5;
    if( item.elapsed > 180 ) r += 5;
    if( item.elapsed > 360 ) r += 5;

    return r;
  }


  function extractPoint(event: google.maps.MouseEvent) : Point
  {
    for(let key in event)
    {
      let e : any = event as any
      let o : any = e[key] as any

      if( o instanceof MouseEvent)
      {
        return { x:o.x, y:o.y }
      }
    }

    return { x:0, y:0 }
  }


  function handleOnMouseOverLongStay(event: google.maps.MouseEvent, item : GPSDataEx)
  {
    let d1 = new Date(item.ts1*1000);
    let d2 = new Date(item.ts2*1000);
    let point = extractPoint(event)

    let label = formatHourHHMM(d2) + ' - ' + formatHourHHMM(d1) + '\n' + describeTimeElapsedHM(item.elapsed, intl);

    setHint({ x: point.x, y: point.y, label: label, visible:true })
}

  function handleOnClickLongStay(event:  google.maps.MouseEvent, item : GPSDataEx)
  {
    let d1 = new Date(item.ts1*1000);
    let d2 = new Date(item.ts2*1000);
    let point = extractPoint(event)

    let label = formatHourHHMM(d2) + ' - ' + formatHourHHMM(d1) + '\n' + describeTimeElapsedHM(item.elapsed, intl);

    setHint({ x: point.x, y: point.y, label: label, visible:true })

    event.stop()
  }


  function handleOnMouseOverCircle(event: google.maps.MouseEvent, date:number)
  {
    //if(gMapRef.current)
    //{
      // let point = gMapRef.current.getProjection().from(event.latLng)
      // console.log(event.latLng.toString())
      // console.log(point.toString())
    //}

    let point = extractPoint(event)

    let d = new Date(date*1000);
    setHint({ x: point.x, y: point.y, label: d.toLocaleTimeString(), visible:true })

  }


  function handleOnClickCircle(event: google.maps.MouseEvent, date: number)
  {
    let d = new Date(date*1000);
    let point = extractPoint(event)

    setHint({ x: point.x, y: point.y, label: d.toLocaleTimeString(), visible:true  })

    event.stop()
  }

  function handleOnMouseOverGrayMarker(event: google.maps.MouseEvent, date: number)
  {
    let d = new Date(date*1000);
    let point = extractPoint(event)
    setHint({ x: point.x, y: point.y, label: d.toLocaleString(), visible:true  })
  }

  function handleOnClickGrayMarker (event: google.maps.MouseEvent, date: number)
  {
    let d = new Date(date*1000);
    let point = extractPoint(event)
    setHint({ x: point.x, y: point.y, label: d.toLocaleString(), visible:true })

    event.stop()
  }

  function hideHint () {
    setHint( {...hint, visible:false} )
  }

  function getMarkerGrayIcon (): google.maps.Icon | undefined {
    if (gMapRef.current) {
      return {
        url: GrayMarkerSrc,
        scaledSize: new (window as any).google.maps.Size(35, 35)
      }
    }

    return
  }

  function onPlacesChanged()
  {}

  React.useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    if (timer) {
      clearTimeout(timer)
    }

    if (gMapRef.current !== null && data.length !== 0 && 'google' in window) {
      const minMaxCoords = calculateMinMaxCoordinates(data)
      const bounds: google.maps.LatLngBounds = new (window as any).google.maps.LatLngBounds(
        { lat: (minMaxCoords.minLat - 0.001), lng: minMaxCoords.minLng - 0.001 },
        { lat: (minMaxCoords.maxLat + 0.001), lng: minMaxCoords.maxLng + 0.001 }
      )

      // Ugly hack fro gMap performance
      timer = setTimeout(() => gMapRef.current && gMapRef.current.fitBounds(bounds), 200)
    }
  }, [data])

  const lastFilteredItem = filteredData.length > 0 && filteredData[0]

  return (
    <React.Fragment>
        <GoogleMap
          ref={gMapRef}
          defaultCenter={{ lat: 50.0646501, lng: 19.9449799 }}
          defaultZoom={10}
          onClick={ () => hideHint() }
          >

        {/* <DrawingManager
          defaultDrawingMode={google.maps.drawing.OverlayType.CIRCLE}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: 2,
              drawingModes: [
                google.maps.drawing.OverlayType.CIRCLE,
                google.maps.drawing.OverlayType.POLYGON,
                google.maps.drawing.OverlayType.POLYLINE,
                google.maps.drawing.OverlayType.RECTANGLE,
              ],
            },
            circleOptions: {
              fillColor: `#ffff00`,
              fillOpacity: 1,
              strokeWeight: 5,
              clickable: false,
              editable: true,
              zIndex: 1,
            },
          }}
        /> */}

        <SearchBox
          controlPosition={google.maps.ControlPosition.TOP_CENTER}
          ref={gSearchBoxRef}
          onPlacesChanged={onPlacesChanged}>
            <LocalizationHint visible={hint.visible} label={hint.label} x={hint.x} y={hint.y} />
        </SearchBox>

        {/* <LocalizationHint visible={hint.visible} label={hint.label} x={hint.x} y={hint.y+50} /> */}

        {/* OK */}
        {showLoading && (
          <div className="map-overlay d-flex justify-content-center">
            <div className="align-self-center">
              <Spinner />
            </div>
        </div>
        )}
        {/* OK */}
        {!showLoading && filteredData.length === 0 && (
          <div className="map-overlay d-flex justify-content-center">
            <div className="align-self-center display-4">
              <I18nMessage id="localization-page.map-no-data" tagName="strong" />
            </div>
          </div>
        )}

        {isCurrentLocalization && filteredData.map((item, i) => (
          <React.Fragment key={`${i}`}>
            {/* OK*/}
            <Circle
              center={{ lat: item.lat, lng: item.lng }}
              options={gMapCircleDefault}
              // onMouseOver={({ ya }: any) => handleOnMouseOverCircle(ya, item.ts)}
              onMouseOver={ (e) => handleOnMouseOverCircle(e, item.ts) }
              onClick={ (e) => handleOnClickCircle(e, item.ts) }
              onMouseOut={hideHint}
            />

            {/* OK */}
            {(i===0 && isCurrentLocalization && (hoursFilter[0] === 0 && hoursFilter[1] === 23)) && (
              <Marker position={{ lat: item.lat, lng: item.lng }} />
            )}
          </React.Fragment>
        ))}

        {/*  OK */}
        {isCurrentLocalization && dataEx.map((item, i) => (

          <React.Fragment key={`${i}ex`}>

            <Circle
              options={gMapCircleLongStay}
              center={{ lat: item.lat, lng: item.lng }}
              radius={getRadiusStay(item)}
              //onMouseOver={({ ya }: any) => handleOnMouseOverLongStay(ya, item)}
              onMouseOver={(e) => handleOnMouseOverLongStay(e, item)}
              onClick={ (e) => handleOnClickLongStay(e, item) }
              onMouseOut={hideHint}
            />

            <Marker
              options={gMapMarkerLongStay}
              position={{ lat: item.lat, lng: item.lng }}
              onMouseOver={(e) => handleOnMouseOverLongStay(e, item)}
              onClick={ (e) => handleOnClickLongStay(e, item) }
              onMouseOut={hideHint}
            />

          </React.Fragment>
        ))}

        {/* OK */}
        {(!isCurrentLocalization && lastFilteredItem) && (
          <Marker
            icon={getMarkerGrayIcon()}
            position={{ lat: lastFilteredItem.lat, lng: lastFilteredItem.lng }}
            //onMouseOver={({ ya }: any) => handleOnMouseOverGrayMarker(ya, lastFilteredItem.ts)}
            onMouseOver={(e) => handleOnMouseOverGrayMarker(e, lastFilteredItem.ts)}
            onClick={(e) => handleOnClickGrayMarker(e, lastFilteredItem.ts)}
            onMouseOut={hideHint}
          />
        )}

        <Polyline
          path={filteredData}
          options={gMapPolylineDefault}
        />
      </GoogleMap>
    </React.Fragment>
  )
}

export default injectIntl(withScriptjs(withGoogleMap(LocalizationMap)))
