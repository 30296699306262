import React from 'react'
import { connect } from 'formik'
import Select from 'react-select'

const SelectField: React.FC<any> = ({
  field,
  formik,
  ...props
}) => {

  const defaultValue = props.options.find((item: { label: string, value: number }) => item.value === field.value)

  function handleOnChange (value: { label: string, value: string }) {
    formik.setFieldValue(field.name, value.value)
  }

  return (
    <Select
      {...props}
      name={field.name}
      defaultValue={defaultValue}
      className="react-select"
      classNamePrefix="react-select"
      onChange={handleOnChange}
    />
  )
}

export default connect(SelectField)
