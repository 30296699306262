import React from 'react'
import { Redirect, RouteProps, Route, RouteComponentProps } from 'react-router-dom'

export interface RestrictedRouteProps<T = {}> extends RouteProps {
  component: React.ComponentType<RouteComponentProps<T>> | React.ComponentType<T>
  isAuthorized: boolean
  redirectToPath?: string
}

const RestrictedRoute = ({
  component: Component,
  isAuthorized,
  redirectToPath = '/login',
  ...routeProps
}: RestrictedRouteProps) => {

  const render = (renderProps: RouteComponentProps) => {
    if (isAuthorized) {
      return <Component {...renderProps} />
    }

    return <Redirect
      to={{ pathname: redirectToPath, state: { from: renderProps.location } }}
    />
  }

  return (
    <Route {...routeProps} render={render} />
  )
}

export default RestrictedRoute
