import React from 'react'
import { FormikProps, connect } from 'formik'
import { CustomInput } from 'reactstrap'

type Props<T = any> = {
  field: any
  formik: FormikProps<T>
}

const CheckboxField: React.FC<any> = ({
  field,
  formik,
  ...props
}) => {

  const checked: boolean = formik.values[field.name]

  function handleOnClick (isChecked: boolean) {
    formik.setFieldValue(field.name, isChecked)
  }

  return (
    <CustomInput
      {...props}
      name={field.name}
      type="checkbox"
      checked={checked}
      onChange={() => handleOnClick(!checked)}
    />
  )
}

export default connect(CheckboxField)
