import React from 'react'
import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'

type Props = {
  filter: string
  onSearchChanged: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AppsFilter: React.FC<Props> = ({
  filter,
  onSearchChanged,
}) => (

  <Row>
    <Col sm="6">
      <FormGroup row className="align-items-center">
        <Label sm="3" style={{flexWrap: "nowrap"}}>
          <I18nMessages id="filter-input.label" />
        </Label>
        <Col sm="9">
          <Input value={filter} onChange={onSearchChanged} />
        </Col>
      </FormGroup>
    </Col>
  </Row>
)

export default AppsFilter
