import React from 'react'
import { Modal, ModalHeader, ModalBody,  Card,  CardBody, Button, CustomInput } from 'reactstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { InjectedI18nProviderProps, withI18nProvider } from '../../providers/i18nProvider'
import { InjectedBenAccountProps, withBenAccount } from '../../providers/benAccountProvider'
import { InjectedBenServiceProps, withBenService, SettingsGlobal } from '../../providers/benServiceProvider'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import I18nMessage from '../../components/I18nMessages'
import { InjectedBenHelperProps, withBenHelper } from '../../providers/benHelperProvider'
import { getBuildConfigStr } from '../../lib/utils'
import TimezoneSelect from 'react-timezone-select'


type Props = InjectedBenHelperProps &
            InjectedI18nProviderProps & 
            InjectedBenAccountProps & 
            InjectedBenServiceProps & 
            RouteComponentProps &
            InjectedIntlProps &
{
    isOpen: boolean
    onToggle: () => void
}


const AccountSettingsPage: React.FC<Props> = ({
    isOpen,
    onToggle,
    benHelper,
    benService,
    i18nProvider,
    history,
    intl
}) => {

    const [settings, setSettings] = React.useState<SettingsGlobal | null >(null)
    const [isLoading, setLoading] = React.useState<boolean> (false)

    const [automaticUpdates, setAutomaticUpdates] = React.useState<boolean> (false)
    const [utcOffset, setUtcOffset] = React.useState<Number> (0)
    const [timeZoneName, setTimeZoneName] = React.useState<string> ( getCurrentTZ() )
    const [sendReports, setSendReports] = React.useState<boolean> (false)

    React.useEffect(() => {

        let isMounted = true

        setLoading(true)

        benService.loadGlobalSettings()
        .then(result => { 
            setSettings(result.data) 

            setAutomaticUpdates(result.data.automaticUpdates)
            setUtcOffset(result.data.utc)
            setTimeZoneName( result.data.timeZoneName )
            setSendReports(result.data.sendReports)
        } )
        .finally(() => {
            if (isMounted) 
                setLoading(false)
        })
            

        return () => {
            isMounted = false
        }

    }, [benService, intl] )
    

    function onSaveAutomaticUpdates(checked: boolean)
    {
        if( !settings)
            return

        setAutomaticUpdates(checked)

        benService.saveGlobalSettings( { automaticUpdates: checked } )
        .then( result => {

        })
        .catch( () => {

        })
    }    


    function getCurrentTZ() : string
    {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }


    function onSaveTimezoneSelect(timezone:any)
    {
        const tzName = timezone.value
        const tzOffset = timezone.offset * 3600

        // console.log('tzName   ' + tzName)
        // console.log('tzOffset ' + tzOffset)

        setTimeZoneName(tzName)
        setUtcOffset(tzOffset)

        benService.saveGlobalSettings( { utc: tzOffset, timeZoneName: tzName } )
        .then( result => {

        })
        .catch( () => {
            
        })
    }


    function onSaveSendReports(enabled: boolean)
    {
        if( !settings)
            return

        setSendReports(enabled)

        benService.saveGlobalSettings( { sendReports:enabled } )
        .then( result => {

        })
        .catch( () => {
            
        })
    }


    return (

        <Modal isOpen={isOpen} toggle={onToggle} size="lg">
            <ModalHeader>
                <Button color="light" size="xs" onClick={onToggle} className="button-x" >
                    X
                </Button>

                <div className="modal-large-title">
                    <I18nMessage id="account-settings-page.title" />
                </div>
            </ModalHeader>

            <ModalBody>

                <Card body className="shadow " >
               
                    <CardBody>

                        <div >
                            <CustomInput
                                id="automaticUpdatesChk"
                                name="automaticUpdatesChk"
                                disabled={isLoading}
                                type="checkbox"
                                label={ intl.formatMessage({ id: 'account-settings-page.automatic-update' }, { app:getBuildConfigStr('NAME') } ) }
                                checked={automaticUpdates}
                                onChange={v => onSaveAutomaticUpdates(v.target.checked)}
                            />
                        </div>

                        <div >
                            <CustomInput
                                id="sendReportsChk"
                                name="sendReportsChk"
                                disabled={isLoading}
                                type="checkbox"
                                label={<I18nMessage id="account-settings-page.send-reports" />}
                                checked={sendReports}
                                onChange={v => onSaveSendReports(v.target.checked)}
                            />
                        </div>

                        <div style={{marginTop:'20px'}}>
                            <I18nMessage id="account-settings-page.timezone" />

                            <TimezoneSelect
                                value={timeZoneName} 
                                onChange={onSaveTimezoneSelect}                            
                                />
                        </div>
                    
                    </CardBody>

                    <Button onClick={onToggle}  color="light">
                        <I18nMessage id="plans-info-page.btn-close" />
                    </Button>

                </Card>
                
            </ModalBody>
        </Modal>
    )
}

export default withBenHelper( withRouter( withBenService(withBenAccount(injectIntl( withI18nProvider(AccountSettingsPage))))) )