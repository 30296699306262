import React from 'react'
import { Formik, Form, Field } from 'formik'
import { Label, FormGroup, Col } from 'reactstrap'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import SelectField from './SelectField'
import I18nMessages from '../components/I18nMessages'
import getMessage from '../language/getMessage'
import { validateNotEmpty } from '../lib/validators'

type ProfileMoveDeviceFormProps = InjectedIntlProps & {
  profileId: any[]
  defaultDeviceId: string | null
  onSubmit: (profileId: string) => void
}

const ProfileMoveDeviceForm: React.FC<ProfileMoveDeviceFormProps> = ({
  intl,
  children,
  profileId,
  onSubmit
}) => {

  const initValues = {
    profileId: ''
  }

  function handleOnFormikSubmit (values: { profileId: string}) {
    onSubmit(values.profileId)
  }

  return (
    <Formik initialValues={initValues} onSubmit={handleOnFormikSubmit}>
      {({ errors, touched }) => (
        <Form className="av-tooltip tooltip-label-bottom">
          <FormGroup row >
            <Col sm="12">
              <Label className="form-group has-float-label mb-4">
                <I18nMessages id="profile-move-device-modal.select-profile-label" />
                <Field
                  name="profileId"
                  component={SelectField}
                  noOptionsMessage={() => getMessage('select-input.no-options-label', intl)}
                  options={profileId}
                  placeholder=""
                  validate={(v: number) => validateNotEmpty(v, intl)}
                />
                {(errors.profileId && touched.profileId) && (
                  <div className="invalid-feedback d-block">{errors.profileId}</div>
                )}
              </Label>
            </Col>
          </FormGroup>

          <div className="form-bottom">
            {children}
          </div>
        </Form>
      )}
    </Formik>
  )

}

export default injectIntl(ProfileMoveDeviceForm)
