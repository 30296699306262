import React from 'react'
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl'
import { MessageKey } from '../language'

interface Props extends FormattedMessage.Props {
  id: MessageKey
}

const I18nMessages = (props: Props) => (
  <FormattedMessage {...props} />
)

export function getI18nMessage (id: MessageKey, intlProps: InjectedIntl): string {
  return intlProps.messages[id]
}

export default injectIntl(I18nMessages, {
  withRef: false
})
