import React from 'react'
import Overlay from '../components/Overlay'
import { Button } from 'reactstrap'
import I18nMessages from '../components/I18nMessages'
import { InjectedBenAccountProps, withBenAccount } from '../providers/benAccountProvider'
import { AccountType } from '../providers/benServiceProvider'
import { RouteComponentProps, withRouter } from 'react-router-dom'

type Props = InjectedBenAccountProps & RouteComponentProps

const LicenseOverlay: React.FC<Props> = ({
  benAccount,
  children,
  history
}) => {

  function handleOnPurchaseClick () {
    history.push('/account/purchase')
  }

  const LicenseI18n = () => {

    if (benAccount.accountType === AccountType.demoExpired)
      return (
        <div className="cart-purchase-expired-alert">
          <I18nMessages id="panel-page.purchase-expired-alert" />
          <Button className="ml-2" size="xs" color="primary" onClick={handleOnPurchaseClick}>
              <I18nMessages id="button-buy-label" />
          </Button>
        </div>
      )
    else
      return (
        <div>
          <I18nMessages id="panel-page.purchase-lic-expired-alert" />
          <Button className="ml-2" size="xs" color="primary" onClick={handleOnPurchaseClick}>
              <I18nMessages id="button-buy-label" />
          </Button>
        </div>
      )
  }

  if (!benAccount.accountType || benAccount.accountType === AccountType.demoExpired || benAccount.accountType === AccountType.licExpired) {
    return (
      <Overlay blurred contentComponent={LicenseI18n()}>
        {children}
      </Overlay>
    )
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}

export default withRouter(withBenAccount(LicenseOverlay))
