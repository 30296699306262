import React from 'react'
import { Card, CardBody } from 'reactstrap'

interface Overlay {
  blurred?: boolean
  contentComponent?: React.ReactNode
  children?: React.ReactNode
  borderStyle?: string
  disablePositionRelative?: boolean
}

const Overlay = ({
  blurred,
  contentComponent,
  children,
  borderStyle = '',
  disablePositionRelative = false
}: Overlay) => {

  if (blurred) {
    return (
      <div style={{position: disablePositionRelative ? 'static' : 'relative' }}>
        <div className="absolute-overlay" style={{ filter: 'blur(10px)', zIndex: 1 }} />
        <div className="email-confirmation-content absolute-overlay d-flex align-items-center justify-content-center" style={{ zIndex: 2, position: disablePositionRelative ? 'absolute' : 'relative' }}>
          { contentComponent && (
            <Card style={{border: borderStyle }}>
              <CardBody>{contentComponent}</CardBody>
            </Card>
          )}
        </div>

        <div style={{ filter: 'blur(8px)' }}>{children}</div>
      </div>
    )
  }

  return <React.Fragment>{children}</React.Fragment>
}

export default Overlay
